/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"

const SubMenu = Menu.SubMenu;

type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    history: History,
    baseUrl: string,
    data: Object
};

type State = {
    menuTag: any,
    menuItemsTop: Array<Object>,
    menuItemsDrop: Array<Object>
};

class TaxesMenu extends React.Component<Props, State> {
    myHistory: History;
    baseUrl: string;

    constructor(props: Props) {
        super(props);
        this.myHistory = this.props.history;
        this.baseUrl = this.props.baseUrl;

        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }

        this.state = {
            menuTag: (<div> </div>),
            menuItemsTop: [
                { KeyComp: "122", key: "open-format", text: "ממשק פתוח", keyLang: 10212, icon: "audit" },
                { KeyComp: "151", key: "movein-format", text: "ממשק פ. יומן לחשבשבת", keyLang: 12808, icon: "audit" },
                { KeyComp: "159", key: "loadfnc-format", text: "דיווחים להנה\"ח", keyLang: 18357, icon: "audit" },
                { KeyComp: "152", key: "movein-settings", text: "הגדרות ממשק לחשבשבת", keyLang: 12809, icon: "audit" },
                { KeyComp: "153", key: "hilan-report", text: "קובץ נוכחות לחילן", keyLang: 15720, icon: "slack" },
                { KeyComp: "154", key: "heshin-file", text: "דיווחים להנה\"ח", keyLang: 15936, icon: "audit" },
                { KeyComp: "155", key: "debt-eshcol", text: "קובץ חיובים אשכול", keyLang: 16282, icon: "file" },
                { KeyComp: "156", key: "paradigma", text: "קובץ חיובים פרדיגמה", keyLang: 16598, icon: "file-excel" },
                { KeyComp: "157", key: "export-sales", text: "יצוא מכירות לאקסל", keyLang: 17011, icon: "export" },
                { KeyComp: "158", key: "deposit-docs", text: "הפקדות", keyLang: 18202, icon: "dollar" },
                { KeyComp: "160", key: "aging-credit-supp", text: "גיול חובות לספקים", keyLang: 18883, icon: "sliders" },
                { KeyComp: "261", key: "advances-setting", text: "הגדרות דוח מקדמות", keyLang: 19259, icon: "setting" },
                { KeyComp: "262", key: "tax-advances-report", text: "דוח מקדמות מס", keyLang: 19269, icon: "file" },
                { KeyComp: "263", key: "imovein", text: "יצירת IMOVEIN", keyLang: 19599, icon: "audit" },
                

             ].filter((menuItem) => this.isActive(menuItem.KeyComp)),
            menuItemsDrop: []
        };
        window.console.log(this.state.menuItemsTop);
        window.console.log(this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code));
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    onMenuSelect(args) {
        this.myHistory.push(
            this.baseUrl + "/taxes/" + (args.key !== "home" ? args.key : ""),
            {}
        );
    }

    updatePriorityMenu = () => {
        let maxHeight = 50; //px
        let _height = -1;
        let firstIter = true;

        let listTop = this.state.menuItemsTop;
        let listDrop = this.state.menuItemsDrop;
        // re-populate menu
        for (let i = 0; i < listDrop.length; i++) {
            listTop.push(listDrop.pop());
        }

        //ReactDOM.findDOMNode(this).clientHeight;
        while (_height > maxHeight || _height < 0) {
            //this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            if (!firstIter) {
                this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            }
            else {
                firstIter = false;
            }
            this.setState(() => {
                return {
                    menuTag: <Menu
                        mode="horizontal"
                        defaultSelectedKeys={[
                            this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
                        ]}
                        onSelect={this.onMenuSelect.bind(this)}
                    >
                        {this.renderMenuItemsTop()}
                        <SubMenu title={<Badge count={listDrop.length}>
                            <span><Icon type="ellipsis" /></span></Badge>}>
                            {this.renderMenuItemsDrop()}
                        </SubMenu>
                    </Menu>
                }
            });
            console.log("findDomNode taxes: ", (this) ? "true" : "false", this);
            let e: Element | null | Text = ReactDOM.findDOMNode(this);
            _height = (e instanceof HTMLElement) ? e.clientHeight : 0;
            //alert(_height);
        }
    };

    renderMenuItemsTop() {
        let res = [];
        let list = this.state.menuItemsTop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
            return res;
        }
    }

    renderMenuItemsDrop() {
        let res = [];
        let list = this.state.menuItemsDrop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
        }
        return res;
    }

    doUpdateOfPriorityMenu = () => {
        setTimeout(this.updatePriorityMenu);
    };

    // Add event listener
    componentDidMount() {
        setTimeout(this.updatePriorityMenu);
        //window.addEventListener("resize", this.updatePriorityMenu);
        window.addEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    // Remove event listener
    componentWillUnmount() {
        //window.removeEventListener("resize", this.updatePriorityMenu);
        window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    render() {
        return this.state.menuTag;
    }
}

export default withRouter(TaxesMenu);
