
import React from "react";
import {
    Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm,
    Tooltip, Modal, Table, Select, Row, Col, Checkbox, message
} from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import Jax from "../Jax/jax.es6.module";

import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import moment from "moment";
import MatrixGrid from "./MatrixGrid";
import MatrixBarcodeTable from "./MatrixBarcodeTable";
import LangContext from "../contextProvider/LangContext";
import RecyclingBin from "./RecyclingBin";


const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

// const codeInvalid = () => {
//     message.error('קוד הפריט כבר קיים במסד הנתונים');
// };

//type State = {}
class ModalMatrix extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            lastSave: Date.now(),
        }

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        this.state.matrixGridMode = true;
        this.state.id = null;
        this.state._PriceList = undefined;
        this.state.manualBarcode2Reception = false;
        this.state.modalSonsTriggerOpen = false;

        this.id = 'MODAL_MATRIX';
        this.id_meta = 'META_MATRIX';
        this.id_edit = 'MATRIX_GRID_EDIT';

        this.id_item = 'ITEM_CREATE_EDIT';
        this.id_priceList = 'ITEM_PRICE_LIST_CREATE_EDIT';
        this.id_suppliers = 'ITEM_SUPPLIERS_CREATE_EDIT';
        this.id_tags = "ITEM_TAGS_CREATE_EDIT";
        this.recycling_id = "MATRIX_SON_ITEMS_DELETION_RECYCLING";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.id_item] === undefined) { props.data[this.id_item] = { ...props.data.genericData }; }
        if (props.data[this.id_meta] === undefined) { props.data[this.id_meta] = { ...props.data.genericData }; }
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericSelectorPrices] === undefined) {
            props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_father_item",
            //create_edit: "create_edit_item",
            //create: "create_item",
            edit: "edit_item",

            //get_priceList: "get_prices_by_item",
            //get_suppliers: "get_suppliers_by_item",
            //get_tags: "get_tags_by_item_with_headers",

            selector_price_list: "get_prices",
            create: "add_to_matrix_grid",
            get_meta: "get_matrix_meta",
            overrideUPC: "set_UPC",
            get_recycling: "get_matrix_son_items_deletion_recycling",
            restoration: "restoration_items",
            restoration_m: "restoration_items_multi",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.genericActionButtons = []
        this.dataSource = [];

        this.UPCButtons = [
            { type: "UPC1", hint: "[2 קוד מידה][2 קוד צבע][7 קוד פריט]" },
            { type: "UPC2", hint: "[3 קוד מידה][3 קוד צבע][5 קוד פריט]" },
            { type: "UPC3", hint: "[3 קוד מידה][8 קוד פריט]" },
            { type: "EAN1", hint: "[2 קוד מידה][2 קוד צבע][8 קוד פריט]" },
            { type: "EAN2", hint: "[3 קוד מידה][3 קוד צבע][6 קוד פריט]" },
            // דניאל ביקש להוריד בתאריך 11.10.2023
            // { type: "EAN3", hint: "[3 קוד מידה][9 קוד פריט]" },
            { type: "Code128", hint: "[3 קוד מידה][3 קוד צבע][6 קוד פריט]" },
            // דניאל ביקש להוסיף בתאריך 12.10.2023
            { type: "Code128-2", hint: "[3 קוד צבע][3 קוד מידה][6 קוד פריט]" },
        ]
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    refreshData = () => {

        this.props.dataActions.genericDataGetEditWithParams(
            "MATRIX_GRID", this.props.user.companyCode, this.props.user.token, { _id: this.state.id }, "get_matrix_grid");

        this.props.dataActions.genericDataGetEditWithParams(
            "MATRIX_GRID_EDIT", this.props.user.companyCode, this.props.user.token, { _id: -1 }, "get_matrix_grid");

        this.props.dataActions.genericDataGetRequested(
            "MATRIX_BARCODE", this.props.user.companyCode, this.props.user.token, "get_barcode_table")

    }

    componentDidMount() {
        // document.title = "Cash On Tab - מטריצה";
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector_price_list);

        /*
        this.props.dataActions.genericDataGetEditWithParams(
            this.id_meta,
            this.props.user.companyCode,
            this.props.user.token,
            { _id: this.props.ui.data, mItemCode: this.props.ui.data },
            this.api.get_meta
        );
        */
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalMatrix);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalMatrix);
        this.setState({ manualBarcode2Reception: false })
    }
    handleClear = () => {
        //this.clear();
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();
        //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
        if (this.isNameValid() === 'success') {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEdit,
                args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
            });
            //this.props.ActionQueue.addToQueue({
            //    action: this.props.dataActions.genericDataGetRequested,
            //    args: ["SUPPLIERS_MAIN", this.props.user.companyCode, this.props.user.token, "get_suppliers_table_list"]
            ///});
            this.props.uiActions.hideModal(TableIDs.modalMatrix);
        }
        else {
            message.warning(this.getText(10583));
        }
    }

    isNameValid = () => {
        const name = this.props.data[this.id].editedRecord['mName'];
        return (name === '' || name === null) ? 'error' : 'success';
    }

    createUPCButton = (type, hint) => {
        return (<Tooltip title={<div><div>{this.getText(10592)}</div><div>{hint}</div></div>}>
            <Popconfirm placement="bottom" title={this.getText(10594)}
                cancelText={this.getText(10595)} okText={this.getText(10596)} onConfirm={
                    () => {
                        this.props.dataActions.genericDataSaveEditWithParams(
                            "MATRIX_BARCODE", this.props.user.companyCode, this.props.user.token,
                            this.api.overrideUPC, "get_barcode_table",
                            {
                                _priceList_id: this.props.data[this.id_meta].editedRecord['mDefaultPriceListId'],
                                _id: this.props.mParentItemCode,
                                mItemCode: this.props.mParentItemCode,
                                ['mCode']: this.props.mParentItemCode,
                                ['mLogic']: type
                            });
                    }
                }>
                <Button /*type="dashed"*/ style={{ marginRight: 8 }} /*onClick={() => { }}*/>
                    <Icon style={{ fontSize: 8, padding: 4 }} type="barcode" />{type}</Button>
            </Popconfirm>
        </Tooltip>)
    }

    // printCSV = () => {
    //     let _fatherItemsList = this.props.data['ITEM_CREATE_EDIT'].editedRecord['mCode'];
    //     this.sendAPI("get_items_matrix_list", "_fatherItemsList\r" + _fatherItemsList, (ob) => {
    //         let data = '"Code","Name","Barcode","Price","Color","Size"\r\n';
    //         if (ob.data) data += '"' + ob.data.split("\r").map(x => x.split("\f").map(y => y.split('"').join('""')).join('","')).join('"\r\n"') + '"';
    //         let blob = new Blob(["\ufeff", data], { type: "text/csv;charset=utf8" });
    //         let a = document.createElement("a");
    //         a.href = URL.createObjectURL(blob);
    //         a.download = 'matrixItems.csv';
    //         a.click();
    //     })
    // }


    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    printItems = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [
                this.props.user.companyCode,
                this.props.user.token,
                {
                    _fatherItemsList: this.props.data['ITEM_CREATE_EDIT'].editedRecord['mCode'],
                    _user: this.props.user.userId,
                    REPORT_LOCALE: this.getText(101),
                    reportName: "reports/MatrixItems.xls",
                }
            ]
        });
    }

    restorationMultiCheckbox = (text, record) => {
        let checked = text == '1';
        let indeterminate = false;
        if (text === 'all') {
            checked = this.props.data[this.recycling_id].count > 0;
            this.props.data[this.recycling_id].data.forEach(x => {
                let y = x.split("\f");
                if (y[6] == '1') indeterminate = true;
                else checked = false;
            })
        }
        return (<Checkbox
            checked={checked}
            indeterminate={indeterminate && !checked}
            onChange={e => {
                console.log("ffffff1", this.props.data[this.recycling_id])
                let data = this.props.data[this.recycling_id].data.map(x => {
                    let y = x.split("\f")
                    if (text === 'all' || (record && y[0] === record.mID)) y[6] = e.target.checked ? '1' : '0'
                    return y.join("\f")
                });
                this.props.dataActions.setJson(this.recycling_id, { data })
                console.log("ffffff2", this.props.data[this.recycling_id].data)
            }}
        />)
    }

    restorationMulti = () => {
        let dataSend = "_item";
        this.props.data[this.recycling_id].data.forEach(x => {
            let y = x.split("\f");
            if (y[6] == '1') dataSend += "\r" + y[0]
        });
        this.sendAPI(this.api.restoration_m, dataSend, () => {
            this.props.dataActions.genericDataGetRequested(
                this.recycling_id, this.props.user.companyCode, this.props.user.token, this.api.get_recycling);
        })
    }



    render() {
        const { loading } = this.state;
        //let request = { _id: 0, };
        let genericActionButtons = [
            <Tooltip title={(this.state.matrixGridMode == true) ? this.getText(10584) : this.getText(10585)}>
                <Button key="toggle_grid" className={"btn"} // disabled={(this.props.data[this.id_directdebit].editing > -1)} 
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    //onClick={this.getFile}
                    onClick={() => this.setState({ matrixGridMode: !this.state.matrixGridMode })}
                >
                    {(this.state.matrixGridMode == true)
                        ? <Icon style={{ fontSize: 16 }} type="table" theme="outlined" />
                        : <Icon style={{ fontSize: 16 }} type="barcode" theme="outlined" />
                    }
                </Button>
            </Tooltip>
        ];

        if (!this.state.matrixGridMode) {
            genericActionButtons = [
                ...genericActionButtons,
                <Tooltip title={this.getText(this.state.manualBarcode2Reception ? 16363 : 16362)}>
                    <Button key="manualBarcode2Reception" className={"btn"} // disabled={(this.props.data[this.id_directdebit].editing > -1)} 
                        style={{ marginTop: "5px", marginLeft: "5px" }}
                        type={this.state.manualBarcode2Reception ? 'primary' : undefined}
                        onClick={() => this.setState({ manualBarcode2Reception: !this.state.manualBarcode2Reception })}
                    >
                        <Icon style={{ fontSize: 16 }} type="scan" theme="outlined" />
                    </Button>
                </Tooltip>,
                <Tooltip title={this.getText(17424)}>
                    <Button key="printItems" className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={this.printItems}>
                        <Icon style={{ fontSize: 16 }} type="file-excel" />
                    </Button>
                </Tooltip>,
                <RecyclingBin

                    {...this.props}
                    id={this.recycling_id}
                    getApi={this.api.get_recycling}
                    filters={{ _fatherCode: this.props.data['ITEM_CREATE_EDIT'].editedRecord['mCode'] }}
                    restorationApi={this.api.restoration}
                    title={this.getText(15403)}
                    columns={[
                        { title: this.getText(18243), key: 'mID', dataIndex: 'mID', width: '12%', render: (t) => t },
                        { title: this.getText(18244), key: 'mName', dataIndex: 'mName', width: '30%', render: (t) => t },
                        { title: this.getText(18245), key: 'mSize', dataIndex: 'mSize', width: '10%', render: (t) => t },
                        { title: this.getText(18246), key: 'mColor', dataIndex: 'mColor', width: '10%', render: (t) => t },
                        { title: this.getText(18247), key: 'mDate', dataIndex: 'mDate', width: '13%', render: (t) => t },
                        { title: this.getText(18248), key: 'mUser', dataIndex: 'mUser', width: '12%', render: (t) => t },
                        { title: this.getText(18249), width: '8%' },
                        { title: this.restorationMultiCheckbox("all"), key: 'mIsChecked', dataIndex: 'mIsChecked', width: '5%', render: this.restorationMultiCheckbox },
                    ]}
                    // columnTitles={[this.getText(15404), this.getText(15405), this.getText(15406), this.getText(15421), this.getText(15407)]}
                    popconfirmText={{ title: this.getText(15408), okText: this.getText(15409), cancelText: this.getText(15410) }}
                    onClose={() => {
                        this.setState({ modalSonsTriggerOpen: false }, () => {
                            this.refreshData()
                            if (this.props.modalSonsTriggerClose) this.props.modalSonsTriggerClose()
                        })
                    }}
                    triggerOpen={this.state.modalSonsTriggerOpen}
                    genericActionButtons={[
                        <Tooltip title={this.getText(18250)}>
                            <Button key="restorationMultiBtn" className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={this.restorationMulti}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"rest"} />
                            </Button>
                        </Tooltip>
                    ]}
                    pagination={{
                        pageSize: parseInt((window.innerHeight - 340 > 40) ? (window.innerHeight - 340) / 40 : 1)
                    }}
                    modalWidth={1200}
                />
            ]
        }


        let fluxProps = { ui: this.props.ui, uiActions: this.props.uiActions, data: this.props.data, dataActions: this.props.dataActions, user: this.props.user }

        let list = Jax.get(this.props.data, TableIDs.genericSelectorPrices + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' - ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={<span>
                        {this.getText(10586)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {this.getText(10587)}: {this.props.data['ITEM_CREATE_EDIT'].editedRecord['mCode']}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {this.getText(10588)}: {this.props.data['ITEM_CREATE_EDIT'].editedRecord['mName']}
                    </span>}
                    actionButtons={this.actionButtons}
                    genericActionButtons={genericActionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'90%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                data={this.props.data} dataAction={this.props.dataAction}>

                <div id={'headers'} style={{ padding: 8, display: this.state.matrixGridMode ? 'none' : 'block' }}>

                    {this.getText(10589)}
                    <Select key={"PriceListList"} style={{ width: 250, marginLeft: 8, marginRight: 8 }}
                        placeholder={this.getText(10590)}
                        showsearch
                        filterOption={true}
                        optionFilterProp="valuefilter"
                        onChange={(e) => {
                            this.props.dataActions.genericDataSetEdit(this.id_meta, { ['mDefaultPriceListId']: e });
                        }}
                        value={this.props.data[this.id_meta].editedRecord['mDefaultPriceListId']}
                        notFoundContent={this.getText(10591)}
                    >
                        {list}
                    </Select>
                    {this.UPCButtons.map(x => this.createUPCButton(x.type, x.hint))}
                    {/* {this.createUPCButton(1, 10593)}
                    {this.createUPCButton(2, 10598)}
                    {this.createUPCButton(3, 10599)}
                    {this.createUPCButton(4, 15402)}
                    {this.createUPCButton(5, 18169)} */}

                </div>
                <div style={{ padding: 8, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>{(this.state.matrixGridMode == true) ? this.getText(10600) : this.getText(10601)}</div>
                <div key="grid_container" style={{ padding: 8 }} >
                    {(this.state.matrixGridMode)
                        ? <MatrixGrid {...fluxProps} itemCode={this.props.mParentItemCode/*ui.data*/} lastSave={this.state.lastSave} />
                        : <MatrixBarcodeTable {...fluxProps}
                            itemCode={this.props.mParentItemCode/*ui.data*/}
                            priceListId={this.props.data[this.id_meta].editedRecord['mDefaultPriceListId']}
                            manualBarcode2Reception={this.state.manualBarcode2Reception}
                            endManualBarcode2Reception={() => { this.setState({ manualBarcode2Reception: false }) }} />}
                </div>
                <div className="action_footer" style={(this.state.matrixGridMode) ? {} : { display: 'none' }}>
                    <Button
                        onClick={this.handleCancel}
                        type="primary" //htmlType="submit" form="create_edit_supplier_form"
                        loading={this.props.ui.workInProgress}
                    >{this.getText(10602)}</Button>
                    <Button
                        onClick={this.addGridData}
                        type="primary" //htmlType="submit" form="create_edit_supplier_form"
                        loading={this.props.ui.workInProgress}
                        //className={"form-button-save"} 
                        icon={"check"}>{this.getText(10603)}</Button>
                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        //let request = { _id: this.props.ui.data };
        //this.props.mParentItemCode
        if (/*this.props.ui.data !== prevProps.ui.data*/
            this.props.mParentItemCode !== prevProps.mParentItemCode
            || (prevProps.toggle === false && this.props.toggle === true)) {
            this.setState({ id: this.props.mParentItemCode/*ui.data*/, matrixGridMode: !this.props.modalSonsTriggerOpen }, () => {
                this.props.dataActions.genericDataGetEditWithParams(
                    this.id,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    this.api.get
                );

                this.props.dataActions.genericDataGetEditWithParams(
                    this.id_meta,
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    this.api.get_meta
                );

                this.props.dataActions.genericDataGetEditWithParams(
                    'ITEM_CREATE_EDIT',
                    this.props.user.companyCode,
                    this.props.user.token,
                    { _id: this.props.mParentItemCode/*ui.data*/, mItemCode: this.props.mParentItemCode/*ui.data*/ },
                    "get_father_item"
                );

                if (this.props.modalSonsTriggerOpen) {
                    setTimeout(() => {
                        this.setState({ modalSonsTriggerOpen: true })
                    }, 500);
                }

            })
        }

    }

    addGridData = () => {

        this.props.dataActions.genericDataJustSaveEditMulti(
            this.id_edit,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.create,
            [
                { name: this.id_edit, sendDataTable: true },
                { name: this.id_meta, sendDataTable: false },
                //{ name: this.id_priceList, sendDataTable: true },
                //{ name: this.id_tags, sendDataTable: true }
            ],
            // refresh parent dataset (like when closing a modal)
            {
                action: this.props.dataActions.genericDataGetEditWithParams,
                args: ["MATRIX_GRID", this.props.user.companyCode,
                    this.props.user.token, { _id: this.state.id }, "get_matrix_grid"]
            }
        )

        this.props.dataActions.genericDataGetEditWithParams("MATRIX_GRID_EDIT", this.props.user.companyCode,
            this.props.user.token, { _id: -1 }, "get_matrix_grid");

        this.setState({ lastSave: Date.now() })
    }
}

export default ModalMatrix;
