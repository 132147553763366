import React, { Component } from 'react';
import { Radio, Input, Icon, Popconfirm, Tooltip, Button, Row, Col, Checkbox, Select, message, Modal, Upload, Popover, Spin, InputNumber, Form } from "antd";
//import { ImportOutlined } from '@ant-design/icons';
import Jax from "../Jax/jax.es6.module";
import TableIDs from '../data/TableIDs.js';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableOptimized from './TableOptimized';
//import { isConstructSignatureDeclaration } from 'typescript';
import LabelPrinter from './LabelPrinter';
import LangContext from "../contextProvider/LangContext";
import styles from '../style/tableOptimized.module.css';
import ModalUnitQtySelector from './ModalUnitQtySelector';
import ModalDocumentSelector from './ModalDocumentSelector';
import GenericModal from './GenericModal';
import ModalCreateEditItem from './ModalCreateEditItem';
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import { encodeXml, decodeXml } from "../utils/utils.js"
import soundFile from "../sound/error-item-sound.mp3";
import { setTwoToneColor } from 'antd/lib/icon/twoTonePrimaryColor';
import ResponsiveTable from './ResponsiveTable';
import TableLineIcons from './TableLineIcons';

const Option = Select.Option;

type RecordObj = {
    key: Number,
    itemCode: String,

    itemBarcode: String,

    description: String,
    quantity: Number,
    unitPriceWithTax: Number,
    discountPercentage: Number,
    totalSumWithTax: Number,
    taxPercentageValue: Number,
    taxId: Number,
    unitPriceNoTax: Number,
    totalSumNoTax: Number,
    isWeightable: Number,
};
/*
type Props = {
    history: History,
    baseUrl: string
};
*/
type State = {
    dataSource: Array<RecordObj>,
};

const { confirm } = Modal;

class TableContainer extends Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        //super();

        if (props.data["document2"] === undefined) props.data["document2"] = { dataSource: [], editedRecord: {} };
        if (props.data["PRICES_BY_PRICELIST"] === undefined) props.data["PRICES_BY_PRICELIST"] = { data: [] };
        if (props.data[TableIDs.genericSelectorPriceList] === undefined) props.data[TableIDs.genericSelectorPriceList] = { ...props.data.genericSelector }


        this.state = {}
        this.state.dataSource = [
            /*
            {
                "key": '1',
                "itemCode": "aaaa",
                "description": "bbb",
                "quantity": "xx",
                "unitPriceWithTax": "xx",
                "discountPercentage": "xx",
                "totalSumWithTax": "xx",
            }, {
                "key": '2', "itemCode": "aaaavvv", "description": "bbb", "quantity": "xx", "unitPriceWithTax": "xx", "discountPercentage": "xx", "totalSumWithTax": "xx",
            }
            */
        ];
        this.state.val = '';
        this.state.counter = 0;
        this.state.editedRecord = {};

        //this.state.isCtrlB = false;
        this.state.buffer = "";
        // this.state.isSumFlag = false;
        this.state.isQtyInput = false;
        this.state.linesValidFilter = 2;
        this.state.linesOrderAsc = false;

        this.state.modalExcelUpload = false;
        this.state.modalEditQtyMulti = false;
        this.state.modalEditQtyMultiData = [];

        this.state.fileList = [];
        // this.state.excelItemCol = null;
        // this.state.excelItemNameCol = null;
        // this.state.excelQtyCol = null;
        this.state.popABC = false;
        this.state.scanDocOpen = false;
        this.state.modalIsSerialize = null;
        // this.state.isCostWithTax = true;
        this.state.modalPercentageDscnt = null;

        this.state.modalOnChangePriceList = null;

        this.api = { check: "check_all_item_code" }

        this.isCreateNewItem = false;

        this.scanDocFieldPos = '';
        this.scanDocFieldType = '';
        this.scanDocFieldNumber = '';

        this.ctrlBKeyFlag = false;
        this.barcodeReaderBuffer = '';

        this.focusTimeout = null;

        this.priceListBack = null;

    }

    static contextType = LangContext;

    getText = (id) => { return this.context.get(id) || '[' + id + ']' }

    componentDidMount() {
        console.log("TableContainer: mounted", this.props);
        if (this.props.linesOrderAscDefault) {
            this.setState({ linesOrderAsc: true })
        }
    }
    componentWillUnmount() { console.log("TableContainer: un-mounted"); }

    componentDidUpdate(prev, prevState) {
        if (prev.linesOrderAscDefault != this.props.linesOrderAscDefault) {
            this.setState({ linesOrderAsc: this.props.linesOrderAscDefault })
        }

        if (!prevState.modalIsSerialize && this.state.modalIsSerialize) {
            setTimeout(() => {
                let inputSN = document.getElementById("input_serial_number");
                if (inputSN) inputSN.focus()
            }, 1000);
        }
    }



    calculateRecord = (fieldName, val) => {
        const { keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage,
            totalSumWithTax, unitPriceNoTax, totalSumNoTax, taxPercentageValue, taxId, remarks, unitID, quantityInUnit, unitQuantity, unitDescription, isWeightable

        } = this.props.data.document2.editedRecord;
        let record = {
            keyVal, isValid, itemCode, itemBarcode, description, quantity, unitPriceWithTax, discountPercentage,
            totalSumWithTax, unitPriceNoTax, totalSumNoTax, taxPercentageValue, taxId, remarks, unitID, quantityInUnit, unitQuantity, unitDescription, isWeightable
        }
        //this.isTotalEdited = false;
        let isTotalEdited = false;
        if (fieldName === 'totalSumNoTax') {
            if (parseFloat(totalSumNoTax) !== parseFloat(val)) {
                isTotalEdited = true;
            }
        }
        if (fieldName === 'totalSumWithTax') {
            if (parseFloat(totalSumWithTax) !== parseFloat(val)) {
                isTotalEdited = true;
            }
        }

        if (fieldName === 'quantity') {
            record.quantity = val; //record.unitPriceWithTax = unitPriceWithTax;
            record.totalSumWithTax = parseFloat((val * unitPriceWithTax) * (1.00 - discountPercentage / 100)).toFixed(4);
            record.totalSumNoTax = parseFloat((val * unitPriceNoTax) * (1.00 - discountPercentage / 100)).toFixed(4);

        } else if (fieldName === 'unitPriceWithTax') {
            record.unitPriceWithTax = (val); //record.unitPriceWithTax = unitPriceWithTax;
            record.totalSumWithTax = parseFloat((quantity * val) * (1.00 - discountPercentage / 100)).toFixed(4);
            record.unitPriceNoTax = parseFloat(val / (1.00 + taxPercentageValue / 100)).toFixed(4);
            record.totalSumNoTax = parseFloat((quantity * record.unitPriceNoTax) * (1.00 - discountPercentage / 100)).toFixed(4);

        } else if (fieldName === 'discountPercentage') {
            record.discountPercentage = val;
            record.totalSumWithTax = parseFloat((quantity * unitPriceWithTax) * (1.00 - val / 100)).toFixed(4);
            record.totalSumNoTax = parseFloat((quantity * unitPriceNoTax) * (1.00 - val / 100)).toFixed(4);
        } else if (fieldName === 'totalSumWithTax') {
            record.totalSumWithTax = val.toFixed(4);
            if (isTotalEdited === true) {
                record.discountPercentage = parseFloat((1.00 - (val / (quantity * unitPriceWithTax))) * 100).toFixed(4);
            }
            record.totalSumNoTax = parseFloat(val / (1.00 + taxPercentageValue / 100)).toFixed(4);

        } else if (fieldName === 'unitPriceNoTax') {
            record.unitPriceNoTax = val; //record.unitPriceWithTax = unitPriceWithTax;
            record.totalSumNoTax = parseFloat((quantity * val) * (1.00 - discountPercentage / 100)).toFixed(4);
            record.unitPriceWithTax = parseFloat(val * (1.00 + taxPercentageValue / 100)).toFixed(4);
            record.totalSumWithTax = parseFloat((quantity * record.unitPriceWithTax) * (1.00 - discountPercentage / 100)).toFixed(4);

        } else if (fieldName === 'totalSumNoTax') {
            record.totalSumNoTax = val;
            if (isTotalEdited === true) {
                record.discountPercentage = parseFloat((1.00 - (val / (quantity * unitPriceNoTax))) * 100).toFixed(4);
            }
            record.totalSumWithTax = parseFloat(val * (1.00 + taxPercentageValue / 100)).toFixed(4);
        }

        return record;
    }

    updatePriceCostLineChange = (record) => {
        let discount = record.discountPercentage;
        let quantity = record.quantity;
        let unitPriceNoTax = record.unitPriceWithTax;
        //let total = quantity * (unitPrice * (100 - discount) / 100);
        //let unitPriceNoTax = unitPrice / (1 + tax / 100);
        let totalNoTax = quantity * (unitPriceNoTax * (100 - discount) / 100);

        return {
            ...record,
            // itemCode: String,
            // description: String,
            // quantity: Number,
            // unitPriceWithTax: Number,
            // discountPercentage: Number,
            totalSumWithTax: totalNoTax,
        }

    }

    updateEditedRecordFields = (obj, callback) => {
        this.props.dataActions.setJson("document2", { editedRecord: { ...this.props.data.document2.editedRecord, ...obj } });
    }

    setEditedRecordFields = (obj, callback) => {
        this.props.dataActions.setJson("document2", { editedRecord: obj });
    }

    saveBuffer = () => {
        this.props.dataActions.setJson("document2",
            {
                dataSource: [{
                    "key": (this.props.data.document2.dataSource).length + 1,
                    "itemCode": this.buffer.join(''),
                    "description": this.buffer.join('')
                }].concat(this.props.data.document2.dataSource),
                counter: this.props.data.document2.counter + 1
            })
    }

    updateRecordUsingData = (obj: RecordObj, callback) => {
        let ds = [...this.props.data.document2.dataSource];
        let ind = ds.findIndex((i) => { return i.key === obj.key });
        if (ind > -1) {
            ds[ind] = { ...ds[ind], ...obj };
            this.props.dataActions.setJson("document2", { dataSource: ds });
            if (this.priceListBack) {
                this.onChangePriceList(this.priceListBack);
                this.priceListBack = null;
            }
        }
    }

    saveEditedRecord = (obj, callback) => {
        let len = (this.props.data.document2.dataSource).length + 1;
        console.log("oooo new record-0", obj);
        let newObj = { ...this.props.data.document2.editedRecord, ...obj, key: len, keyVal: len };
        let salesDoc = [1, 2, 22].filter(f => f == this.props.documentType).length > 0;
        let stockDoc = [4, 11, 16, 17, 18, 5051].filter(f => f == this.props.documentType).length > 0;
        let isSerialize = (newObj.isSerialize == '3' && stockDoc) || (newObj.isSerialize > 0 && salesDoc)
        if (isSerialize && !newObj.serialNumber) {
            let serialNumbers = [];
            for (let x = 1; x <= newObj.quantity; x++) {
                serialNumbers.push({ key: x, value: "", status: 0, active: x === 1 });
            }
            this.setState({ modalIsSerialize: { ...newObj, serialNumbers } });
            return;
        }

        Object.keys(newObj).forEach(x => {
            if (newObj[x] === "NaN") newObj[x] = 0;
            if (newObj[x] === "Infinity") newObj[x] = 0;
            if (newObj[x] === "-Infinity") newObj[x] = 0;
        })
        console.log("oooo new record", newObj);
        let emptyRecord: RecordObj = {
            key: 0,
            keyVal: 0,
            itemCode: '',
            itemBarcode: '',
            description: '',
            quantity: 0.00,
            unitPriceWithTax: 0.00,
            discountPercentage: 0.00,
            totalSumWithTax: 0.00,

            unitID: 0,
            quantityInUnit: 0,
            unitQuantity: 0.00,
            unitDescription: '',

            isWeightable: 0,
        };

        let dataSource = [...this.props.data.document2.dataSource, newObj];
        console.log("bonusCalcData 111", newObj.bonusCalcData)
        if (newObj.bonusCalcData && newObj.bonusCalcData.mBonusQty && newObj.bonusCalcData.mQtyForBonusQty) {
            let _q = Math.floor(newObj.quantity / newObj.bonusCalcData.mQtyForBonusQty) * newObj.bonusCalcData.mBonusQty;
            console.log("bonusCalcData 111", _q, newObj.quantity, newObj.bonusCalcData.mQtyForBonusQty, newObj.bonusCalcData.mBonusQty)
            if (_q) {
                len++;
                dataSource.push({
                    ...this.props.data.document2.editedRecord,
                    ...obj,
                    key: len,
                    keyVal: len,
                    quantity: _q,
                    unitQuantity: _q,
                    unitPriceWithTax: 0,
                    unitPriceNoTax: 0,
                    discountPercentage: 0,
                    totalSumWithTax: 0,
                    totalSumNoTax: 0,
                    bonusCalcData: {
                        mBaseCost: 0,
                        mSupDscnt1: 0,
                        mSupDscnt2: 0,
                        mSupDscnt3: 0,
                        mCostIncludeDscnt: 0,
                        mBonusQty: 0,
                        mQtyForBonusQty: 0,
                        mCostIncludeBonus: 0
                    }
                });
            }
        }

        this.props.dataActions.setJson("document2", { dataSource, editedRecord: emptyRecord });

        if (!newObj.isValid) {
            let audio = new Audio(soundFile);
            audio.play();
        }

        if (this.props.continueSearchList) {
            this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data });
        }

        if (this.priceListBack) {
            this.onChangePriceList(this.priceListBack);
            this.priceListBack = null;
        }
    }

    deleteRecord = (key, callback) => {
        let arr = this.props.data.document2.dataSource
            .filter(i => i.key !== key) // remove item
            .map((i, ind) => { return { ...i, key: ind + 1 } }); // re-index key
        this.props.dataActions.setJson("document2", { dataSource: arr });  // this.setState({ dataSource: arr }, callback);
    }

    editRecord = (key, callback) => {
        let record = this.props.data.document2.dataSource.find(i => i.key === key) // remove item
        console.log("on edit: ", record);
        this.props.dataActions.setJson("document2", { editedRecord: record }); // this.setState({ editedRecord: record }, callback);
        this.getUOMSizesRuler(record.itemCode)
    }

    updateRecord = (key, callback) => {
        let arr = this.props.data.document2.dataSource.map(i => { return (i.key === key) ? this.props.data.document2.editedRecord : i }) // update item
        let newObj = this.props.data.document2.dataSource.find(i => (i.key === key))

        if (newObj.bonusCalcData && newObj.bonusCalcData.mBonusQty && newObj.bonusCalcData.mQtyForBonusQty) {
            let _q = Math.floor(newObj.quantity / newObj.bonusCalcData.mQtyForBonusQty) * newObj.bonusCalcData.mBonusQty;

            if (_q) {
                let len = (this.props.data.document2.dataSource).length + 1;
                arr.push({
                    ...this.props.data.document2.editedRecord,
                    key: len,
                    keyVal: len,
                    quantity: _q,
                    unitQuantity: _q,
                    unitPriceWithTax: 0,
                    unitPriceNoTax: 0,
                    discountPercentage: 0,
                    totalSumWithTax: 0,
                    totalSumNoTax: 0,
                });
            }
        }
        this.props.dataActions.setJson("document2", {
            dataSource: arr, editedRecord: {
                key: 0,
                keyVal: 0,
                itemCode: '',
                itemBarcode: '',
                description: '',
                quantity: 0.00,
                unitPriceWithTax: 0.00,
                discountPercentage: 0.00,
                totalSumWithTax: 0.00,

                unitID: 0,
                quantityInUnit: 0,
                unitQuantity: 0.00,
                unitDescription: '',

                isWeightable: 0,
            }
        }); //this.setState({ dataSource: arr, editedRecord: {} }, callback);
    }

    callApi = (inputData, action, succCallback, errCallback) => {
        //let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + selection.target.value + "\f1\f1";
        let dataSend = inputData;//"_itemCode\f_isWithMatrix\f_isWithFather\r" + selection.target.value + "\f1\f1";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        //let job = new Job(user, this.api.check, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        let job = new Job(user, action, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", succCallback, errCallback);
    }

    getPosTaxRegion() {
        return Jax(this.props.data).path(/*"PointsOfSale"*/"PointsOfSaleByUser").values("taxRegion").where("id").equals(parseInt(this.props.posId, 10))[0];
    }

    onChangePriceList = (priceListId, changeAll) => {
        let dataSend = "_id\f_page_num\f_rows_num\f_sort_by\f_isAsc\f_words\r" + priceListId + "\f1\f99999999\fcol_1\f1\f";

        this.props.sendAPI("get_prices_by_price_list", dataSend, ob => {
            let data = ob.data ? ob.data.split("\r") : []
            this.props.dataActions.setJson("document2", { priceListId })
            this.props.dataActions.setJson("PRICES_BY_PRICELIST", { data })

            const { dataSource, editedRecord, priceList } = this.props.data.document2;
            let isPricesWithVat = priceList.find(i => i.id == priceListId).includesTax;

            const getRecordLine = (i) => {
                let priceRecord = data.find((j) => { return j.split('\f')[3] === i.itemCode })
                if (!priceRecord) return i

                let priceArr = priceRecord.split('\f');
                let _itemPrice = parseFloat(priceArr[5]);

                if (!_itemPrice) return i;

                let _taxPercentageValue = parseFloat(i.taxPercentageValue);
                let _discountPercentage = parseFloat(i.discountPercentage);
                let _quantity = parseFloat(i.quantity);

                if (isPricesWithVat === true) {
                    return {
                        ...i,
                        unitPriceNoTax: (_itemPrice / (1.00 + (_taxPercentageValue / 100))).toFixed(4),
                        unitPriceWithTax: _itemPrice.toFixed(4),
                        totalSumNoTax: ((_quantity * (_itemPrice / (1.00 + (_taxPercentageValue / 100)))) * (1.00 - _discountPercentage / 100)).toFixed(4),
                        totalSumWithTax: ((_quantity * _itemPrice) * (1.00 - _discountPercentage / 100)).toFixed(4)
                    }
                } else {
                    return {
                        ...i,
                        unitPriceNoTax: _itemPrice.toFixed(4),
                        unitPriceWithTax: (_itemPrice * (1.00 + (_taxPercentageValue / 100))).toFixed(4),
                        totalSumNoTax: ((_quantity * _itemPrice) * (1.00 - _discountPercentage / 100)).toFixed(4),
                        totalSumWithTax: ((_quantity * (_itemPrice * (1.00 + (_taxPercentageValue / 100)))) * (1.00 - _discountPercentage / 100)).toFixed(4)
                    }
                }
            }

            if (changeAll) {
                this.props.dataActions.setJson("document2", {
                    dataSource: dataSource.map(getRecordLine),
                    editedRecord: getRecordLine(editedRecord)
                })
            } else {
                this.props.dataActions.setJson("document2", {
                    editedRecord: getRecordLine(editedRecord)
                })
            }


        })
    }

    // handlePriceListChange(value: number) {
    //     this.props.ActionQueue.addToQueue(
    //         {
    //             action: this.props.dataActions.genericDataSetFilter,
    //             args: [
    //                 "PRICES_BY_PRICELIST",
    //                 this.props.user.companyCode,
    //                 this.props.user.token,
    //                 {
    //                     _id: value,
    //                     recordsPerPage: 999999,
    //                     _rows_num: 999999,
    //                     _words: "",
    //                     _page_num: 1,
    //                     _isAsc: 1,
    //                     _sort_by: 'col_1'
    //                 },
    //                 "get_prices_by_price_list"
    //             ]
    //         }
    //     );

    //     this.props.ActionQueue.addToQueue({
    //         action: this.props.dataActions.setJson,
    //         args: [
    //             "document2",
    //             { priceListId: value }
    //         ]
    //     });
    // }

    // onChangePriceList = (value) => {
    //     confirm({
    //         title: this.getText(19669),
    //         content: (<div>
    //             <h2>{this.getText(19670)}</h2>
    //             <Radio.Group
    //                 value={this.state.updatePriceType}
    //                 onChange={e => { this.setState({ updatePriceType: e.target.value }) }}>
    //                 <Radio value={"1"}>{this.getText(19671)}</Radio>
    //                 <Radio value={"2"}>{this.getText(19672)}</Radio>
    //                 <Radio value={"3"}>{this.getText(19673)}</Radio>
    //             </Radio.Group>
    //         </div>),
    //         footer: null
    //         // iconType: 'exclamation-circle',
    //         // okText: this.getText(19674),
    //         // cancelText: this.getText(19675),
    //         // onOk: () => {
    //         //     this.handlePriceListChange(value);
    //         //     setTimeout(this.updateLineOnPriceListChange, 2500)
    //         // },
    //         // onCancel() { },
    //     });
    // }

    // updateLineOnPriceListChange = () => {
    //     // "description", "discountPercentage", "itemCode", "key", "keyVal", "quantity", "totalSumNoTax", "totalSumWithTax",
    //     // "unitPriceNoTax", "unitPriceWithTax", "taxId", "taxPercentageValue", "isValid"
    //     const lines = this.props.data.document2.dataSource;
    //     const pricesLists = this.props.data.document2.priceList;
    //     const prices = this.props.data['PRICES_BY_PRICELIST'].data;
    //     const priceId = this.props.data.document2.priceListId;

    //     console.log(prices, 'this.props.data.ITEM_PRICE_LIST_CREATE_EDIT')

    //     //{id: 1, name: "צרכן 1 כולל מע"מ", taxRegion: 1, includesTax: false}
    //     let isPricesWithVat = pricesLists.find(i => {
    //         return parseInt(i.id, 10) === parseInt(priceId, 10);
    //     }).includesTax;

    //     const updatedLines = lines.map((i) => {
    //         let priceRecord = prices.find((j) => { return j.split('\f')[3] === i.itemCode })
    //         if (!priceRecord) {
    //             return i
    //             // return {
    //             //     ...i,
    //             //     unitPriceNoTax: 0.00,
    //             //     unitPriceWithTax: 0.00,
    //             //     totalSumNoTax: 0.00,
    //             //     totalSumWithTax: 0.00
    //             // }
    //         }

    //         let priceArr = priceRecord.split('\f');
    //         let _itemPrice = parseFloat(priceArr[5]);

    //         let _taxPercentageValue = parseFloat(i.taxPercentageValue);
    //         let _discountPercentage = parseFloat(i.discountPercentage);
    //         let _quantity = parseFloat(i.quantity);

    //         if (isPricesWithVat === true) {
    //             return {
    //                 ...i,
    //                 unitPriceNoTax: (_itemPrice / (1.00 + (_taxPercentageValue / 100))).toFixed(4),
    //                 unitPriceWithTax: _itemPrice.toFixed(4),
    //                 totalSumNoTax: ((_quantity * (_itemPrice / (1.00 + (_taxPercentageValue / 100)))) * (1.00 - _discountPercentage / 100)).toFixed(4),
    //                 totalSumWithTax: ((_quantity * _itemPrice) * (1.00 - _discountPercentage / 100)).toFixed(4)
    //             }
    //         } else {
    //             return {
    //                 ...i,
    //                 unitPriceNoTax: _itemPrice.toFixed(4),
    //                 unitPriceWithTax: (_itemPrice * (1.00 + (_taxPercentageValue / 100))).toFixed(4),
    //                 totalSumNoTax: ((_quantity * _itemPrice) * (1.00 - _discountPercentage / 100)).toFixed(4),
    //                 totalSumWithTax: ((_quantity * (_itemPrice * (1.00 + (_taxPercentageValue / 100)))) * (1.00 - _discountPercentage / 100)).toFixed(4)
    //             }
    //         }
    //     })
    //     this.props.dataActions.setJson("document2", { dataSource: updatedLines })
    // }

    // updateEditLineOnPriceListChange = () => {
    //     let record = { ...this.props.data.document2.editedRecord };
    //     const pricesLists = this.props.data.document2.priceList;
    //     const prices = this.props.data['PRICES_BY_PRICELIST'].data;
    //     const priceId = this.props.data.document2.priceListId;

    //     let isPricesWithVat = pricesLists.find(i => {
    //         return parseInt(i.id, 10) === parseInt(priceId, 10);
    //     }).includesTax;

    //     let priceRecord = prices.find((j) => { return j.split('\f')[3] === record.itemCode })
    //     if (!priceRecord) return;

    //     let priceArr = priceRecord.split('\f');
    //     let _itemPrice = parseFloat(priceArr[5]);

    //     let _taxPercentageValue = parseFloat(record.taxPercentageValue);
    //     let _discountPercentage = parseFloat(record.discountPercentage);
    //     let _quantity = parseFloat(record.quantity);

    //     if (isPricesWithVat === true) {
    //         record = {
    //             ...record,
    //             unitPriceNoTax: (_itemPrice / (1.00 + (_taxPercentageValue / 100))).toFixed(4),
    //             unitPriceWithTax: _itemPrice.toFixed(4),
    //             totalSumNoTax: ((_quantity * (_itemPrice / (1.00 + (_taxPercentageValue / 100)))) * (1.00 - _discountPercentage / 100)).toFixed(4),
    //             totalSumWithTax: ((_quantity * _itemPrice) * (1.00 - _discountPercentage / 100)).toFixed(4)
    //         }
    //     } else {
    //         record = {
    //             ...record,
    //             unitPriceNoTax: _itemPrice.toFixed(4),
    //             unitPriceWithTax: (_itemPrice * (1.00 + (_taxPercentageValue / 100))).toFixed(4),
    //             totalSumNoTax: ((_quantity * _itemPrice) * (1.00 - _discountPercentage / 100)).toFixed(4),
    //             totalSumWithTax: ((_quantity * (_itemPrice * (1.00 + (_taxPercentageValue / 100)))) * (1.00 - _discountPercentage / 100)).toFixed(4)
    //         }
    //     }

    //     this.props.dataActions.setJson("document2", { editedRecord: record })
    // }


    responsLabels = () => {
        //let data = Jax.get(this.props.data, "document.finalRecord.Lines", [])
        let data = Jax.get(this.props.data, "document2.dataSource", [])
        let res = [];
        data.map((i) => {
            let qty = parseInt(i.quantity);
            if (qty > 0) res.push({
                _ITEM_CODE: i.itemCode,
                _quantity: qty,
                _CUSTOMER_ID: 0,
                _CART_CUSTOMER_NAME: '',
            })
        })
        return res;
    }

    itemLabel = () => {
        return [{
            _ITEM_CODE: this.state.itemCode,
            //_quantity: 1,
            _CUSTOMER_ID: 0,
            _CART_CUSTOMER_NAME: '',
        }]
    }

    uploadExcelButton = () => {

        const { fileList } = this.state;
        const { supplierCode, priceListId, dataSource, excelColumns } = this.props.data.document2;
        const {
            excelItemCol, excelItemNameCol, excelQtyCol,
            excelBarcodeCol, excelPriceCol, excelCostCol, excelDiscountPercentageCol, excelQtyInUnitCol, excelUnitQtyCol,
            excelBaseCostNoTaxCol, excelBaseCostWithTaxCol, excelDscnt1Col, excelDscnt2Col, excelBonusQtyCol, excelQtyForBonusQtyCol
        } = excelColumns ? excelColumns : {};


        let GetTableFromExcel = (data) => {
            let workbook = XLSX.read(data, { type: 'binary' });
            let Sheet = workbook.SheetNames[0];
            let fullData = workbook.Sheets[Sheet];
            let ref = fullData['!ref'].split(':');
            let refStart = parseInt(ref[0].replace(/[A-Z]+/, ''));
            let refEnd = parseInt(ref[1].replace(/[A-Z]+/, ''));
            let dataSend = [...dataSource];
            let dataSourceCount = dataSource.length;
            let fd = (key, def) => {
                let res = key && fullData[key] && fullData[key].w ? fullData[key].w : "";
                return res ?
                    res.toString()
                        .split("")
                        .filter(f => {
                            let decimalCode = f.charCodeAt(0)
                            return decimalCode < 8064 || decimalCode > 8319
                        })
                        .join("")
                        .replace(/[\x00-\x1F\x80-\xFF]/, '')
                        .trim()
                    : def ? def : ""
            }

            let signFactor = (this.props.documentType == 21 || this.props.documentType == 22 || this.props.documentType == 9 /*|| this.props.documentType == 17*/) ? -1 : 1;
            let notValidItemCode = false;

            for (let n = refStart; n <= refEnd; n++) {
                let itemCode = n > 1 ? fd(excelItemCol + n) : null;
                let itemBarcode = n > 1 ? fd(excelBarcodeCol + n) : null;
                if (itemCode && itemCode.length > 15) {
                    notValidItemCode = true;
                } else if (itemCode) {
                    if (!itemBarcode) itemBarcode = itemCode;
                    let key = dataSourceCount + n - 1;
                    let qty = fd(excelQtyCol + n, 1);
                    let qtyInUnit = fd(excelQtyInUnitCol + n, 1);
                    let unitQty = fd(excelUnitQtyCol + n, 1);
                    if (excelUnitQtyCol && excelQtyInUnitCol) {
                        qty = unitQty * qtyInUnit
                    }

                    dataSend.push({
                        key,
                        keyVal: key,
                        itemCode,
                        itemBarcode,
                        quantity: qty * signFactor,
                        description: fd(excelItemNameCol + n),
                        discountPercentage: fd(excelDiscountPercentageCol + n, 0),
                        isValid: 1,
                        quantityInUnit: qtyInUnit,
                        remarks: "",
                        taxId: "1",
                        taxPercentageValue: "17.00",
                        totalSumNoTax: 0,
                        totalSumWithTax: 0,
                        unitDescription: "",
                        unitID: "0",
                        unitPriceNoTax: 0,
                        unitPriceWithTax: 0,
                        unitQuantity: unitQty,
                        excelPriceValue: fd(excelPriceCol + n, null),
                        excelCostValue: fd(excelCostCol + n, null),
                        excelBaseCostNoTaxValue: fd(excelBaseCostNoTaxCol + n, null),
                        excelBaseCostWithTaxValue: fd(excelBaseCostWithTaxCol + n, null),
                        excelDscnt1Value: fd(excelDscnt1Col + n, null),
                        excelDscnt2Value: fd(excelDscnt2Col + n, null),
                        excelBonusQtyValue: fd(excelBonusQtyCol + n, null),
                        excelQtyForBonusQtyValue: fd(excelQtyForBonusQtyCol + n, null),
                    });

                    console.log("test_upload_excel_2", { qty, unitQty, qtyInUnit, signFactor, quantity: qty * signFactor, tt: qty === '1' })
                }
            }

            if (notValidItemCode) message.error(this.getText(18342))

            console.log("test_upload_excel", dataSend)

            this.props.updatePricesPerSupplier(supplierCode, priceListId, dataSend, this.closeExcelModel)

        };

        let beforeUpload = (fileUpload) => {
            this.setState({ fileList: [fileUpload] });
            let reader = new FileReader();

            //For Browsers other than IE.
            if (reader.readAsBinaryString) {
                reader.onload = function (e) {
                    GetTableFromExcel(e.target.result);
                };
                reader.readAsBinaryString(fileUpload);
            } else {
                //For IE Browser.
                reader.onload = function (e) {
                    let data = "";
                    let bytes = new Uint8Array(e.target.result);
                    for (let i = 0; i < bytes.byteLength; i++) {
                        data += String.fromCharCode(bytes[i]);
                    }
                    GetTableFromExcel(data);
                };
                reader.readAsArrayBuffer(fileUpload);
            }
            return false;
        }

        let gt = this.getText;
        let disabled = !excelItemCol || !excelQtyCol;
        return (<Upload fileList={fileList} beforeUpload={beforeUpload} disabled={disabled}>
            <Button key={"upButton"} disabled={disabled}><Icon type="upload" style={{ marginLeft: 5 }} />{gt(15659)}</Button>
        </Upload>)
    }

    getExcelColBtn = (stateName, label) => {
        const { excelColumns } = this.props.data.document2;
        let value = excelColumns ? excelColumns[stateName] : null;
        let disabled = false;

        if (stateName == "excelBaseCostNoTaxCol" && excelColumns?.excelBaseCostWithTaxCol) disabled = true;
        if (stateName == "excelBaseCostWithTaxCol" && excelColumns?.excelBaseCostNoTaxCol) disabled = true;
        if ((stateName == "excelDscnt1Col" || stateName == "excelDscnt2Col" || stateName == "excelBonusQtyCol" || stateName == "excelQtyForBonusQtyCol")
            && (!excelColumns || (!excelColumns.excelBaseCostNoTaxCol && !excelColumns.excelBaseCostWithTaxCol))) disabled = true;

        let content = [<Button onClick={() => {
            this.setState({ popABC: false }, () => {
                this.props.dataActions.setJson('document2', { excelColumns: { ...(excelColumns ? excelColumns : {}), [stateName]: null } })
            })
        }}><Icon type={"delete"} /></Button>];

        "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").forEach((x, i) => {
            if (i == 8 || i == 17) content.push(<br />)
            content.push(<Button onClick={() => {
                this.setState({ popABC: false }, () => {
                    this.props.dataActions.setJson('document2', { excelColumns: { ...(excelColumns ? excelColumns : {}), [stateName]: x } })
                })
            }}>{x}</Button>)
        });

        return (<h4>
            <Popover placement="right" content={content} title={this.getText(14614)} trigger="click" visible={this.state.popABC === stateName}>
                <Button disabled={disabled} style={{ marginLeft: 10 }} onClick={() => { this.setState({ popABC: stateName }) }} >{value ? value : '?'}</Button>
                {stateName == 'excelItemCol' || stateName == 'excelItemNameCol' || stateName == 'excelQtyCol' ? (<span style={{ color: 'red' }}> * </span>) : ""}
                {label}
            </Popover>
        </h4>)
    }

    closeExcelModel = () => {
        this.setState({
            modalExcelUpload: false,
            // excelItemCol: null,
            // excelItemNameCol: null,
            // xcelQtyCol: null,
            popABC: false,
            fileList: [],
        });
    }

    getXmlValue = (v) => {
        let value = v;
        let xmlSplit = v ? v.split('>') : [];
        let xmlValue = '';
        if (xmlSplit.length > 1) {
            value = xmlSplit.splice(-1)[0];
            xmlValue = xmlSplit.join('>') + '>';
        }
        return { value, xmlValue }
    }

    onEditedRecordChange = (record: RecordObj, callback) => {
        this.props.dataActions.setJson("document2", { editedRecord: record });
        if (callback) { callback(record); }
    }

    getUOMSizesRuler = (itemCode) => {
        const clearRuler = () => { this.props.dataActions.overrideJson("UOM_Ruler", {}) };
        var dataSend = "_ItemCode\r" + itemCode;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_UOM_ruler_by_itemCode", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", (ob) => {
            if (ob.data) {
                let rowsArr = (ob.data).split('\r');
                if (rowsArr.length > 1) {
                    let arr = rowsArr.map((row) => {
                        let rowArr = row.split('\f');
                        return {
                            sizeID: rowArr[0],
                            sizeDesc: rowArr[1],
                            sizeUOMQty: rowArr[2],
                            itemRuler1ID: rowArr[3],
                        }
                    })
                    this.props.dataActions.setJson("UOM_Ruler", { data: arr });
                    setTimeout(() => {
                        let record = this.props.data.document2.editedRecord;
                        let res = arr[0];
                        if (!record.unitID && res) {
                            let quantityInUnit = res.sizeUOMQty ? res.sizeUOMQty : 1;
                            let unitQuantity = (this.props.documentType == 21 || this.props.documentType == 22 || this.props.documentType == 9 /*|| this.props.documentType == 17*/) ? -1 : 1;
                            let newRecord = {
                                ...record,
                                unitID: res.sizeID,
                                unitDescription: res.sizeDesc,
                                unitQuantity,
                                quantityInUnit,
                                quantity: parseFloat(quantityInUnit * unitQuantity).toFixed(3),
                                totalSumWithTax: parseFloat((quantityInUnit * unitQuantity * record.unitPriceWithTax) * (1.00 - record.discountPercentage / 100)).toFixed(4),
                                totalSumNoTax: parseFloat((quantityInUnit * unitQuantity * record.unitPriceNoTax) * (1.00 - record.discountPercentage / 100)).toFixed(4),
                            }
                            this.onEditedRecordChange(newRecord)
                        }

                        if (arr.length > 1) {
                            clearTimeout(this.focusTimeout);
                            this.focusTimeout = setTimeout(() => {
                                let inputSelector = document.getElementById("unit_size_selector")
                                let inputSelectorDiv = inputSelector.getElementsByTagName("div")[0]
                                inputSelectorDiv.focus()
                            }, 500);
                        }
                    }, 500);
                } else {
                    clearRuler();
                }
            } else {
                clearRuler();
            }
        },
            e => e); // callback problem here 
    }

    getSizeDescription = (_sizeID) => {
        // get description of sizeid of the ruler in store
        let obj = Jax.get(this.props.data, "UOM_Ruler.data", [])
            .find(i => (i.sizeID).toString() === _sizeID.toString());
        return (obj) ? obj['sizeDesc'] : '';
    }

    onBlurItemInput = (selection) => {

        console.log("onBlur");
        //for empty string do nothing
        if (!selection.target.value) return;

        let record = this.props.data.document2.editedRecord;

        let _supplier = this.props.data.document2.filterItemsPerSupplier == '1' ? this.props.data.document2.supplierCode : '0';
        let val = selection.target.value;
        let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\f_supplier\r" + val + "\f1\f1\f" + _supplier;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, 'check_all_item_code_per_supplier', OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob: Object) => {
                let resArr = (ob.data).split('\f');
                let _ItemId = resArr[0];
                let _ItemCode = resArr[1];
                let _ItemName = resArr[2];
                let _IsMatrix = resArr[3];
                let _IsMatrixFather = resArr[4];
                let _ItemBarCode = resArr[5];
                let _ItemSize1ID = resArr[6];
                let _ItemColorID = resArr[7];

                let _Size1Desc = resArr[8];
                let _ColorDesc = resArr[9];

                let _IsMatrixReverseFather = resArr[10];
                let _IsMatrixReverse = resArr[11];
                let _UOM_Qty = resArr[12];
                let _IsValid = (this.props.data.document2.dataSource.filter(i => i.itemCode === _ItemCode && i.isValid == 1 && i.isSerialize == '0').length) ? 5 : parseInt(resArr[13]);

                if (!_ItemCode) {
                    // item not found
                    // item found and is regular
                    this.onEditedRecordChange({
                        ...record,
                        itemCode: val,//_ItemCode,
                        itemBarcode: _ItemBarCode,
                        description: _ItemName,
                        quantity: (this.props.documentType == 21 || this.props.documentType == 22 || this.props.documentType == 9 /*|| this.props.documentType == 17*/) ? -1 : 1,
                        isValid: 0,
                        unitPriceWithTax: 0.00,
                        discountPercentage: 0.00,
                        totalSumWithTax: 0.00,
                    }, selection.callback);

                    this.props.uiActions.showModal(TableIDs.SelectorItemsModal, {});
                    return;
                }
                if (_IsMatrixFather === '1') {
                    // item found and is matrix father
                    let modalParamObj = { ...this.props.ui.data, mCode: _ItemCode };
                    this.props.uiActions.showModal(TableIDs.modalMatrixQuantities, modalParamObj);
                    return;
                }

                this.getUOMSizesRuler(_ItemCode);

                // get item select price/cost
                let dataSend = "_itemCode\f_customerId\f_supplierId\f_priceListId\f_posId\f_docTime\r" +
                    [_ItemCode, //val,
                        this.props.data.document2.customerCode,
                        this.props.data.document2.supplierCode, //null,
                        this.props.data.document2.priceListId,
                        this.props.posId,
                        this.props.data.document2.docDateTime
                    ].join("\f");

                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, "get_item_line_meta", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab", (ob: Object) => {
                    let resArr = (ob.data).split('\f');
                    let _unitCost = resArr[0];
                    let _price = resArr[1];
                    let _unitCostNoTax = resArr[2];
                    let _priceNoTax = resArr[3];
                    let _taxId = resArr[4];
                    let _taxPercentageValue = resArr[5];
                    let _supplierId = resArr[6];
                    let _isSerialize = resArr[7];
                    let _isWeightable = resArr[8];

                    // console.log('this.state.hidePrices && this.props.documentType', this.props.hidePrices, this.props.documentType)
                    if (this.props.hidePrices && this.props.documentType == '15') {
                        _unitCost = 0;
                        _price = 0;
                        _unitCostNoTax = 0;
                        _priceNoTax = 0;
                    }


                    let customerDiscount = this.props.data.document2.customerDiscount ? this.props.data.document2.customerDiscount : 0;
                    console.log("ocument2 data", customerDiscount)


                    let prevRemarks = this.getXmlValue(record.remarks).value;
                    let _remarks = '';
                    if (parseInt(_IsMatrix, 10) === 1) {
                        _remarks = encodeXml(_remarks, 'Color', _ColorDesc);
                        _remarks = encodeXml(_remarks, 'Size1', _Size1Desc);
                    }

                    if (prevRemarks) _remarks += prevRemarks;
                    if (resArr[24]) _remarks += resArr[24];

                    let _uq = (_IsMatrixReverseFather && (_IsMatrixReverseFather === 1 || _IsMatrixReverseFather === '1')) ? _UOM_Qty * 1.00 : 1.00;
                    let _fq = (this.props.documentType == 21 || this.props.documentType == 22 || this.props.documentType == 9 /*|| this.props.documentType == 17*/) ? -1 : 1;
                    let _q = _fq * _uq;

                    if (this.props.config.isUnitCost) {
                        if ((this.props.costCalcOption == '1'||this.props.costCalcOption == '2') && resArr[20]) {
                            _unitCostNoTax = resArr[20];
                            _unitCost = _unitCostNoTax * ((parseFloat(_taxPercentageValue) + 100) / 100);
                        }
                        this.onEditedRecordChange({
                            ...record,
                            itemCode: _ItemCode,
                            itemBarcode: _ItemBarCode,
                            description: _ItemName,
                            isValid: _IsValid,
                            quantity: _q,
                            unitPriceWithTax: parseFloat(_unitCost).toFixed(4),//_unitCost,//1,
                            unitPriceNoTax: parseFloat(_unitCostNoTax).toFixed(4),//_unitCost,//1,
                            discountPercentage: customerDiscount,
                            totalSumWithTax: (parseFloat(_unitCost) * _q * (1 - customerDiscount / 100)).toFixed(4),
                            totalSumNoTax: (parseFloat(_unitCostNoTax) * _q * (1 - customerDiscount / 100)).toFixed(4),
                            taxId: _taxId,
                            taxPercentageValue: parseFloat(_taxPercentageValue),
                            remarks: _remarks,

                            unitID: _ItemSize1ID, //_Size1Desc,
                            quantityInUnit: _uq,
                            unitQuantity: _fq,
                            unitDescription: this.getSizeDescription(_ItemSize1ID),//`$DESC(${_q})`,
                            isWeightable: _isWeightable,
                            isSerialize: _isSerialize,

                            cost: parseFloat(_unitCostNoTax).toFixed(4),
                            supplierId: _supplierId,

                            costCurrencyId: resArr[10],
                            costCurrencySymbol: resArr[11],
                            costCurrencyValue: resArr[12],
                            costCurrencyRate: resArr[13],

                            bonusCalcData: {
                                mBaseCost: resArr[16],
                                mSupDscnt1: resArr[17],
                                mSupDscnt2: resArr[18],
                                mSupDscnt3: resArr[19],
                                mCostIncludeDscnt: resArr[20],
                                mBonusQty: resArr[21],
                                mQtyForBonusQty: resArr[22],
                                mCostIncludeBonus: resArr[23]
                            }


                        }, selection.callback);
                    }
                    else {
                        this.onEditedRecordChange({
                            ...record,
                            itemCode: _ItemCode,
                            itemBarcode: _ItemBarCode,
                            description: _ItemName,
                            isValid: _IsValid,
                            quantity: _q,
                            unitPriceWithTax: parseFloat(_price).toFixed(4),//_unitCost,//1,
                            unitPriceNoTax: parseFloat(_priceNoTax).toFixed(4),//_unitCost,//1,
                            discountPercentage: customerDiscount,
                            totalSumWithTax: (parseFloat(_price) * _q * (1 - customerDiscount / 100)).toFixed(4),
                            totalSumNoTax: (parseFloat(_priceNoTax) * _q * (1 - customerDiscount / 100)).toFixed(4),
                            taxId: _taxId,
                            taxPercentageValue: parseFloat(_taxPercentageValue),
                            remarks: _remarks,

                            unitID: _ItemSize1ID, //_Size1Desc,
                            quantityInUnit: _uq,
                            unitQuantity: _fq,
                            unitDescription: this.getSizeDescription(_ItemSize1ID),
                            // unitDescription: `${this.getSizeDescription(_ItemSize1ID)}(${_uq})`,//`$DESC(${_q})`,
                            isWeightable: _isWeightable,
                            isSerialize: _isSerialize,

                            cost: parseFloat(_unitCostNoTax).toFixed(4),
                            supplierId: _supplierId,

                        }, selection.callback);
                    }

                    setTimeout(() => {
                        this.setState({ disabledAddItem: false })
                    }, 100);

                }, (error: any) => {
                    message.error('שגיאה לא ידועה');
                });
            },
            (error: any) => {
                message.error('שגיאה לא ידועה');
            }
        );
    }

    openModalCreateEditItem = (itemId) => {
        this.isCreateNewItem = !itemId;
        this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { itemId })
    }

    handleAddDocLines = (DocumentNumber, DocumentPOSID, DocumentTypeId) => {
        var dataSend = "mDocNumber\fmDocPos\fmDocType\r" + DocumentNumber + '\f' + DocumentPOSID + '\f' + DocumentTypeId;
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, "get_document_lines", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", (ob) => {
            let lines = (ob.data) ? ob.data.split('\r') : [];
            //console.log("nnnnnnnnnnnnnnnn1: ", lines);
            let demi = lines.map(i => {
                let line = i.split('\f');
                let entry = {
                    //key,
                    //keyVal,
                    isValid: 1,
                    itemCode: line[3],
                    // 4 - barcode
                    description: line[5],
                    unitPriceNoTax: line[6],
                    unitPriceWithTax: line[7],
                    quantity: line[8],

                    discountPercentage: line[9],
                    totalSumNoTax: line[10],
                    totalSumWithTax: line[11],
                    taxId: line[12],
                    taxPercentageValue: line[13],
                    remarks: line[16],
                    unitID: line[18],
                    quantityInUnit: line[19],
                    unitDescription: '',
                    unitQuantity: line[20],

                }
                //console.log("nnnnnnnnnnnnnnnn: ", entry);
                this.onAddDocLine(entry, DocumentTypeId);
                return i;
            })
            message.success(this.getText(15423), 3);

        }, (e) => {
            message.error(this.getText(15424), 3);
        }
        ); // callback problem here  

    }

    onAddDocLine = (obj, sourceDocumentTypeId) => {
        let len = (this.props.data.document2.dataSource).length + 1;
        let newObj = { ...this.props.data.document2.editedRecord, ...obj, key: len, keyVal: len };
        let sourcePlus = ![9, 21, 22].filter(f => f == sourceDocumentTypeId).length;
        let curPlus = ![9, 21, 22].filter(f => f == this.props.documentType).length;
        if ((sourcePlus && !curPlus) || (!sourcePlus && curPlus)) {
            newObj = { ...newObj, quantity: newObj.quantity * -1, totalSumNoTax: newObj.totalSumNoTax * -1, totalSumWithTax: newObj.totalSumWithTax * -1 }
        }
        let emptyRecord: RecordObj = {
            key: 0,
            keyVal: 0,
            itemCode: '',
            itemBarcode: '',
            description: '',
            quantity: 0.00,
            unitPriceWithTax: 0.00,
            discountPercentage: 0.00,
            totalSumWithTax: 0.00,
            isWeightable: 0,
        };
        this.props.dataActions.setJson("document2", { dataSource: [...this.props.data.document2.dataSource, newObj], editedRecord: emptyRecord });
    }

    modalEditQtyMultiInput = (text, record) => {
        if (record.isActive) {
            return (<InputNumber
                autoFocus
                onFocus={e => e.target.select()}
                value={text}
                precision={4}
                step={1}
                onChange={newQty => {
                    this.setState({
                        modalEditQtyMultiData:
                            this.state.modalEditQtyMultiData.map((x, i) => {
                                return (i === record.index) ? { ...x, quantity: newQty } : x
                            })
                    })
                }}
                onKeyDown={e => {
                    if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp") {
                        let indexActive = record.index;
                        if (e.key === "ArrowUp") indexActive--;
                        else indexActive++;
                        this.setState({
                            modalEditQtyMultiData:
                                this.state.modalEditQtyMultiData.map((x, i) => {
                                    return (i === indexActive) ? { ...x, isActive: true } : { ...x, isActive: false }
                                })
                        })
                    }
                }}
            />)
        } else {
            return (<span
                onClick={() => {
                    this.setState({
                        modalEditQtyMultiData:
                            this.state.modalEditQtyMultiData.map((x, i) => {
                                return (i === record.index) ? { ...x, isActive: true } : { ...x, isActive: false }
                            })
                    })
                }}>{text}</span>)
        }
    }

    modalEditQtyMultiSave = () => {
        let dataSource = this.props.data.document2.dataSource.map(x => {
            // changed by oz - 29/3/23
            // let dataSource = [...this.props.data.document2.dataSource];
            // if (!this.state.linesOrderAsc) { dataSource = dataSource.reverse() }
            // //
            // dataSource = dataSource.map((x, i) => {  
            // let y = this.state.modalEditQtyMultiData[i];
            let y = this.state.modalEditQtyMultiData.find(f => f.keyVal == x.keyVal);
            return {
                ...x,
                quantity: y.quantity,
                totalSumWithTax: parseFloat((y.quantity * x.unitPriceWithTax) * (1.00 - x.discountPercentage / 100)).toFixed(4),
                totalSumNoTax: parseFloat((y.quantity * x.unitPriceNoTax) * (1.00 - x.discountPercentage / 100)).toFixed(4),
            }
        })
        this.props.dataActions.setJson("document2", { dataSource })
        this.setState({ modalEditQtyMulti: false, modalEditQtyMultiData: [] })
    }

    renderStatusIsSerialize = (text) => {
        switch (text) {
            case 1: return (<Icon type="check" style={{ color: 'green' }} />)
            case 2: return (<Icon type="close" style={{ color: 'red' }} />)
            default: return null
        }
    }

    renderSerialNum = (text, record) => {
        const { serialNumbers } = this.state.modalIsSerialize
        const onChange = (e, a) => {
            if (!this.ctrlBKeyFlag) {
                this.setStateSerialNumbers(
                    serialNumbers.map(x => {
                        return {
                            ...x,
                            value: (x.key === record.key) ? e : x.value,
                            active: (x.key === a)
                        }
                    })
                )
            }
        }

        if (record.active) {
            return (<Input
                autoFocus
                id="input_serial_number"
                value={text}
                onChange={e => {
                    onChange(e.target.value, record.key)
                }}
                onKeyDown={e => {
                    e.stopPropagation()
                    switch (e.key) {
                        case "b": case "B": case "נ": case "M": case "m": case "צ":
                            if (e.ctrlKey) {
                                if (!serialNumbers.filter(f => f.active).length) {
                                    message.error(this.getText(18508))
                                }
                                this.ctrlBKeyFlag = true;
                                this.barcodeReaderBuffer = '';
                            } else {
                                if (this.ctrlBKeyFlag) {
                                    this.barcodeReaderBuffer += e.key;
                                }
                            }
                            break;

                        case "Enter": case "ArrowDown":
                            if (e.key === "Enter" && this.ctrlBKeyFlag) {
                                this.ctrlBKeyFlag = false;
                                onChange(this.barcodeReaderBuffer, record.key + 1)
                            } else {
                                onChange(text, record.key + 1)
                            }

                            break;

                        case "ArrowUp":
                            onChange(text, record.key - 1)
                            break;

                        default:
                            if (this.ctrlBKeyFlag && e.key.length == 1) {
                                this.barcodeReaderBuffer += e.key;
                            }
                            break;
                    }
                }}
            />)
        } else {
            return (<span onClick={() => { onChange(text, record.key) }}>
                <Icon type="edit" style={{ marginLeft: 10 }} />
                {text}
            </span>);
        }
    }

    renderActionsIsSerialize = (text, record) => {
        if (this.state.modalIsSerialize) {
            const { serialNumbers } = this.state.modalIsSerialize;

            let genericIcons = [{
                type: "delete",
                onClick: () => {
                    this.setStateSerialNumbers(serialNumbers.filter(f => f.key != record.key).map((x, i) => { return { ...x, key: i + 1 } }))
                },
                tooltip: this.getText(18512)
            }]

            if (record.status > 1) {
                genericIcons.push({
                    type: "issues-close",
                    onClick: () => {
                        this.setStateSerialNumbers(serialNumbers.map(x => { return x.key == record.key ? { ...x, status: 1 } : x }))
                    },
                    tooltip: this.getText([4, 16, 22].indexOf(parseInt(this.props.documentType)) > -1 ? 18513
                        : [17, 18, 1, 2].indexOf(parseInt(this.props.documentType)) > -1 ? 18514
                            : 18515)
                })
            }

            return (<TableLineIcons {...{ genericIcons }} />)

        }
    }

    setStateSerialNumbers = (serialNumbers, next) => {
        this.setState({ modalIsSerialize: { ...this.state.modalIsSerialize, serialNumbers } }, next)
    }

    checkSerialNumbers = () => {
        const msn = this.state.modalIsSerialize
        this.setStateSerialNumbers(
            msn.serialNumbers.map(x => { return { ...x, active: false, status: x.status == 1 ? 1 : 0 } }),
            () => { this.checkSerialNumber(1) }
        )
    }

    tooltipButtonIcon = (tooltip, onClick, icon, popconfirm, popover) => {
        if (popconfirm) {
            return (<Tooltip title={this.getText(tooltip)}>
                <Popconfirm {...popconfirm} onConfirm={onClick}>
                    <Button style={{ marginRight: 2, width: 45, paddingRight: 0, paddingLeft: 0 }}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={icon} />
                    </Button>
                </Popconfirm>
            </Tooltip>)
        } else if (popover) {
            return (<Tooltip title={this.getText(tooltip)}>
                <Popover {...popover}>
                    <Button style={{ marginRight: 2, width: 45, paddingRight: 0, paddingLeft: 0 }} onClick={onClick}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={icon} />
                    </Button>
                </Popover>
            </Tooltip>)
        } else {
            return (<Tooltip title={this.getText(tooltip)}>
                <Button style={{ marginRight: 2, width: 45, paddingRight: 0, paddingLeft: 0 }} onClick={onClick}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={icon} />
                </Button>
            </Tooltip>)
        }
    }

    checkSerialNumber = (key) => {
        const msn = this.state.modalIsSerialize
        const nsList = msn ? msn.serialNumbers : []
        const findRow = nsList.find(f => f.key === key)
        const { dataSource, storageId } = this.props.data.document2;
        // console.log("checkSerialNumber", key, this.props)
        if (findRow) {
            if (msn.isSerialize == 1 && !findRow.value) {
                this.setStateSerialNumbers(
                    nsList.map(x => { return { ...x, status: x.key === key ? 1 : x.status } }),
                    () => { this.checkSerialNumber(key + 1) }
                )
            } else if (findRow.status == 1) {
                this.checkSerialNumber(key + 1);
            } else {
                let dataSend = "_item\f_sn\f_storage\f_draftNumber\r"
                    + msn.itemCode + "\f" + findRow.value + "\f" + storageId + "\f" + this.props.data.document.editedRecord.number
                this.props.sendAPI("get_stock_serial_number", dataSend, (ob) => {
                    let splitRes = ob.data ? ob.data.split("\r") : []
                    let stockRes = parseInt(splitRes[0]);
                    let issetInDraft = parseInt(splitRes[1]);
                    let isInDoc = [4, 16, 21, 22].indexOf(parseInt(this.props.documentType)) > -1;
                    let isOutDoc = [17, 18, 20, 5051, 1, 2].indexOf(parseInt(this.props.documentType)) > -1;
                    let supp = isOutDoc && splitRes[2] ? splitRes[2] : null;
                    let newStatus = issetInDraft ? 2 : 1;
                    if ((stockRes && isInDoc) || (!stockRes && isOutDoc)) newStatus = 2;
                    this.setStateSerialNumbers(
                        nsList.map(x => { return { ...x, status: x.key === key ? newStatus : x.status, supp } }),
                        () => { this.checkSerialNumber(key + 1) }
                    )
                })
            }
        } else {

            if (msn && !nsList.filter(f => f.status != 1).length) {
                let addRows = nsList.map(x => {
                    let key = x.key + dataSource.length;
                    let calcRecord = this.calculateRecord("quantity", 1);
                    if (!calcRecord.itemCode) calcRecord = { ...msn }
                    return { ...calcRecord, unitQuantity: 1, serialNumber: x.value, key, keyVal: key, remarks: x.supp ? "יבואן: " + x.supp : "" }
                })

                console.log("isvalid", addRows)
                this.props.dataActions.setJson("document2", {
                    dataSource: [
                        ...dataSource,
                        ...addRows
                    ],
                    editedRecord: {
                        key: 0,
                        keyVal: 0,
                        itemCode: '',
                        itemBarcode: '',
                        description: '',
                        quantity: 0.00,
                        unitPriceWithTax: 0.00,
                        discountPercentage: 0.00,
                        totalSumWithTax: 0.00,

                        unitID: 0,
                        quantityInUnit: 0,
                        unitQuantity: 0.00,
                        unitDescription: '',

                        isWeightable: 0,
                    }
                })

                this.setState({ modalIsSerialize: null }, () => {
                    if (this.priceListBack) {
                        this.onChangePriceList(this.priceListBack);
                        this.priceListBack = null;
                    }
                })
            }
        }
    }

    modalPercentageDscntContent = () => {
        const { value, type } = this.state.modalPercentageDscnt;
        return (<div>
            <Form.Item label={this.getText(18741)}>
                <InputNumber min={0} max={100} value={value} onChange={(e) => { this.setState({ modalPercentageDscnt: { value: e, type } }) }} />
            </Form.Item>
            <Form.Item>
                <Radio.Group value={type} onChange={(e) => { this.setState({ modalPercentageDscnt: { value, type: e.target.value } }) }}>
                    <Radio value={"1"}>{this.getText(18742)}</Radio><br />
                    <Radio value={"2"}>{this.getText(18743)}</Radio><br />
                    <Radio value={"3"}>{this.getText(18744)}</Radio>
                </Radio.Group>
            </Form.Item>
        </div>)
    }

    savePercentageDscnt = () => {
        const { value, type } = this.state.modalPercentageDscnt;
        const closeModal = () => { this.setState({ modalPercentageDscnt: null }) }
        let itemList = Jax.get(this.props.data, "document2.dataSource", []);

        if (type == 3) {
            let sumDoc = itemList.reduce(function (a, v) {
                if (v.key === 0) return a;
                return a + parseFloat(v.totalSumWithTax ? v.totalSumWithTax : 0.0);
            }, 0) || 0.0;
            let finishedSum = sumDoc * (1 - (value / 100))
            this.props.addDiscountRoundItem(finishedSum, closeModal);
        } else {
            let dataSource = itemList.map(record => {
                const { quantity, unitPriceNoTax, unitPriceWithTax, discountPercentage, bonusCalcData, taxPercentageValue } = record;
                if ((this.props.costCalcOption == '1'||this.props.costCalcOption == '2') && (this.props.documentType == 4 || this.props.documentType == 16)) {
                    if (type == 2 && bonusCalcData && bonusCalcData.mSupDscnt3 > 0) return record;
                    let mBaseCost = bonusCalcData.mBaseCost ? parseFloat(bonusCalcData.mBaseCost) : unitPriceNoTax;
                    let newUnitPriceNoTax = mBaseCost;
                    if (bonusCalcData.mSupDscnt1) newUnitPriceNoTax = parseFloat(newUnitPriceNoTax * (1.00 - bonusCalcData.mSupDscnt1 / 100))
                    if (bonusCalcData.mSupDscnt2) newUnitPriceNoTax = parseFloat(newUnitPriceNoTax * (1.00 - bonusCalcData.mSupDscnt2 / 100))
                    newUnitPriceNoTax = parseFloat(newUnitPriceNoTax * (1.00 - value / 100));
                    let newUnitPriceWithTax = parseFloat(newUnitPriceNoTax * (1.00 + taxPercentageValue / 100));
                    return {
                        ...record,
                        unitPriceNoTax: newUnitPriceNoTax.toFixed(4),
                        unitPriceWithTax: newUnitPriceWithTax.toFixed(4),
                        discountPercentage: '0.00',
                        totalSumNoTax: parseFloat(quantity * newUnitPriceNoTax).toFixed(4),
                        totalSumWithTax: parseFloat(quantity * newUnitPriceWithTax).toFixed(4),
                        bonusCalcData: {
                            ...bonusCalcData,
                            mBaseCost,
                            mSupDscnt3: value,
                            mCostIncludeDscnt: newUnitPriceNoTax,
                            mCostIncludeBonus: parseFloat(newUnitPriceNoTax * bonusCalcData.mQtyForBonusQty / (bonusCalcData.mQtyForBonusQty + bonusCalcData.mBonusQty))
                        }
                    }
                } else {
                    if (type == 2 && discountPercentage > 0) return record;
                    return {
                        ...record,
                        discountPercentage: value,
                        totalSumWithTax: parseFloat((quantity * unitPriceWithTax) * (1.00 - value / 100)).toFixed(4),
                        totalSumNoTax: parseFloat((quantity * unitPriceNoTax) * (1.00 - value / 100)).toFixed(4),
                    }
                }
            })
            this.props.dataActions.setJson("document2", { dataSource });
            closeModal()
        }
    }


    render() {
        ///console.log("customerRecord-333", this.props.data.document2.priceListId)

        //const { onAdd, onDelete } = this.props;
        const priceLists = Jax(this.props.data)
            .path("document2.priceList")
            .list()
            .where("taxRegion")
            .equals(this.getPosTaxRegion())
            .map((el) => {
                return (<Option key={el.id}>{el.name}</Option>)
            });

        let _dataSource = [...this.props.data.document2.dataSource];
        _dataSource = _dataSource.map(i => i); // just create a copy for calling reverse
        if (this.state.linesValidFilter === 3) {
            _dataSource = _dataSource.filter((i: RecordObj) => { return i.unitPriceWithTax === 0 || i.unitPriceWithTax === 0.0 });
        } else if (this.state.linesValidFilter === 12) {
            _dataSource = _dataSource.filter((i: RecordObj) => { return i.isValid == 2 });
        } else if (this.state.linesValidFilter === 13) {
            _dataSource = _dataSource.filter((i: RecordObj) => { return i.isValid == 3 });
        } else if (this.state.linesValidFilter === 14) {
            _dataSource = _dataSource.filter((i: RecordObj) => { return i.isValid == 4 });
        } else if (this.state.linesValidFilter === 15) {
            _dataSource = _dataSource.filter((i: RecordObj) => { return i.isValid == 5 });
        } else {
            // not 3
            if (this.state.linesValidFilter !== 2) {
                _dataSource = _dataSource.filter((i: RecordObj) => {
                    if (this.state.linesValidFilter === 1) {
                        return i.isValid === 1; //valid
                    } else {
                        return !i.isValid || i.isValid === 0; // not valid or null
                    }
                })
            }
        }

        let curIsAsc = _dataSource.length > 1 && _dataSource[0].key < _dataSource[1].key;
        if ((!this.state.linesOrderAsc && curIsAsc) || this.state.linesOrderAsc && !curIsAsc) {
            _dataSource.reverse();
        }

        let columns = [{
            title: this.getText(12513),
            dataIndex: "key",
            key: "key",
            sorter: false,
            width: '4%'/*50*/,
            render: (text, record) => text
        }, {
            title: this.getText(12514),
            dataIndex: "itemCode",
            key: "itemCode",
            sorter: false,
            width: '19%'/*200*/,
            render: (text, record) => {
                if (this.state.editedRecord.key === record.key) {
                    return (
                        <Input
                            value={this.state.editedRecord.itemCode}
                            onChange={(e) => { this.updateEditedRecordFields({ itemCode: e.target.value }, null); }}
                        />)
                } else {
                    return text;
                }
            }
        }, {
            title: this.getText(12515),
            dataIndex: "description",
            key: "description",
            sorter: false,
            width: '23%'/*200*/,
            render: (text, record) => {
                if (this.state.editedRecord.key === record.key) {
                    return (
                        <Input
                            value={this.state.editedRecord.description}
                            onChange={(e) => { this.updateEditedRecordFields({ description: e.target.value }, null); }}
                        />)
                } else {
                    return text;
                }
            }
        },

        {
            title: this.getText(16169),//this.getText(12516),
            dataIndex: "unitID",
            key: "unitID",
            sorter: false,
            width: '10%'/*100*/,
            render: (text, record) => {
                if (this.state.editedRecord.key === record.key) {
                    return (
                        <Input
                            value={this.state.editedRecord.quantity}
                            onChange={(e) => {
                                let _record = { ...record, quantity: e.target.value };
                                let _updated_record = this.updatePriceCostLineChange(_record);
                                this.updateEditedRecordFields({ ..._updated_record }, null);
                            }
                            }
                        />)
                } else {
                    return text;
                }
            }
        }, {
            title: this.getText(16170),//this.getText(12516),
            dataIndex: "unitQuantity",
            key: "unitQuantity",
            sorter: false,
            width: '5%'/*100*/,
            render: (text, record) => {
                if (this.state.editedRecord.key === record.key) {
                    return (
                        <Input
                            value={this.state.editedRecord.quantity}
                            onChange={(e) => {
                                let _record = { ...record, quantity: e.target.value };
                                let _updated_record = this.updatePriceCostLineChange(_record);
                                this.updateEditedRecordFields({ ..._updated_record }, null);
                            }
                            }
                        />)
                } else {
                    return text;
                }
            }
        },

        {
            title: this.getText(12516),
            dataIndex: "quantity",
            key: "quantity",
            sorter: false,
            width: '7%'/*100*/,
            render: (text, record) => {
                if (this.state.editedRecord.key === record.key) {
                    return (
                        <Input
                            value={this.state.editedRecord.quantity}
                            onChange={(e) => {
                                let _record = { ...record, quantity: e.target.value };
                                let _updated_record = this.updatePriceCostLineChange(_record);
                                this.updateEditedRecordFields({ ..._updated_record }, null);
                            }
                            }
                        />)
                } else {
                    return text;
                }
            }
        }, {
            title: this.getText(12517),
            dataIndex: "unitPriceWithTax",
            key: "unitPriceWithTax",
            sorter: false,
            width: '7%'/*100*/,
            render: (text, record) => {
                if (this.state.editedRecord.key === record.key) {
                    return (
                        <Input
                            value={this.state.editedRecord.unitPriceWithTax}
                            onChange={(e) => {
                                let _record = { ...record, unitPriceWithTax: e.target.value };
                                let _updated_record = this.updatePriceCostLineChange(_record);
                                this.updateEditedRecordFields(_updated_record, null);
                            }}
                        />)
                } else {
                    return text;
                }
            }
        }, {
            title: this.getText(12518),
            dataIndex: "discountPercentage",
            key: "discountPercentage",
            sorter: false,
            width: '7%'/*100*/,
            render: (text, record) => {
                if (this.state.editedRecord.key === record.key) {
                    return (
                        <Input
                            value={this.state.editedRecord.discountPercentage}
                            onChange={(e) => {
                                let _record = { ...record, discountPercentage: e.target.value };
                                let _updated_record = this.updatePriceCostLineChange(_record);
                                this.updateEditedRecordFields({ ..._updated_record } /*{ discountPercentage: e.target.value }*/, null);
                            }}
                        />)
                } else {
                    return text;
                }
            }
        }, {
            title: this.getText(12519),
            dataIndex: "totalSumWithTax",
            key: "totalSumWithTax",
            sorter: false,
            width: '8%'/*100*/,
            render: (text, record) => {
                if (this.state.editedRecord.key === record.key) {
                    return (
                        <Input
                            value={this.state.editedRecord.totalSumWithTax}
                            onChange={(e) => { this.updateEditedRecordFields({ totalSumWithTax: e.target.value }, null); }}
                        />)
                } else {
                    return text;
                }
            }
        }, {
            title: "",
            dataIndex: "",
            key: "actions",
            // fixed: "left",
            sorter: false,
            width: '10%'/*100*/,
            render: (text, record) => {
                if (this.props.data.document2/*state*/.editedRecord.key === record.key) {
                    return (<span>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                            onClick={() => {
                                this.props.dataActions.setJson({
                                    //this.setState({
                                    dataSource: this.props.data.document2/*state*/.dataSource.map(i => {
                                        return (record.key === i.key)
                                            ? this.props.data.document2/*state*/.editedRecord
                                            : i
                                    }),
                                    editedRecord: {}
                                });
                            }} />
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                            onClick={() => {
                                this.setEditedRecordFields({}, null);
                            }} />
                    </span>);

                } else {
                    return (<span>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"}
                            onClick={(e) => { this.updateEditedRecordFields({ ...record }); }
                            }
                        />
                        <Popconfirm title={this.getText(12520)}
                            onConfirm={() => {
                                let d = this.props.data.document2.dataSource.filter(i => i.key !== record.key);
                                //this.setState({ dataSource: d });
                                this.props.dataActions.setJson("document2", { dataSource: d });
                            }}
                            okText={this.getText(12521)} cancelText={this.getText(12522)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                        </Popconfirm>

                        <Tooltip title={record.itemCode + this.getText(12523)}>
                            <Icon style={{ fontSize: 16, display: 'none' }} className={"clickable margin-4"} type={"search"} onClick={() => {
                                this.props.dataActions.showModal(TableIDs.modal)
                            }} />
                        </Tooltip>
                        <Tooltip title={this.getText(12524)}>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"barcode"}
                                onClick={() => {
                                    this.setState({ isQtyInput: true, itemCode: record.itemCode/*Jax.get(this.props.data, "document.editedRecord.lines.editedRecord.itemCode", "")*/ },
                                        () => { this.props.uiActions.showModal(TableIDs.labelPrinter) })
                                }} />
                        </Tooltip>

                    </span>);
                }
            }
        }
        ]

        const disabledCheckSerialNumbers = this.state.modalIsSerialize
            && this.state.modalIsSerialize.isSerialize != 1
            && this.state.modalIsSerialize.serialNumbers.filter(f => !f.value).length;

        const duplicateSerialNumbers = r => {
            return (this.state.modalIsSerialize
                && this.state.modalIsSerialize.serialNumbers.filter(f => f.value && f.value == r.value).length > 1)
                || this.props.data.document2.dataSource.filter(f => f.serialNumber && f.serialNumber == r.value).length;
        }

        let issetDuplicateSerialNumbers = false;
        if (this.state.modalIsSerialize) {
            this.state.modalIsSerialize.serialNumbers.forEach(x => {
                if (duplicateSerialNumbers(x)) issetDuplicateSerialNumbers = true;
            })
        }

        const addSerialNumberRow = (<Icon type={"plus"} onClick={() => {
            const { serialNumbers } = this.state.modalIsSerialize;
            this.setStateSerialNumbers([...serialNumbers.map(x => { return { ...x, active: false } }), { key: serialNumbers.length + 1, value: "", status: 0, active: true }])
        }} />)

        const isProcurement = [4, 16, 17, 18, 31].filter(f => this.props.documentType == f).length ? true : false;

        return (
            <div>
                <Row type="flex">
                    <Col md={{ span: 6 }} sm={{ span: 24 }} xs={{ span: 24 }}
                        style={{ marginBottom: 4/*, display: (parseInt(this.props.documentType, 10) === 4) ? 'none' : undefined*/ }}>
                        {
                            isProcurement ?
                                <span>
                                    {this.getText(16226)}:
                                    <Select
                                        onChange={(val) => { this.props.dataActions.setJson('document2', { filterItemsPerSupplier: val }) }}
                                        value={this.props.data.document2.filterItemsPerSupplier ? this.props.data.document2.filterItemsPerSupplier : '0'}
                                        style={{ width: "200px", marginRight: "4px" }}
                                    >
                                        <Option value={'0'}>{this.getText(16227)}</Option>
                                        <Option value={'1'}>{this.getText(16228)}</Option>
                                    </Select>
                                </span>
                                // ? <div>
                                //     {'עלות מע"מ:'}
                                //     <Switch style={{ marginRight: 8 }}
                                //         defaultChecked
                                //         checked={this.state.isCostWithTax}
                                //         onChange={(checked) => {
                                //             //console.log("checked value: ", checked);
                                //             this.setState({ isCostWithTax: checked })
                                //         }}
                                //         checkedChildren={'כולל'} //<Icon type="check" />}
                                //         unCheckedChildren={'ללא'}//<Icon type="close" />}
                                //     />
                                // </div>
                                : <div style={{ display: (parseInt(this.props.documentType, 10) === 11) ? 'none' : undefined }}>
                                    {this.getText(12525)}:
                                    {/* {this.props.data.document2.custPriceList ?
                                        <h3>{this.props.data.document2.custPriceList.code} - {this.props.data.document2.custPriceList.name}</h3>
                                        :  */}
                                    <Select
                                        className={(this.props.data.document2.priceListId) ? null : styles.requiredSelect}
                                        //combobox={null}
                                        onChange={(val) => {
                                            let oldPriceList = this.props.data.document2.priceListId
                                            this.setState({ modalOnChangePriceList: { oldPriceList, val } })
                                            // this.handlePriceListChange.call(this, val);
                                            // let self = this;
                                            // setTimeout(function () {
                                            //     self.updateLineOnPriceListChange.call(self, null);
                                            // }, 2500)
                                        }}
                                        placeholder={this.getText(16171)}
                                        value={this.props.data.document2.priceListId}
                                        style={{ width: "200px", marginRight: "4px" }}
                                        notFoundContent={this.getText(12528)}
                                        disabled={isProcurement}
                                    >{priceLists}
                                    </Select>
                                    {/* } */}
                                </div>
                        }
                    </Col>
                    <Col md={{ span: 6 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ marginBottom: 4 }}>
                        {this.getText(12526)}:
                        <Select
                            combobox={null}
                            value={this.state.linesValidFilter}
                            onChange={(val) => { this.setState({ linesValidFilter: val }) }}
                            style={{ width: "200px", marginRight: "4px", }}
                        //notFoundContent={"לא נמצאו מחירונים"}
                        //disabled={parseInt(this.props.documentType, 10) === 4}
                        >
                            <Option key={"filter_2"} value={2}>{this.getText(12529)}</Option>
                            <Option key={"filter_1"} value={1}>{this.getText(12530)}</Option>
                            <Option key={"filter_0"} value={0}>{this.getText(12531)}</Option>
                            <Option key={"filter_3"} value={3}>{this.getText(16172)}</Option>

                            <Option key={"valid_type_2"} value={12}>{this.getText(16261)}</Option>
                            <Option key={"valid_type_3"} value={13}>{this.getText(16262)}</Option>
                            <Option key={"valid_type_4"} value={14}>{this.getText(16263)}</Option>
                            <Option key={"valid_type_5"} value={15}>{this.getText(16264)}</Option>
                        </Select>
                    </Col>
                    <Col md={{ span: 5 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ marginBottom: 4 }}>
                        <Checkbox
                            // onChange={(e) => { this.setState({ isSumFlag: e.target.checked }); }}
                            // checked={this.state.isSumFlag}
                            onChange={this.props.changeSumFlag}
                            checked={this.props.isSumFlag}
                            defaultChecked={false}
                        >
                            {this.getText(12527)}
                        </Checkbox>
                    </Col>
                    <Col md={{ span: 7 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ marginBottom: 4 }}>
                        {this.tooltipButtonIcon(16243, () => { this.openModalCreateEditItem('') }, "plus")}
                        {this.tooltipButtonIcon(12524, () => {
                            this.setState({ isQtyInput: false }, () => { this.props.uiActions.showModal(TableIDs.labelPrinter) })
                        }, "barcode")}

                        {this.tooltipButtonIcon(12532, () => {
                            // delete records by filter
                            const filterFlag = this.state.linesValidFilter;
                            const contentText = this.getText((filterFlag === 0) ? 16173 : (filterFlag === 1) ? 16174 : (filterFlag === 2) ? 16175 : 16176)

                            const self = this;

                            confirm({
                                title: this.getText(16177),
                                content: contentText,
                                iconType: 'exclamation-circle',
                                okText: this.getText(16178), // עבור ללשונית מסמך
                                cancelText: this.getText(16179), //ביטול
                                onOk() {
                                    const flag = self.state.linesValidFilter;
                                    self.setState({ linesValidFilter: 2 }, () => {
                                        self.props.dataActions.setJson("document2", {
                                            dataSource: self.props.data.document2.dataSource.filter(i => {
                                                let filter = flag; //this.state.linesValidFilter;
                                                if (filter === 1) {
                                                    return !(i.isValid === 1);
                                                } else if (filter === 0) {
                                                    return !(!i.isValid || i.isValid === 0)
                                                } else if (filter === 3) {
                                                    return !(i.unitPriceWithTax === 0 || i.unitPriceWithTax === 0.0);
                                                    // } else if (filter === 12) {
                                                    //     return !(i.isValid == 2);
                                                    // } else if (filter === 13) {
                                                    //     return !(i.isValid == 3);
                                                    // } else if (filter === 14) {
                                                    //     return !(i.isValid == 4);
                                                    // } else if (filter === 15) {
                                                    //     return !(i.isValid == 5);
                                                } else {
                                                    return false;
                                                }
                                            })
                                        }
                                        )
                                    })

                                },
                                // okButtonProps: { style: { display: 'none' } },
                                onCancel() { },
                            });
                        }, "delete", { title: this.getText(12520), okText: this.getText(12521), cancelText: this.getText(12522) })}

                        {this.tooltipButtonIcon(16180, () => {
                            this.setState({
                                linesOrderAsc: !this.state.linesOrderAsc
                            })
                        }, (this.state.linesOrderAsc === true) ? "sort-ascending" : "sort-descending")}

                        {this.tooltipButtonIcon(16181, () => { this.props.uiActions.showModal(TableIDs.ModalDocumentSelector) }, "import")}

                        {this.tooltipButtonIcon(17067, () => {
                            this.setState({ scanDocOpen: !this.state.scanDocOpen }, () => {
                                let e = document.getElementById("scan-doc-input")
                                if (e) e.focus()
                            })
                        }, "scan", false, {
                            trigger: 'click', title: this.getText(17068), visible: this.state.scanDocOpen,
                            content: (<Input
                                value={""}
                                autoFocus={this.state.scanDocOpen}
                                onBlur={() => { this.setState({ scanDocOpen: false }) }}
                                onKeyDown={e => {
                                    console.log("test scan doc ", e.key)
                                    if (e.key == 'Enter') {
                                        let _pos = parseInt(this.scanDocFieldPos);
                                        let _type = parseInt(this.scanDocFieldType);
                                        let _number = parseInt(this.scanDocFieldNumber);
                                        this.handleAddDocLines(_number, _pos, _type)
                                        this.scanDocFieldPos = '';
                                        this.scanDocFieldType = '';
                                        this.scanDocFieldNumber = '';
                                        // this.setState({ scanDocOpen: false })
                                    } else {
                                        let regex = new RegExp(/[0-9]/);
                                        if (regex.test(e.key)) {
                                            if (this.scanDocFieldPos.length < 3) {
                                                this.scanDocFieldPos += e.key
                                            } else if (this.scanDocFieldType.length < 3) {
                                                this.scanDocFieldType += e.key
                                            } else {
                                                this.scanDocFieldNumber += e.key
                                            }
                                        }
                                    }

                                }} />)
                        })}
                        {this.tooltipButtonIcon(16182, () => { this.setState({ modalExcelUpload: true }) }, "file-excel")}
                        {this.tooltipButtonIcon(18475, () => {
                            let dataSource = this.state.linesOrderAsc ? this.props.data.document2.dataSource : [...this.props.data.document2.dataSource].reverse()
                            let modalEditQtyMultiData = dataSource.map((x, index) => {
                                const { keyVal, itemCode, description, quantity, remarks } = x;
                                const _Size1 = decodeXml(remarks, 'Size1');
                                const _color = decodeXml(remarks, 'Color');
                                return { index, keyVal, itemCode, description, quantity, _Size1, _color, isActive: !index }
                            })

                            this.setState({ modalEditQtyMulti: true, modalEditQtyMultiData })
                        }, "form")}
                        {this.tooltipButtonIcon(18696, () => {
                            this.props.openSelectorTemplete("itemsOnly", () => {
                                this.onChangePriceList(this.props.data.document2.priceListId);
                                // this.handlePriceListChange(this.props.data.document2.priceListId);
                                // setTimeout(this.updateLineOnPriceListChange, 2500)
                            })
                        }, "history")}

                        {this.tooltipButtonIcon(18737, () => {
                            this.setState({ modalPercentageDscnt: { value: "", type: "1" }, })
                        }, "percentage")}

                    </Col>
                </Row>

                <Row>
                    {this.getText(16110)}:

                    <Checkbox
                        checked={this.props.isCostNoTax}
                        onChange={e => {
                            this.props.onChangeState({
                                isCostNoTax: e.target.checked,
                                isCostWithTax: !e.target.checked || this.props.isCostWithTax,
                            })
                        }}>{this.getText(16112)}</Checkbox>
                    <Checkbox
                        checked={this.props.isCostWithTax}
                        onChange={e => {
                            this.props.onChangeState({
                                isCostNoTax: !e.target.checked || this.props.isCostNoTax,
                                isCostWithTax: e.target.checked,
                            })
                        }}>{this.getText(16111)}</Checkbox>

                    {/* <Radio.Group
                        style={{ direction: 'rtl' }}
                        value={this.props.isCostWithTaxRadio}
                        onChange={(e) => {
                            this.props.onChangeTax(e.target.value);
                            // if (this.props.documentType == 4 || this.props.documentType == 18) {
                            //     setTimeout(() => {
                            //         const { supplierCode, priceListId, dataSource } = this.props.data.document2;
                            //         this.props.updatePricesPerSupplier(supplierCode, priceListId, dataSource)
                            //     }, 1000);
                            // } else {
                            //     this.props.ActionQueue.addToQueue(
                            //         {
                            //             action: this.props.dataActions.genericDataSetFilter,
                            //             args: [
                            //                 "PRICES_BY_PRICELIST",
                            //                 this.props.user.companyCode,
                            //                 this.props.user.token,
                            //                 {
                            //                     _id: this.props.data.document2.priceListId,
                            //                     recordsPerPage: 999999,
                            //                     _rows_num: 999999,
                            //                     _words: "",
                            //                     _page_num: 1,
                            //                     _isAsc: 1,
                            //                     _sort_by: 'col_1'
                            //                 },
                            //                 "get_prices_by_price_list"
                            //             ]
                            //         }
                            //     );
                            //     let self = this;
                            //     setTimeout(function () {
                            //         self.updateLineOnPriceListChange.call(self, null);
                            //     }, 1000)
                            // }
                        }}>
                        <Radio value={'0'}>{this.getText(16112)}</Radio>
                        <Radio value={'1'}>{this.getText(16111)}</Radio>
                    </Radio.Group> */}

                </Row>
                {/* ) : ""} */}
                <Row /*type="flex"*/>
                    <TableOptimized
                        user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                        editedRecord={this.props.data.document2.editedRecord}
                        dataSource={_dataSource}
                        columns={columns}
                        api={this.api} callApi={this.callApi}
                        posId={this.props.posId}
                        documentType={this.props.documentType}
                        config={this.props.config}
                        loading={this.props.data.document2.loading}

                        // update local state
                        onDataSourceChange={(data: Array) => { this.props.dataActions.setJson("document2", { dataSource: data }); }}
                        onEditedRecordChange={(record: RecordObj, callback) => {
                            this.props.dataActions.setJson("document2", { editedRecord: record });
                            if (callback) { callback(record); }
                        }}
                        // event handlers
                        onUpdateData={(record: RecordObj) => { this.updateRecordUsingData(record); }} // save using record data
                        onAdd={(record: RecordObj) => { this.saveEditedRecord(record); }} // new row
                        onCheck={(record) => { }} // save edit
                        onEdit={(key) => { this.editRecord(key) }} // make row editable
                        onDelete={(key) => { this.deleteRecord(key); }} // delete row
                        onCancel={(key) => { this.setEditedRecordFields({}, null) }} // cancel save edit
                        onUpdate={(key) => { this.updateRecord(key) }} // save from edit
                        // updateLineOnPriceListChange={() => {
                        //     let self = this;
                        //     setTimeout(function () {
                        //         self.updateLineOnPriceListChange.call(self, null);
                        //     }, 2500)
                        // }}
                        openModalCreateEditItem={this.openModalCreateEditItem}
                        // isSumFlag={this.state.isSumFlag} 
                        isSumFlag={this.props.isSumFlag}
                        linesOrderAsc={this.state.linesOrderAsc}
                        onLabelPrinterChange={(obj, callback) => { this.setState(obj /*{ isQtyInput: true, itemCode: itemCode}*/, callback()) }}
                        // isCostWithTax={this.state.isCostWithTax}
                        hidePrices={this.props.hidePrices}
                        isCostNoTax={this.props.isCostNoTax}
                        isCostWithTax={this.props.isCostWithTax}
                        onBlurItemInput={this.onBlurItemInput}
                        disabledAddItem={this.state.disabledAddItem}
                        setDisabledAddItem={e => {
                            // setTimeout(() => {
                            console.log("setDisabledAddItem", e)
                            this.setState({ disabledAddItem: e })
                            // }, 500);
                        }}
                        scanDocOpen={this.state.scanDocOpen || this.state.modalIsSerialize !== null || this.isCreateNewItem}
                        sendAPI={this.props.sendAPI}
                        costCalcOption={this.props.costCalcOption}
                    //isCostShown={configByDocType(this.props.documentType).isCostShown}
                    />
                </Row>

                <LabelPrinter
                    id={TableIDs.labelPrinter}
                    labels={(this.state.isQtyInput) ? this.itemLabel() : this.responsLabels()}
                    qtyInput={this.state.isQtyInput/*false*/}
                    defaultQty={this.state.defaultQty}

                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
                    title={this.getText(12533)}
                />

                <ModalUnitQtySelector
                    id={TableIDs.modalSelectorUOMQty}
                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions}
                    data={this.props.data} ActionQueue={this.props.ActionQueue}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSelectorUOMQty)}
                    onConfirm={(val) => {
                        this.props.dataActions.setJson("document2", { editedRecord: { ...this.props.data.document2.editedRecord, ...this.calculateRecord('quantity', val) } });
                        //this.onEditedRecordChange({ ...this.props.data.document2.editedRecord, ...this.calculateRecord('quantity', val) });
                    }}
                />

                <ModalDocumentSelector
                    id={TableIDs.modalDocumentSelector}
                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalDocumentSelector)}
                    onConfirm={(val) => {
                        this.props.dataActions.setJson("document2", { editedRecord: { ...this.props.data.document2.editedRecord, ...this.calculateRecord('quantity', val) } });
                        //this.onEditedRecordChange({ ...this.props.data.document2.editedRecord, ...this.calculateRecord('quantity', val) });
                    }}
                    handleAddDocLines={this.handleAddDocLines}
                    sendAPI={this.props.sendAPI}
                    documentType={this.props.documentType}
                />
                <GenericModal
                    title={this.getText(16183)}
                    visible={this.state.modalExcelUpload}
                    onCancel={this.closeExcelModel}
                    width={600}>
                    {this.state.fileList.length ? [
                        <h2>{this.getText(16196)}</h2>,
                        <Spin style={{ margin: 'auto' }} />
                    ] : [
                        <h3>{this.getText(16184)}</h3>,
                        this.getExcelColBtn('excelItemCol', this.getText(16185)),
                        this.getExcelColBtn('excelItemNameCol', this.getText(16186)),
                        this.getExcelColBtn('excelQtyCol', this.getText(16187)),

                        this.getExcelColBtn('excelBarcodeCol', this.getText(18094)),
                        this.getExcelColBtn('excelPriceCol', this.getText(17698)),
                        isProcurement ? "" : this.getExcelColBtn('excelCostCol', this.getText(17699)),
                        isProcurement ? "" : this.getExcelColBtn('excelDiscountPercentageCol', this.getText(17700)),
                        this.getExcelColBtn('excelQtyInUnitCol', this.getText(17701)),
                        this.getExcelColBtn('excelUnitQtyCol', this.getText(17702)),
                        isProcurement ? this.getExcelColBtn('excelBaseCostNoTaxCol', this.getText(19512)) : "",
                        isProcurement ? this.getExcelColBtn('excelBaseCostWithTaxCol', this.getText(19513)) : "",
                        isProcurement ? this.getExcelColBtn('excelDscnt1Col', this.getText(19514)) : "",
                        isProcurement ? this.getExcelColBtn('excelDscnt2Col', this.getText(19515)) : "",
                        isProcurement ? this.getExcelColBtn('excelBonusQtyCol', this.getText(19516)) : "",
                        isProcurement ? this.getExcelColBtn('excelQtyForBonusQtyCol', this.getText(19517)) : "",
                        this.uploadExcelButton()
                    ]}

                </GenericModal>
                <GenericModal
                    title={this.getText(18475)}
                    visible={this.state.modalEditQtyMulti}
                    onCancel={() => { this.setState({ modalEditQtyMulti: false, modalEditQtyMultiData: [] }) }}
                    width={1000}
                    footer={[
                        <Button onClick={() => { this.setState({ modalEditQtyMulti: false, modalEditQtyMultiData: [] }) }}>{this.getText(18478)}</Button>,
                        <Button type="primary" onClick={this.modalEditQtyMultiSave}>{this.getText(18479)}</Button>
                    ]}>
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.state.modalEditQtyMultiData}
                        columns={[//#&#
                            { title: this.getText(12513), key: "keyVal", dataIndex: "keyVal", width: "5%" },
                            { title: this.getText(12514), key: "itemCode", dataIndex: "itemCode", width: "15%" },
                            { title: this.getText(12515), key: "description", dataIndex: "description", width: "35%" },
                            { title: this.getText(18476), key: "_Size1", dataIndex: "_Size1", width: "15%" },
                            { title: this.getText(18477), key: "_color", dataIndex: "_color", width: "15%" },
                            { title: this.getText(12516), key: "quantity", dataIndex: "quantity", width: "15%", render: this.modalEditQtyMultiInput },
                        ]}
                        pagination={false}
                        scroll={{ y: window.innerHeight - 400 }}
                    />
                </GenericModal>
                <GenericModal
                    visible={this.state.modalIsSerialize !== null}
                    onCancel={() => { this.setState({ modalIsSerialize: null }) }}
                    title={this.getText(18505)}
                    width={600}
                    footer={[
                        <Button onClick={() => { this.setState({ modalIsSerialize: null }) }}>
                            {this.getText(18509)}
                        </Button>,
                        <Button type={"primary"} onClick={this.checkSerialNumbers} disabled={disabledCheckSerialNumbers || issetDuplicateSerialNumbers}>
                            {this.getText(18510)}
                        </Button>
                    ]}>
                    <ResponsiveTable
                        tableOnly
                        dataSource={this.state.modalIsSerialize ? this.state.modalIsSerialize.serialNumbers : []}
                        columns={[
                            { title: "#", key: "key", dataIndex: "key", width: "10%" },
                            { title: this.getText(18506), key: "value", dataIndex: "value", width: "50%", render: this.renderSerialNum },
                            { title: this.getText(18507), key: "status", dataIndex: "status", width: "30%", render: this.renderStatusIsSerialize },
                            { title: addSerialNumberRow, width: "20%", render: this.renderActionsIsSerialize },
                        ]}
                        pagination={false}
                        scroll={{ y: window.innerHeight - 400 }}
                        rowClassName={(record) => duplicateSerialNumbers(record) ? "row_pink" : ""}
                    />
                    {issetDuplicateSerialNumbers ? (<span style={{ color: "red" }}>{this.getText(18511)}</span>) : ""}
                </GenericModal>
                <ModalCreateEditItem
                    id={TableIDs.modalCreateEditItem}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditItem)}
                    title={this.getText(10241)}
                    onSaveFromCreateDoc={(newItemCode, newItemName) => {

                        if (this.isCreateNewItem && !this.props.data.ITEM_CREATE_EDIT.createNewFromDoc) {
                            setTimeout(() => {
                                this.onBlurItemInput({ target: { value: newItemCode } })
                            }, 1000);
                            // } else if (this.props.documentType == 4 || this.props.documentType == 18) {
                            //     setTimeout(() => {
                            //         const { supplierCode, priceListId, dataSource } = this.props.data.document2;

                            //         let newDataSource = dataSource.map(x => x.itemCode === newItemCode ? { ...x, description: newItemName } : x)
                            //         this.props.updatePricesPerSupplier(supplierCode, priceListId, newDataSource)
                            //     }, 1000);
                        } else {

                            let priceData = this.props.data.ITEM_PRICE_LIST_CREATE_EDIT.data.map(f => {
                                let y = f.split('\f');
                                return {
                                    mPriceListId: y[1],
                                    mPriceVal: y[3],
                                    mPriceNoVat: y[4],
                                }
                            }).find(f => f.mPriceListId === this.props.data.document2.priceListId);

                            let suppliersData = this.props.data.ITEM_SUPPLIERS_CREATE_EDIT.data.map(f => {
                                let y = f.split('\f');
                                return {
                                    _suppCode: y[1],
                                    _suppCost: y[2],
                                    _suppIsDefault: y[4],
                                }
                            });

                            let costData = suppliersData.find(f => f._suppCode == this.props.data.document2.supplierCode);
                            if (!costData) costData = suppliersData.find(f => f._suppIsDefault == '1');

                            let cost = parseFloat(costData._suppCost);

                            let selectorData = this.props.data[TableIDs.genericSelectorPriceList].dataset;
                            let noTaxText = '(לא כולל מע"מ)';
                            let plIsNoTax = selectorData.find(f => f.code === this.props.data.document2.priceListId && f.name.indexOf(noTaxText) > -1) ? true : false

                            let dataSource = this.props.data.document2.dataSource.map(i => {
                                if (i.itemCode === newItemCode) {
                                    let taxPercentageValue = parseFloat(i.taxPercentageValue);

                                    let _quantity = parseFloat(i.quantity);
                                    let _discountPercentage = parseFloat(i.discountPercentage);
                                    let _itemPrice = plIsNoTax ? parseFloat(priceData.mPriceVal) * (1.00 + taxPercentageValue / 100) : parseFloat(priceData.mPriceVal);
                                    let _itemPriceNoTax = parseFloat(priceData.mPriceNoVat);
                                    let unitID = i.unitID ? i.unitID : '0';
                                    let unitDescription = i.unitDescription ? i.unitDescription : '';
                                    let unitQuantity = i.unitQuantity ? i.unitQuantity : '1';

                                    if (isProcurement) {
                                        _itemPriceNoTax = cost;
                                        _itemPrice = cost * (1.00 + taxPercentageValue / 100);
                                    }

                                    return {
                                        ...i,
                                        description: newItemName,
                                        isValid: 1,
                                        unitDescription,
                                        unitID,
                                        unitQuantity,
                                        cost,
                                        unitPriceNoTax: _itemPriceNoTax ? _itemPriceNoTax.toFixed(4) : '0.00',
                                        unitPriceWithTax: _itemPrice ? _itemPrice.toFixed(4) : '0.00',
                                        totalSumNoTax: ((_quantity * _itemPriceNoTax) * (1.00 - _discountPercentage / 100)).toFixed(4),
                                        totalSumWithTax: ((_quantity * _itemPrice) * (1.00 - _discountPercentage / 100)).toFixed(4)
                                    }
                                } else {
                                    return i
                                }
                            })

                            this.props.dataActions.setJson("document2", { dataSource })

                        }

                        this.isCreateNewItem = false;

                        clearTimeout(this.focusTimeout);
                        this.focusTimeout = setTimeout(() => {
                            document.getElementById("quantity_input").focus();
                        }, 1000);
                    }}
                    defaultSupplier={isProcurement ? this.props.data.document2.supplierCode : null}
                />
                <GenericModal
                    title={this.getText(18738)}
                    width={500}
                    visible={this.state.modalPercentageDscnt != null}
                    onCancel={() => { this.setState({ modalPercentageDscnt: null }) }}
                    footer={[
                        <Button style={{ marginLeft: 10 }} type={"primary"} onClick={this.savePercentageDscnt}>{this.getText(18739)}</Button>,
                        <Button onClick={() => { this.setState({ modalPercentageDscnt: null }) }}>{this.getText(18740)}</Button>,
                    ]}
                >{this.state.modalPercentageDscnt ? this.modalPercentageDscntContent() : ""}</GenericModal>
                <GenericModal
                    visible={this.state.modalOnChangePriceList !== null}
                    onCancel={() => { this.setState({ modalOnChangePriceList: null }) }}
                    title={this.getText(19669)}
                    width={400}>

                    <h3>{this.getText(19670)}</h3>

                    <Button type={"primary"} style={{ width: "100%", margin: 5 }} onClick={() => {
                        const { val } = this.state.modalOnChangePriceList;
                        this.setState({ modalOnChangePriceList: null }, () => { this.onChangePriceList(val, true) })
                    }}>{this.getText(19671)}</Button>
                    <br />

                    <Button type={"primary"} style={{ width: "100%", margin: 5 }} onClick={() => {
                        const { oldPriceList, val } = this.state.modalOnChangePriceList;
                        this.setState({ modalOnChangePriceList: null }, () => { this.onChangePriceList(val) })
                        this.priceListBack = oldPriceList;
                    }}>{this.getText(19672)}</Button>
                    <br />

                    <Button type={"primary"} style={{ width: "100%", margin: 5 }} onClick={() => {
                        const { val } = this.state.modalOnChangePriceList;
                        this.setState({ modalOnChangePriceList: null }, () => { this.onChangePriceList(val) })
                    }}>{this.getText(19673)}</Button>
                    <br />

                    <Button style={{ width: "100%", margin: 5 }} onClick={() => {
                        this.setState({ modalOnChangePriceList: null })
                    }}>{this.getText(19675)}</Button>

                </GenericModal>
            </div >
        )
    }

}

export default TableContainer;
