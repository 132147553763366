/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../contextProvider/LangContext";
import KdsCustLink from "../pages/Kds/KdsCustLink";

class FormQrCodeCustLink extends Component<Props, State> {

    state = {
        backgroundImage: null,
        formStyle: '0',
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']';
    }

    componentDidMount() {
        this.props.sendAPI("get_customer_portal_declarations", "_id\r12", (ob) => {
            if (ob.data) {
                let y = ob.data.split("\f");
                this.setState({ backgroundImage: y[5], formStyle: y[0], paddingTop: y[1] + "px" })
            }
        })
    }

    render() {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const originUrl = isDev ? "http://147.235.163.248" : origin
        const background = this.state.backgroundImage ? 'url(' + originUrl + '/uploads/' + this.props.compCode + '/' + this.state.backgroundImage + ')' : null;

        return (<div>
            <div style={{
                backgroundImage: background,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                opacity: this.state.formStyle == 1 ? 1 : 0.2,
                height: "100%",
                width: '100%',
                position: 'fixed',
            }} />
            <KdsCustLink
                height={window.innerHeight * 0.8}
                getText={this.getText}
                sendAPI={(s, d, c) => { this.props.sendAPI(s, d, c, (e) => { console.error(e) }) }}
                isFromQRCode
                formStyle={this.state.formStyle}
                paddingTop={this.state.paddingTop}
            />
        </div>)
    }
}
export default FormQrCodeCustLink;