
import React from "react";
import { Input, Button, Tooltip, Modal, Select, Checkbox, message, Switch, Icon, Popover, Upload, Progress, Radio } from 'antd';
import Jax from "../Jax/jax.es6.module";

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import LangContext from "../contextProvider/LangContext";
import ModalAdvanceSearch from "./ModalAdvanceSearch";
import ResponsiveTable from "./ResponsiveTable";
import ModalCreateEditItem from "./ModalCreateEditItem";

import "../style/generalUI.css";
import FormItem from "antd/lib/form/FormItem";
import { escapeRegExp } from "../utils/utils";
import * as XLSX from 'xlsx/xlsx.mjs';
import GenericModal from "./GenericModal";
import CustomerFilter from "../pages/Customers/CustomerFilter";
import PictureView from "./PictureView";

const { Option, OptGroup } = Select;

const topHeight = 350; // px
const rowHeight = 40;  // px

//type State = {}
class ModalAttributeItemTags extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            // isMultiChange: true,
            // countAll: 0,
            // countInFilter: 0,

            filterActive: false,
            valueSearch: '',
            filterRequest: {},
            lastFilter: null,

            uploadExcelPop: false,
            uploadExcelCol: null,
            fileList: [],

            loadingSaveBtn: false,

            attrProgrssBar: 0,
            attrProgrssBarMax: 0,

            modalFilterCust: false,
            modalFatherItems: false,
            modalFatherItemsFilterType: '0',
        }

        this.id = this.props.id;
        this.items_id = "ITEMS_LIST_TO_ATTREBUTE";

        this.father_id = "FATHER_ITEMS_LIST_TO_ATTREBUTE"
        // this.main_id = "TAGS_TABLE";
        this.edit_id = "TAGS_TABLE_EDIT";


        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { dataset: null };
        // if (props.data[this.main_id] === undefined) props.data[this.main_id] = { ...props.data.genericData };
        if (props.data[this.items_id] === undefined) props.data[this.items_id] = { ...props.data.genericData };
        if (props.data[this.edit_id] === undefined) props.data[this.edit_id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericMultiSelectorTagTypes] === undefined) {
            props.data[TableIDs.genericMultiSelectorTagTypes] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_items_per_tags",
            get_cust: "get_cust_per_tags",

            get_items: "get_items_list_all_with_filter",
            get_cust_filter: "get_cust_list_all_with_filter",

            get_father: "get_father_items_per_tag",

            get_main: "get_tags_by_page",
            create_main: "create_new_tag",
            edit_main: "edit_tag",

            attr: "attrebute_items_to_teg",
            attr_cust: "attrebute_cust_to_teg",

            selector: "get_tag_types_with_relation_list",
        }

        this.itemsDataList = [];
        this.itemsAttrList = new Set();
        this.itemsAttrListOld = new Set();

        this.prevTagID = null;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prev) {
        let tID = this.props.ui.data ? this.props.ui.data.tID : null;
        let ptID = prev.ui.data ? prev.ui.data.tID : null;
        let mTypeId = this.props.data[this.edit_id].editedRecord ? this.props.data[this.edit_id].editedRecord['mTypeId'] : null
        let pmTypeId = prev.data[this.edit_id].editedRecord ? prev.data[this.edit_id].editedRecord['mTypeId'] : null
        if ((tID !== ptID) || (pmTypeId !== mTypeId)) {
            // this.setState({ tID: tID }, () => {
            if (tID !== ptID && !tID && this.props.tagType) {
                this.handleClearNew();
            } else {
                this.initFilterItems(tID, tID == '0');
            }
            // });
        }
        // let ds = this.props.data[this.id].dataset;
        // let pds = prev.data[this.id].dataset;
        // let itemsData = this.props.data[this.items_id];
        // let pItemsData = prev.data[this.items_id];
        // if (pds && ds && pds.size !== ds.size) this.setState({ countAll: ds.size, countInFilter: this.countAttrInFilter() })

        // if (itemsData !== pItemsData || (pds && ds && pds.size !== ds.size)) this.setState({ countInFilter: this.countAttrInFilter() })


        // let curFilter = this.props.data[this.items_id].filters ? this.props.data[this.items_id].filters._filter : "";
        // let prevFilter = prev.data[this.items_id].filters ? prev.data[this.items_id].filters._filter : "";
        // if (prevFilter !== curFilter) {
        //     this.setState({ valueSearch: curFilter })
        // }

    }

    apiItemsSetFilter = (request) => {
        let isItemType = this.checkIfItemType()

        if (isItemType) {

            let filterRequest = {
                _filter: '',
                _tagList: "",
                _isActive: '',
                _isWeightable: '',
                _isFixedPrice: '',
                _isForSale: '',
                _isDiscount: '',
                _saleAlert: '',
                _supplierList: '',
                _isForInventory: '',
                _isSerialize: '',
                _isMatrixReverse: '',
                _fatherItemsList: '',
                _connectedItems: '',
                _taxType: '',
                _searchColFilter: '1',
                _notAssociatedTag: '0',
                _notAssociatedSupp: '0',
                _rulerList: '',
                _notAssociatedRuler: '0',
                _itemSerialNumber: '',
                _supplierItemCode: '',
                _tagRelation: '1',
                
                ...this.state.filterRequest,
                ...request
            }

            let filterKeys = Object.keys(filterRequest)
            let dataSend = filterKeys.join("\f") + "\r" + filterKeys.map(x => filterRequest[x] ? filterRequest[x] : "").join("\f")

            // this.props.dataActions.genericDataSetFilter(
            //     this.items_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_items);

            this.sendAPI(this.api.get_items, dataSend, (ob) => {
                let res = ob.data ? ob.data.split("\r") : [];
                this.itemsDataList = res.slice(1, -1).map((x, index) => {
                    let y = x.split("\f");
                    let obj = { key: y[0], index, mCode: y[1], mName: y[2] }
                    return obj;
                });
                this.setState({
                    filterRequest,
                    lastFilter: Date.now(),
                    valueSearch: filterRequest._filter ? filterRequest._filter.split("\\").join("") : ""
                })
            })

        } else {

            let filterRequest = {
                _words: '',
                customerTags: '',
                birthDayLower: '',
                birthDayUpper: '',
                clubJoinLower: '',
                clubJoinUpper: '',
                lastClubJoinLower: '',
                lastClubJoinUpper: '',
                updateDateLower: '',
                updateDateUpper: '',
                creationPos: '',
                custCredit: 0,
                custMailingIsActive: 0,
                notActiveView: 0,
                custEmployeeId: '',

                ...this.state.filterRequest,
                ...request
            }

            let filterKeys = Object.keys(filterRequest)
            let dataSend = filterKeys.join("\f") + "\r" + filterKeys.map(x => filterRequest[x] ? filterRequest[x] : "").join("\f")

            // this.props.dataActions.genericDataSetFilter(
            //     this.items_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_items);

            this.sendAPI(this.api.get_cust_filter, dataSend, (ob) => {
                let res = ob.data ? ob.data.split("\r") : [];
                this.itemsDataList = res.map((x, index) => {
                    let y = x.split("\f");
                    let obj = { key: y[0], index, mCode: y[0], mName: y[1] }
                    return obj;
                });
                this.setState({ filterRequest, lastFilter: Date.now(), valueSearch: filterRequest._words })
            })
        }


    }

    initFilterItems = (tID, isClear, notClearAttrList) => {

        this.apiItemsSetFilter({ _tagList: isClear ? "" : tID, customerTags: isClear ? "" : tID });

        this.setState({ filterActive: !isClear ? true : false, valueSearch: "" })

        if (!notClearAttrList) {

            let isItemType = this.checkIfItemType()

            this.sendAPI(isItemType ? this.api.get : this.api.get_cust, "tID\r" + tID, (ob) => {
                let dataset = ob.data ? ob.data.split("\r") : [];
                this.itemsAttrList = new Set(dataset);
                this.itemsAttrListOld = new Set(dataset);
                this.setState({ lastFilter: Date.now() })
                // let count = dataset ? dataset.length : 0;
                // this.setState({ countAll: count, countInFilter: count }, () => {
                //     this.props.dataActions.setJson(this.id, { dataset: new Set(dataset) })
                // })
            });

            this.props.dataActions.genericMultiSelectorRefreshDataset(
                TableIDs.genericMultiSelectorTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector);
        }
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { message.error(e) });
    }

    handleSaveAttr = (tagId, attrebuteDelete, attrebuteAdd, close) => {
        let script = this.checkIfItemType() ? this.api.attr : this.api.attr_cust;

        if (attrebuteDelete.length) {
            let d = [...attrebuteDelete];
            let i = "tID\ftItems\ftIsAdd\r" + tagId + "\f" + d.splice(0, 1000).join(',') + "\f0";
            this.sendAPI(script, i, () => {
                this.setState({ attrProgrssBar: this.state.attrProgrssBar + 1 }, () => {
                    this.handleSaveAttr(tagId, d, attrebuteAdd, close)
                })
            })
        } else if (attrebuteAdd.length) {
            let d = [...attrebuteAdd];
            let i = "tID\ftItems\ftIsAdd\r" + tagId + "\f" + d.splice(0, 1000).join(',') + "\f1";
            this.sendAPI(script, i, () => {
                this.setState({ attrProgrssBar: this.state.attrProgrssBar + 1 }, () => {
                    this.handleSaveAttr(tagId, [], d, close)
                })
            })
        } else {
            this.setState({ loading: false, attrProgrssBar: 0, attrProgrssBarMax: 0 }, () => {
                message.success(this.getText(12634))
                if (close) {
                    setTimeout(() => { this.handleCancel() }, 1000);
                } else if (!parseInt(this.props.ui.data['tID'])) {
                    this.handleClearNew();
                }

                if (this.props.onSave) this.props.onSave(tagId);

                // setTimeout(() => {
                this.itemsAttrListOld = new Set(this.itemsAttrList);
                // }, 1000);
            })
        }
    }

    handleSave = (close) => {
        const { editing, editedRecord } = this.props.data[this.edit_id]
        if (!editedRecord.mTypeId || !editedRecord.mName) {
            message.error(this.getText(17823));
            return;
        }
        let script = (editing == 0) ? this.api.create_main : this.api.edit_main;
        // console.log("tags barg ", script, this.props.data[this.edit_id])
        let er = this.props.data[this.edit_id].editedRecord;
        let data = "mID\fmTypeId\fmName\fmIsActive\fmVSImage\fmVSDescription\r" +
            er.key + "\f" + er.mTypeId + "\f" + er.mName + "\f" + er.mIsActive + "\f" + er.mVSImage + "\f" + er.mVSDescription;

        this.setState({ loading: true }, () => {
            this.sendAPI(script, data, (ob) => {
                let newID = ob.data && ob.data !== "0" ? ob.data : null;
                let editID = this.props.ui.data['tID'] ? this.props.ui.data['tID'] : null;
                if (newID || editID) {
                    // let dataset = this.props.data[this.id].dataset

                    // const onSuccess = () => {
                    //     this.setState({ loading: false })
                    //     message.success(this.getText(12634))
                    //     if (close) {
                    //         setTimeout(() => { this.handleCancel() }, 1000);
                    //     } else if (!parseInt(this.props.ui.data['tID'])) {
                    //         this.handleClearNew();
                    //     }

                    //     if (this.props.onSave) this.props.onSave(newID || editID);
                    // }

                    let attrebute = Array.from(this.itemsAttrList);
                    let attrebuteOld = Array.from(this.itemsAttrListOld);
                    let attrebuteAdd = attrebute.filter(f => !this.itemsAttrListOld.has(f))
                    let attrebuteDelete = attrebuteOld.filter(f => !this.itemsAttrList.has(f))

                    console.log("attrebute", { attrebute, attrebuteOld, attrebuteAdd, attrebuteDelete })


                    this.setState({ attrProgrssBarMax: Math.ceil(attrebuteDelete.length / 1000) + Math.ceil(attrebuteAdd.length / 1000) })

                    this.handleSaveAttr((newID || editID), attrebuteDelete, attrebuteAdd, close)

                    // let aData = "tID\ftItems\r" + (newID || editID) + "\f" + attrebute.join(',');
                    // this.sendAPI(this.api.attr, aData, onSuccess)
                    // console.log('yuda ok ', aData.split("\r"));

                } else {
                    this.setState({ loading: false })
                    message.error(this.getText(14083))
                }
            })
        });
    }

    handleCancel = () => {
        if (this.props.data[this.edit_id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.edit_id);
        else this.props.dataActions.genericDataCancelEditing(this.edit_id);
        this.setState({ visible: false }, () => {
            this.props.uiActions.hideModal(TableIDs.modalAttributeItemTags);
            this.handleClear();
            if (this.props.onClose) this.props.onClose();
        });
    }

    handleClear = () => {

        // this.setState({ isMultiChange: true }, () => {
        let data = this.props.data[this.edit_id].data;
        let y = data[0] ? data[0].split("\f") : [0, '', '', ''];

        console.log("handleClear", y, data)

        this.props.dataActions.genericDataSetEdit(this.edit_id, {
            mTypeId: y[1],
            mName: y[2],
            mIsActive: y[3],
        });
        this.initFilterItems(y[0], y[0] == '0');
        // })
    }

    handleClearNew = () => {
        let obj = {
            mTypeId: '',
            mName: '',
            mIsActive: 1,
            mVSImage: '',
            mVSDescription: '',
        }
        this.props.dataActions.genericDataSetEdit(this.edit_id, obj);
        this.initFilterItems(0, true);
    }

    filterOpen = (isItemType) => {
        if (isItemType) {
            this.props.uiActions.showModal(TableIDs.modalSearchItem, this.props.ui.data ? this.props.ui.data : {})
        } else {
            this.setState({ modalFilterCust: true })
        }
    }

    multiItemChange = (e) => {
        let checked = e.target.checked;
        this.itemsDataList.forEach((y) => {
            if (checked) this.itemsAttrList.add(y.mCode);
            else this.itemsAttrList.delete(y.mCode)
            this.setState({ lastFilter: Date.now() })
        });
    }

    countAttrInFilter = () => {
        // let dataset = this.props.data[this.id].dataset;
        // let itemsData = this.props.data[this.items_id].data;
        // let countInFilter = 0;
        // if (dataset && itemsData && itemsData[0]) {
        //     itemsData.map((x) => {
        //         let y = x.split("\f");
        //         if (dataset.has(y[1])) countInFilter++
        //     });
        // }
        // return countInFilter;
        return this.itemsDataList.filter(f => this.itemsAttrList.has(f.mCode)).length
    }

    createNewItem = () => {
        this.props.uiActions.setIsEditFatherItem(false);
        this.props.uiActions.showModal(TableIDs.modalCreateEditItem, {
            ...this.props.ui.data,
            itemId: null,
            onSave: (s) => { this.apiItemsSetFilter({ _filter: escapeRegExp(s.mName), _tagList: "", customerTags: "" }) }
        })
    }



    uploadExcelButton = () => {

        const { fileList, uploadExcelCol } = this.state;
        let content = [];

        "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").forEach((x, i) => {
            content.push(<Button type={uploadExcelCol === x ? "primary" : ""} onClick={() => { this.setState({ uploadExcelCol: x }) }}>{x}</Button>)
            if (i == 8 || i == 17 || i == 25) content.push(<br />)
        });


        let GetTableFromExcel = (data) => {
            let workbook = XLSX.read(data, { type: 'binary' });
            let Sheet = workbook.SheetNames[0];
            let fullData = workbook.Sheets[Sheet];
            let ref = fullData['!ref'].split(':');
            let refStart = parseInt(ref[0].replace(/[A-Z]+/, ''));
            let refEnd = parseInt(ref[1].replace(/[A-Z]+/, ''));

            const fd = (key) => {
                let res = fullData[key] && fullData[key].w ? fullData[key].w : '';
                return res.replace(/[\x00-\x1F\x80-\xFF]/, '').trim()
            }

            for (let n = refStart; n <= refEnd; n++) {
                let item = fd(uploadExcelCol + n)
                if (item) this.itemsAttrList.add(item)
            }

            this.setState({ lastFilter: Date.now() })
        };

        let beforeUpload = (fileUpload) => {
            this.setState({ fileList: [fileUpload], uploadExcelPop: false });
            let reader = new FileReader();

            //For Browsers other than IE.
            if (reader.readAsBinaryString) {
                reader.onload = function (e) {
                    GetTableFromExcel(e.target.result);
                };
                reader.readAsBinaryString(fileUpload);
            } else {
                //For IE Browser.
                reader.onload = function (e) {
                    let data = "";
                    let bytes = new Uint8Array(e.target.result);
                    for (let i = 0; i < bytes.byteLength; i++) {
                        data += String.fromCharCode(bytes[i]);
                    }
                    GetTableFromExcel(data);
                };
                reader.readAsArrayBuffer(fileUpload);
            }
            return false;
        }

        let disabled = !uploadExcelCol;

        return (<div>
            {content}
            <Upload fileList={fileList} beforeUpload={beforeUpload} disabled={disabled}>
                <Button key={"upButton"} disabled={disabled}><Icon type="upload" style={{ marginLeft: 5 }} />{this.getText(15659)}</Button>
            </Upload>
        </div>)
    }

    checkIfItemType = () => {
        let findType = this.props.data[TableIDs.genericMultiSelectorTagTypes].dataset.find(f => f.tags.find(f => f.id == this.props.data[this.edit_id].editedRecord.mTypeId))
        return findType && findType.type && findType.type.id && findType.type.id.indexOf("Item") > -1;
    }

    openModalFatherItems = () => {
        this.setState({ modalFatherItems: true }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.father_id, this.props.user.companyCode, this.props.user.token,
                {
                    tagId: this.props.ui.data.tID,
                    filterType: this.state.modalFatherItemsFilterType
                },
                this.api.get_father)
        })
    }

    render() {
        const { loading } = this.state;


        let rawList = Jax.get(this.props.data, TableIDs.genericMultiSelectorTagTypes + ".dataset", [])
            .filter(f => this.props.tagType && f.type ? f.type.id === this.props.tagType : true);
        // console.log('yuda2 rawList ', rawList)
        let list = rawList.map(
            (node) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let modalTitle = this.getText(12626);
        if (this.props.ui.data && this.props.ui.data.tID > 0) modalTitle = this.getText(12627) + " " + this.props.ui.data.tID;

        let titleBar = (title, buttons) => {
            return (
                <div style={{
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    paddingTop: '4px',
                    paddingBottom: '0px',
                }}>
                    <PageToolbar title={title} actionButtons={buttons} isModal={true}
                        ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataActions={this.props.dataActions}
                    />
                </div>)
        }

        let editedRecord = this.props.data[this.edit_id].editedRecord;
        let onChange = (f, v) => { this.props.dataActions.genericDataSetEdit(this.edit_id, { [f]: v }) }

        let isItemType = this.checkIfItemType()

        let fStyle = {
            display: 'inline-block',
            width: window.innerWidth <= 600 ? "95%" : "48%",
            padding: 15,
            verticalAlign: 'text-top',
        }

        // let dataset = this.props.data[this.id].dataset;

        let itemsColumns = [
            {
                title: this.getText(isItemType ? 12628 : 18334),
                dataIndex: "mCode",
                key: "mCode",
                width: '40%',
                render: (text, record) => text,
            }, {
                title: this.getText(isItemType ? 12629 : 18335),
                dataIndex: "mName",
                key: "mName",
                width: '40%',
                render: (text, record) => text,
            }, {
                title: this.getText(12630),
                width: '20%',
                render: (text, record) => {
                    // let tagData = new Set(dataset);
                    return (<Switch
                        checkedChildren={this.getText(12631)}
                        unCheckedChildren={this.getText(12632)}
                        onChange={(v) => {
                            if (v) this.itemsAttrList.add(record.mCode)
                            else this.itemsAttrList.delete(record.mCode)
                            this.setState({ lastFilter: Date.now() })

                            // this.props.dataActions.setJson(this.id, { dataset: tagData })
                        }}
                        checked={this.itemsAttrList.has(record.mCode)}
                    />)
                },
            }
        ];

        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let tID = this.props.ui.data ? this.props.ui.data.tID : null;

        let countCheckedFilter = this.countAttrInFilter();
        let countCheckedAll = this.itemsAttrList.size;
        let countItemsFilter = this.itemsDataList.length;


        let tagsActionButtons = [{
            buttonIsClicked: false,
            buttonType: 'filter',
            buttonFunction: () => { this.filterOpen(isItemType) },
        }, {
            buttonDisabled: !this.state.filterActive,
            buttonType: 'clear',
            buttonFunction: () => { this.initFilterItems(tID, true, true) },
        }]

        let tagsGenericActionButtons = [
            <Tooltip title={this.getText(18164)}>
                <Popover title={this.getText(isItemType ? 18165 : 18337)} content={this.uploadExcelButton()} visible={this.state.uploadExcelPop}>
                    <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => { this.setState({ uploadExcelPop: !this.state.uploadExcelPop }) }}>
                        <Icon type={"file-excel"} />
                    </Button>
                </Popover>
            </Tooltip>,
            <Tooltip title={this.getText(12633)}>
                <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                    <Checkbox
                        onChange={this.multiItemChange}
                        checked={countCheckedFilter && countCheckedFilter == countItemsFilter}
                        indeterminate={countCheckedFilter && countCheckedFilter < countItemsFilter}
                    />
                </Button>
            </Tooltip>
        ]

        if (isItemType) {
            tagsActionButtons.push({
                buttonType: 'add',
                buttonFunction: this.createNewItem,
            })

            tagsGenericActionButtons = [
                <Tooltip title={this.getText(18345)}>
                    <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={this.openModalFatherItems}>
                        <Icon type="table" />
                    </Button>
                </Tooltip>,
                ...tagsGenericActionButtons
            ]
        }
        console.log("componentDidUpdate", editedRecord)


        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={'90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar(modalTitle, [{
                    buttonType: 'refresh',
                    buttonFunction: this.handleClear
                }])}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={
                    [
                        <Button key="back" onClick={this.handleCancel}>{this.getText(12635)}</Button>,
                        <Button key="clear" loading={loading} onClick={this.handleClear}>{this.getText(12636)}</Button>,
                        <Button key="create" type="primary" loading={loading} onClick={() => { this.handleSave(false) }}>{this.getText(12637)}</Button>,
                        <Button key="create" type="primary" loading={loading} onClick={() => { this.handleSave(true) }}>{this.getText(12638)}</Button>,
                    ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <div style={fStyle}>
                    <FormItem label={this.getText(12621)} required validateStatus={editedRecord['mName'] ? "success" : "error"}>
                        <Input value={editedRecord['mName']} onChange={(v) => {
                            this.props.dataActions.genericDataSetEdit(this.edit_id, { mName: v.target.value, mVSDescription: v.target.value })
                        }} />
                    </FormItem>
                    <FormItem label={this.getText(12622)} required validateStatus={editedRecord['mTypeId'] ? "success" : "error"}>
                        <Select
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            optionFilterProp="children" placeholder={this.getText(12624)} value={editedRecord['mTypeId']} style={{ width: '100%', paddingLeft: '4px' }}
                            onChange={(v) => { onChange('mTypeId', v) }}
                        >{list}</Select>
                    </FormItem>
                    {editedRecord.mTypeId == '7' ?
                        <FormItem label={this.getText(18735)}>
                            <PictureView
                                {...this.props}
                                imageType={'V'} aspect={1} url={editedRecord["mVSImage"]}
                                onRemove={() => { onChange('mVSImage', '') }}
                                onChange={(v) => { onChange('mVSImage', v) }}
                                onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
                            />
                            <Input value={editedRecord['mVSDescription']} onChange={(v) => { onChange('mVSDescription', v.target.value) }} />
                        </FormItem>
                        : ""}
                    <FormItem>
                        <Checkbox onChange={(e) => { onChange('mIsActive', e.target.checked ? 1 : 0) }} checked={editedRecord['mIsActive'] == 1 ? true : false} >
                            {this.getText(12623)}
                        </Checkbox>
                    </FormItem>
                </div>
                {editedRecord.mTypeId ?
                    <div style={{ ...fStyle, display: 'inline-block' }}>
                        <ResponsiveTable
                            // idTable={this.props.data[this.items_id]}
                            dataSource={this.itemsDataList}
                            title={this.getText(isItemType ? 12625 : 18336)}
                            ui={this.props.ui}
                            uiActions={this.props.uiActions}
                            columns={itemsColumns}
                            showsearch={(val) => {
                                this.apiItemsSetFilter({
                                    // ...this.props.data[this.items_id].filters,
                                    _filter: escapeRegExp(val),
                                    _words: escapeRegExp(val),
                                    //  _filter: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                    page: 1,
                                    _page_num: 1,
                                });
                                if (val) { this.setState({ filterActive: true }) }
                            }}
                            valueSearch={this.state.valueSearch}
                            onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                            pagination={{ pageSize: numOfRowsByHeight }}
                            actionButtons={tagsActionButtons}
                            genericActionButtons={tagsGenericActionButtons}
                        />
                        <div>
                            {this.getText(12639)}: {countCheckedAll}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {this.getText(12640)}: {countCheckedFilter}
                        </div>
                    </div>
                    : ""}

                <ModalAdvanceSearch
                    id={TableIDs.modalSearchItem}
                    getApi={this.api.get_items} context={this.items_id}
                    filterFatherItems={true}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalSearchItem)}
                    title={this.getText(10242)}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                    filterRequest={{ ...this.state.filterRequest, _filter: this.state.valueSearch }}
                    onFilterOk={this.apiItemsSetFilter}
                />

                <CustomerFilter
                    visible={this.state.modalFilterCust}
                    onClose={() => { this.setState({ modalFilterCust: false }) }}
                    user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                    filterActive={this.state.filterActive}
                    setFilterActive={e => { this.setState({ filterActive: e }) }}
                    // valueSearch={this.state.valueSearch}
                    // onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                    filterRequest={this.state.filterRequest}
                    onFilterOk={this.apiItemsSetFilter}
                />

                <ModalCreateEditItem
                    id={TableIDs.modalCreateEditItem}
                    context={this.items_id} context_api_get={this.api.get_items}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditItem)}
                    title={this.getText(10241)}
                />
                <GenericModal
                    visible={this.state.attrProgrssBarMax}
                    title={this.getText(18239)}
                    width={600}>
                    <Progress percent={Math.ceil((this.state.attrProgrssBar / this.state.attrProgrssBarMax) * 100)} status={"active"} />
                </GenericModal>

                <GenericModal
                    visible={this.state.modalFatherItems}
                    onCancel={() => { this.setState({ modalFatherItems: false }) }}
                    title={this.getText(18346)}
                    width={800}>
                    <Radio.Group
                        value={this.state.modalFatherItemsFilterType}
                        onChange={e => { this.setState({ modalFatherItemsFilterType: e.target.value }, this.openModalFatherItems) }}>
                        <Radio value={'0'}>{this.getText(18533)}</Radio>
                        <Radio value={'1'}>{this.getText(18534)}</Radio>
                        <Radio value={'2'}>{this.getText(18535)}</Radio>
                        <Radio value={'3'}>{this.getText(18536)}</Radio>
                    </Radio.Group>
                    <hr />
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.father_id]}
                        columns={[//"_fatherCode","_fatherName","_countSons","_countAttr"
                            { title: this.getText(18351), key: "_fatherCode", dataIndex: "_fatherCode", width: "25%", render: t => t },
                            { title: this.getText(18352), key: "_fatherName", dataIndex: "_fatherName", width: "25%", render: t => t },
                            { title: this.getText(18353), key: "_countSons", dataIndex: "_countSons", width: "25%", render: t => t },
                            { title: this.getText(18354), key: "_countAttr", dataIndex: "_countAttr", width: "25%", render: t => t },
                        ]}
                        pagination={{ pageSize: 10 }}
                    />
                </GenericModal>

            </Modal >
        )
    }
}

export default ModalAttributeItemTags;
