/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"

const SubMenu = Menu.SubMenu;

type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    history: History,
    baseUrl: string,
    data: Object
};

type State = {
    menuTag: any,
    menuItemsTop: Array<Object>,
    menuItemsDrop: Array<Object>
};

class AdminMenu extends React.Component<Props, State> {
    myHistory: History;
    baseUrl: string;

    constructor(props: Props) {
        super(props);
        console.log('appmenu', this.props.history)
      this.myHistory = this.props.history;
        this.baseUrl = this.props.baseUrl;

        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }

        this.state = {
            menuTag: (<div> </div>),
            menuItemsTop: [
                { KeyComp: "191", key: "companies", text: "ניהול חברות", keyLang: 15219, icon: "cluster" },
                { KeyComp: "192", key: "pos-versions", text: "דו\"ח קופות", keyLang: 15328, icon: "cluster" },
                { KeyComp: "97", key: "points-of-sale", text: "קופות", keyLang: 10208, icon: "setting" },
                { KeyComp: "99", key: "users", text: "משתמשים", keyLang: 10210, icon: "setting" },
                { KeyComp: "181", key: "update-parameters", text: "עדכון פרמטרים", keyLang: 14675, icon: "setting" },
                { KeyComp: "149", key: "scheduled-services", text: "הגדרת שירותים מתוזמנים", keyLang: 14324, icon: "setting" },
                { KeyComp: "141", key: "multilingual", text: "ריבוי שפות", keyLang: 10294, icon: "setting" },
                { KeyComp: "143", key: "external-order-settings", text: "הגדרת ספקים חיצוניים", keyLang: 13442, icon: "setting" },
                { KeyComp: "146", key: "franchisees", text: "זכיינים", keyLang: 13994, icon: "setting" },
                { KeyComp: "193", key: "button-actions", text: "סוגי כפתורים", keyLang: 15508, icon: "setting" },
                { KeyComp: "194", key: "kosher-types", text: "סוגי כשרות", keyLang: 15577, icon: "setting" },
                { KeyComp: "195", key: "heshin-settings", text: "הגדרות קובץ HESHIN לחשבשבת", keyLang: 15910, icon: "setting" },
                { KeyComp: "196", key: "print-documents", text: "הגדרות הדפסת מסמך", keyLang: 16373, icon: "file-protect" },
                { KeyComp: "197", key: "help-documentations-edit", text: "ניהול מרכז מידע", keyLang: 16472, icon: "global" },
                { KeyComp: "198", key: "hashavshevet-job", text: "בקרת ממשק חשבשבת", keyLang: 16711, icon: "codepen" },
                { KeyComp: "199", key: "storenext", text: "סטורנקסט", keyLang: 17242, icon: "ant-design" },
                { KeyComp: "211", key: "benefits-distributors", text: "מפיצי קופונים", keyLang: 17453, icon: "build" },
                { KeyComp: "212", key: "currencies", text: "סוגי מטבעות", keyLang: 17894, icon: "dollar" },
            ].filter((menuItem) => this.isActive(menuItem.KeyComp)),
            menuItemsDrop: []
        };
        window.console.log(this.state.menuItemsTop);
        window.console.log(this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code));
        console.log('menuItemsTop ,', this.state.menuItemsTop.map(x => x.KeyComp))
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    onMenuSelect(args) {
        this.myHistory.push(
            this.baseUrl + "/admin/" + (args.key !== "home" ? args.key : ""),
            {}
        );
    }

    updatePriorityMenu = () => {
        let maxHeight = 50; //px
        let _height = -1;
        let firstIter = true;

        let listTop = this.state.menuItemsTop;
        let listDrop = this.state.menuItemsDrop;
        // re-populate menu
        for (let i = 0; i < listDrop.length; i++) {
            listTop.push(listDrop.pop());
        }

        //ReactDOM.findDOMNode(this).clientHeight;
        while (_height > maxHeight || _height < 0) {
            //this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            if (!firstIter) {
                this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            }
            else {
                firstIter = false;
            }
            this.setState(() => {
                return {
                    menuTag: <Menu
                        mode="horizontal"
                        defaultSelectedKeys={[
                            this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
                        ]}
                        onSelect={this.onMenuSelect.bind(this)}
                    >
                        {this.renderMenuItemsTop()}
                        <SubMenu title={<Badge count={listDrop.length}><span><Icon type="ellipsis" /></span></Badge>}>
                            {this.renderMenuItemsDrop()}
                        </SubMenu>
                    </Menu>
                }
            });
            console.log("findDomNode Admin: ", (this) ? "true" : "false", this);
            let e: Element | null | Text = ReactDOM.findDOMNode(this);
            _height = (e instanceof HTMLElement) ? e.clientHeight : 0;
            //alert(_height);
        }
    };

    renderMenuItemsTop() {
        let res = [];
        let list = this.state.menuItemsTop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
            return res;
        }
    }

    renderMenuItemsDrop() {
        let res = [];
        let list = this.state.menuItemsDrop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
        }
        return res;
    }

    doUpdateOfPriorityMenu = () => {
        setTimeout(this.updatePriorityMenu);
    };

    // Add event listener
    componentDidMount() {
        setTimeout(this.updatePriorityMenu);
        //window.addEventListener("resize", this.updatePriorityMenu);
        window.addEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    // Remove event listener
    componentWillUnmount() {
        //window.removeEventListener("resize", this.updatePriorityMenu);
        window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    render() {
       
        return this.state.menuTag;
    }
}

export default withRouter(AdminMenu);
