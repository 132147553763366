/* @flow */
/*jshint esversion: 6 */
/*jslint es6*/
import { ReduceStore } from "flux/utils";
import { message } from "antd";
import ActionTypes from "./ActionTypes";
import Dispatcher from "./Dispatcher";
import Jax from "../Jax/jax.es6.module";


type UiState = {
    mainMenuCollapsed: boolean,
    workInProgress: boolean,
    previousFormValues: Object,
    modalStack: Array<string>,
    onClickCreateBtn: string,
    itemDataArr: Array<any>,
    itemDataArrSize: number,
    dialogs: {
        itemPicker: {
            visible: boolean,
            defaultValue: string,
            data: Array<string>,
            onChange: Function
        },
        customerPicker: {
            visible: boolean,
            defaultValue: string,
            data: Array<string>,
            onChange: Function
        }
    },
    selectors: {
        id: string,
        taxType: Array<{ code: String, name: String }>,
        salesAlert: Array<{ code: String, name: String }>,
        suppliers: Array<{ code: String, name: String }>,
        prices: Array<{ code: String, name: String }>,
    },
    isEditFatherItem: boolean,
    isLogOutPressed: boolean,
}

class UiStore extends ReduceStore {
    messageRemove: Array<Function> = [];

    constructor() {
        super(Dispatcher);
        this.state = {};
        this.state.isConfDiagShown = false;
        this.state.isRenewModalShown = 0;
        this.state.modalStack = [];
        this.state.onClickCreateBtn = "";

        this.state.dialogs = {
            itemPicker: {
                visible: false,
                defaultValue: "",
                data: [],
                onChange: () => {
                }
            }
        };
        this.state.selectors = {
            taxType: [],
            salesAlert: [],
            suppliers: [],
            prices: [],
            customerFields: [
                {
                    code: 0,
                    name: "תווית",
                }, {
                    code: 1,
                    name: "קלט טקסט",
                }, {
                    code: 2,
                    name: "קלט מחיר",
                }, {
                    code: 3,
                    name: "קלט דצימלי",
                }, {
                    code: 4,
                    name: "קלט נומרי",
                }, {
                    code: 5,
                    name: "בורר תאריכים",
                }, {
                    code: 6,
                    name: "תיבת סימון",
                }, {
                    code: 7,
                    name: "בחירה",
                }, {
                    code: 8,
                    name: "בחירה מרובה",
                }, {
                    code: 9,
                    name: "תמונה",
                }],
        };
        this.optionList = [];
    }

    getInitialState() {
        return {
            isConfDiagShown: false,
            isRenewModalShown: 0,
            mainMenuCollapsed: true,
            workInProgress: false,
            previousFormValues: {},
            modalStack: [],
            onClickCreateBtn: "",
            dialogs: {
                itemPicker: {
                    visible: false,
                    defaultValue: "",
                    data: [],
                    onChange: () => {
                    }
                },
                customerPicker: {
                    visible: false,
                    defaultValue: "",
                    data: [],
                    onChange: () => {
                    }
                }
            },
            selector: {
                id: null,
                taxType: [],
                salesAlert: [],
                suppliers: [],
                prices: [],
                customerFields: [
                    {
                        code: 0,
                        name: "תווית",
                    }, {
                        code: 1,
                        name: "קלט טקסט",
                    }, {
                        code: 2,
                        name: "קלט מחיר",
                    }, {
                        code: 3,
                        name: "קלט דצימלי",
                    }, {
                        code: 4,
                        name: "קלט נומרי",
                    }, {
                        code: 5,
                        name: "בורר תאריכים",
                    }, {
                        code: 6,
                        name: "תיבת סימון",
                    }, {
                        code: 7,
                        name: "בחירה",
                    }, {
                        code: 8,
                        name: "בחירה מרובה",
                    }, {
                        code: 9,
                        name: "תמונה",
                    }],
            },
            isEditFatherItem: null,
            isLogOutPressed: false,
        }
    }

    reduce(state: UiState, action: Object) {
        let dialogs: Object;
        let dialog: Object;
        // let messageClose: Function;
        //
        //  message.config({
        //      // top: 100,
        //     // duration: 2,
        //     maxCount: 1,
        // });

        function destroyMessage() {
            try {
                message.destroy();
            } catch (e) {
                // do nothing :P
            }
        }

        //window.console.log("UI: " + action.type);
        //window.console.log(action.data);
        //window.console.log("UI:DATA: ")

        switch (action.type) {
            case ActionTypes.RENEW_MODAL:
                return Object.assign({}, state, { isRenewModalShown: action.flag, workInProgress: false, isLogOutPressed: false });

            case ActionTypes.CONFIRMATION_MODAL:
                return Object.assign({}, state, { isConfDiagShown: action.flag, workInProgress: false });

            case ActionTypes.TOGGLE_MENU:
                return Object.assign({}, state, {
                    mainMenuCollapsed: !state.mainMenuCollapsed
                });

            case ActionTypes.USER_LOGIN:
                return Object.assign({}, state, {
                    workInProgress: true, isLogOutPressed: false
                });

            case ActionTypes.USER_LOGIN_ERROR:
                message.error("חלה שגיאה בהתחברות (" + action.error + ")", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.USER_LOGIN_SUCCESS:
                // this.messageRemove.push(message.loading("זוהית בהצלחה. מתחבר...", 0));
                destroyMessage();
                if (action.isTwoWay && action.isTwoWay > 0) {
                    let showConfDiag = false;
                    let workInProgress = false;
                    if (action.token && action.token.length > 0) {
                        // STEP 1
                        showConfDiag = false;
                    } else {
                        // STEP 2
                        showConfDiag = false;
                        message.loading("זוהית בהצלחה. מתחבר...", 0);
                    }
                    return Object.assign({}, state, {
                        workInProgress: workInProgress,
                        isConfDiagShown: showConfDiag
                    });
                } else {
                    message.loading("זוהית בהצלחה. מתחבר...", 0);
                    return Object.assign({}, state, {
                        workInProgress: true,
                    });
                };

            case ActionTypes.USER_LOGOUT:
                return Object.assign({}, state, {
                    isLogOutPressed: true
                });

            case ActionTypes.USER_GET_UI_DATA_SUCCESS:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     window.console.log(typeof messageClose);
                //     messageClose();
                // }
                destroyMessage();

                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.USER_GET_UI_DATA_ERROR:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.error("חלה שגיאה בטעינת נתוני המשתמש שלך (" + action.error + ")", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.SAVE_FORM_VALUES:
                return Object.assign({}, state, {
                    previousFormValues: action.values
                });

            case ActionTypes.SET_IS_EDIT_FATHER_ITEM:
                return Object.assign({}, state, { isEditFatherItem: action.data });

            case ActionTypes.SHOW_MODAL:
                if (!state.modalStack.find(f => f === action.id)) state.modalStack.push(action.id);
                return Object.assign({}, state, { toggleModal: state.modalStack, data: action.data });

            case ActionTypes.HIDE_MODAL:
                let s = state.modalStack;
                if (s.length > 0) {
                    s.pop();
                }
                return Object.assign({}, state, { toggleModal: s, });

            case ActionTypes.SET_MODAL_UI_DATA:
                return Object.assign({}, state, { data: action.data });

            case ActionTypes.PRESS_ENTER:
                console.log("PRESS_ENTER", "\n id:", action.id, "\n record: ", action.record);
                return Object.assign({}, state, {
                    onPressEnter: {
                        id: action.id,
                        record: action.record,
                    }
                });

            case ActionTypes.ON_CREATE_BTN:
                return Object.assign({}, state, {
                    onClickCreateBtn: action.id
                });

            case ActionTypes.GET_COMPS_DATA:
                //console.log("EEE get comp data\nsenderID: ", action.senderID, "\ncompID: ", action.compsID);
                state.itemDataArr = [];
                state.itemDataArrSize = action.compsID.length;
                return {
                    ...state,
                    getCompsData: {
                        senderID: action.senderID,
                        compsID: action.compsID
                    }
                };

            case ActionTypes.GET_COMP_DATA:
                //console.log("EEE get comp data\nsenderID: ", action.senderID, "\ncompID: ", action.compID);
                return {
                    ...state,
                    getCompData: {
                        senderID: action.senderID,
                        compID: action.compID
                    }
                };

            case ActionTypes.SEND_COMP_DATA:
                //console.log("EEE send comp data\nsenderID: ", action.senderID, "\ncompID: ", action.compID, "\ndata: ", action.data);
                state.itemDataArr.push({
                    senderID: action.senderID,
                    compID: action.compID,
                    data: action.data,
                });
                if (state.itemDataArr.length === state.itemDataArrSize) {
                    //console.log("EEE", "send action return from store data");
                    // console.log("EEE", state.itemDataArr);
                    return {
                        ...state,
                        sendCompData: state.itemDataArr,
                    };
                } else {
                    //console.log("EEE", "send action not finished collecting data");
                    return { ...state };
                }
            // DATA REQUESTS
            case ActionTypes.API_FAILED_FORBIDDEN:
                message.error("פג טוקן האבטחה שלך. נא התחבר שוב", 5);
                return state;

            case ActionTypes.EARNING_POINTS_DELETE_ROW_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.success("הנתונים נמחקו בהצלחה", 5);
            /* Fall through */
            case ActionTypes.GET_USING_POINTS_REQUESTED:
            case ActionTypes.EARNING_POINTS_SET_FILTER:
            case ActionTypes.GET_EARNING_POINTS_REQUESTED:
                // this.messageRemove.push(message.loading("טוען נתונים...", 0));
                destroyMessage();
                message.loading("טוען נתונים...", 0);
                return Object.assign({}, state, {
                    workInProgress: true
                });

            case ActionTypes.GET_USING_POINTS_SUCCEEDED:
            case ActionTypes.GET_EARNING_POINTS_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.GET_USING_POINTS_FAILED:
            case ActionTypes.GET_EARNING_POINTS_FAILED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.error("חלה שגיאה בטעינת הנתונים מהשרת (" + action.errorMessage + ")", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.SAVE_USING_POINTS_REQUESTED:
            case ActionTypes.EARNING_POINTS_SAVE_EDIT:
                // this.messageRemove.push(message.loading("שומר נתונים...", 0));
                destroyMessage();
                message.loading("שומר נתונים...", 0);
                return Object.assign({}, state, {
                    workInProgress: true
                });


            case ActionTypes.SAVE_USING_POINTS_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.success("הנתונים נשמרו בהצלחה", 5);
                if (state.modalStack.indexOf("EarningPointsNew") > -1) {
                    state.modalStack.splice(state.modalStack.indexOf("EarningPointsNew"), 1);
                }
                return Object.assign({}, state, {
                    workInProgress: false,
                });

            case ActionTypes.EARNING_POINTS_SAVE_EDIT_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.success("הנתונים נשמרו בהצלחה", 5);
                if (state.modalStack.indexOf("EarningPointsNew") > -1) {
                    state.modalStack.splice(state.modalStack.indexOf("EarningPointsNew"), 1);
                }
                // this.messageRemove.push(message.loading("טוען נתונים...", 0));
                destroyMessage();
                message.loading("טוען נתונים...", 0);
                return Object.assign({}, state, {
                    workInProgress: true,
                    modalStack: state.modalStack
                });

            case ActionTypes.SAVE_USING_POINTS_FAILED:
            case ActionTypes.EARNING_POINTS_SAVE_EDIT_FAILED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.error("חלה שגיאה בשמירת הנתונים (" + action.errorMessage + ")", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.EARNING_POINTS_DELETE_ROW:
                // this.messageRemove.push(message.loading("מוחק נתונים...", 0));
                destroyMessage();
                message.loading("מוחק נתונים...", 0);
                return Object.assign({}, state, {
                    workInProgress: true
                });

            case ActionTypes.EARNING_POINTS_DELETE_ROW_FAILED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.error("חלה שגיאה במחיקת הנתונים (" + action.errorMessage + ")", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            // DIALOG REQUEST
            case ActionTypes.UI_SHOW_ITEM_PICKER:
                dialog = Object.assign({}, state.dialogs.itemPicker, {
                    visible: true,
                    data: action.data,
                    onChange: action.onChange,
                    defaultValue: action.defaultValue
                });
                dialogs = Object.assign({}, state.dialogs, { itemPicker: dialog });
                return Object.assign({}, state, { dialogs });
            case ActionTypes.UI_HIDE_ITEM_PICKER:
                dialog = Object.assign({}, state.dialogs.itemPicker, { visible: false });
                dialogs = Object.assign({}, state.dialogs, { itemPicker: dialog });
                return Object.assign({}, state, { dialogs });

            case ActionTypes.UI_SHOW_CUSTOMER_PICKER:
                dialog = Object.assign({}, state.dialogs.customerPicker, {
                    visible: true,
                    data: action.data,
                    onChange: action.onChange,
                    defaultValue: action.defaultValue
                });
                dialogs = Object.assign({}, state.dialogs, { customerPicker: dialog });
                return Object.assign({}, state, { dialogs });
            case ActionTypes.UI_HIDE_CUSTOMER_PICKER:
                dialog = Object.assign({}, state.dialogs.customerPicker, { visible: false });
                dialogs = Object.assign({}, state.dialogs, { customerPicker: dialog });
                return Object.assign({}, state, { dialogs });


            case ActionTypes.EARNING_POINTS_START_NEW:
                state.modalStack.push("EarningPointsNew");
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.EARNING_POINTS_CANCEL_NEW:
                state.modalStack.splice(state.modalStack.indexOf("EarningPointsNew"), 1);
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.EARNING_POINTS_SHOW_FILTERS:
                state.modalStack.push("EarningPointsFilters");
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.EARNING_POINTS_HIDE_FILTERS:
                state.modalStack.splice(state.modalStack.indexOf("EarningPointsFilters"), 1);
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.EARNING_POINTS_CLEAR_FILTERS:
                state.modalStack.splice(state.modalStack.indexOf("EarningPointsFilters"), 1);
                // this.messageRemove.push(message.loading("טוען נתונים...", 0));
                destroyMessage();
                message.loading("טוען נתונים...", 0);
                return Object.assign({}, state, { modalStack: state.modalStack, workInProgess: true });

            // GENERIC DATA
            case ActionTypes.GENERIC_DATA_DELETE_ROW_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.success("הנתונים נמחקו בהצלחה", 5);
            /* Fall through */
            case ActionTypes.GENERIC_DATA_SET_FILTER:
            case ActionTypes.GENERIC_DATA_GET_REQUESTED:
            case ActionTypes.GENERIC_DATA_GET_EDIT_REQUESTED:
            case ActionTypes.GENERIC_JSON_GET:
            case ActionTypes.GENERIC_JSON_GET_AND_EDIT:
                // this.messageRemove.push(message.loading("טוען נתונים...", 0));
                destroyMessage();
                message.loading("טוען נתונים...", 0);
                return Object.assign({}, state, {
                    workInProgress: true
                });

            case ActionTypes.GENERIC_DATA_GET_SUCCEEDED:
            case ActionTypes.GENERIC_DATA_GET_EDIT_SUCCEEDED:
            case ActionTypes.GENERIC_JSON_GET_SUCCEEDED:
            case ActionTypes.GENERIC_JSON_GET_AND_EDIT_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.GENERIC_DATA_GET_FAILED:
            case ActionTypes.GENERIC_JSON_GET_FAILED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.error("חלה שגיאה בטעינת הנתונים מהשרת (" + action.errorMessage + ")", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.GENERIC_DATA_SAVE_EDIT:
            case ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT:
            case ActionTypes.GENERIC_DATA_SAVE_EDIT_MULTI:
            case ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_MULTI:
            case ActionTypes.GENERIC_JSON_SET:
            case ActionTypes.GENERIC_JSON_SET_AND_RELOAD:
                // this.messageRemove.push(message.loading("שומר נתונים...", 0));
                destroyMessage();
                message.loading("שומר נתונים...", 0);
                return Object.assign({}, state, {
                    workInProgress: true
                });

            case ActionTypes.GENERIC_DATA_SAVE_EDIT_SUCCEEDED:
            case ActionTypes.GENERIC_JSON_SET_AND_RELOAD_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.success("הנתונים נשמרו בהצלחה", 5);
                if (action.getScript && action.getScript.who) {
                    if (state.modalStack.indexOf(action.getScript.who + "New") > -1) {
                        state.modalStack.splice(state.modalStack.indexOf(action.getScript.who + "New"), 1);
                    }
                } else {
                    if (state.modalStack.indexOf(action.who + "New") > -1) {
                        state.modalStack.splice(state.modalStack.indexOf(action.who + "New"), 1);
                    }
                }
                if (action.getScript && action.getScript.who) {
                    if (state.modalStack.indexOf(action.getScript.who + "Editor") > -1) {
                        state.modalStack.splice(state.modalStack.indexOf(action.getScript.who + "Editor"), 1);
                    }
                } else {
                    if (state.modalStack.indexOf(action.who + "Editor") > -1) {
                        state.modalStack.splice(state.modalStack.indexOf(action.who + "Editor"), 1);
                    }
                }

                // this.messageRemove.push(message.loading("טוען נתונים...", 0));
                destroyMessage();
                message.loading("טוען נתונים...", 0);
                return Object.assign({}, state, {
                    workInProgress: true,
                    modalStack: state.modalStack
                });
            case ActionTypes.GENERIC_DATA_JUST_SAVE_EDIT_SUCCEEDED:
            case ActionTypes.GENERIC_JSON_SET_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.success("הנתונים נשמרו בהצלחה", 5);
                if (action.getScript && action.getScript.who) {
                    if (state.modalStack.indexOf(action.getScript.who + "New") > -1) {
                        state.modalStack.splice(state.modalStack.indexOf(action.getScript.who + "New"), 1);
                    }
                } else {
                    if (state.modalStack.indexOf(action.who + "New") > -1) {
                        state.modalStack.splice(state.modalStack.indexOf(action.who + "New"), 1);
                    }
                }
                if (action.getScript && action.getScript.who) {
                    if (state.modalStack.indexOf(action.getScript.who + "Editor") > -1) {
                        state.modalStack.splice(state.modalStack.indexOf(action.getScript.who + "Editor"), 1);
                    }
                } else {
                    if (state.modalStack.indexOf(action.who + "Editor") > -1) {
                        state.modalStack.splice(state.modalStack.indexOf(action.who + "Editor"), 1);
                    }
                }
                return Object.assign({}, state, {
                    workInProgress: false,
                    modalStack: state.modalStack
                });

            case ActionTypes.GENERIC_DATA_SAVE_EDIT_FAILED:
            case ActionTypes.GENERIC_JSON_SET_FAILED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.error("חלה שגיאה בשמירת הנתונים (" + action.errorMessage + ")", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.GENERIC_DATA_DELETE_ROW:
            case ActionTypes.GENERIC_DATA_DELETE_ROW_WITH_PARAMS:
                // this.messageRemove.push(message.loading("מוחק נתונים...", 0));
                destroyMessage();
                message.loading("מוחק נתונים...", 0);
                return Object.assign({}, state, {
                    workInProgress: true
                });

            case ActionTypes.GENERIC_DATA_DELETE_ROW_FAILED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                message.error("חלה שגיאה במחיקת הנתונים (" + action.errorMessage + ")", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.GENERIC_DATA_CANCEL_INLINE_CREATE:
            case ActionTypes.GENERIC_DATA_CANCEL_EDITING:
                if (action.ignoreModal === true)
                    return Object.assign({}, state, {});

                if (state.modalStack.length > 0)
                    state.modalStack.pop();
                return Object.assign({}, state, { toggleModal: state.modalStack, });

            case ActionTypes.GENERIC_DATA_START_NEW:
                state.modalStack.push(action.who + "Editor");
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.GENERIC_DATA_START_EDITING:
            case ActionTypes.GENERIC_DATA_START_EDITING_PATH:
                state.modalStack.push(action.who + "Editor");
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.GENERIC_DATA_CANCEL_NEW:
                state.modalStack.splice(state.modalStack.indexOf(action.who + "New"), 1);
                if (state.modalStack.indexOf(action.who + "New") > -1) {
                    state.modalStack.splice(state.modalStack.indexOf(action.who + "New"), 1);
                }
                state.modalStack.splice(state.modalStack.indexOf(action.who + "Editor"), 1);
                if (state.modalStack.indexOf(action.who + "Editor") > -1) {
                    state.modalStack.splice(state.modalStack.indexOf(action.who + "Editor"), 1);
                }
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.GENERIC_DATA_SHOW_FILTERS:
                state.modalStack.push(action.who + "Filters");
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.GENERIC_DATA_HIDE_FILTERS:
                state.modalStack.splice(state.modalStack.indexOf(action.who + "Filters"), 1);
                return Object.assign({}, state, { modalStack: state.modalStack });
            case ActionTypes.GENERIC_DATA_CLEAR_FILTERS:
                state.modalStack.splice(state.modalStack.indexOf(action.who + "Filters"), 1);
                // this.messageRemove.push(message.loading("טוען נתונים...", 0));
                destroyMessage();
                message.loading("טוען נתונים...", 0);
                return Object.assign({}, state, { modalStack: state.modalStack, workInProgess: true });

            case ActionTypes.GENERIC_NETWORK_REQUEST:
                window.console.log(action);
                if (action.messages.loading.length > 0) {
                    // this.messageRemove.push(message.loading(action.messages.loading, 0));
                    destroyMessage();
                    message.loading(action.messages.loading, 0);
                }
                return Object.assign({}, state, {
                    workInProgress: true
                });
            case ActionTypes.GENERIC_NETWORK_REQUEST_SUCCEEDED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                // this.messageRemove.push(message.success(action.messages.success, 5));
                //console.log("ddddddd: ", action, action.messages, action.messages.success);

                message.success(action.messages.success, 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });
            case ActionTypes.GENERIC_NETWORK_REQUEST_FAILED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                // this.messageRemove.push(message.error(action.messages.failed, 5));
                message.error(action.messages.failed, 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.NETWORK_FETCH_DATA:
            case ActionTypes.NETWORK_POST_DATA:
            case ActionTypes.NETWORK_FETCH_JSON:
            case ActionTypes.NETWORK_POST_JSON:
            case ActionTypes.NETWORK_POST_JSON_AS_DATA:
            case ActionTypes.NETWORK_FETCH:
            case ActionTypes.NETWORK_POST:
                if (action.messages.loading.length > 0) {
                    // this.messageRemove.push(message.loading(action.messages.loading, 0));
                    destroyMessage();
                    message.loading(action.messages.loading, 0);
                }
                return Object.assign({}, state, {
                    workInProgress: true
                });
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA:
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON:
            case ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA:
            case ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON:
                if (action.postMessages.loading.length > 0) {
                    // this.messageRemove.push(message.loading(action.postMessages.loading, 0));
                    destroyMessage();
                    message.loading(action.postMessages.loading, 0);
                }
                return Object.assign({}, state, {
                    workInProgress: true
                });
            case ActionTypes.NETWORK_FETCH_DATA_FAIL:
            case ActionTypes.NETWORK_POST_DATA_FAIL:
            case ActionTypes.NETWORK_FETCH_JSON_FAIL:
            case ActionTypes.NETWORK_POST_JSON_FAIL:
            case ActionTypes.NETWORK_FETCH_FAIL:
            case ActionTypes.NETWORK_POST_FAIL:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                if (action.messages.failed.length > 0) {
                    // this.messageRemove.push(message.error(action.messages.failed, 5));
                    destroyMessage();
                    message.error(action.messages.failed, 5);
                }
                return Object.assign({}, state, {
                    workInProgress: false
                });
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA_FAIL:
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON_FAIL:
            case ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA_FAIL:
            case ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON_FAIL:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                if (action.postMessages.failed.length > 0) {
                    // this.messageRemove.push(message.error(action.postMessages.failed, 5));
                    destroyMessage();
                    message.error(action.postMessages.failed, 5);
                }
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.NETWORK_FETCH_DATA_SUCCESS:
            case ActionTypes.NETWORK_POST_DATA_SUCCESS:
            case ActionTypes.NETWORK_FETCH_JSON_SUCCESS:
            case ActionTypes.NETWORK_POST_JSON_SUCCESS:
            case ActionTypes.NETWORK_FETCH_SUCCESS:
            case ActionTypes.NETWORK_POST_SUCCESS:
                // messageClose = this.messageRemove.pop();
                // window.console.log(messageClose, this.messageRemove);
                // if (messageClose) {
                //     window.console.log("I'm here - about to close message");
                //     messageClose();
                // }
                destroyMessage();
                if (action.messages.success.length > 0) {
                    // this.messageRemove.push(message.success(action.messages.success, 5));
                    destroyMessage();
                    message.success(action.messages.success, 5);
                }
                return Object.assign({}, state, {
                    workInProgress: false
                });
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_DATA_SUCCESS:
            case ActionTypes.NETWORK_POST_AND_THEN_FETCH_JSON_SUCCESS:
            case ActionTypes.NETWORK_POST_JSON_AND_THEN_FETCH_DATA_SUCCESS:
            case ActionTypes.NETWORK_POST_DATA_AND_THEN_FETCH_JSON_SUCCESS:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                if (action.postMessages.success.length > 0) {
                    // this.messageRemove.push(message.success(action.postMessages.success, 5));
                    destroyMessage();
                    message.success(action.postMessages.success, 5);
                }
                return Object.assign({}, state, {
                    workInProgress: false
                });
            case ActionTypes.HIDE_MODAL_BY_NAME:
                if (state.modalStack.indexOf(action.name) > -1) {
                    state.modalStack.splice(state.modalStack.indexOf(action.name), 1);
                }
                return Object.assign({}, state);

            case ActionTypes.IMAGE_UPLOAD_REQUESTED:
                // this.messageRemove.push(message.loading("מעלה תמונה...", 0));
                destroyMessage();
                message.loading("מעלה תמונה...", 0);
                return Object.assign({}, state, {
                    workInProgress: true
                });
            case ActionTypes.IMAGE_UPLOAD_SUCCESS:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                // this.messageRemove.push(message.success("התמונה הועלתה לשרת בהצלחה", 5));
                message.success("התמונה הועלתה לשרת בהצלחה", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });
            case ActionTypes.IMAGE_UPLOAD_FAILED:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                // this.messageRemove.push(message.error("חלה שגיאה בהעלאת התמונה", 5));
                message.error("חלה שגיאה בהעלאת התמונה", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });
            case ActionTypes.GENERATE_REPORT:
                // this.messageRemove.push(message.loading("מפיק דו\"ח...", 0));
                destroyMessage();
                message.loading("מפיק דו\"ח...", 0);
                return Object.assign({}, state, {
                    workInProgress: true
                });
            case ActionTypes.GENERATE_REPORT_FAIL:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                // this.messageRemove.push(message.error("חלה שגיאה בהפקת הדו\"ח", 5));
                message.error("חלה שגיאה בהפקת הדו\"ח", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });
            case ActionTypes.GENERATE_REPORT_SUCCESS:
                // messageClose = this.messageRemove.pop();
                // if (messageClose) {
                //     messageClose();
                // }
                destroyMessage();
                // this.messageRemove.push(message.success("הדו\"ח הופק בהצלחה", 5));
                message.success("הדו\"ח הופק בהצלחה", 5);
                return Object.assign({}, state, {
                    workInProgress: false
                });


            case ActionTypes.RAISE_WORKINPROGRESS_FLAG:
                return Object.assign({}, state, {
                    workInProgress: true
                });

            case ActionTypes.LOWER_WORKINPROGRESS_FLAG:
                return Object.assign({}, state, {
                    workInProgress: false
                });

            case ActionTypes.SET_JSON_UI:
                return Jax.mutate(state, action.who, action.obj);

            case ActionTypes.OVERRIDE_JSON_UI:
                return Jax.set(state, action.who, action.obj);

            default:
                return state;


        }
    }
}

export default new UiStore();
