/* @flow */
/*jshint esversion: 6 */
import * as React from "react";
import {
  Table,
  Modal,
  Input,
  Button,
  Icon,
  //notification,
  Tooltip,
  Checkbox,
  message
} from "antd";
import TableIDs from "../data/TableIDs.js";
import SelectorItemsModal from "./SelectorItemsModal.js";
import LangContext from "../contextProvider/LangContext";


const Search = Input.Search;

type Props = {
  // title: string
};

type State = {};

class SelectorItems extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.searchTimeout = null;
    this.state = { value: '' }
  }

  static contextType = LangContext;
  getText = (id) => this.context.get(id) || '[' + id + ']';


  componentDidMount() {
    console.log("zzzz  selectorItems mounted")
  }
  componentWillUnmount() {
    console.log("zzzz  selectorItems un-mounted")
  }

  componentDidUpdate(prevProps) {
    console.log("zzzz CDM ", prevProps.value, this.props.value)
    if (prevProps.value !== this.props.value) {
      console.log("zzzz if ", prevProps.value, this.props.value)
      this.setState({ value: this.props.value })
    }
  }

  getTooltipButton = (key, icon, tooltip, onClick) => {
    return (
      <span style={{ display: "inline-block", marginLeft: 1 }}>
        <Tooltip title={this.getText(tooltip)}>
          <Button
            key={key} className={"btn"}
            type={"primary"}
            onClick={onClick}
            disabled={this.props.disabled}>
            <Icon style={{ fontSize: 16 }} type={icon} theme="outlined" />
          </Button>
        </Tooltip>
      </span>)
  }

  render() {
    let inlineStyle = { display: "inline-block", marginLeft: 1 }
console.log("SelectorItemsModal test", this.props.ui.modalStack)
    return (
      <div>
        <span style={inlineStyle}>
          {/*
          <Input
            onChange={this.props.onChange}
            //onEnter={this.props.onEnter()}
            onBlur={this.props.onBlur}
            onKeyDown={(this.props.onKeyDown) ? this.props.onKeyDown : () => { }}
            value={this.props.value}
          />
          */}

          <Search
            id={"search_item_input_id"}
            tabIndex={this.props.inputTabIndex}
            autoFocus={this.props.doFocus ? true : false}
            style={{ width: 160 }}
            maxLength={15}
            // onChange={(e) => {
            //   clearTimeout(this.searchTimeout);
            //   this.searchTimeout = setTimeout((val) => { console.log(this); this.props.onChange(val); }, 100, e)
            // }}
            onChange={this.props.onChange}
            //onSearch={(v, e) => { }}            
            //onEnter={this.props.onEnter()}
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            onKeyDown={(this.props.onKeyDown) ? this.props.onKeyDown : () => { }}
            value={this.props.value /*this.state.value*/}
            disabled={this.props.disabled}
          />
        </span>
        {/* <span style={inlineStyle}>
          <Button
            key="test" className={"btn"}
            type={"primary"}
            //style={{ height: 40 }}
            onClick={() => { this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: this.props.idSelector }); }}
          >
            <Icon style={{ fontSize: 16 }} type="ellipsis" theme="outlined" />
          </Button>
        </span> */}
        {this.getTooltipButton('openItems', 'ellipsis', 16268, () => {
          this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: this.props.idSelector });
        })}
        {this.props.onSaveNewItem ?
          this.getTooltipButton("addItem", "plus", 14351, () => {
            this.props.uiActions.setIsEditFatherItem(false);
            this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { ...this.props.ui.data, itemId: null, onSave: this.props.onSaveNewItem })
          })
          : ''}
        {/* import modalCreateEditItem will be in father component */}

        <SelectorItemsModal
          // key={this.props.key}
          id={TableIDs.SelectorItemsModal}
          idSelector={this.props.idSelector}
          user={this.props.user}
          uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data}
          isExpandMatrixFatherItem={this.props.isExpandMatrixFatherItem}
          toggle={this.props.ui.modalStack.includes(TableIDs.SelectorItemsModal) && (this.props.idSelector === undefined || this.props.idSelector === this.props.ui.data.idSelector)}
          //title={"חיפוש פריט"}
          ActionQueue={this.props.ActionQueue}
          isRefreshParentTable={false}
          onChange={this.props.onChangeSearch}
          value={this.props.value}
          itemType={this.props.itemType ? this.props.itemType : 0}
          supplierId={this.props.supplierId}
          supplierName={this.props.supplierName}
          filterPerSupplier={this.props.filterPerSupplier}
          onLoadDataSource={this.props.onLoadDataSource}
          dataSource2={this.props.dataSource2}
          columns2={this.props.columns2}

        //onBlur={this.props.onBlur}
        />

      </div>
    );
  }
}

export default SelectorItems;
