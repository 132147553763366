/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Input, Icon, Button, Tooltip, Col, Row, message, Table, Popconfirm, InputNumber, Select, Form } from "antd";
import { fixCurrentPage } from "../utils/utils";
import Jax from "../Jax/jax.es6.module";
import TableIDs from '../data/TableIDs.js';
import LabelPrinter from "./LabelPrinter";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 30;  // px

type State = {};

class MultiBarcodeTable extends React.Component<Props, State> {
  constructor(props/*: Props*/) {
    super(props);
    this.state = { loading: false, visible: false, }
    //this.state._id = null;

    this.id = "MATRIX_BARCODE";

    // calc page height
    this.state.height = window.innerHeight;
    this.doOnResize = null;

    if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }

    this.api = {
      get: "get_barcode_table",
      edit: "edit_multi_barcode",
      create: "add_to_matrix_reverse_grid",
      delete: "delete_item",
      get_size_bar: "get_size_bar",
    };

  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  responsLabels = () => {
    //let data = Jax.get(this.props.data, "document.finalRecord.Lines", [])
    /*
    let res = [];
    data.map((i) => {
      let qty = parseInt(i.quantity);
      if (qty > 0) res.push({
        _ITEM_CODE: i.itemCode,
        _quantity: qty,
        _CUSTOMER_ID: 0,
        _CART_CUSTOMER_NAME: '',
      })
    })
    return res;
    */
    return [{
      _ITEM_CODE: this.state.itemCode,
      //_quantity: 0,
      _CUSTOMER_ID: 0,
      _CART_CUSTOMER_NAME: '',
    }];
  }

  componentDidMount() {
    //document.title = "Cash On Tab - פריטי מטריצה";
    //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
    this.initFilters();
    //this.props.dataActions.genericSelectorRefreshDataset(
    //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    //this.refreshData();

    window.addEventListener("resize", () => {
      clearTimeout(this.doOnResize);
      this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ height: window.innerHeight }, this.initFilters);
    //console.log("re render", window.innerHeight, " state: ", this.state.height)
  };

  handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
    let col = 9;
    // fall through
    switch (sorter.field) {
      case "key": col--; // 0
      //case "mId": col--;
      case "mCode": col--;
      case "mBarCode2": col--;
      case "mSize1ID": col--;
      case "mSizeDesc": col--;
      case "mColorID": col--;
      case "mColorDesc": col--;
      case "mPriceVal": col--;
      case "mUOMQty": col--;
      case "mDescription": break; // 8
      default:
        col = 0;
    }

    console.log("pagination: ", pagination, "sorter: ", sorter, "\ncol: ", col);

    let request = {
      // just of ui-pagination update
      page: pagination.current,
      recordsPerPage: pagination.pageSize,
      sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
      sortColumn: col,
      //sortColumn: 'col_1',
      //
      _page_num: pagination.current,
      _rows_num: pagination.pageSize,
      _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
      _sort_by: 'col_' + col,
      //_words: '',
    };

    this.props.dataActions.genericDataSetFilter(
      this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
  }

  initFilters = () => {
    //console.log("xxx 555", this.props.itemCode);
    this.props.dataActions.genericSelectorRefreshDataset(
      TableIDs.genericSelectorSizes, this.props.user.companyCode, this.props.user.token, this.api.get_size_bar, { _id: this.props.itemCode });

    let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
      ? (this.state.height - topHeight) / rowHeight
      : 1);

    let request = {
      _id: this.props.itemCode,
      // ui
      page: 1,
      recordsPerPage: /*5,*/numOfRowsByHeight,
      sortType: 1,
      sortColumn: 1,
      //dataObject: this.id,
      // api
      _words: '',
      _page_num: 1,
      _rows_num: /*5,*/numOfRowsByHeight,
      _isAsc: 1,
      _sort_by: 'col_1',

      _priceList_id: this.props.priceListId,
    };
    this.props.dataActions.genericDataSetFilter(
      this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
  }

  refreshData = () => {
    this.props.dataActions.genericDataGetRequested(
      this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
  }


  render() {
    const dataSource = this.props.data[this.id].data.map((x, index) => {
      let y = x.split("\f");
      return ({
        key: y[0], // 0
        mId: y[0],
        mCode: y[1],
        mBarCode2: y[2],
        mSize1ID: y[5],
        mSizeDesc: y[6],
        mColorID: y[8],
        mColorDesc: y[9],
        mPriceVal: y[10],
        mUOMQty: y[11], // 8
        mDescription: y[12],
        index: index
      })
    });

    let request = {
      // ui-pagination 
      page: 1,
      sortType: 1,
      sortColumn: 1,
      // api
      _page_num: 1,
      _isAsc: 1,
      _sort_by: 'col_1',
    };

    const divStyle = {
      align: "center",
      dir: "rtl", // dir: "ltr"
      padding: "2% 2% 2% 2%",
      //whiteSpace: "unset",
    };

    let listSizes = Jax.get(this.props.data, TableIDs.genericSelectorSizes + ".dataset", []).map((obj, index) => {
      return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' - ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
    });

    let columns = [
      {
        title: this.getText(10670),
        dataIndex: "mCode",
        key: "mCode",
        sorter: true,
        width: '20%',
        render: (text, record) => text,
      }, {
        title: this.getText(10671),
        dataIndex: "mBarCode2",
        key: "mBarCode2",
        sorter: true,
        width: '15%',
        render: (text, record) => {
          if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
            return (
              <Form.Item validateStatus={this.props.data[this.id].editedRecord.mBarCode2 ? "success" : "error"}>
                <Input
                  autoFocus
                  style={{ width: '98%', textAlign: 'center' }}
                  maxlength={15}
                  defaultValue={text}
                  onChange={(value) => {
                    //console.log("input number: ", text, record);
                    this.props.dataActions.genericDataSetEdit(this.id, { mBarCode2: value.target.value });
                  }} />
              </Form.Item>
            );
          } else {
            return text;
          }
        }
      }, {
        //mColorID
        title: this.getText(14415),
        dataIndex: "mDescription",
        key: "mDescription",
        sorter: true,
        width: '15%',
        render: (text, record) => text,

        render: (text, record) => {
          if (this.props.data[this.id].editing === record.key) {
            return (
              <Input
                style={{ width: '98%', textAlign: 'center' }}
                //defaultValue={text}
                value={this.props.data[this.id].editedRecord['mDescription']}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mDescription: e.target.value }); }} />);
          } else {
            return text;
          }
        }
      }, {
        title: this.getText(10673),
        dataIndex: "mSizeDesc",
        key: "mSizeDesc",
        sorter: true,
        width: '20%',
        //render: (text, record) => text,
        render: (text, record) => {
          if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
            return (
              <Select
                style={{ width: '98%', textAlign: 'center' }}
                placeholder={this.getText(14413)}
                showsearch
                filterOption={true}
                optionFilterProp="valuefilter"
                //defaultValue={0}
                onChange={(e) => {
                  let sizeObj = Jax.get(this.props.data, TableIDs.genericSelectorSizes + ".dataset", []).find(obj => obj['code'] === e);
                  this.props.dataActions.genericDataSetEdit(this.id, { mSize1ID: sizeObj['code'], mSizeDesc: sizeObj['name'] });
                }
                }
                value={this.props.data[this.id].editedRecord['mSize1ID']}
                notFoundContent={this.getText(14414)}
              >
                {listSizes}
              </Select >
            );
          } else {
            return text;
          }
        },
      }, {
        title: this.getText(10672),
        dataIndex: "mSize1ID",
        key: "mSize1ID",
        sorter: true,
        width: '10%',
        render: (text, record) => text,
      },
      /*{
        title: this.getText(10675),
        dataIndex: "mColorDesc",
        key: "mColorDesc",
        sorter: true,
        width: '15%',
        render: (text, record) => text,
      }, 
      */
      {
        title: this.props.isReverse ? this.getText(10678) : this.getText(10676),
        dataIndex: this.props.isReverse ? "mUOMQty" : "mPriceVal",
        key: this.props.isReverse ? "mUOMQty" : "mPriceVal",
        sorter: true,
        width: '10%',
        render: (text, record) => {
          if (this.props.data[this.id].editing === record.key && !this.props.isReverse /*&& this.props.data[this.id].editing == 0*/) {
            return (
              <InputNumber
                //min={0} max={100}
                precision={2} step={0.1}
                style={{ width: '98%', textAlign: 'center' }}
                //maxlength={15}
                defaultValue={text}
                onChange={(value) => {
                  //console.log("input number: ", text, record);
                  this.props.dataActions.genericDataSetEdit(this.id, { mPriceVal: value });
                }} />);
          } else {
            return text;
          }
        }
      }, {
        title: this.getText(10677),
        width: '10%',
        render: (text, record) => {
          if (this.props.data[this.id].editing !== record.key) {
            return (
              <span>
                <Tooltip title={this.getText(10679)}>
                  <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                    //this.props.uiActions.showModal(TableIDs.modalCreateEditMatrixItem, record['mCode'])
                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                  }} />
                </Tooltip>
                {!this.props.isReverse ?
                  (<Tooltip title={this.getText(10680)}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"highlight"} onClick={() => {
                      this.props.uiActions.setIsEditFatherItem(false);
                      this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { itemId: record['mCode'], fromMatrix: true })
                    }} />
                  </Tooltip>) :
                  (<Popconfirm title={this.getText(14417)} okText={this.getText(14418)} cancelText={this.getText(14419)}
                    onConfirm={() => {
                      console.log("record", record);
                      fixCurrentPage.bind(this)();
                      this.props.dataActions.genericDataDeleteRow(
                        this.id,
                        record['mCode'],
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.delete,
                        this.api.get);
                    }} >
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                  </Popconfirm>)}

                <Tooltip title={this.getText(10681)}>
                  <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"barcode"} onClick={() => {
                    this.setState({ itemCode: record['mCode'] }, () => { this.props.uiActions.showModal(TableIDs.labelPrinter) })
                  }} />
                </Tooltip>
                {/*
                          <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                              //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                              this.props.uiActions.showModal(TableIDs.modalCreateEditFatherItem, record['mId'])
                          }} />
                */}




              </span >);
          } else {
            let isCreate = (this.props.data[this.id].editing == '0') ? true : false;
            return (<span>
              <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                onClick={() => {
                  if (!this.props.data[this.id].editedRecord.mBarCode2) {
                    message.error(this.getText(17776));
                    return;
                  }
                  //let request = { _priceList_id: this.props.data['META_MATRIX'].editedRecord['mDefaultPriceListId'] }
                  let request = {
                    _priceList_id: this.props.priceListId,
                  }


                  console.log("price list id ", this.props.priceListId);

                  this.props.dataActions.genericDataSetFilter(
                    this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)

                  this.props.dataActions.genericDataSaveEditWithParams(
                    this.id, this.props.user.companyCode, this.props.user.token,
                    isCreate ? this.api.create : this.api.edit, this.api.get, request);

                }} />
              <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                onClick={() => {
                  if (isCreate) {
                    this.props.dataActions.genericDataCancelInlineCreate(this.id);
                  } else {
                    this.props.dataActions.genericDataCancelEditing(this.id);
                  }
                }} />
            </span>)
          }
        }
      }
    ];

    console.log("uistore", this.props.ui)


    return (
      <div key="barcode_grid" style={{ marginTop: 16 }}>
        <ResponsiveTable ui={this.props.ui} tableOnly={true}
          dataSource={dataSource}
          columns={columns}
          loading={this.props.ui.workInProgress}
          //scroll={{ x: 1650 }}
          bordered
          pagination={this.props.data[this.id].pagination}
          onChange={this.handleTableChange}
          locale={{ emptyText: this.getText(10682) }}
        />

        <LabelPrinter
          id={TableIDs.labelPrinter}
          labels={this.responsLabels()}
          qtyInput={true}
          user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data}
          toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
          title={this.getText(10683)}
        />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    //let request = { _id: this.props.ui.data };
    let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
      ? (this.state.height - topHeight) / rowHeight
      : 1);

    let request = {
      _id: this.props.itemCode,
      // ui
      page: 1,
      recordsPerPage: numOfRowsByHeight, //5,
      sortType: 1,
      sortColumn: 1,
      // api
      _words: '',
      _page_num: 1,
      _rows_num: numOfRowsByHeight, //5,
      _isAsc: 1,
      _sort_by: 'col_1',

      _priceList_id: this.props.priceListId,
    };

    if (this.props.priceListId !== prevProps.priceListId ||
      this.props.itemCode !== prevProps.itemCode ||
      (prevProps.toggle === false && this.props.toggle === true)) {
      this.props.dataActions.genericDataGetEditWithParams(
        this.id,
        this.props.user.companyCode,
        this.props.user.token,
        //{ _id: this.props.ui.data },
        request,
        this.api.get
      );
    }
  }
}

export default MultiBarcodeTable;
