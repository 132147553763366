/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import SettingMenu from "../menus/SettingMenu";
import Index from "./Settings/Index";
import EarningPoints from "./Settings/EarningPoints";
import UsingPoints from "./Settings/UsingPoints";
import CustomerClub from "./Settings/CustomerClub";
import CustomerCreditSettings from "./Settings/CustomerCreditSettings";
import CustomerField from "./Customers/CustomerField";
import PermissionTable from "./UserSettings/Permissions";
import TableIDs from '../data/TableIDs';
import StandingOrdersSettings from "./Settings/StandingOrdersSettings";
import BusinessLogic from "./Settings/BusinessLogic";
import LabelFormats from "./Settings/LabelFormats";
import ExternalOrders from "./Settings/ExternalOrders";
// import PointsOfSale from "./Settings/PointsOfSale";
import LogoSettings from "./Settings/LogoSettings";
// import UsersTable from "./Settings/UsersTable";
import PriceListSettings from "./Settings/PriceListSettings";
// import Multilingual from "./Settings/Multilingual";
import ImportExcelSettings from "./Settings/ImportExcelSettings";
import OrdersSiteSettings from "./VirtualShopSettings/OrdersSiteSettings";
import GoogleBilling from "./Settings/GoogleBilling";
import PaymentTypes from "./Settings/PaymentTypes";
// import ScheduledServices from "./Settings/ScheduledServices";
import ExportExcel from "./Settings/ExportExcel";
// import UpdateParameters from "./Settings/UpdateParameters";
import Priority from "./Settings/Priority";
import CreditTerms from "./Settings/CreditTerms";
import KdsConfigurations from "./Kds/KdsConfigurations";
import CustomerPortal from "./Settings/CustomerPortal";
import Benefits from "./Settings/Benefits";
import WooSites from "./Settings/WooSites";
import InsertUsersLog from "./Settings/InsertUsersLog";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object
};

type State = {};

class Settings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={91} path={this.props.baseUrl + "/settings/hakafa-docs"} exact render={() => <CustomerCreditSettings {...routeProps} />} />),
            (<Route key={82} path={this.props.baseUrl + "/settings/customer-fields"} exact render={() => <CustomerField {...routeProps} />} />),
            //(<Route key={92} path={this.props.baseUrl + "/settings/edit-buttons"} exact render={() => <ButtonsEditor {...routeProps} />} />),
            (<Route key={93} path={this.props.baseUrl + "/settings/standingOrders-defaults"} exact render={() => <StandingOrdersSettings {...routeProps} />} />),
            (<Route key={94} path={this.props.baseUrl + "/settings/business-logic"} exact render={() => <BusinessLogic {...routeProps} />} />),
            // (<Route key={95} path={this.props.baseUrl + "/settings/label-formats"} exact render={() => <LabelFormats {...routeProps} />} />),
            (<Route key={96} path={this.props.baseUrl + "/settings/external-orders"} exact render={() => <ExternalOrders {...routeProps} />} />),
            (<Route key={98} path={this.props.baseUrl + "/settings/logo-settings"} exact render={() => <LogoSettings {...routeProps} />} />),
            (<Route key={92} path={this.props.baseUrl + "/settings/price-list"} exact render={() => <PriceListSettings {...routeProps} />} />),
            (<Route key={144} path={this.props.baseUrl + "/settings/orders-site"} exact render={() => <OrdersSiteSettings {...routeProps} />} />),
            (<Route key={145} path={this.props.baseUrl + "/settings/import-excel-actions"} exact render={() => <ImportExcelSettings {...routeProps} />} />),
            (<Route key={147} path={this.props.baseUrl + "/settings/google-billing"} exact render={() => <GoogleBilling {...routeProps} />} />),
            (<Route key={148} path={this.props.baseUrl + "/settings/payment-types"} exact render={() => <PaymentTypes {...routeProps} />} />),
            (<Route key={150} path={this.props.baseUrl + "/settings/export-excel"} exact render={() => <ExportExcel admin {...routeProps} />} />),
            (<Route key={182} path={this.props.baseUrl + "/settings/priority"} exact render={() => <Priority admin={true} {...routeProps} />} />),
            (<Route key={183} path={this.props.baseUrl + "/settings/credit-terms"} exact render={() => <CreditTerms admin={true} {...routeProps} />} />),
            (<Route key={184} path={this.props.baseUrl + "/settings/kds-configurations"} exact render={() => <KdsConfigurations {...routeProps} />} />),
            (<Route key={185} path={this.props.baseUrl + "/settings/customer-portal"} exact render={() => <CustomerPortal {...routeProps} />} />),
            (<Route key={186} path={this.props.baseUrl + "/settings/benefits"} exact render={() => <Benefits {...routeProps} />} />),
            (<Route key={187} path={this.props.baseUrl + "/settings/woo-commerce"} exact render={() => <WooSites {...routeProps} />} />),
            (<Route key={188} path={this.props.baseUrl + "/settings/insert-users-log"} exact render={() => <InsertUsersLog {...routeProps} />} />),

            (<Route key={-1} path={this.props.baseUrl + "/settings/permissions"} exact render={() => <PermissionTable {...routeProps} />} />),
            (<Route key={-3} path={this.props.baseUrl + "/settings/earning-points"} exact render={() => <EarningPoints {...routeProps} />} />),
            (<Route key={-4} path={this.props.baseUrl + "/settings/sing-pointsu"} exact render={() => <UsingPoints {...routeProps} />} />),
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/settings"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <SettingMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default Settings;

