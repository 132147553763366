
import React from "react";
import { Form, Card, InputNumber, DatePicker, TimePicker, Button, Icon, Checkbox, Col, Row, message, Tooltip } from 'antd';
import TableIDs from '../../data/TableIDs.js';
//import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
// import SelectorItems from "../../components/SelectorItems";
import ResponsiveTable from "../../components/ResponsiveTable";
//import PictureView from "../../components/PictureView";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import VideoView from "../../components/VideoView";
import moment from "moment";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelector from "../../components/GenericSelector.js";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages.js";

const topHeight = 340; // px
const rowHeight = 50;  // px

class Advertisements extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            height: window.innerHeight,
            modalCreateEdit: null,
            modalHours: null,
            modalReport: null,
            modalOrder: false,
            modalFilters: null,
            adLogReportDateFrom: null,
            adLogReportDateTo: null,
            modalOrderPosFilter: null,
        }

        this.doOnResize = null;
        this.drag_id = null;

        this.id = "AD_TABLE";
        this.hours_id = "AD_SHOW_TIME_TABLE";
        this.order_id = "AD_ORDER_TABLE";

        this.api = {
            get: "get_ad_content",
            create_edit: "create_edit_ad_content",
            delete: "delete_ad_content",

            get_hours: "get_ad_show_time",
            create_edit_hours: "create_edit_ad_show_time",
            delete_hours: "delete_ad_show_time",

            get_order: "get_ad_order_pos",
            set_order: "set_ad_order_pos",
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
        if (props.data[this.hours_id] === undefined) { props.data[this.hours_id] = { ...props.data.genericData } }
        if (props.data[this.order_id] === undefined) { props.data[this.order_id] = { ...props.data.genericSelector } }
        if (props.data[TableIDs.genericSelectorPos] === undefined) { props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector } }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilter();
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, "list_pos"]
        });
    }

    initFilter = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            _fieldId: false,
            page: 1,
            recordsPerPage: numOfRowsByHeight, //5,
            sortType: 1,
            sortColumn: 1,
            // api
            _page_num: 1,
            _rows_num: numOfRowsByHeight, //5,

            _posList: "",
            _isActive: '1',
        };
        this.setFilter(request);
    }

    setFilter = (request) => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    handleTableChange = (pagination) => {

        let request = {
            _id: 0,

            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: 1,
            sortColumn: 1,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
        };

        this.setFilter(request)
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(14424);
    }

    onChange = (field, value) => {
        this.setState({ modalCreateEdit: { ...this.state.modalCreateEdit, [field]: value } })
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: null })
    }

    handleClear = (key) => {
        let obj = {}
        this.props.data[this.id].headers.forEach((x) => { obj = { ...obj, [x]: '' } });
        obj = { ...obj, key: key, tID: key }
        this.setState({ modalCreateEdit: obj }, () => { this.apiHours(key) })
    }

    handleOk = () => {

        // this.props.dataActions.genericDataSaveEditWithParams(
        //     this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, {...this.state.modalCreateEdit});

        const { tID, tPosList, tImageFileId, tSeconds, tPricePerShow, tActive } = this.state.modalCreateEdit;
        const { headers, data, editing } = this.props.data[this.hours_id];

        if (!tImageFileId) { message.error(this.getText(18310)); return; }
        if (!tSeconds) { message.error(this.getText(18311)); return; }
        if (!data.length) { message.error(this.getText(18312)); return; }
        if (editing > -1) { message.error(this.getText(18313)); return; }

        const dataSend = "tID\ftPosList\ftImageFileId\ftSeconds\ftPricePerShow\ftActive\r"
            + tID + "\f" + tPosList + "\f" + tImageFileId + "\f" + tSeconds + "\f" + tPricePerShow + "\f" + tActive;

        this.sendAPI(this.api.create_edit, dataSend, (ob) => {
            this.sendAPI(this.api.create_edit_hours, headers.join("\f") + "\feAdContendId\r" + data.map(x => x + "\f" + ob.data).join("\r"), () => {
                this.refreshData();
                this.handleCancel();
            })
        })
    }

    apiHours = (adID) => {
        this.props.dataActions.genericDataSetFilter(
            this.hours_id, this.props.user.companyCode, this.props.user.token, { adID }, this.api.get_hours);
    }

    getHourItem = (field) => {
        let er = this.props.data[this.hours_id].editedRecord;
        let typeTime = field === "eFromHour" || field === "eToHour";
        let format = typeTime ? "HH:mm:ss" : "DD/MM/YYYY";
        let sqlFormat = typeTime ? "HH:mm:ss" : "YYYY-MM-DD";
        let value = er && er[field] ? moment(er[field], sqlFormat) : null;
        let onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.hours_id, { [field]: e ? e.format(sqlFormat) : "" }) }
        let minDate = field == 'eToDate' ? er.eFromDate : null
        let maxDate = field == 'eFromDate' ? er.eToDate : null
        let disabledDate = d => disabledDatePicker(d, minDate, maxDate)
        return typeTime ? (<TimePicker {...{ format, value, onChange }} />) : (<DatePicker {...{ format, value, onChange, disabledDate }} />)
    }

    generateReport = () => {
        const { modalReport, adLogReportDateFrom, adLogReportDateTo } = this.state;
        let params = {
            adId: modalReport,
            dateFrom: adLogReportDateFrom,
            dateTo: adLogReportDateTo,
            reportName: "reports/AdLog.pdf",
            REPORT_LOCALE: this.getText(101),
        }
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [this.props.user.companyCode, this.props.user.token, params]
        });
    }

    getReportDatePicker = (label, field) => {
        let sqlFormat = "YYYY-MM-DD";
        let minDate = field == 'adLogReportDateTo' ? this.state.adLogReportDateFrom : null
        let maxDate = field == 'adLogReportDateFrom' ? this.state.adLogReportDateTo : null
        return (<Form.Item label={this.getText(label)}>
            <DatePicker
                format="DD/MM/YYYY"
                disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                value={this.state[field] ? moment(this.state[field], sqlFormat) : null}
                onChange={(e) => { this.setState({ [field]: e ? e.format(sqlFormat) : null }) }}
            />
        </Form.Item>)
    }

    // getField = (text, record, field) => {
    //     let editing = record.key === this.props.data[this.id].editing;
    //     let value = editing ? this.props.data[this.id].editedRecord[field] : text;
    //     let onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }
    //     let min = 1;
    //     let step = 1;
    //     let precision = 0;
    //     switch (field) {
    //         case 'tSeconds':
    //             return editing && /\.(jpe?g|png|gif|bmp)$/i.test(this.props.data[this.id].editedRecord.tImageFileId) ?
    //                 (<InputNumber {...{ value, onChange, min, step, precision, max: 9999 }} />)
    //                 : value;
    //         case 'tPricePerShow':
    //             min = 0; step = 1; precision = 2;
    //             return editing ? (<InputNumber {...{ value, onChange, min, step, precision }} />) : value ? parseFloat(value).toFixed(2) : 0;
    //         case 'tActive':
    //             return (<Checkbox checked={value == '1'} onChange={(e) => { if (editing) onChange(e.target.checked ? '1' : '0') }} disabled={!editing} />)
    //         case 'tImageFileId':
    //             return editing ? (<VideoView
    //                 {...this.props}
    //                 url={value}
    //                 onChange={onChange}
    //                 onRemove={() => { onChange(null) }}
    //                 onLoadDuration={(e) => { if (e) this.props.dataActions.genericDataSetEdit(this.id, { tSeconds: e ? Math.ceil(e) : 0 }) }}
    //             />) : <a
    //                 href={"https://" + (window.location.hostname == "localhost" ? "http://147.235.163.248" : window.location.hostname)
    //                     + "/uploads/" + this.props.user.companyCode + "/" + value}
    //                 target={"_blank"}>{value}</a>;
    //         case 'tPosList':
    //             return (<GenericSelector
    //                 {...this.props}
    //                 multi
    //                 disabled={!editing}
    //                 id={TableIDs.genericSelectorPos}
    //                 value={value ? value.split(',') : []}
    //                 onChange={e => { onChange(e ? e.join(',') : "") }}
    //             />);
    //     }
    // }

    getOrderData = (_pos) => {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.order_id, this.props.user.companyCode, this.props.user.token, this.api.get_order, { _pos });
    }

    orderChange = (_order) => {
        let idTable = this.props.data[this.id];
        let tPosIsset = idTable.data[0] ? idTable.data[0].split('\f')[6] : '0'
        let _pos = tPosIsset == '1' ? this.state.modalOrderPosFilter : '0'
        let dataSend = "_id\f_order\f_pos\r" + this.drag_id + "\f" + _order + "\f" + _pos
        this.drag_id = null;
        this.sendAPI(this.api.set_order, dataSend, () => { this.getOrderData(_pos) })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };
        console.log('onChangeVideo', this.props.data[this.id].editedRecord)

        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let idTable = this.props.data[this.id];
        let gt = this.getText;
        let tPosIsset = idTable.data[0] ? idTable.data[0].split('\f')[6] : '0'

        let colTxt = (text) => text

        let deleteQuetion = { title: gt(14394), okText: gt(14395), cancelText: gt(14396) }

        let colEdit = (text, record) => {
            // let editing = this.props.data[this.id].editing
            // if (editing != record.key) {
            return (<TableLineIcons
                // onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                onEdit={() => { this.setState({ modalCreateEdit: record }, () => { this.apiHours(record.key) }) }}
                onDelete={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get);
                }}
                deleteQuetion={deleteQuetion}
                genericIcons={[
                    // {
                    //     type: 'clock-circle',
                    //     tooltip: gt(14459),
                    //     onClick: () => { this.setState({ modalHours: record.key }, this.apiHours) }
                    // },
                    {
                        type: 'printer',
                        tooltip: gt(14552),
                        onClick: () => { this.setState({ modalReport: record.key }) }
                    }
                ]}
            />)
            // } else {
            //     return (<TableLineIcons
            //         onCheck={() => {
            //             this.props.dataActions.genericDataSaveEditWithParams(
            //                 this.id,
            //                 this.props.user.companyCode,
            //                 this.props.user.token,
            //                 this.api.create_edit,
            //                 this.api.get);
            //             // if (editing == 0) {
            //             //     setTimeout(() => {
            //             //         this.setState({ modalHours: this.props.data[this.id].data[0].split("\f")[0] }, () => {
            //             //             this.apiHours()
            //             //             setTimeout(() => {
            //             //                 this.props.dataActions.genericDataStartInlineCreate(this.hours_id)
            //             //             }, 1000);
            //             //         })
            //             //     }, 1000);
            //             // }
            //         }}
            //         onClose={() => {
            //             if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id, true);
            //             else this.props.dataActions.genericDataCancelEditing(this.id);
            //         }}
            //     />)
            // }
        }

        let colEditHours = (text, record) => {
            let editing = this.props.data[this.hours_id].editing
            let props = {}
            if (editing != record.key) {
                props = {
                    onEdit: () => { this.props.dataActions.genericDataStartEditing(this.hours_id, record.key, record.index) },
                    onDelete: () => {
                        // this.props.dataActions.genericDataDeleteRow(
                        //     this.hours_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete_hours, this.api.get_hours);
                        this.props.dataActions.dataTableDeleteRow(this.hours_id, record.index);
                    },
                    deleteQuetion
                }
            } else {
                props = {
                    onCheck: () => {
                        const { editedRecord } = this.props.data[this.hours_id]
                        // this.props.dataActions.genericDataSaveEditWithParams(
                        //     this.hours_id,
                        //     this.props.user.companyCode,
                        //     this.props.user.token,
                        //     this.api.create_edit_hours,
                        //     this.api.get_hours,
                        //     { eAdContendId: this.state.modalHours });

                        if (!editedRecord.eFromDate || !editedRecord.eToDate || !editedRecord.eFromHour || !editedRecord.eToHour) {
                            message.error(gt(18314));
                            return;
                        }

                        this.props.dataActions.dataTableSetRow(this.hours_id, record.index, editedRecord, true);
                        if (this.props.ui.modalStack.indexOf(this.hours_id + "Editor") > 0) this.props.uiActions.hideModal(this.hours_id + "Editor");
                    },
                    onClose: () => {
                        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.hours_id, true);
                        else this.props.dataActions.genericDataCancelEditing(this.hours_id);
                    }
                }
            }

            return (<TableLineIcons {...props} />)
        }

        let colHour = (field, record) => {
            return (this.props.data[this.hours_id].editing == record.key) ? this.getHourItem(field) : record[field];
        }

        let columns = [
            { title: gt(14553), dataIndex: "tID", key: "tID", width: '8%', render: colTxt },
            { title: gt(14427), dataIndex: "tImageFileId", key: "tImageFileId", width: '25%', render: t => (<VideoView  {...this.props} url={t} size={40} readOnly />) },
            { title: gt(14428), dataIndex: "tSeconds", key: "tSeconds", width: '10%', render: colTxt },
            { title: gt(14429), dataIndex: "tPricePerShow", key: "tPricePerShow", width: '10%', render: colTxt },
            { title: gt(14430), dataIndex: "tActive", key: "tActive", width: '7%', render: t => (<Checkbox checked={t == '1'} disabled />) },
            { title: gt(16415), dataIndex: "tPosList", key: "tPosList", width: '30%', render: t => (<GenericSelector {...this.props} disabled multi id={TableIDs.genericSelectorPos} value={t ? t.split(',') : []} onChange={() => { }} />) },
            { title: gt(14431), width: '10%', render: colEdit },
        ];

        let columnsHours = [
            { title: gt(14461), dataIndex: "eFromDate", key: "eFromDate", width: '22%', render: (t, r) => colHour('eFromDate', r) },
            { title: gt(14462), dataIndex: "eToDate", key: "eToDate", width: '22%', render: (t, r) => colHour('eToDate', r) },
            { title: gt(14463), dataIndex: "eFromHour", key: "eFromHour", width: '22%', render: (t, r) => colHour('eFromHour', r) },
            { title: gt(14464), dataIndex: "eToHour", key: "eToHour", width: '22%', render: (t, r) => colHour('eToHour', r) },
            { title: gt(14431), width: '12%', render: colEditHours },
        ];

        const orderCardStyle = {
            width: 100,
            height: 100,
            margin: 5,
            cursor: "move",
            display: 'inline-block',
            verticalAlign: 'text-top',
        }

        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        let baseUrl = isDev ? "http://147.235.163.248" : origin;
        let fileUrl = baseUrl + "/uploads/" + this.props.user.companyCode + "/";


        return (<div style={divStyle}>
            <ResponsiveTable
                idTable={idTable}
                columns={columns}
                percentageToMobile={[0, 20, 60, 20]}
                title={gt(14425)}
                actionButtons={[
                    {
                        buttonType: 'add',
                        buttonFunction: () => { this.handleClear('0') }
                        // buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) }
                    }, {
                        buttonType: 'filter',
                        buttonFunction: () => { this.setState({ modalFilters: this.props.data[this.id].filters }) }
                        // buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.id) }
                    },
                    {
                        buttonType: 'refresh',
                        buttonFunction: this.refreshData
                    }
                ]}
                genericActionButtons={[
                    <Tooltip title={gt(14426)}>
                        <Button onClick={() => { this.setState({ modalOrder: true }, () => { this.getOrderData('0') }) }}>
                            <Icon type="ordered-list" style={{ fontSize: 16 }} />
                        </Button>
                    </Tooltip>
                ]}
                onChange={this.handleTableChange}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />

            {/* <GenericModal
                visible={this.state.modalHours}
                onCancel={() => { this.setState({ modalHours: null }) }}
                title={gt(14459) + ' - ' + gt(14554) + ' ' + this.state.modalHours} width={1000}
                actionButtons={[
                    {
                        buttonType: 'refresh',
                        buttonFunction: this.apiHours
                    }, {
                        buttonType: 'add',
                        buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.hours_id) }
                    }
                ]}>
                <ResponsiveTable
                    tableOnly
                    idTable={this.props.data[this.hours_id]}
                    pagination={{ pageSize: numOfRowsByHeight }}
                    columns={columnsHours}
                    percentageToMobile={[50, 50]}
                />
            </GenericModal> */}
            <GenericModal
                visible={this.state.modalFilters != null}
                onCancel={() => { this.setState({ modalFilters: null }) }}
                title={gt(19343)}
                width={400}
                footer={[
                    <Button onClick={() => {
                        this.setState({ modalFilters: null })
                    }}>{gt(19344)}</Button>,
                    <Button onClick={() => {
                        this.setState({ modalFilters: null }, () => {
                            this.setFilter({ _posList: "", _isActive: "", page: 1, _page_num: 1 })
                        })
                    }}>{gt(19346)}</Button>,
                    <Button type="primary" onClick={() => {
                        const { _posList, _isActive } = this.state.modalFilters
                        this.setState({ modalFilters: null }, () => {
                            this.setFilter({ _posList, _isActive, page: 1, _page_num: 1 })
                        })
                    }}>{gt(19345)}</Button>,
                ]}>
                <Form.Item label={gt(19347)}>
                    <GenericSelectorPosStorages
                        {...this.props}
                        multi
                        // id={TableIDs.genericSelectorPos}
                        // api={"list_pos"}
                        value={this.state.modalFilters?._posList ? this.state.modalFilters._posList.split(",") : []}
                        onChange={e => { this.setState({ modalFilters: { ...this.state.modalFilters, _posList: e ? e.join(",") : "" } }) }}
                    />
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={this.state.modalFilters?._isActive == '1'}
                        onChange={e => { this.setState({ modalFilters: { ...this.state.modalFilters, _isActive: e.target.checked ? "1" : "0" } }) }}>{gt(19348)}</Checkbox>
                </Form.Item>
            </GenericModal>
            <GenericModal
                visible={this.state.modalCreateEdit != null}
                onCancel={() => { this.setState({ modalCreateEdit: null }) }}
                title={gt(this.state.modalCreateEdit && this.state.modalCreateEdit.key > 0 ? 18306 : 18305)}
                footer={[
                    <Button onClick={this.handleCancel}>{gt(18308)}</Button>,
                    <Button onClick={() => { this.handleClear(this.state.modalCreateEdit.key) }}>{gt(18309)}</Button>,
                    <Button type="primary" onClick={this.handleOk}>{gt(18307)}</Button>,
                ]}>
                {this.state.modalCreateEdit != null ?
                    (<Row>
                        <Col span={window.innerWidth > 600 ? 12 : 24}>
                            <Row style={{ textAlign: "center" }}>
                                <Col span={window.innerWidth > 600 ? 6 : 24}>
                                    <Form.Item label={gt(14427)}>
                                        <VideoView
                                            {...this.props}
                                            url={this.state.modalCreateEdit.tImageFileId}
                                            onChange={e => this.onChange("tImageFileId", e)}
                                            onRemove={() => { this.onChange("tImageFileId", null) }}
                                            onLoadDuration={(e) => { if (e) this.onChange("tSeconds", e ? Math.ceil(e) : 0) }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={window.innerWidth > 600 ? 6 : 24}>
                                    <Form.Item label={gt(14428)}>
                                        <InputNumber
                                            min={1} max={9999} step={1} precision={0}
                                            value={this.state.modalCreateEdit.tSeconds}
                                            onChange={e => this.onChange("tSeconds", e)}
                                            readOnly={!(/\.(jpe?g|png|gif|bmp)$/i.test(this.state.modalCreateEdit.tImageFileId))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={window.innerWidth > 600 ? 6 : 24}>
                                    <Form.Item label={gt(14429)}>
                                        <InputNumber
                                            min={0} step={1} precision={2}
                                            value={this.state.modalCreateEdit.tPricePerShow}
                                            onChange={e => this.onChange("tPricePerShow", e)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={window.innerWidth > 600 ? 6 : 24}>
                                    <Form.Item>
                                        <Checkbox
                                            checked={this.state.modalCreateEdit.tActive == '1'}
                                            onChange={e => this.onChange("tActive", e.target.checked ? '1' : '0')}
                                        >{gt(14430)}</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label={gt(16415)}>
                                <GenericSelectorPosStorages
                                    {...this.props}
                                    multi
                                    // id={TableIDs.genericSelectorPos}
                                    value={this.state.modalCreateEdit.tPosList ? this.state.modalCreateEdit.tPosList.split(',') : []}
                                    onChange={e => { this.onChange("tPosList", e ? e.join(',') : "") }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={window.innerWidth > 600 ? 12 : 24}>
                            <ResponsiveTable
                                title={gt(14459)} width={1000}
                                actionButtons={[
                                    {
                                        buttonType: 'refresh',
                                        buttonFunction: () => { this.apiHours(this.state.modalCreateEdit.key) }
                                    }, {
                                        buttonType: 'add',
                                        buttonFunction: () => { this.props.dataActions.genericDataStartInlineCreate(this.hours_id) }
                                    }
                                ]}
                                idTable={this.props.data[this.hours_id]}
                                pagination={{ pageSize: numOfRowsByHeight }}
                                columns={columnsHours}
                                percentageToMobile={[50, 50]}
                            />
                        </Col>
                    </Row>)
                    : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalReport}
                onCancel={() => { this.setState({ modalReport: null }) }}
                title={gt(14552) + ' - ' + gt(14554) + ' ' + this.state.modalReport}
                width={1000}
                footer={[
                    <Button key="back" onClick={() => { this.setState({ modalReport: null }) }}>{gt(14399)}</Button>,
                    <Button key="print" type="primary" onClick={this.generateReport} style={{ marginRight: 5 }}>{gt(14555)}</Button>
                ]}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>{this.getReportDatePicker(14557, 'adLogReportDateFrom')}</Col>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>{this.getReportDatePicker(14558, 'adLogReportDateTo')}</Col>
                </Row>
            </GenericModal>
            <GenericModal
                visible={this.state.modalOrder}
                onCancel={() => { this.setState({ modalOrder: false, modalOrderPosFilter: null }) }}
                title={gt(14426)}>
                {tPosIsset == '1' ? (
                    <Form.Item label={gt(16416)}>
                        <GenericSelectorPosStorages
                            {...this.props}
                            // id={TableIDs.genericSelectorPos}
                            value={this.state.modalOrderPosFilter}
                            onChange={e => { this.setState({ modalOrderPosFilter: e }, () => { this.getOrderData(e) }) }}
                        />
                    </Form.Item>
                ) : ""}
                {this.props.data[this.order_id].dataset.filter(f => f.code).map((x, i) => {
                    let previewvideo = fileUrl + x.name;
                    return (<Card
                        style={orderCardStyle}
                        draggable
                        onDragStart={() => { this.drag_id = x.code }}
                        onDragOver={(e) => { e.preventDefault() }}
                        onDrop={() => { this.orderChange(i + 1) }} >
                        <div style={{ height: 60, overflowY: 'hidden' }}>
                            {/\.(jpe?g|png|gif|bmp)$/i.test(x.name) ?
                                (<img alt="example" style={{ width: '100%' }} src={previewvideo} />) :
                                (<video style={{ width: '100%' }} src={previewvideo}></video>)}
                        </div>
                        <div style={{ textAlign: 'center' }}>{i + 1}</div>
                    </Card>)
                })}
            </GenericModal>
        </div>)
    }

}

export default Advertisements;
