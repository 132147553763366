
import React from "react";
import { Checkbox, Select, Modal, Button, Table, Input, Icon, Row, Col } from 'antd';

const { Option, OptGroup } = Select;

class GenericSelector extends React.Component/*<Props, State>*/ {

    state = {
        modalOpen: false,
        modalSearch: '',
        modalData: [],
        modalGroupData: [],
        notModalView: false,
    }

    componentDidMount() {
        if (this.props.api && this.props.id) {
            this.loadData()
        }
    }

    componentDidUpdate(prev) {
        if (this.props.id) {
            const dataset = this.props.data[this.props.id]?.dataset;
            const pDataset = prev.data[this.props.id]?.dataset
            if (dataset !== pDataset && this.props.onChangeGetDetailed && this.props.value) {
                let fData = this.onChangeGetDetailed(this.props.value)
                if (fData) this.props.onChangeGetDetailed(fData)
            }
        }

        if ((prev.optionsGroup != this.props.optionsGroup && this.props.api && this.props.id) || (prev.id != this.props.id)) {
            this.loadData()
        }
    }

    loadData = () => {
        if (this.props.optionsGroup) {
            this.props.dataActions.genericMultiSelectorRefreshDataset(
                this.props.id, this.props.user.companyCode, this.props.user.token, this.props.api, this.props.filters);
        } else {
            this.props.dataActions.genericSelectorRefreshDataset(
                this.props.id, this.props.user.companyCode, this.props.user.token, this.props.api, this.props.filters);
        }
    }

    nameText = (text) => {
        return this.props.nameText ? this.props.nameText(parseInt(text)) : text;
    }

    filterList = (filter) => {

        if (this.props.filterList) {
            return this.props.filterList.indexOf(filter.code) > -1 || this.props.filterList.indexOf(filter.id) > -1
        } else {
            return true
        }
    }

    groupFilterList = (filter) => {
        if (this.props.groupFilterList && filter.type) {
            return this.props.groupFilterList.indexOf(filter.type.id) > -1
        } else {
            return true
        }
    }

    getOptions = (dataset) => {
        if (this.props.addFirstRow) {
            dataset = [this.props.addFirstRow, ...dataset];
        }
        return dataset ? dataset.filter(this.filterList).map((x) => {
            let textValue = (this.props.notCodeView ? '' : x['code'] + ' - ') + this.nameText(x['name'])
            return x.code ? (<Option dir={"rtl"} key={x['code']} value={x['code']} valuefilter={x['code'] + ' ' + this.nameText(x['name'])}>
                {textValue}
            </Option>) : ""
        }) : [];
    }

    getOptionGroups = (dataset) => {
        let res = dataset ? dataset.filter(this.groupFilterList).map((node) => {
            return node.type ?
                (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                    {node.tags.filter(this.filterList).map((item) => {
                        return (<Option key={"tag_" + item.id} value={item.id} valuefilter={this.nameText(item.text)}>{this.nameText(item.text)}</Option>)
                    })}
                </OptGroup>)
                : []
        }) : []

        return this.props.addFirstRow ? [<Option value={this.props.addFirstRow.id}>{this.props.addFirstRow.text}</Option>, ...res] : res;
    }

    getMultiCheckbox = (dataset) => {
        let fullData = dataset.filter(this.filterList).map(this.props.optionsGroup ? (node) => node.tags.map((item) => item.id) : (x) => x.code).flat()
        return (<Checkbox
            disabled={this.props.disabled}
            checked={this.props.value && fullData.length === this.props.value.length}
            indeterminate={this.props.value.length && this.props.value.length < fullData.length}
            onChange={(e) => { this.onChange(e.target.checked ? fullData : []) }}
        />)
    }

    getDataset = () => {
        return this.props.dataset ? this.props.dataset
            : this.props.id && this.props.data[this.props.id] ?
                this.props.data[this.props.id].dataset
                : []
    }

    getListIcon = () => {
        return (<Icon type="unordered-list" style={{ cursor: 'pointer', width: "5%", verticalAlign: "top" }} onClick={() => { this.setState({ notModalView: !this.state.notModalView }) }} />)
    }

    getSelectStyle = () => {
        let sData = this.getDataset();


        let list = this.props.optionsGroup ? this.getOptionGroups(sData) : this.getOptions(sData);

        let sStyle = {
            width: ((this.props.multi ? 95 : 100) - (this.props.modalStyle && this.state.notModalView ? 5: 0)) + "%",
            paddingLeft: '4px',
            paddingRight: '4px'
        }

        return (<div style={this.props.style ? this.props.style : {}}>
            {this.props.modalStyle && this.state.notModalView ? this.getListIcon() : ""}
            {!this.props.hideCheckbox && this.props.multi && (!this.props.modalStyle || this.state.notModalView) ? this.getMultiCheckbox(sData) : ""}
            <Select
                key={this.props.id}
                showSearch
                filterOption={true}
                optionFilterProp="valuefilter"
                mode={this.props.multi ? "multiple" : null}
                maxTagCount={this.props.maxTagCount ? this.props.maxTagCount : 4}
                style={sStyle}
                value={this.props.value}
                onChange={this.onChange}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
            >{list}</Select>
        </div>)
    }

    openModal = () => {
        let sData = this.getDataset();
        let mData = [];
        let gData = [];
        let gChanged = this.state.groupChanged;

        if (this.props.optionsGroup) {
            sData.forEach((node) => {

                gData.push(node);
                if (!gChanged) gChanged = node.type.id;
                if (this.state.modalSearch) {
                    gChanged = null;
                    mData = [
                        ...mData,
                        ...node.tags
                            .filter(this.filterList)
                            .filter(f => f.id.indexOf(this.state.modalSearch) > -1 || f.text.indexOf(this.state.modalSearch) > -1)
                            .map(x => {
                                let v = this.props.multi ? this.props.value : [this.props.value];
                                return { code: x.id, name: x.text, isSelected: v.indexOf(x.id) > -1 }
                            })
                    ]

                } else if (gChanged === node.type.id) {
                    mData = node.tags
                        .filter(this.filterList)
                        .map(x => {
                            let v = this.props.multi ? this.props.value : [this.props.value];
                            return { code: x.id, name: x.text, isSelected: v.indexOf(x.id) > -1 }
                        })

                }
            });

        } else {
            mData = sData
                .filter(this.filterList)
                .filter(f => !this.state.modalSearch || f.code.indexOf(this.state.modalSearch) > -1 || f.name.indexOf(this.state.modalSearch) > -1)
                .map(x => {
                    let v = this.props.multi ? this.props.value : [this.props.value];
                    return { ...x, isSelected: v.indexOf(x.code) > -1 }
                })

        }


        this.setState({ modalOpen: true, modalData: mData, modalGroupData: gData, groupChanged: gChanged })
    }

    getModalSelectorStyle = () => {

        const { modalData, modalGroupData, groupChanged } = this.state;
        const { multi, value, disabled, modalStyle } = this.props;
        const dataset = this.getDataset();

        const fullData = dataset.map(this.props.optionsGroup ? (node) => node.tags : (x) => { return { id: x.code, text: x.name } }).flat().filter(this.filterList)
        const valueData = value ? fullData.filter(f => multi ? value.indexOf(f.id) > -1 : value === f.id) : []
        const resValue = valueData.map(x => x.id + " - " + x.text).join("\n");

        let groupData = modalGroupData.length ? modalGroupData : null;



        let filterModal = (e) => {
            this.setState({ modalSearch: e.target.value }, this.openModal);
        }

        let modalEditCheckbox = (value, record) => {
            return (<Checkbox
                checked={value === 'all' ? !modalData.find(f => !f.isSelected) : value}
                onChange={e => {
                    if (value === 'all') {
                        this.setState({
                            modalData: modalData.map(x => {
                                return { ...x, isSelected: e.target.checked }
                            })
                        }, () => {
                            let newData = e.target.checked ? modalData.map(x => x.code) : [];
                            if (this.props.optionsGroup) {
                                if (e.target.checked) {
                                    newData = [...this.props.value.filter(f => newData.indexOf(f) === -1), ...newData]
                                } else {
                                    newData = this.props.value.filter(f => modalData.map(x => x.code).indexOf(f) === -1)
                                }
                            }
                            this.onChange(newData);
                        })
                    } else if (this.props.multi) {
                        this.setState({
                            modalData: modalData.map(x => {
                                return x.code === record.code ? { ...x, isSelected: e.target.checked } : x
                            })
                        }, () => {
                            this.onChange(e.target.checked ? [...this.props.value, record.code] : this.props.value.filter(f => f !== record.code));
                        })
                    } else {
                        this.setState({
                            modalOpen: false, modalData: modalData.map(x => {
                                return x.code === record.code ? { ...x, isSelected: e.target.checked } : x
                            })
                        }, () => {
                            this.onChange(e.target.checked ? record.code : "");
                        })
                    }
                }}
            />)
        }

        const inputProps = {
            rows: 3,
            readOnly: true,
            disabled,
            value: resValue,
            onClick: this.openModal,
            prefix: <Icon type="down" style={{ color: disabled ? '#aaaaaa' : '#000000' }} onClick={this.openModal} />,
            style: { cursor: 'pointer', width: "95%" },
        }

        return (<div style={this.props.style ? this.props.style : {}}>
            {/* <Row>
                <Col span={3}>
                    <Button type="primary" disabled={this.props.disabled} onClick={this.openModal}><Icon type="bars" style={{ fontSize: 16 }} /></Button>
                </Col>
                <Col span={21}>
                    <div style={{ display: "inline-block", width: '100%', paddingRight: 5 }}>{this.getSelectStyle()}</div>
                </Col>
            </Row> */}
            <div style={{ display: "inline-block", width: '100%', paddingRight: 5 }}>
                {this.getListIcon()}
                {multi ? (<Input.TextArea {...inputProps} />) : (<Input {...inputProps} />)}
            </div>

            <Modal
                visible={this.state.modalOpen}
                onCancel={() => { this.setState({ modalOpen: false }) }}
                title={modalStyle.title}
                width={groupData ? 800 : 600}
                footer={[
                    <Button onClick={() => {
                        this.setState({ modalOpen: false })
                    }}>{modalStyle.cancelText}</Button>,
                    <Button onClick={() => {
                        filterModal({ target: { value: "" } })
                        this.onChange(this.props.multi ? [] : '')
                    }}>{modalStyle.clearText}</Button>,
                ]}>

                <Row>
                    <Col span={groupData ? 7 : 0}>
                        <Checkbox
                            style={{ marginLeft: 5 }}
                            checked={value?.length >= fullData?.length}
                            indeterminate={value?.length > 0 && value?.length < fullData?.length}
                            onChange={e => {
                                this.onChange(e.target.checked ? fullData.map(x => x.id) : []);
                                this.setState({ modalData: modalData.map(x => { return { ...x, isSelected: e.target.checked } }) })
                            }}>
                            <b>בחר הכל</b>
                        </Checkbox>
                        {groupData?.map(x => {
                            let tTags = x.tags.filter(this.filterList);
                            let checkedCount = tTags.filter(f => this.props.value && this.props.value.indexOf(f.id) > -1).length;
                            return (<div
                                style={{
                                    background: x.type.id === groupChanged ? '#40a9ff' : 'white',
                                    color: x.type.id === groupChanged ? 'white' : 'black',
                                    cursor: 'pointer',
                                    padding: 2
                                }}
                                onClick={() => {
                                    this.setState({ groupChanged: x.type.id, modalSearch: '', }, this.openModal)
                                }}>
                                <Checkbox
                                    style={{ marginLeft: 5 }}
                                    checked={checkedCount === tTags.length}
                                    indeterminate={checkedCount && checkedCount < tTags.length}
                                    onChange={e => {
                                        let newData = e.target.checked ? tTags.map(x => x.id) : [];
                                        if (e.target.checked) {
                                            newData = [...this.props.value.filter(f => newData.indexOf(f) === -1), ...newData]
                                        } else {
                                            newData = this.props.value.filter(f => tTags.map(x => x.id).indexOf(f) === -1)
                                        }
                                        this.onChange(newData);
                                    }}
                                />
                                {x.type.text}
                            </div>
                            )
                        })}
                    </Col>
                    <Col span={groupData ? 17 : 24}>
                        <Input.Search
                            value={this.state.modalSearch}
                            onChange={filterModal}
                        />
                        <Table
                            dataSource={modalData}
                            columns={[
                                { title: modalStyle.col1Title, key: 'code', dataIndex: 'code', sorter: true, width: '25%' },
                                { title: modalStyle.col2Title, key: 'name', dataIndex: 'name', sorter: true, width: '50%' },
                                { title: this.props.multi ? modalEditCheckbox('all') : "", key: 'isSelected', dataIndex: 'isSelected', width: '25%', render: modalEditCheckbox },
                            ]}
                            onChange={(pagination, filters, sorter) => {
                                if (sorter.order) {
                                    let dataset = modalData.sort((a, b) => {
                                        if (sorter.order === "ascend") {
                                            return sorter.field === 'code' ? a.code - b.code : a.name.localeCompare(b.name)
                                        } else {
                                            return sorter.field === 'code' ? b.code - a.code : b.name.localeCompare(a.name)
                                        }
                                    })
                                    this.setState({ modalData: dataset })
                                }
                            }}
                        />
                    </Col>
                </Row>

            </Modal>
        </div>)
    }

    onChangeGetDetailed = (id) => {
        let sData = this.getDataset();
        if (this.props.optionsGroup) {
            let typeCode = null;
            let typeName = null;
            let name = null;
            sData.forEach((node) => {
                if (node.type) {
                    node.tags.forEach((item) => {
                        if (item.id == id) {
                            typeCode = node.type.id;
                            typeName = node.type.text;
                            name = item.text;
                        }
                    })
                }
            })
            return { code: id, name, typeCode, typeName }
        } else {
            return sData.find(f => f.code == id)
        }
    }

    onChange = (e) => {
        this.props.onChange(e);
        if (this.props.onChangeGetDetailed) {
            if (this.props.multi) {
                this.props.onChangeGetDetailed(e.map(x => this.onChangeGetDetailed(x)))
            } else {
                this.props.onChangeGetDetailed(this.onChangeGetDetailed(e))
            }
        }
    }

    render() {

        return this.props.modalStyle && !this.state.notModalView ? this.getModalSelectorStyle() : this.getSelectStyle()
    }
}

export default GenericSelector;
