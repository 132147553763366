
import React from "react";
import {
    Input, InputNumber, Card, Button, Modal, Select, Form,
    Checkbox, message, Switch, Tooltip, Popconfirm, Icon,
} from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import Jax from "../Jax/jax.es6.module";
// import TextArea from "antd/lib/input/TextArea";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import DateTimePicker from "./DateTimePicker";

const { Option, OptGroup } = Select;
const { TextArea, Password } = Input;

//type State = {}
class ModalCreateEditPointsOfSale extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);

        this.state = {
            modalSwiich: false,
            modalNewSwiich: false,
            newSwiichName: "",
            newSwiichPassword: "",
        }

        this.id = "MODAL_CREATE_EDIT_POINTS_OF_SALE";
        this.swiich_id = "SWIICH_TOKENS";

        this.api = {
            get: "get_points_of_sale_single_data",
            create_edit: "create_edit_points_of_sale",

            get_swiich: "get_swiich_tokens",
            delete_swiich: "delete_swiich_token",
        }

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.selectors = [
            { id: TableIDs.genericSelectorPointsOfSaleTypes, script: "points_of_sale_types_list" },
            { id: TableIDs.genericSelectorTaxRegion, script: "get_tax_region_list" },
            { id: TableIDs.genericSelectorStorages, script: "get_storage_list" },
            { id: TableIDs.genericSelectorPosOs, script: "get_pos_os_list" },
            { id: TableIDs.genericSelectorInterfaceLocales, script: "get_interface_locales_list" },
            { id: TableIDs.genericSelectorPriceList, script: "get_prices" },
            { id: TableIDs.genericSelectorUsers, script: "get_users_list" },
        ];

        this.requiredField = [
            "sName",
            "sTypeID",
            "sOS",
            "sLocaleCode",
            "sTaxRegionID",
            "sStorageID",
            "sWalkInCustomerID",
            "sShvaUserName",
            "sShvaPassword",
            "sShvaMerchant",
            "sPriceList",
            "sPriceListDefault",
        ];

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        if (props.data[this.swiich_id] === undefined) {
            props.data[this.swiich_id] = { ...props.data.genericData };
        }

        this.selectors.map((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        this.selectors.map((x) => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericSelectorRefreshDataset,
                args: [x.id, this.props.user.companyCode, this.props.user.token, x.script]
            });
        });
        this.swiichApi();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    initFilters = () => {
        let request = { _id: this.props.ui.data ? this.props.ui.data['sID'] : 0 }
        this.props.dataActions.genericDataGetEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true))
            this.initFilters();
    }

    dataObject = (id) => {
        let headers = this.props.data[id].headers;
        return this.props.data[id].data.map((x, indexData) => {
            let y = x.split("\f");
            let ob = { key: y[0], index: indexData }
            headers.map((z, index) => ob = { ...ob, [z]: y[index] });
            return ob;
        });
    }

    handleOk = () => {
        let rData = this.props.data[this.id].editedRecord;

        if (!parseInt(rData["sID"])) {
            message.error(this.getText(11710))
        } if (parseInt(rData["sID"]) > 1000) {
            message.error(this.getText(18443))
        } else if (this.requiredField.filter(f => !rData[f]).length) {
            message.error(this.getText(11711))
        } else {
            let isNew = this.props.ui.data && this.props.ui.data['sID'] > 0 ? 0 : 1;

            let getRequested = () => {
                message.success(this.getText(11712));

                this.props.dataActions.genericDataGetRequested(
                    "POINTS_OF_SALE_TABLE",
                    this.props.user.companyCode,
                    this.props.user.token,
                    "get_points_of_sale_table");

                this.handleCancel();
            }

            let shvaChangePassword = () => {
                let dataSend = "CompanyId\fPosId\r" + rData['sCompanyId'] + "\f" + rData['sID'];
                this.sendAPI("shva_change_password_for_company", dataSend, getRequested);
            }

            let sCallback = (ob) => {
                if (!parseInt(ob.data)) {

                    this.props.dataActions.genericDataJustSaveEditWithParams(
                        this.id,
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.create_edit,
                        { _isNew: isNew, _id: rData['sID'], ...rData });

                    setTimeout(rData['sShvaNewTerminal'] == 1 ? shvaChangePassword : getRequested, 700);
                }
            }

            this.checksIfPosExist(parseInt(rData["sID"]), sCallback)
        }
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateEditPointsOfSale);
    }

    responsiveCard = (title, content) => {
        let responsiveStyle = (window.innerWidth > 600) ? {
            width: '32%',
            display: 'inline-block',
            marginRight: '1%',
            verticalAlign: 'text-top',
            backgroundColor: '#dddddd'
        } : {}

        return (<Card style={responsiveStyle} title={title}>{content}</Card>)
    };

    checksIfPosExist = (id, sCallback) => {
        let data = this.props.data[this.id];
        let posNum = data && data.data && data.data[0] ? data.data[0].split("\f")[0] : "";
        if (parseInt(id) != posNum) {
            this.sendAPI("checks_if_pos_not_exist", "_id\r" + id, sCallback);
        } else {
            sCallback({ data: 0 });
        }
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => e);
    }

    swiichApi = () => {
        this.props.dataActions.genericDataSetFilter(
            this.swiich_id, this.props.user.companyCode, this.props.user.token, {}, this.api.get_swiich)
    }

    modalSwiich = () => {
        let getCol = (title, key, width) => {
            return {
                title: title ? this.getText(title) : '',
                key: key,
                dataIndex: key,
                width: width,
                render: (t) => t
            }
        }

        let getIconButton = (tooltip, title, icon, onConfirm) => {
            return (<Tooltip title={this.getText(tooltip)}>
                <Popconfirm title={this.getText(title)} onConfirm={onConfirm}
                    okText={this.getText(14280)} cancelText={this.getText(14281)}>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={icon} />
                </Popconfirm>
            </Tooltip>)
        }

        let columns = [
            getCol('', 'nID', '10%'),
            getCol(14284, 'mLoginName', '20%'),
            getCol(14247, 'nDesc', '20%'),
            getCol(14248, 'nToken', '30%'),
            {
                width: '10%',
                render: (t, r) => {
                    return (<Button type="primary" onClick={() => {
                        this.setState({ modalSwiich: false }, () => {
                            this.props.dataActions.genericDataSetEdit(this.id, { sSwiichVendorToken: r.nToken })
                        })
                    }}>{this.getText(14249)}</Button>)
                }
            }, {
                width: '10%',
                render: (t, r) => {
                    return (<span>
                        {getIconButton(14278, 14279, "reload", () => {
                            this.newSwiichApi(r.mLoginName, r.mLoginPassword);
                        })}
                        {getIconButton(14282, 14283, "delete", () => {
                            this.props.dataActions.genericDataDeleteRow(
                                this.swiich_id, r.key, this.props.user.companyCode, this.props.user.token,
                                this.api.delete_swiich, this.api.get_swiich);
                        })}
                    </span>)
                }
            }
        ];

        return (<Modal
            destroyOnClose={true}
            width={window.innerWidth > 800 ? 800 : '95%'}
            visible={this.state.modalSwiich}
            onCancel={() => { this.setState({ modalSwiich: false }) }}
            title={(<div style={{ padding: 4 }}>
                <PageToolbar
                    title={this.getText(14230)}
                    actionButtons={[{
                        buttonType: 'add',
                        buttonFunction: () => { this.setState({ modalNewSwiich: true }) }
                    }]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)}
            footer={false}
        >
            <ResponsiveTable
                tableOnly={true}
                ui={this.props.ui}
                idTable={this.props.data[this.swiich_id]}
                columns={columns}
                pagination={{ pageSize: 10 }}
            />
            {this.modalNewSwiich()}
        </Modal>)
    }

    modalNewSwiich = () => {
        const { newSwiichName, newSwiichPassword } = this.state;
        let setStateInput = (field, value) => { this.setState({ [field]: value.target.value }) }
        return (<Modal
            destroyOnClose={true}
            width={window.innerWidth > 600 ? 500 : '85%'}
            visible={this.state.modalNewSwiich}
            onCancel={() => { this.setState({ modalNewSwiich: false, newSwiichName: "", newSwiichPassword: "" }) }}
            footer={false}
        >
            <Form.Item label={this.getText(14275)}>
                <Input value={newSwiichName} onChange={(e) => { setStateInput('newSwiichName', e) }} />
            </Form.Item>
            <Form.Item label={this.getText(14276)}>
                <Password value={newSwiichPassword} onChange={(e) => { setStateInput('newSwiichPassword', e) }} />
            </Form.Item>
            <Button type="primary" onClick={() => {
                let findLoginName = (f) => {
                    let y = f ? f.split('\f') : [];
                    return y[3] && y[3] === newSwiichName;
                }
                if (!newSwiichName || !newSwiichPassword) {
                    message.error(this.getText(14286))
                } else if (this.props.data[this.swiich_id].data.find(findLoginName)) {
                    message.error(this.getText(14285))
                } else {
                    this.newSwiichApi(newSwiichName, newSwiichPassword)
                }
            }}>{this.getText(14277)}</Button>
        </Modal>)
    }

    newSwiichApi = (name, password) => {
        console.log('apiSwiich', name, password);
        this.setState({
            modalNewSwiich: false,
            newSwiichName: "",
            newSwiichPassword: ""
        }, this.swiichApi);
    }

    render() {
        const { loading } = this.state;

        let posNum = this.props.ui.data ? this.props.ui.data['sID'] : 0;
        let posName = this.dataObject(this.id)[0] ? this.dataObject(this.id)[0]['sName'] : '';

        let titleName = this.getText(11713) + " ";
        titleName += (posNum > 0) ? this.getText(11714) + ' ' + posNum + ' - ' + posName : this.getText(11715);

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={titleName}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let cardStyle = { backgroundColor: '#dddddd', margin: 5 }

        let dStyle = (w) => (window.innerWidth > 600) ? { width: w + '%', display: 'inline-block', verticalAlign: 'text-top' } : {}

        let cStyle = (w) => { return { width: w + '%', padding: 2, display: 'inline-block' } }

        let editedRecord = this.props.data[this.id].editedRecord;

        let updateIn = (f, v, e) => { if ((typeof e === "boolean" && e) || (typeof e === "number" && v.length <= e)) this.props.dataActions.genericDataSetEdit(this.id, { [f]: v }) }

        let frameForm = (l, c) => (<div><div style={cStyle(33)}>{l}:</div><div style={cStyle(66)}> {c} </div> </div>);

        let rField = (l, f) => (this.requiredField.indexOf(f) > -1) ? (<span>{l}<span style={{ color: 'red' }}> * </span></span>) : l;

        let tInput = (l, f, e) => frameForm(rField(l, f), (<Input value={editedRecord[f]} onChange={(v) => { updateIn(f, v.target.value, e) }} />))
        let txInput = (l, f, e) => frameForm(rField(l, f), (<TextArea rows={5} value={editedRecord[f]} onChange={(v) => { updateIn(f, v.target.value, e) }} />))
        let nInput = (l, f) => frameForm(rField(l, f), (<InputNumber value={editedRecord[f]} onChange={(v) => { updateIn(f, v, true) }} />));

        let sCallback = (ob) => {
            if (parseInt(ob.data) > 0) {
                message.error(this.getText(11716));
                this.props.dataActions.genericDataSetEdit(this.id, { ["sID"]: posNum });
            }
        }

        let posNumInput = (l) => frameForm(l, (<InputNumber value={editedRecord["sID"] > 0 ? editedRecord["sID"] : ""} onChange={(v) => { updateIn("sID", v, posNum === 0) }}
            onBlur={(n) => this.checksIfPosExist(n.target.value, sCallback)} />))

        let gSelector = (num, label, field) => {
            let selectData = Jax.get(this.props.data, this.selectors[num].id + ".dataset", []);

            let aSelect = selectData.map((obj) => {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}> {obj['name']}</Option>)
            });

            return frameForm(rField(label, field),
                (<Select
                    key={field} showSearch filterOption={true} style={{ width: '100%' }} optionFilterProp="valuefilter"
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: value }) }}
                    value={editedRecord ? editedRecord[field] : ''} >
                    {aSelect}
                </Select>));
        }

        let mSelector = (num, label1, label2, field1, field2) => {
            let selectData = Jax.get(this.props.data, this.selectors[num].id + ".dataset", []);
            let selected = editedRecord && editedRecord[field1] ? editedRecord[field1].split(',') : [];

            let aSelect = selectData.map((obj) => {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            });

            let bSelect = selectData.map((obj) => {
                if (selected.indexOf(obj['code']) > -1)
                    return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            });

            return (<span>
                {frameForm(rField(label1, field1),
                    (<span>
                        <Checkbox onChange={(e) => {
                            if (e.target.checked)
                                this.props.dataActions.genericDataSetEdit(this.id, { [field1]: selectData.map((obj) => obj['code']).join(',') });
                            else
                                this.props.dataActions.genericDataSetEdit(this.id, { [field1]: '' });
                        }} />

                        <Select
                            key={field1} mode="multiple" maxTagCount={4} showSearch filterOption={true} style={{ width: '93%' }} optionFilterProp="valuefilter"
                            onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { [field1]: value.join(',') }) }}
                            value={selected} >
                            {aSelect}
                        </Select>
                    </span>))}
                {field2 ? frameForm(rField(label2, field2),
                    (<Select
                        key={field2} showSearch filterOption={true} style={{ width: '100%' }} optionFilterProp="valuefilter"
                        onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { [field2]: value }) }}
                        value={editedRecord ? editedRecord[field2] : ''} >
                        {bSelect}
                    </Select>)) : ''}
            </span>);
        }


        let gSwitch = (l, f) => {
            return frameForm(l, (<Switch checkedChildren={this.getText(11717)} unCheckedChildren={this.getText(11718)}
                onChange={(v) => this.props.dataActions.genericDataSetEdit(this.id, { [f]: v ? 1 : 0 })}
                checked={(editedRecord[f] == 1) ? true : false}
            />))
        }

        let datePickerEdit = (l, f, e) => frameForm(rField(l, f), (<DateTimePicker format="DD/MM/YYYY" value={editedRecord[f]} onChange={(v) => { updateIn(f, v, e) }} />))

        return (
            <Modal
                destroyOnClose={true} width={'95%'} visible={false || this.props.toggle}
                title={titleBar} onOk={this.handleOk} onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(11719)}</Button>,
                    <Button key="clear" loading={loading} onClick={this.initFilters}>{this.getText(11720)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11721)}</Button>,
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction} >
                <Form //id="search_working_hours_form" layout="horizontal" //className="ant-advanced-search-form"
                    style={{ columnWidth: 'auto', columnCount: '1' }}
                //onSubmit={this.handleSearch}
                >

                    <div style={dStyle(40)}>
                        <Card style={cardStyle} title={this.getText(11722)}>
                            {posNumInput(this.getText(11730))}
                            {tInput(this.getText(11731), "sActivationTime", false)}
                            {datePickerEdit(this.getText(16807), "sAgreementDate", true)}
                            {tInput(this.getText(11732), "sHardwareIdentifier", false)}
                            {tInput(this.getText(11733), "sVersion", false)}
                            {tInput(this.getText(17369), "sSerialNumber", false)}
                            {tInput(this.getText(11734), "sName", true)}
                            {tInput(this.getText(13905), "sShortName", 10)}
                            {gSelector(0, this.getText(11735), 'sTypeID')}
                            {gSelector(3, this.getText(11736), 'sOS')}
                            {gSelector(4, this.getText(11737), 'sLocaleCode')}
                            {gSelector(1, this.getText(11738), 'sTaxRegionID')}
                            {gSelector(2, this.getText(11739), 'sStorageID')}
                            {nInput(this.getText(11740), "sWalkInCustomerID")}
                            {mSelector(5, this.getText(11741), this.getText(11744), 'sPriceList', 'sPriceListDefault')}
                            {gSwitch(this.getText(11742), "sSendNumerators")}
                            {mSelector(6, this.getText(11743), false, 'sUsers', false)}
                            {nInput(this.getText(14064), "sIphoneModule")}
                            {nInput(this.getText(15703), "sBiSchemaId")}
                            {gSwitch(this.getText(17481), "sIsBOPos")}
                        </Card>
                    </div>
                    <div style={dStyle(28)}>
                        <Card style={cardStyle} title={this.getText(11723)}>
                            {tInput(this.getText(11745), "sShvaUserName", true)}
                            {tInput(this.getText(11746), "sShvaPassword", true)}
                            {tInput(this.getText(11747), "sShvaMerchant", true)}
                            {gSwitch(this.getText(11748), "sShvaNewTerminal")}
                        </Card>
                        <Card style={cardStyle} title={this.getText(11724)}>
                            {tInput(this.getText(11749), "sErnNumber", true)}
                            {gSwitch(this.getText(11750), "sIsErnRequired")}
                            {txInput(this.getText(11751), "sErnCheckPrintout", true)}
                        </Card>
                        <Card style={cardStyle} title={this.getText(15384)}>
                            {tInput(this.getText(15385), "sAppliCheckUser", true)}
                            {tInput(this.getText(15386), "sAppliCheckPassword", true)}
                            {tInput(this.getText(15387), "sAppliCheckBusinessId", true)}
                        </Card>
                        <Card style={cardStyle} title={this.getText(11725)}>
                            {tInput(this.getText(11752), "sCardcomTerminalNumber", true)}
                            {tInput(this.getText(11753), "sCardcomTerminalUsername", true)}
                        </Card>
                        <Card style={cardStyle} title={this.getText(14209)}>
                            {frameForm(
                                rField(this.getText(14210), 'sSwiichVendorToken'),
                                (<Input
                                    onClick={() => { this.setState({ modalSwiich: true }, this.swiichApi) }}
                                    onKeyDown={() => { this.setState({ modalSwiich: true }) }}
                                    value={editedRecord['sSwiichVendorToken']}
                                />)
                            )}
                        </Card>

                    </div>
                    <div style={dStyle(28)}>
                        <Card style={cardStyle} title={this.getText(11726)}>
                            {tInput(this.getText(11754), "sPinPadSerialNumber", true)}
                            {/* <Button style={editedRecord["sPinPadSerialNumber"] === "" ? { display: 'none' } : {}} type="primary"
                                onClick={() => { }}
                            >{this.getText(11762)}</Button> */}
                        </Card>
                        <Card style={cardStyle} title={this.getText(13374)}>
                            {tInput(this.getText(13375), "sPinpadDangotCode", true)}
                            {tInput(this.getText(13376), "sPinpadDangotSerial", true)}
                            {tInput(this.getText(13377), "sPinpadDangotIP", true)}
                        </Card>
                        <Card style={cardStyle} title={this.getText(11727)}>
                            {tInput(this.getText(11755), "s10BisResId", true)}
                            {tInput(this.getText(11756), "s10BisUsername", true)}
                            {tInput(this.getText(11757), "s10BisPassword", true)}
                        </Card>
                        <Card style={cardStyle} title={this.getText(11728)}>
                            {tInput(this.getText(11758), "sGoodiRestaurantId", true)}
                            {tInput(this.getText(11759), "sGoodiTerminalId", true)}
                        </Card>
                        <Card style={cardStyle} title={this.getText(11729)}>
                            {tInput(this.getText(11760), "sSodexoRestaurantId", true)}
                            {tInput(this.getText(11761), "sSodexoPosId", true)}
                        </Card>
                        <Card style={cardStyle} title={this.getText(15605)}>
                            {nInput(this.getText(15606), "sMultiPassPosId")}
                        </Card>
                        <Card style={cardStyle} title={this.getText(15810)}>
                            {txInput(this.getText(15607), "sSensePassDeviceKey", true)}
                            {tInput(this.getText(15608), "sSensePassPinCode", true)}
                        </Card>
                    </div>
                </Form>
                {this.modalSwiich()}
            </Modal >)
    }
}

export default ModalCreateEditPointsOfSale;
