/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { Input, Icon, Button, Tooltip, Col, Row, message, Table, Popconfirm, InputNumber } from "antd";
import { fixCurrentPage } from "../utils/utils";
import Jax from "../Jax/jax.es6.module";
import TableIDs from '../data/TableIDs.js';
import LabelPrinter from "./LabelPrinter";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import { Job, User, OutputType, ProcessType } from '../JmReact';

const topHeight = 340; // px
const rowHeight = 30;  // px

type State = {};

class MatrixBarcodeTable extends React.Component<Props, State> {
  constructor(props/*: Props*/) {
    super(props);
    this.state = { loading: false, visible: false, }
    //this.state._id = null;

    this.id = "MATRIX_BARCODE";
    this.id_meta = 'META_MATRIX';

    // calc page height
    this.state.height = window.innerHeight;
    this.doOnResize = null;

    if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
    if (props.data[this.id_meta] === undefined) { props.data[this.id_meta] = { ...props.data.genericData }; }

    this.api = {
      get: "get_barcode_table",
      edit: "edit_barcode",
    };

  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  responsLabels = () => {
    //let data = Jax.get(this.props.data, "document.finalRecord.Lines", [])
    /*
    let res = [];
    data.map((i) => {
      let qty = parseInt(i.quantity);
      if (qty > 0) res.push({
        _ITEM_CODE: i.itemCode,
        _quantity: qty,
        _CUSTOMER_ID: 0,
        _CART_CUSTOMER_NAME: '',
      })
    })
    return res;
    */
    return [{
      _ITEM_CODE: this.state.itemCode,
      //_quantity: 0,
      _CUSTOMER_ID: 0,
      _CART_CUSTOMER_NAME: '',
    }];
  }

  componentDidMount() {
    //document.title = "Cash On Tab - פריטי מטריצה";
    //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
    this.initFilters();
    //this.props.dataActions.genericSelectorRefreshDataset(
    //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    //this.refreshData();

    window.addEventListener("resize", () => {
      clearTimeout(this.doOnResize);
      this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ height: window.innerHeight }, this.initFilters);
    //console.log("re render", window.innerHeight, " state: ", this.state.height)
  };

  handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
    if (!this.props.manualBarcode2Reception) {

      let col = 9;
      // fall through
      switch (sorter.field) {
        case "key": col--; // 0
        //case "mId": col--;
        case "mCode": col--;
        case "mBarCode2": col--;
        case "mSizeUserCode": col--;
        case "mSizeDesc": col--;
        case "mColorUserCode": col--;
        case "mColorDesc": col--;
        case "mPriceCost": col--;
        case "mPriceVal": col--;
        case "mUOMQty": break; // 9
        default:
          col = 0;
      }

      console.log("pagination: ", pagination, "sorter: ", sorter, "\ncol: ", col);

      let request = {
        // just of ui-pagination update
        page: pagination.current,
        recordsPerPage: pagination.pageSize,
        sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
        sortColumn: col,
        //sortColumn: 'col_1',
        //
        _page_num: pagination.current,
        _rows_num: pagination.pageSize,
        _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
        _sort_by: 'col_' + col,
        //_words: '',
      };

      this.props.dataActions.genericDataSetFilter(
        this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }
  }

  initFilters = () => {
    //console.log("xxx 555", this.props.itemCode);
    let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
      ? (this.state.height - topHeight) / rowHeight
      : 1);

    let request = {
      _id: this.props.itemCode,
      // ui
      page: 1,
      recordsPerPage: /*5,*/numOfRowsByHeight,
      sortType: 1,
      sortColumn: 1,
      //dataObject: this.id,
      // api
      _words: '',
      _page_num: 1,
      _rows_num: /*5,*/numOfRowsByHeight,
      _isAsc: 1,
      _sort_by: 'col_1',

      _priceList_id: this.props.priceListId,
    };
    this.props.dataActions.genericDataSetFilter(
      this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
  }

  refreshData = () => {
    this.props.dataActions.genericDataGetRequested(
      this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
  }

  saveRow = () => {
    //let request = { _priceList_id: this.props.data['META_MATRIX'].editedRecord['mDefaultPriceListId'] }
    let request = { _priceList_id: this.props.priceListId }

    console.log("price list id ", this.props.priceListId);

    this.props.dataActions.genericDataSetFilter(
      this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)

    this.props.dataActions.genericDataSaveEditWithParams(
      this.id, this.props.user.companyCode, this.props.user.token,
      this.api.edit, this.api.get, request);

  }

  sendAPI = (script, data, sCallback, eCallback) => {
    let user = new User(this.props.user.token, this.props.user.companyCode);
    let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
    job.setInput(data);
    job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
  }

  saveRowsFromManualBarcode2 = (nextPage) => {
    const { headers, data } = this.props.data[this.id];
    let dataSend = headers.join('\f')
      + '\f_priceList_id\r'
      + data.join('\f' + this.props.priceListId + '\r')
      + '\f' + this.props.priceListId;

    this.sendAPI(this.api.edit, dataSend, () => {
      this.props.dataActions.genericDataCancelEditing(this.id);
      this.props.dataActions.genericDataSetFilter(
        this.id, this.props.user.companyCode, this.props.user.token, { _page_num: nextPage, page: nextPage }, this.api.get)
      if (nextPage > 1) {
        setTimeout(() => {
          let firstRow = this.props.data[this.id].data[0];
          if (firstRow) {
            let firstKey = firstRow.split('\f')[0];
            this.props.dataActions.genericDataStartEditing(this.id, firstKey, 0);
          }
        }, 1000);
      }
    }, e => { console.error(e) })
  }

  renderBarCode2 = (text, record) => {
    if (this.props.data[this.id].editing === record.key /*&& this.props.data[this.id].editing == 0*/) {
      return (
        <Input
          autoFocus
          onFocus={(e) => { e.target.select() }}
          style={{ width: '98%', textAlign: 'center', direction: 'ltr' }}
          maxlength={15}
          defaultValue={text}
          onChange={(value) => {
            if (!this.ctrlBKeyFlag)
              this.props.dataActions.genericDataSetEdit(this.id, { mBarCode2: value.target.value });
          }}
          onKeyDown={(e) => {
            console.log(e.key, " onKeyDown ", this.barcodeReaderBuffer)
            switch (e.key) {
              case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                  this.props.dataActions.genericDataSetEdit(this.id, { mBarCode2: '' });
                  this.ctrlBKeyFlag = true;
                  this.barcodeReaderBuffer = '';
                } else {
                  if (this.ctrlBKeyFlag == true) this.barcodeReaderBuffer += e.key;
                }
                break;

              case "Shift":
                break;

              case "Enter":
                if (this.props.manualBarcode2Reception) {
                  let newRecord = { ...record }
                  if (this.ctrlBKeyFlag == true) {
                    this.ctrlBKeyFlag = false;
                    if (this.barcodeReaderBuffer || this.isBufferFull === true) {
                      newRecord = { ...record, mBarCode2: this.barcodeReaderBuffer }
                    }
                  } else {
                    newRecord = this.props.data[this.id].editedRecord;
                  }

                  this.props.dataActions.dataTableSetRow(this.id, record.index, newRecord, true);
                  let nextIndex = record.index + 1;
                  let nextRow = this.props.data[this.id].data[nextIndex];
                  if (nextRow) {
                    this.props.uiActions.hideModal('MATRIX_BARCODEEditor')
                    let nextKey = nextRow.split('\f')[0];
                    this.props.dataActions.genericDataStartEditing(this.id, nextKey, nextIndex);
                  } else {
                    const { count, filters } = this.props.data[this.id];
                    let nextPage = filters._rows_num * filters._page_num < count ? filters._page_num + 1 : 1;
                    if (nextPage > 1) {
                      this.saveRowsFromManualBarcode2(nextPage)
                    } else {
                      this.props.endManualBarcode2Reception()
                    }
                  }

                } else {
                  if (this.ctrlBKeyFlag == true) {
                    this.ctrlBKeyFlag = false;
                    if (this.barcodeReaderBuffer || this.isBufferFull === true) {
                      this.props.dataActions.genericDataSetEdit(this.id, { mBarCode2: this.barcodeReaderBuffer });
                    }
                  }
                }
                break;


              default:
                if (this.ctrlBKeyFlag == true) this.barcodeReaderBuffer += e.key;

                break;
            }

            console.log(e.key, " onKeyDown2 ", this.barcodeReaderBuffer)

          }}
        />);
    } else {
      return <div dir="ltr">{text}</div> ;
    }
  }

  renderEditInput = (text, record, field, symbol) => {
    const { editedRecord, editing } = this.props.data[this.id];
    if (editing === record.key && !this.props.manualBarcode2Reception) {
      return [
        symbol && editedRecord[field] ? symbol + " " : "",
        <InputNumber
          precision={2} step={0.1}
          style={{ width: '98%', textAlign: 'center' }}
          value={editedRecord[field]}
          onChange={(value) => {
            this.props.dataActions.genericDataSetEdit(this.id, { [field]: value });
          }} />];
    } else {
      return symbol && text ? symbol + " " + text : text;
    }
  }

  renderEditIcons = (text, record) => {
    if (this.props.manualBarcode2Reception)
      return '';

    if (this.props.data[this.id].editing !== record.key) {
      return (
        <span>
          <Tooltip title={this.getText(10679)}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
              //this.props.uiActions.showModal(TableIDs.modalCreateEditMatrixItem, record['mCode'])
              this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
            }} />
          </Tooltip>
          {!this.props.isReverse ?
            (<Tooltip title={this.getText(10680)}>
              <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"highlight"} onClick={() => {
                this.props.uiActions.setIsEditFatherItem(false);
                this.props.uiActions.showModal(TableIDs.modalCreateEditItem, { itemId: record['mCode'], fromMatrix: true })
              }} />
            </Tooltip>)
            : ""}

          <Tooltip title={this.getText(10681)}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"barcode"} onClick={() => {
              this.setState({ itemCode: record['mCode'] }, () => { this.props.uiActions.showModal(TableIDs.labelPrinter) })
            }} />
          </Tooltip>
        </span >);
    } else {
      let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
      return (<span>
        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"} onClick={this.saveRow} />
        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
          onClick={() => {
            if (isCreate)
              this.props.dataActions.genericDataCancelInlineCreate(this.id);
            else
              this.props.dataActions.genericDataCancelEditing(this.id);
          }} />
      </span>)
    }
  }

  render() {
    const dataSource = this.props.data[this.id].data.map((x, index) => {
      let y = x.split("\f");
      let obj = { key: y[0], index }
      this.props.data[this.id].headers.forEach((z, i) => {
        obj = { ...obj, [z]: y[i] }
      });
      return obj;

    });

    let gt = this.getText;
    let render = t => t;
    let sorter = !this.props.manualBarcode2Reception;

    let columns = this.props.isReverse ?
      [
        { title: gt(10670), dataIndex: "mCode", key: "mCode", sorter, width: '15%', render },
        { title: gt(10671), dataIndex: "mBarCode2", key: "mBarCode2", sorter, width: '15%', render: this.renderBarCode2 },
        { title: gt(10672), dataIndex: "mSizeUserCode", key: "mSizeUserCode", sorter, width: '10%', render },
        { title: gt(10673), dataIndex: "mSizeDesc", key: "mSizeDesc", sorter, width: '15%', render },
        { title: gt(10674), dataIndex: "mColorUserCode", key: "mColorUserCode", sorter, width: '10%', render },
        { title: gt(10675), dataIndex: "mColorDesc", key: "mColorDesc", sorter, width: '15%', render },
        { title: gt(10678), dataIndex: "mUOMQty", key: "mUOMQty", sorter, width: '10%', render },
        { title: gt(10677), width: '10%', render: this.renderEditIcons },
      ] : [
        { title: gt(10670), dataIndex: "mCode", key: "mCode", sorter, width: '15%', render },
        { title: gt(10671), dataIndex: "mBarCode2", key: "mBarCode2", sorter, width: '15%', render: this.renderBarCode2 },
        { title: gt(10672), dataIndex: "mSizeUserCode", key: "mSizeUserCode", sorter, width: '8%', render },
        { title: gt(10673), dataIndex: "mSizeDesc", key: "mSizeDesc", sorter, width: '13%', render },
        { title: gt(10674), dataIndex: "mColorUserCode", key: "mColorUserCode", sorter, width: '8%', render },
        { title: gt(10675), dataIndex: "mColorDesc", key: "mColorDesc", sorter, width: '13%', render },
        { title: gt(16809), dataIndex: "mPriceCost", key: "mPriceCost", sorter, width: '10%', render: (t, r) => this.renderEditInput(t, r, 'mPriceCost', r.mCurrencySymbol) },
        { title: gt(10676), dataIndex: "mPriceVal", key: "mPriceVal", sorter, width: '10%', render: (t, r) => this.renderEditInput(t, r, 'mPriceVal') },
        { title: gt(10677), width: '8%', render: this.renderEditIcons },
      ];

    return (
      <div key="barcode_grid" style={{ marginTop: 16 }}>
        <ResponsiveTable ui={this.props.ui} tableOnly={true}
          dataSource={dataSource}
          columns={columns}
          loading={this.props.ui.workInProgress}
          //scroll={{ x: 1650 }}
          bordered
          pagination={this.props.data[this.id].pagination}
          onChange={this.handleTableChange}
          locale={{ emptyText: this.getText(10682) }}
          rowClassName={(record) => record.mIsActive != 1 ? "row_pink" : ""}

        />

        <LabelPrinter
          id={TableIDs.labelPrinter}
          labels={this.responsLabels()}
          qtyInput={true}
          user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data}
          toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
          title={this.getText(10683)}
        />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    //let request = { _id: this.props.ui.data };
    let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
      ? (this.state.height - topHeight) / rowHeight
      : 1);

    let request = {
      _id: this.props.itemCode,
      // ui
      page: 1,
      recordsPerPage: numOfRowsByHeight, //5,
      sortType: 1,
      sortColumn: 1,
      // api
      _words: '',
      _page_num: 1,
      _rows_num: numOfRowsByHeight, //5,
      _isAsc: 1,
      _sort_by: 'col_1',

      _priceList_id: this.props.priceListId,
    };

    if (this.props.priceListId !== prevProps.priceListId ||
      this.props.itemCode !== prevProps.itemCode ||
      (prevProps.toggle === false && this.props.toggle === true)) {
      this.props.dataActions.genericDataGetEditWithParams(
        this.id,
        this.props.user.companyCode,
        this.props.user.token,
        //{ _id: this.props.ui.data },
        request,
        this.api.get
      );
    }

    if (this.props.manualBarcode2Reception !== prevProps.manualBarcode2Reception) {
      if (this.props.manualBarcode2Reception) {
        let firstRow = this.props.data[this.id].data[0];
        if (firstRow) {
          let firstKey = firstRow.split('\f')[0];
          this.props.dataActions.genericDataStartEditing(this.id, firstKey, 0);
        }
      } else {
        this.saveRowsFromManualBarcode2(1)
      }
    }
  }
}

export default MatrixBarcodeTable;
