
import React from "react";
import { Button, Checkbox, Input, InputNumber, message } from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";
import GenericTable from "./GenericTable";
import TableLineIcons from "./TableLineIcons";
import TableIDs from "../data/TableIDs";


class ModalCreateEditItemStock extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            modalLocations: null,
            locationsLoading: false,
        }

        this.inv_limits_id = "ITEM_STORAGES_INVENTORY_LIMITS";
        this.id_locations = "ITEM_STORAGE_LOCATIONS";


        this.api = {

            get_inv_limits: "get_item_storages_inventory_limits",
            save_inv_limits: "save_item_storages_inventory_limits",

            get_locations: "get_item_storage_locations",
            create_edit_locations: "create_edit_item_storage_locations",

        }

        if (props.data[this.inv_limits_id] === undefined) props.data[this.inv_limits_id] = { ...props.data.genericData }
        if (props.data[this.id_locations] === undefined) props.data[this.id_locations] = { ...props.data.genericData }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (!p.visible && this.props.visible) this.refreshData()
    }


    refreshData = () => {
        const { _words, _rows_num, _sort_by, _isAsc } = this.props.data[this.id].filters ?? {};
        this.props.dataActions.genericDataSetFilter(
            this.inv_limits_id, this.props.user.companyCode, this.props.user.token, {
            _words: _words ?? "",
            _page_num: 1,
            _rows_num: _rows_num ?? 10,
            _sort_by: _sort_by ?? "col_0",
            _isAsc: _isAsc ?? 1,
            _itemCode: this.props.itemCode
        }, this.api.get_inv_limits);
    }

    render() {

        return (<span>
            <GenericModal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                title={this.getText(16395) + " - " + this.getText(19354) + ": " + this.props.itemCode + " - " + this.getText(19355) + ": " + this.props.itemName}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: this.refreshData
                }]}>
                <GenericTable
                    {...this.props}
                    tableOnly
                    id={this.inv_limits_id}
                    getApi={this.api.get_inv_limits}
                    filters={{ _itemCode: this.props.itemCode }}
                    columns={[
                        { title: this.getText(16396), width: "10%", key: "tStorageId", dataIndex: "tStorageId", sorter: true, render: t => t },
                        { title: this.getText(16397), width: "35%", key: "tStorageName", dataIndex: "tStorageName", sorter: true, render: t => t },
                        { title: this.getText(16743), width: "12%", key: "tBalance", dataIndex: "tBalance", sorter: true, render: this.inventoryBalanceField },
                        { title: this.getText(16398), width: "12%", key: "tMinimum", dataIndex: "tMinimum", sorter: true, render: (t, r) => this.inventoryLimitField(t, r, 'tMinimum') },
                        { title: this.getText(16399), width: "12%", key: "tMaximum", dataIndex: "tMaximum", sorter: true, render: (t, r) => this.inventoryLimitField(t, r, 'tMaximum') },
                        { title: this.getText(16400), width: "12%", key: "tDays", dataIndex: "tDays", sorter: true, render: (t, r) => this.inventoryLimitField(t, r, 'tDays') },
                        { title: this.getText(16401), width: "7%", render: this.inventoryLimitEdit },
                    ]}

                />
            </GenericModal>
            <GenericModal
                visible={this.state.modalLocations != null}
                onCancel={() => { this.setState({ modalLocations: null }) }}
                width={700}
                title={this.getText(19152) + " " + (this.state.modalLocations ? this.state.modalLocations.tStorageName : "")}
                actionButtons={[{
                    buttonType: 'refresh',
                    buttonFunction: () => {
                        this.props.dataActions.genericDataGetRequested(
                            this.id_locations, this.props.user.companyCode, this.props.user.token, this.api.get_locations);
                    }
                }]}
                footer={[
                    <Button onClick={() => { this.setState({ modalLocations: null }) }}>{this.getText(19157)}</Button>,
                    <Button type="primary" loading={this.state.locationsLoading} onClick={this.handleSaveLocations}>{this.getText(19158)}</Button>,
                ]}>
                <ResponsiveTable
                    {...this.props}
                    tableOnly
                    idTable={this.props.data[this.id_locations]}
                    columns={[
                        { title: this.getText(19153), width: "20%", key: "lId", dataIndex: "lId", render: t => t },
                        { title: this.getText(19154), width: "60%", key: "lName", dataIndex: "lName", render: t => t },
                        { title: this.getText(19155), width: "10%", key: "lIsChecked", dataIndex: "lIsChecked", render: this.locationIsChecked },
                        { title: this.getText(19156), width: "10%", key: "lIsPreferred", dataIndex: "lIsPreferred", render: this.locationIsPreferred },
                    ]}

                />
            </GenericModal>
        </span>)
    }

    locationIsChecked = (text, record) => {
        return (<Checkbox
            checked={text == '1'}
            onChange={e => {
                this.props.dataActions.setJson(this.id_locations, {
                    data: this.props.data[this.id_locations].data.map((x, i) => {
                        if (i == record.index) {
                            let y = x.split("\f");
                            if (e.target.checked) { y[2] = '1' }
                            else { y[2] = '0'; y[3] = '0' }
                            return y.join("\f");
                        } else {
                            return x;
                        }
                    })
                })
            }}
        />)
    }

    locationIsPreferred = (text, record) => {
        return (<Checkbox
            checked={text == '1'}
            disabled={record.lIsChecked != '1'}
            onChange={e => {
                this.props.dataActions.setJson(this.id_locations, {
                    data: this.props.data[this.id_locations].data.map((x, i) => {
                        let y = x.split("\f");
                        if (i == record.index && e.target.checked) y[3] = '1';
                        else y[3] = '0';
                        return y.join("\f");

                    })
                })
            }}
        />)
    }

    handleSaveLocations = () => {
        this.setState({ locationsLoading: true }, () => {
            const mItemCode = this.props.itemCode;
            const mStorage = this.state.modalLocations.key;
            let locationList = [];
            let locationPreferred = '';

            this.props.data[this.id_locations].data.forEach(x => {
                let y = x.split("\f");
                if (y[2] == '1') locationList.push(y[0])
                if (y[3] == '1') locationPreferred = y[0];
            })

            let dataSend = "mItemCode\fmStorage\fmLocationList\fmLocationPreferred\r"
                + mItemCode + "\f" + mStorage + "\f" + locationList.join(",") + "\f" + locationPreferred;

            this.sendAPI(this.api.create_edit_locations, dataSend, () => {
                this.setState({ modalLocations: null, locationsLoading: false })
            })
        })
    }

    inventoryBalanceField = (text, record) => {
        const { editedRecord, editing } = this.props.data[this.inv_limits_id];
        const isUserEditStock = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '520')

        if (isUserEditStock && record.tStorageId > 0 && editing === record.key) {
            return [
                <InputNumber
                    min={0}
                    value={editedRecord.tBalance < 0 ? null : editedRecord.tBalance}
                    onChange={e => {
                        if (!record.tInventoryNumber) this.props.dataActions.genericDataSetEdit(this.inv_limits_id, { tBalance: e })
                    }}
                    onFocus={() => {
                        if (record.tInventoryNumber) message.error(this.getText(19356))
                    }}
                />,
                <Input
                    value={editedRecord.tBalanceRemarks}
                    onChange={e => {
                        if (!record.tInventoryNumber) this.props.dataActions.genericDataSetEdit(this.inv_limits_id, { tBalanceRemarks: e.target.value })
                    }}
                />
            ]
        } else {
            return (<div style={{ direction: "ltr", textAlign: "right" }}>{text}</div>)
        }
    }

    inventoryLimitField = (text, record, field) => {
        const { editedRecord, editing } = this.props.data[this.inv_limits_id];
        if (editing === record.key) {
            return (<InputNumber
                value={editedRecord[field]}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.inv_limits_id, { [field]: e }) }}
            />)
        } else {
            return text;
        }
    }

    inventoryLimitEdit = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.inv_limits_id];

        let isEdit = record.key === editing;
        return (<TableLineIcons
            onEdit={isEdit ? null : () => { this.props.dataActions.genericDataStartEditing(this.inv_limits_id, record.key, record.index) }}
            genericIcons={isEdit ? null : [
                { type: "table", onClick: () => { this.openModalLocations(record) }, tooltip: this.getText(19151) },
                { type: "eye", onClick: () => { this.props.onOpenItemCardModal(this.props.itemCode, this.props.itemName, record.tStorageId) }, tooltip: this.getText(17164) },
            ]}
            onCheck={isEdit ? () => {
                const isUserEditStock = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset?.find(f => f.code === '520');
                if (isUserEditStock && record.tStorageId > 0 && record.tBalance != editedRecord.tBalance) {
                    if (record.tInventoryNumber) {
                        message.error(this.getText(19356))
                    } else {
                        this.sendAPI(
                            "set_item_stock_single_v2",
                            "_storage\f_item\f_qty\f_remarks\r" + record.tStorageId + "\f" + this.props.itemCode + "\f" + editedRecord.tBalance + "\f" + editedRecord.tBalanceRemarks,
                            this.refreshData)
                    }
                }
                this.props.dataActions.genericDataSaveEdit(
                    this.inv_limits_id, this.props.user.companyCode, this.props.user.token, this.api.save_inv_limits, this.api.get_inv_limits);
            } : null}
            onClose={isEdit ? () => {
                if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.inv_limits_id);
                else this.props.dataActions.genericDataCancelEditing(this.inv_limits_id);
            } : null}
        />)
    }

    openModalLocations = (record) => {
        const mItemCode = this.props.itemCode;
        this.setState({ modalLocations: record }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id_locations, this.props.user.companyCode, this.props.user.token, { mItemCode, mStorage: record.key }, this.api.get_locations);
        })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }
}
export default ModalCreateEditItemStock;
