/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import ItemMenu from "../menus/ItemMenu";
import TagIndex from "./Tags/Index";
import ItemListIndex from "./ItemList/Index";
import Bargains from "./Bargains/Bargains";

import PricesTableIndex from './Prices/Index';
//import PriceListTableIndex from './priceList/Index';

import SuppliersTableIndex from './Suppliers/Index';
import TableIDs from '../data/TableIDs';
import MatrixItemsTable from "./MatrixItemList/MatrixItemsTable";
import FoodsTable from "./foods/FoodsTable";
//import PizzaItems from "./foods/PizzaItems";
import RulerTable from "./MatrixItemList/RulerTable";
import MultiUploadImagesForFiles from "./ItemList/MultiUploadImagesForFiles";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: any,
    ActionQueue: any
};

type State = {};

class Items extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

    const routes = [
            (<Route key={'31'} path={this.props.baseUrl + "/items/itemList"} exact render={() => <ItemListIndex {...routeProps} />} />),
            (<Route key={'36'} path={this.props.baseUrl + "/items/matrixItemList"} exact render={() => <MatrixItemsTable {...routeProps} />} />),
            (<Route key={'32'} path={this.props.baseUrl + "/items/tags"} exact render={() => <TagIndex {...routeProps} />} />),
            (<Route key={'33'} path={this.props.baseUrl + "/items/bargains"} exact render={() => <Bargains {...routeProps} />} />),
            //(<Route key={'34'} path={this.props.baseUrl + "/items/pricesList"} exact render={() => <PriceListTableIndex {...routeProps} />} />),
            (<Route key={'34'} path={this.props.baseUrl + "/items/prices"} exact render={() => <PricesTableIndex {...routeProps} />} />),
            (<Route key={'35'} path={this.props.baseUrl + "/items/providers" /*suppliers*/} exact render={() => <SuppliersTableIndex {...routeProps} />} />),
            (<Route key={'37'} path={this.props.baseUrl + "/items/foods" /*suppliers*/} exact render={() => <FoodsTable {...routeProps} />} />),
            // (<Route key={'38'} path={this.props.baseUrl + "/items/pizza-items" /*suppliers*/} exact render={() => <PizzaItems {...routeProps} />} />),
            (<Route key={'112'} path={this.props.baseUrl + "/items/rulers"} exact render={() => <RulerTable {...routeProps} />} />),
            (<Route key={'39'} path={this.props.baseUrl + "/items/multi-image-upload"} exact render={() => <MultiUploadImagesForFiles {...routeProps} />} />),

            

        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/items"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <ItemMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default Items;
