/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Select, InputNumber, Checkbox, Popconfirm, Button, Input } from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";

import ModalCreateEditMessage from "../../components/ModalCreateEditMessage";
import { fixCurrentPage } from "../../utils/utils";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";

const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 40;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class MessageBank extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "MESSAGE_BANK_MAIN";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        //if (props.data[TableIDs.genericSelectorPrices] === undefined) {
        //    props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        //}

        this.api = {
            get: "get_messages_table_list",
            delete: "delete_message",
        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreateEditMessage) }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];

    }

	static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        //this.refreshData();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

	componentDidUpdate(){
        document.title = "Cash On Tab - " + this.getText(10177);
    } 
	
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 3;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mID": col--;
            case "mName": col--;
            case "mMessage": break; // 3
            /*
            case "ContactPersonName": col--;
            case "ContactPersonPhone": col--;
            case "ContactPersonMobile": col--;
            case "ContactPersonEmail": col--;
            case "ContactPersonAddress": break; // 7
            */
            default:
                col = 0;
        }
        console.log("mailing-msg - handle table change: ", pagination, filters, sorter);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mID: y[0],
                mName: y[1],
                mText: y[2],
                index: index
            })
        });


        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            {
                title: this.getText(12341),
                dataIndex: "mID",
                key: "mID",
                sorter: true,
                width: '10%',
                render: (text, record) => text,
            }, {
                title: this.getText(12342),
                dataIndex: "mName",
                key: "mName",
                width: '20%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(12343),
                dataIndex: "mText",
                key: "mText",
                width: '60%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(12344),
                width: '10%',
                render: (text, record) => {
                    //if (this.props.data[this.id].editing !== record.key) {
                    return (
                        <span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                this.props.uiActions.showModal(TableIDs.modalCreateEditMessage, record['mID']);
                            }} />
                            <Popconfirm title={this.getText(12345)}
                                onConfirm={() => {
                                    console.log("record", record);
                                    fixCurrentPage.bind(this)();
                                    this.props.dataActions.genericDataDeleteRow(
                                        this.id,
                                        record['mID'],
                                        this.props.user.companyCode,
                                        this.props.user.token,
                                        this.api.delete,
                                        this.api.get);
                                }
                                }
                                okText={this.getText(12346)} cancelText={this.getText(12347)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                        </span >);
                    /*} else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)      
                    }*/
                }
            }
        ];
 
        //window.console.log(TableIDs.genericSelectorPrices);
        //window.console.log(this.props.data);
        let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
        });
        //let list = (this.props.data[this.id])[].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //});

        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(12340)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                    page: 1,
                                    _page_num: 1,
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />
                
                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    tableOnly={true}
                    percentageToMobile={[20, 80]}
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

              

                <ModalCreateEditMessage
                    id={TableIDs.modalCreateEditMessage}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditMessage)}
                 />
            </div>
        );
    }
}

export default MessageBank;

