/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Select, Popconfirm, Form, Switch, Tooltip, Button } from "antd";
import Jax from "../../Jax/jax.es6.module";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import ResponsiveTable from "../../components/ResponsiveTable";
//import ModalCreatePriceList from "../../components/ModalCreatePriceList";
//import ModalCreatePrice from "../../components/ModalCreatePrice";
import ModalCreateEditSuppliers from "../../components/ModalCreateEditSuppliers";
import { escapeRegExp, fixCurrentPage } from "../../utils/utils";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import DateTimePicker from "../../components/DateTimePicker";
import ModalMatchDocs from "../../components/ModalMatchDocs";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import RecyclingBin from "../../components/RecyclingBin";
import GenericSelector from "../../components/GenericSelector";

import ModalContacts from "../../components/ModalContacts";

const { Option, OptGroup } = Select;
const FormItem = Form.Item;

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class SuppliersTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "SUPPLIERS_MAIN";
        this.recycling_id = "SUPPLIERS_DELETION_RECYCLING";

        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            modalSupplierDebtReport: false,

            SupplierDebtDetailed: {
                mID: null,
                mLowerDate: null,
                mUpperDate: null,
                isWithPurchase: 1,
                isWithMatchDocs: 1,
                isWithOpenBalance: 1,
                isWithRemarks: 0,
                mStorageList: '',
                reportName: "reports/SupplierDebtDetailed.pdf",
            },

            modalContacts: null
        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.recycling_id] === undefined) props.data[this.recycling_id] = { ...props.data.genericData };


        //if (props.data[TableIDs.genericSelectorPrices] === undefined) {
        //    props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        //}

        this.api = {
            get: "get_suppliers_table_list",
            selector_storages: "get_storage_list",
            //create: "create_price",
            //edit: "edit_price",
            delete: "delete_supplier",
            //selector: "get_prices",
            //modalPriceListGet: "Prices_List_v2",
            calc: "calc_supplier_balances",

            get_recycling: "get_supplier_deletion_recycling",
            restoration: "restoration_supplier",

        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreateEditSupplier) }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - ספקים";
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        //this.refreshData();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10175);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 8;
        // fall through
        switch (sorter.field) {
            case "key": col--; // fallsthrough  // 0
            case "ID": col--; // fallsthrough 
            case "Name": col--; // fallsthrough 
            case "ContactPersonName": col--; // fallsthrough 
            case "ContactPersonPhone": col--; // fallsthrough 
            case "ContactPersonMobile": col--; // fallsthrough 
            case "ContactPersonEmail": col--; // fallsthrough 
            case "ContactPersonAddress": col--; // fallsthrough 
            case "Balance": break; // 8
            default:
                col = 0;
        }
        console.log("Suppliers - handle table change: ", pagination, filters, sorter);

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    calcBalances = () => {
        this.sendAPI(this.api.calc, "", this.refreshData)
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let columns = [
            {
                title: this.getText(10538),
                dataIndex: "ID",
                key: "ID",
                sorter: true,
                width: '6%',
                render: (text, record) => text,
            }, {
                title: this.getText(10539),
                dataIndex: "Name",
                key: "Name",
                width: '12%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10540),
                dataIndex: "ContactPersonName",
                key: "ContactPersonName",
                width: '12%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10541),
                dataIndex: "ContactPersonPhone",
                key: "ContactPersonPhone",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10542),
                dataIndex: "ContactPersonMobile",
                key: "ContactPersonMobile",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10543),
                dataIndex: "ContactPersonEmail",
                key: "ContactPersonEmail",
                width: '12%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(10544),
                dataIndex: "ContactPersonAddress",
                key: "ContactPersonAddress",
                width: '20%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(18880),
                dataIndex: "Balance",
                key: "Balance",
                width: '10%',
                sorter: true,
                render: (text, record) => text,
            },
            /*{
                title: 'שם פריט',
                dataIndex: "mShortName",
                key: "mShortName",
                width: '20%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key && this.props.data[this.id].editing == 0) {
                        return (
                            <Input
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mShortName: value });
                                }} />);
                    } else {
                        return text;
                    }
                }
            }, {
                title: 'מחיר',
                dataIndex: "mPriceVal",
                key: "mPriceVal",
                width: '15%',
                sorter: true,
                render: (text, record) => {
                    if (this.props.data[this.id].editing === record.key) {
                        return (
                            <InputNumber min={0} step={0.01}
                                style={{ width: '98%', textAlign: 'center' }}
                                defaultValue={text}
                                onChange={(value: number) => {
                                    console.log("input number: ", text, record);
                                    this.props.dataActions.genericDataSetEdit(this.id, { mPriceVal: value });
                                }} />);
                    } else {
                        return text;
                    }
                },
            },
            */
            {
                title: this.getText(10545),
                width: '8%',
                render: (text, record) => {
                    //if (this.props.data[this.id].editing !== record.key) {
                    return (
                        <span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                this.props.uiActions.showModal(TableIDs.modalCreateEditSupplier, record['ID']);
                            }} />
                            <Popconfirm title={this.getText(10546)}
                                onConfirm={() => {
                                    console.log("record", record);
                                    fixCurrentPage.bind(this)();
                                    this.props.dataActions.genericDataDeleteRow(
                                        this.id,
                                        record['ID'],
                                        this.props.user.companyCode,
                                        this.props.user.token,
                                        this.api.delete,
                                        this.api.get);
                                }
                                }
                                okText={this.getText(10547)} cancelText={this.getText(10548)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                            <Tooltip title={this.getText(18378)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file"} onClick={() => {
                                    this.setState({ modalSupplierDebtReport: true }, () => {
                                        this.setReportParameters("SupplierDebtDetailed", { mID: record['ID'] })
                                    })
                                }} />
                            </Tooltip>
                            <ModalMatchDocs {...this.props} key={record.key} record={record} isSupplier mId={record.key} mName={record.Name} />
                            <Tooltip title={this.getText(19665)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"table"} onClick={() => { this.setState({ modalContacts: { code: record.key, name: record.Name } }) }} />
                            </Tooltip>
                        </span >);
                    /*} else {
                        let isCreate = (this.props.data[this.id].editing == 0) ? true : false;
                        return (<span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                                onClick={() => {
                                    this.props.dataActions.genericDataSaveEdit(
                                        this.id, this.props.user.companyCode, this.props.user.token, this.api.edit, this.api.get);
                                }} />
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                                onClick={() => {
                                    if (isCreate)
                                        this.props.dataActions.genericDataCancelInlineCreate(this.id);
                                    else
                                        this.props.dataActions.genericDataCancelEditing(this.id);
                                }} />
                        </span>)      
                    }*/
                }
            }
        ];

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                ID: y[0],
                Name: y[1],
                ContactPersonName: y[2],
                ContactPersonPhone: y[3],
                ContactPersonMobile: y[4],
                ContactPersonEmail: y[5],
                ContactPersonAddress: y[6],
                Balance: y[7],
                index: index
            })
        });


        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        //window.console.log(TableIDs.genericSelectorPrices);
        //window.console.log(this.props.data);
        let list = this.props.data[TableIDs.genericSelectorPrices].dataset.map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
        });
        //let list = (this.props.data[this.id])[].dataset.map((obj, index) => {
        //    return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        //});

        return (
            <div/* style={divStyle}*/>
                <PageToolbar
                    title={this.getText(10549)}
                    actionButtons={this.actionButtons}
                    genericActionButtons={[
                        <Tooltip title={this.getText(18879)}>
                            <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={this.calcBalances}>
                                <Icon type={"calculator"} style={{ fontSize: "16px" }} />
                            </Button>
                        </Tooltip>,
                        <RecyclingBin
                            {...this.props}
                            id={this.recycling_id}
                            getApi={this.api.get_recycling}
                            restorationApi={this.api.restoration}
                            title={this.getText(18993)}
                            columnTitles={[this.getText(18994), this.getText(18995), this.getText(18996), this.getText(18998), this.getText(18997)]}
                            popconfirmText={{ title: this.getText(18999), okText: this.getText(19000), cancelText: this.getText(19001) }}
                            onClose={this.refreshData}
                        />
                    ]}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: escapeRegExp(val),
                                //  .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ResponsiveTable
                    idTable={this.props.data[this.id]}
                    dataSource={dataSource}
                    columns={columns}
                    tableOnly={true}
                    ui={this.props.ui}
                    percentageToMobile={[false, 30, 40, 30]}
                    onChange={this.handleTableChange}

                //loading={this.props.ui.workInProgress}
                //scroll={{ x: 1650 }}
                //bordered
                //pagination={this.props.data[this.id].pagination}
                //locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />

                <ModalCreateEditSuppliers
                    id={TableIDs.modalCreateEditSupplier}
                    user={this.props.user}
                    ActionQueue={this.props.ActionQueue}
                    uiActions={this.props.uiActions} ui={this.props.ui}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditSupplier)}
                // title={"ספק יצירת/עריכת"}
                />

                <GenericModal
                    visible={this.state.modalSupplierDebtReport}
                    onCancel={() => { this.setState({ modalSupplierDebtReport: false }) }}
                    width={'60%'}
                    title={this.getText(18379)}
                >
                    <Form id="report_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}
                        onSubmit={this.handleSaveChanges}
                    >

                        <FormItem label={this.getText(18380)} /*label={"מתאריך חיוב:"}*/>
                            {/* <DatePicker
                            disabledDate={this.disabledStartDate}
                            showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            //format="D/M/YY"
                            //value={FromDate ? moment(FromDate, "YYYY-MM-DD") : null}
                            placeholder={this.getText(10765)}
                            onChange={this.onStartChangeAction}
                        //onOpenChange={this.handleStartOpenChange}
                        /> */}
                            <DateTimePicker
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                maxDate={this.state.SupplierDebtDetailed.mUpperDate}
                                value={this.state.SupplierDebtDetailed.mLowerDate}
                                placeholder={this.getText(18385)}
                                onChange={(value) => { this.setReportParameters("SupplierDebtDetailed", { mLowerDate: value }) }}
                            />

                        </FormItem>
                        <FormItem label={this.getText(18381)} /*label={"עד תאריך חיוב:"} style={isEndDateShownStyle}*/>
                            {/* <DatePicker
                            disabledDate={this.disabledEndDate}
                            showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            //format="D/M/YY"
                            //value={ToDate ? moment(ToDate, "YYYY-MM-DD") : null}
                            placeholder={this.getText(10766)}
                            onChange={this.onEndChangeAction}
                        //open={endOpen}
                        //onOpenChange={this.handleEndOpenChange}
                        /> */}
                            <DateTimePicker
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                minDate={this.state.SupplierDebtDetailed.mLowerDate}
                                value={this.state.SupplierDebtDetailed.mUpperDate}
                                placeholder={this.getText(18386)}
                                onChange={(value) => { this.setReportParameters("SupplierDebtDetailed", { mUpperDate: value }) }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(18382)}>
                            <Switch checkedChildren={this.getText(18387)} unCheckedChildren={this.getText(18388)}
                                onChange={(value) => this.setReportParameters("SupplierDebtDetailed", { isWithPurchase: (value === true) ? 1 : 0 })}
                                checked={(this.state.SupplierDebtDetailed.isWithPurchase === 1) ? true : false}
                            />
                        </FormItem>

                        <FormItem label={this.getText(18383)}>
                            <Switch checkedChildren={this.getText(18387)} unCheckedChildren={this.getText(18388)}
                                onChange={(value) => this.setReportParameters("SupplierDebtDetailed", { isWithMatchDocs: (value === true) ? 1 : 0 })}
                                checked={(this.state.SupplierDebtDetailed.isWithMatchDocs === 1) ? true : false}
                            />
                        </FormItem>

                        <FormItem label={this.getText(18384)}>
                            <Switch checkedChildren={this.getText(18387)} unCheckedChildren={this.getText(18388)}
                                onChange={(value) => this.setReportParameters("SupplierDebtDetailed", { isWithOpenBalance: (value === true) ? 1 : 0 })}
                                checked={(this.state.SupplierDebtDetailed.isWithOpenBalance === 1) ? true : false}
                            />
                        </FormItem>

                        <FormItem label={this.getText(19127)}>
                            <Switch checkedChildren={this.getText(18387)} unCheckedChildren={this.getText(18388)}
                                onChange={(value) => this.setReportParameters("SupplierDebtDetailed", { isWithRemarks: (value === true) ? 1 : 0 })}
                                checked={(this.state.SupplierDebtDetailed.isWithRemarks === 1) ? true : false}
                            />
                        </FormItem>

                        <FormItem label={this.getText(11191)}>
                            <GenericSelector
                                {...this.props}
                                multi
                                id={TableIDs.genericSelectorStorages}
                                api={this.api.selector_storages}
                                onChange={(value) => this.setReportParameters("SupplierDebtDetailed", { mStorageList: value.join(',') })}
                                value={

                                    (this.state.SupplierDebtDetailed)
                                        ? ((this.state.SupplierDebtDetailed.mStorageList === '') ? [] : this.state.SupplierDebtDetailed.mStorageList.split(','))
                                        : []
                                }
                            />
                        </FormItem>


                        <FormItem key={"reportName"} label={this.getText(18393)}>
                            <Select style={{ width: 250 }}
                                //size={"large"}
                                className={"reports/SupplierDebtDetailed.pdf"}
                                //combobox={null}
                                defaultValue={"pdf"}
                                onChange={(value) => {
                                    this.setReportParameters("SupplierDebtDetailed", {
                                        reportName: "reports/SupplierDebtDetailed." + value,
                                        type: value
                                    });
                                }}
                            >
                                <Option key={"pdf"}>{this.getText(18390)}</Option>
                                <Option key={"xls"}>{this.getText(18391)}</Option>
                                <Option key={"docx"}>{this.getText(18392)}</Option>
                            </Select>
                        </FormItem>

                        {/*
                    <FormItem key={"submit"}>
                        <Button type={"primary"} onClick={() => {
                            //const type = this.state.RequestedReport;
                            this.generateReport.apply(this, ["SupplierDebtDetailed"])
                        }}>הפק דו"ח</Button>
                    </FormItem>
                    */}
                    </Form>
                    <div className="action_footer">
                        <Button type="primary" htmlType="submit" form="report_form" onClick={() => {
                            //const type = this.state.RequestedReport;
                            this.generateReport.apply(this, ["SupplierDebtDetailed"])
                        }}>{this.getText(18389)}</Button>
                    </div>
                </GenericModal>

                <ModalContacts
                    {...this.props}
                    visible={this.state.modalContacts?.code ?? null}
                    onCancel={() => { this.setState({ modalContacts: null }) }}
                    title={this.getText(19666) + " " + this.state.modalContacts?.code + " - " + this.state.modalContacts?.name}
                    ownerType={"1"}
                />
            </div>
        );
    }
}

export default SuppliersTable;



/*
<Select
//disabled={!(this.props.data[this.id].editing === record.key)}
value={this.props.data[this.id].filters['_id']}
defaultValue='none'
style={{ width: '256px', padding: '4px' }}
onChange={(e) => {
    this.props.dataActions.genericDataSetFilter(
        this.id, this.props.user.companyCode, this.props.user.token,
        { _id: e }, this.api.get)
}}>
<Option key={0} value={0}>-כל המחירים-</Option>
{list}
</Select>

<Button key="showModal" type="primary"
onClick={() => { this.props.uiActions.showModal(TableIDs.modalCreatePriceList) }}>הרחב</Button>
*/