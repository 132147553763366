

/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Checkbox, InputNumber, Select } from "antd";
//import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import TagRelationRadio from "../../components/TagRelationRadio";
import CustPicker from "../../components/CustPicker";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
//import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;
const { Option } = Select;
//const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class ProfitExpanded extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            _fromDate: null,
            _toDate: null,
            _priceListId: null,
            _posList: "",
            _storageList: "",
            _tagList: "",
            _tagRelation: '1',
            _daysCulcRolling: null,
            _periodsToExceed: null,
            _withSendingDocs: '1',
            _custId: null,
            _calculateCostMethod: "2",
            _includeConnectedItems: '0',
            _itemCode: '',
            _divisionByStorage: false,
            _purchaseSupplier: null,
            reportName: "reports/ProfitExpanded.pdf",
        };

        this.selectors = [
            { id: TableIDs.genericSelectorPrices, api: "get_prices" },
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types" },
            { id: TableIDs.genericSelectorPos, api: "list_pos" },
        ];

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(16489);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }

    createDatePicker = (label, field) => {
        return (<FormItem label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={field == '_toDate' ? this.state._fromDate : null}
                maxDate={field == '_fromDate' ? this.state._toDate : null}
            />
        </FormItem>)
    }

    createSelector = (label, field, index, multi, optionsGroup) => {
        if (field === '_tagList') {
            return (<FormItem label={this.getText(label)}>
                <GenericSelectorItemTagsWithRelation
                    {...this.props}
                    value={this.state._tagList}
                    onChange={(e) => { this.setState({ _tagList: e }) }}
                    tagRelationValue={this.state._tagRelation}
                    tagRelationOnChange={(value) => {
                        let _tagList = this.state._tagList;
                        let from = this.state._tagRelation;
                        let to = value.target.value;
                        let oldJson = _tagList.indexOf("{") > -1 ? JSON.parse(_tagList) : {}
                        if (from == "2") _tagList = oldJson.tagList1 ?? ""
                        if (to == "2") _tagList = JSON.stringify({ tagList1: _tagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                        this.setState({ _tagRelation: to, _tagList })
                    }}
                />
            </FormItem>)
        }
        return (<FormItem label={this.getText(label)}>
            <GenericSelector
                {...this.props}
                {...this.selectors[index]}
                {...{ multi, optionsGroup }}
                value={multi ? this.state[field] ? this.state[field].split(',') : [] : this.state[field]}
                onChange={(e) => { this.setState({ [field]: multi ? e ? e.join(',') : '' : e }) }}
            />
        </FormItem>)
    }

    createInputNumber = (label, field) => {
        return (<FormItem label={this.getText(label)}>
            <InputNumber min={0} precision={0} value={this.state[field]} onChange={(e) => { this.setState({ [field]: e }) }} />
        </FormItem>)
    }

    render() {

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        let isUserCalcFifo = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset &&
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '517')

        let params = { ...this.state }
        if (params._divisionByStorage) params = { ...params, reportName: "reports/ProfitExpandedByStorage.pdf" }

        let encodeURIFields = ['_itemCode']
        if (this.state._tagRelation == '2') encodeURIFields.push('_tagList')


        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(16490)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 8} style={{ paddingRight: 20, paddingLeft: 20, }}>
                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state._withSendingDocs}
                                onChange={e => { this.setState({ _withSendingDocs: e }) }}
                            />
                        </FormItem>
                        <Form.Item>
                            <Checkbox
                                checked={this.state._includeConnectedItems == '1'}
                                onChange={e => { this.setState({ _includeConnectedItems: e.target.checked ? "1" : "0" }) }}
                            >{this.getText(19333)}</Checkbox>
                        </Form.Item>
                        <FormItem label={this.getText(19565)}>
                            <SelectorItemClassic
                                {...this.props}
                                valueId={this.state._itemCode}
                                onChange={code => { this.setState({ _itemCode: code }) }}
                            />
                        </FormItem>
                        <FormItem label={this.getText(18096)} >
                            <CustPicker
                                value={this.state._custId}
                                onChange={e => { this.setState({ _custId: e }) }}
                                defaultView={this.getText(12121)}
                                {...this.props}
                            />
                        </FormItem>

                        <FormItem label={this.getText(19889)} >
                            <GenericSelectorSuppliers 
                                {...this.props}
                                value={this.state._purchaseSupplier}
                                onChange={e => { this.setState({ _purchaseSupplier: e }) }}
                            />
                        </FormItem>

                        <FormItem>
                            <Checkbox
                                checked={this.state._divisionByStorage}
                                onChange={e => { this.setState({ _divisionByStorage: e.target.checked }) }}
                            >{this.getText(19566)}</Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={mobile ? 24 : 8} style={{ paddingRight: 20, paddingLeft: 20, }}>
                        {this.createSelector(16493, '_priceListId', 0)}
                        {this.createSelector(18441, '_posList', 3, true)}
                        {this.createSelector(16494, '_storageList', 1, true)}
                        {this.createSelector(16495, '_tagList', 2, true, true)}
                    </Col>
                    <Col span={mobile ? 24 : 8} style={{ paddingRight: 20, paddingLeft: 20, }}>
                        {this.createDatePicker(16491, '_fromDate')}
                        {this.createDatePicker(16492, '_toDate')}
                        {this.createInputNumber(16496, '_daysCulcRolling')}
                        {this.createInputNumber(16497, '_periodsToExceed')}
                        <FormItem label={this.getText(18842)}>
                            <Select key={"_calculateCostMethod"} style={{ width: 250 }}
                                value={this.state._calculateCostMethod}
                                onChange={(value) => { this.setState({ _calculateCostMethod: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'1'}>{this.getText(18843)}</Option>
                                <Option key={2} value={'2'}>{this.getText(18844)}</Option>
                                {isUserCalcFifo ? <Option key={3} value={'3'}>{this.getText(18845)}</Option> : ""}
                                {isUserCalcFifo ? <Option key={4} value={'4'}>{this.getText(18846)}</Option> : ""}
                            </Select>

                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            xlsReportName={params.reportName.split(".")[0] + "Excel.xls"}
                            storagesField={'_storageList'}
                            posField={'_posList'}
                            tagsField={'_tagList'}
                            datesToFavorites={[
                                { field: '_fromDate', label: this.getText(16491) },
                                { field: '_toDate', label: this.getText(16492) },
                            ]}
                            encodeURIFields={encodeURIFields}
                            favoriteSave />
                    </Col>
                </Row>

            </Card>
        </div >
        );
    }
}
export default ProfitExpanded;