/* eslint-disable */
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import {
    Card, Col, Row, Button, Form, Select, message, Icon, Popconfirm,
    Tooltip, Popover, Input, InputNumber, Checkbox, Avatar, Modal, Spin, Alert
} from "antd";

// import { DragSource, ConnectDragSource, ConnectDragPreview, DragSourceConnector, DragSourceMonitor, DragSourceCollector, } from 'react-dnd'

import { TwitterPicker, BlockPicker, CompactPicker } from 'react-color';
import { colorPallete, colourNameToHex, colourHexToName, colourHexToNameCS } from "../../utils/utils";

import { Job, User, OutputType, ProcessType } from '../../JmReact';
import SelectorItems from "../../components/SelectorItems";

import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import PictureView from "../../components/PictureView";
//import ItemPicker from "../../components/ItemPicker";
import LangContext from "../../contextProvider/LangContext";
import GenericSelector from "../../components/GenericSelector";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class ButtonsEditor extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            mPOSID: null,//undefined,
            mPageNumber: null,//undefined, 
            mPick: null,//undefined,
            createLoading: false,
            pageController: {
                mCreatePage_pageNumber: undefined,
                mCreatePage_pageName: undefined,
                mCreatePage_tagId: undefined,
                mCreatePage_suppId: undefined,
                mCreatePage_backToPage: undefined,
                mCreatePage_extendPage: undefined,
                mCreatePage_colsPage: undefined,
                mCreatePage_style: undefined,
                mEditPage_pageNumber: undefined,
                mEditPage_pageName: undefined,
                mEditPage_extendPage: undefined,
                mEditPage_colsPage: undefined,
                mEditPage_style: undefined,
                mCopyPage_pos: undefined,
                mCopyPage_posListTo: undefined,
                mCopyPage_pageNumber: undefined,
                mDeletePage_pageNumber: undefined,
                mDragFrom: undefined,
                mDragTo: undefined,
            },

            selectorItemsDataSource2: [],
            defaultPriceListId: null,
        };

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        if (props.data[TableIDs.genericSelectorButtonPage] === undefined) {
            props.data[TableIDs.genericSelectorButtonPage] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorButtonPage + '2'] === undefined) {
            props.data[TableIDs.genericSelectorButtonPage + '2'] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }

        if (props.data.POS_PERMISSIONS_LIST === undefined) props.data.POS_PERMISSIONS_LIST = { ...props.data.genericSelector };

        this.api = {
            getPage: "get_button_layout_page",
            getButtons: "get_button_layout_button_list", // 24
            getParams: "get_button_params", // pos
            getPageMeta: "get_layout_page_meta",

            createPage: "create_button_layout_page_v2",
            deletePage: "delete_button_layout_page",
            editPage: "edit_button_layout_page_v2",
            copyPages: "duplicate_button_layout_pages",
            orderButtons: "order_button_layout_pages",

            editCreateButton: "edit_create_button",
            deleteButton: "delete_button",
            swapButtons: "swap_buttons",

            selectorPage: "get_button_layout_pages_list",
            selectorPos: "list_pos",
            selectorButtonActions: "get_button_action_list",
        }

        this.id_button_form = 'BUTTON_FORM';
        this.id_buttons = 'BUTTON_TABLE';
        this.id_params = 'BUTTON_PARAMS';


        if (props.data[this.id_buttons] === undefined) { props.data[this.id_buttons] = { ...props.data.genericData }; }
        if (props.data[this.id_button_form] === undefined) { props.data[this.id_button_form] = { ...props.data.genericData }; }
        if (props.data[this.id_params] === undefined) { props.data[this.id_params] = { ...props.data.genericData }; }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selectorPos);

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorButtonActions, this.props.user.companyCode, this.props.user.token, this.api.selectorButtonActions);

        this.dataSourceButtons = null;
        this.dataSourceButtonForm = null;
        this.dataSourceButtonParams = null;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();

    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(10213);
    }

    setStateVal(root: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, root, params);
        this.setState(newState);
    }

    loadData() {
        console.log(this.props);
        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);

        this.props.dataActions.genericDataGetEditWithParams(
            this.id_params,
            this.props.user.companyCode,
            this.props.user.token,
            {},
            this.api.getParams
        )

        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selectorPos]
        // });

        this.sendAPI(this.api.selectorPos, "", ob => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { code: y[0], name: y[1] }
            }) : [];
            this.props.dataActions.setJson(TableIDs.genericSelectorPos, { dataset });
            if (dataset[0]) this.onChangePos(dataset[0].code)
        })

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorButtonActions, this.props.user.companyCode, this.props.user.token, this.api.selectorButtonActions]
        });
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();

        this.props.dataActions.genericDataJustSaveEdit(
            this.id, this.props.user.companyCode,
            this.props.user.token, this.api.edit);
    }

    createItemPicker = (local, codeField, nameField) => {

        let source = this.props.data[local].editedRecord;

        let onChangeItemCode = (code, name) => {
            if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
                this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
            } else {
                console.log("onChangeItemCode", local, codeField, nameField, code, name)
                this.sendAPI('get_picture_file_from_item_code', 'itemCode\r' + code, (ob) => {
                    let imageFile = ob && ob.data ? ob.data : null

                    this.props.dataActions.genericDataSetEdit(local, { [codeField]: code, [nameField]: name, mImage: imageFile });

                    // const textValue = this.props.data[this.id_buttons].editedRecord['mValue1'];
                    // if (textValue === null || textValue === '') {
                    if (name.length > 20) {
                        name = name.slice(0, 20);
                    }
                    console.log('yuda1 ', name)
                    this.props.dataActions.genericDataSetEdit(this.id_buttons, { ['mValue1']: name })
                    // }
                })
            }
        }

        let checkIfItemExist = (s) => {
            if (s) {
                let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
                job.setInput(dataSend);
                job.send("/cgi-bin/CashOnTab",
                    (ob) => {
                        let itemsRows = (ob.data).split("\r");
                        let _ItemData = itemsRows[0].split('\f');
                        let _ItemCode = _ItemData[1];
                        let _ItemName = _ItemData[2];

                        if (!_ItemCode || itemsRows.length > 1) {
                            this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data, idSelector: codeField });
                        } else {
                            onChangeItemCode(_ItemCode, _ItemName)
                        }
                    },
                    (error) => {
                        message.error(this.getText(11295));
                    }
                );
            } else {
                onChangeItemCode("", "")
            }
        }

        let keyboardListener = (e) => {
            let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
            switch (e.key) {
                case "b": case "B": case "נ": case "M": case "m": case "צ":
                    if (e.ctrlKey) {
                        this.ctrlBKeyFlag = true;
                        this.barcodeReaderBuffer = '';
                    } else {
                        bufferUpdate(e.key)
                    }
                    break;

                case "Enter":
                    if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                    checkIfItemExist(this.barcodeReaderBuffer)
                    break;

                default:
                    bufferUpdate(e.key)
                    break;
            }
        }

        return (<span>
            <SelectorItems
                idSelector={codeField}
                user={this.props.user}
                uiActions={this.props.uiActions}
                ui={this.props.ui}
                dataActions={this.props.dataActions}
                data={this.props.data}
                ActionQueue={this.props.ActionQueue}
                ctrlBKeyFlag={null}
                isExpandMatrixFatherItem={false}
                itemType={0}
                value={source[codeField]}
                onChangeSearch={(s) => { onChangeItemCode(s.mCode, s.mName) }}
                onChange={(s) => { this.props.dataActions.genericDataSetEdit(local, { [codeField]: s.target.value }) }}
                onBlur={(s) => { checkIfItemExist(s.target.value) }}
                onKeyDown={keyboardListener}
                columns2={[{ title: this.getText(17171), key: "iSalePrice", dataIndex: "iSalePrice" }]}
                dataSource2={this.state.selectorItemsDataSource2}
                onLoadDataSource={this.onLoadSelectorItems}

            />
            <h4>{source[codeField] !== "" ? source[nameField] : this.getText(11296)}</h4>
        </span>)
    }

    onLoadSelectorItems = (itemKeys) => {
        let _priceListId = this.state.defaultPriceListId ?? '0';
        let dataSend = "_itemId\f_priceListId\r" + itemKeys.join("\f" + _priceListId + "\r") + "\f" + _priceListId;
        this.sendAPI("get_more_data_for_item_list", dataSend, (ob) => {
            let selectorItemsDataSource2 = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { key: y[0], iCostPrice: y[1], iSalePrice: y[2], iTagDefault: y[3] }
            }) : []
            this.setState({ selectorItemsDataSource2 })
        })
    }

    getFormItem = (key, text, content) => {
        return (<FormItem
            key={key} label={this.getText(text)} required={true} style={{ marginBottom: 12 }} help={''}
            labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>{content}</FormItem>)
    }

    orderButtonsOrderAbc = () => {
        let buttonsData = [...this.props.data[this.id_buttons].data];
        let sortData = buttonsData.sort((a, b) => {
            let aKeys = a.split('\f');
            let bKeys = b.split('\f');
            console.log('orderButtonsOrderAbc', aKeys[4], bKeys[4])
            return aKeys[4].localeCompare(bKeys[4]);
        }).map((x, i) => {
            let y = x.split('\f');
            let _pos = this.state.mPOSID;
            let _page = this.state.mPageNumber;
            let _old = y[0];
            let _new = (i + 1) * 1000;

            return _pos + "\f" + _page + "\f" + _old + "\f" + _new
        }).join('\r');

        this.sendAPI(this.api.orderButtons, '_pos\f_page\f_old\f_new\r' + sortData, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id_buttons, this.props.user.companyCode, this.props.user.token,
                { mPOSID: this.state.mPOSID, mPageNumber: this.state.mPageNumber }, this.api.getButtons)
        })

    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    getSelector = (label, required, placeholder, multi, id, value, onChange, filterList, optionsGroup, api) => {
        return (< FormItem label={this.getText(label)} required={required} style={{ marginBottom: 12 }} help={''} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            {id === TableIDs.genericSelectorSuppliers ?
                (<GenericSelectorSuppliers {...{ multi, optionsGroup, value, onChange, filterList, placeholder: this.getText(placeholder), ...this.props }} />)
                : id === TableIDs.genericSelectorPos ?
                    (<GenericSelectorPosStorages {...{ ...this.props, multi, value, onChange, filterList, placeholder: this.getText(placeholder) }} />)
                    : (<GenericSelector {...{ id, api, multi, optionsGroup, value, onChange, filterList, placeholder: this.getText(placeholder), ...this.props }} />)}
        </ FormItem>)
    }

    onChangePos = (e) => {
        this.sendAPI(this.api.selectorPage, "mPOSID\r" + e, ob => {
            this.sendAPI("get_price_list_default", e, ob2 => {
                let dataset = ob.data ? ob.data.split("\r").map(x => {
                    let y = x.split("\f");
                    return { code: y[0], name: y[1] }
                }) : [];
                let mPageNumber = dataset[0] ? dataset[0].code : null;
                this.props.dataActions.setJson(TableIDs.genericSelectorButtonPage, { dataset })
                this.setState({ mPOSID: e, mPageNumber, mPick: null, defaultPriceListId: ob2.data });
                if (mPageNumber) this.onChangePageNumber(mPageNumber);
                this.handleclearForm();
            })
        })
    }

    onChangePageNumber = (e) => {
        this.setState({ ['mPageNumber']: e, ['mPick']: undefined },
            ((e) => {
                this.handleclearForm();
                this.props.dataActions.genericDataSetFilter(
                    this.id_buttons, this.props.user.companyCode, this.props.user.token,
                    { ['mPOSID']: this.state.mPOSID, ['mPageNumber']: e }, this.api.getButtons)
                this.api_meta_page(this.state.mPOSID, e);
            })(e)
        );
    }

    render() {
        const divStyle = {
            align: "center", dir: "rtl", padding: "2% 2% 2% 2%", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: 'column',
        };

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' - ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        let listButtonActions = Jax.get(this.props.data, TableIDs.genericSelectorButtonActions + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' - ' + this.getText(parseInt(obj['name']))}>{obj['code'] + ' - ' + this.getText(parseInt(obj['name']))}</Option>)
        });

        let listPages = Jax.get(this.props.data, TableIDs.genericSelectorButtonPage + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' - ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        let mobile = window.innerWidth > 600 ? false : true

        this.dataSourceButtons = this.props.data[this.id_buttons].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: index,
                mNumber: y[0],
                mActionID: y[1],
                mImage: y[2],
                mColor: y[3],
                mValue1: y[4],
                mValue2: y[5],
                mValue3: y[6],
                mValue4: y[7],
                mValue5: y[8],
                index: index
            })
        });

        this.dataSourceButtonParams = this.props.data[this.id_params].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: index,
                mActionId: y[0],
                mFieldName: y[1],
                mNumber: y[2],
                mValidationMethodId: y[3],
                mIsRequired: y[4],
                index: index
            })

        });

        const { mCopyPage_pos, mCopyPage_posListTo, mCopyPage_pageNumber, mDeletePage_pageNumber } = this.state.pageController;


        const toolBar = (() => {
            const config = [
                {
                    title: this.getText(11297),
                    icon: 'plus',
                    onClick: this.onClickAddPage,
                    disabled: (this.state.mPOSID) ? false : true,
                    // loading: this.state.createLoading,
                    modal: TableIDs.modalAddPage,
                    modalContent: (<div>
                        <Form id="mCreatePage" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                            style={{ columnWidth: 'auto', columnCount: '1' }}
                            onSubmit={this.handleSaveChanges}>
                            {this.getFormItem('mCreatePage_pageName', 11302, (
                                <Input
                                    style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                                    onChange={(e) => { this.setState({ pageController: { ...this.state.pageController, mCreatePage_pageName: e.target.value } }) }}
                                    value={this.state.pageController.mCreatePage_pageName}
                                />))}


                            {!this.state.pageController.mCreatePage_tagId && !this.state.pageController.mCreatePage_suppId ? [

                                this.getFormItem('mCreatePage_extendPage', 11305, (
                                    <Checkbox onChange={(e) => { this.setState({ pageController: { ...this.state.pageController, mCreatePage_extendPage: (e.target.checked) ? 1 : 0 } }) }}
                                        checked={(this.state.pageController.mCreatePage_extendPage == 1) ? true : false}
                                    />)),
                                this.getFormItem('mCreatePage_colsPage', 15489, (
                                    <InputNumber
                                        style={{ width: '80%', direction: "ltr", textAlign: 'right' }}
                                        min={1} max={10} precision={0} step={1}
                                        onChange={(e) => { this.setState({ pageController: { ...this.state.pageController, mCreatePage_colsPage: e } }) }}
                                        value={this.state.pageController.mCreatePage_colsPage}
                                    />)),
                                this.getFormItem('mCreatePage_style', 19351, (
                                    <Select
                                        style={{ width: 250 }}
                                        onChange={(e) => { this.setState({ pageController: { ...this.state.pageController, mCreatePage_style: e } }) }}
                                        value={this.state.pageController.mCreatePage_style}
                                    >
                                        <Option value={'0'}>2.4 * 2.4 cm</Option>
                                        <Option value={'1'}>3.6 * 3.6 cm</Option>
                                        <Option value={'2'}>4.8 * 4.8 cm</Option>
                                    </Select>))
                            ] : ""}


                            {this.getSelector(
                                15490, false, 15491, false, TableIDs.genericMultiSelectorItemTags,
                                this.state.pageController.mCreatePage_tagId,
                                (e) => {
                                    let tagName = '';
                                    this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.forEach((node) => {
                                        node.tags.forEach((item) => { if (item.id === e) tagName = item.text })
                                    });
                                    this.setState({
                                        pageController: {
                                            ...this.state.pageController,
                                            mCreatePage_tagId: e, mCreatePage_pageName: tagName, mCreatePage_suppId: undefined
                                        }
                                    })
                                },
                                null, true, "get_tags_with_types"
                            )}
                            {this.getSelector(
                                15497, false, 15498, false, TableIDs.genericSelectorSuppliers,
                                this.state.pageController.mCreatePage_suppId,
                                (e) => {
                                    let suppData = this.props.data[TableIDs.genericSelectorSuppliers].dataset.find(f => f.code === e);
                                    this.setState({
                                        pageController: {
                                            ...this.state.pageController,
                                            mCreatePage_suppId: e, mCreatePage_pageName: suppData ? suppData.name : '', mCreatePage_tagId: undefined,
                                        }
                                    })
                                },
                                null, false, "get_suppliers"
                            )}
                            {this.state.pageController.mCreatePage_tagId || this.state.pageController.mCreatePage_suppId ?
                                this.getSelector(
                                    15499, false, 15500, false, TableIDs.genericSelectorButtonPage,
                                    this.state.pageController.mCreatePage_backToPage,
                                    (e) => { this.setState({ pageController: { ...this.state.pageController, ['mCreatePage_backToPage']: e } }) }
                                ) : ""}
                            {this.state.createLoading ? (
                                <div style={{ marginTop: 20, textAlign: 'center' }}>
                                    <Spin size="large" />
                                    <Alert message={this.getText(15501)} type={"info"} />
                                </div>
                            ) : ""}
                        </Form>
                    </div >),
                    handleOK: () => { this.api_create_page(); }, //() => { this.props.uiActions.hideModal(TableIDs.modalAddPage) },
                    cancelText: this.getText(11303),
                    okText: this.getText(11304)
                }, {
                    title: this.getText(11298),
                    icon: 'edit',
                    onClick: this.onClickEditPage,
                    disabled: (this.state.mPageNumber) ? false : true,

                    modal: TableIDs.modalEditPage,
                    modalContent: (<div>
                        <Form id="mEditPage" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                            style={{ columnWidth: 'auto', columnCount: '1' }}
                            onSubmit={this.handleSaveChanges}>
                            {this.getFormItem('mEditPage_pageName', 11302, (
                                <Input
                                    style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                                    onChange={(e) => { this.setState({ pageController: { ...this.state.pageController, mEditPage_pageName: e.target.value } }) }}
                                    value={this.state.pageController.mEditPage_pageName}
                                />))}
                            {this.getFormItem('mEditPage_extendPage', 11305, (
                                <Checkbox onChange={(e) => { this.setState({ pageController: { ...this.state.pageController, mEditPage_extendPage: (e.target.checked) ? 1 : 0 } }) }}
                                    checked={(this.state.pageController.mEditPage_extendPage == 1) ? true : false}
                                />))}

                            {this.getFormItem('mEditPage_colsPage', 15489, (
                                <InputNumber
                                    style={{ width: '80%', direction: "ltr", textAlign: 'right' }}
                                    min={1} max={10} precision={0} step={1}
                                    onChange={(e) => { this.setState({ pageController: { ...this.state.pageController, mEditPage_colsPage: e } }) }}
                                    value={this.state.pageController.mEditPage_colsPage}
                                />))}

                            {this.getFormItem('mEditPage_style', 19351, (
                                <Select
                                    style={{ width: 250 }}
                                    onChange={(e) => { this.setState({ pageController: { ...this.state.pageController, mEditPage_style: e } }) }}
                                    value={this.state.pageController.mEditPage_style}
                                >
                                    <Option value={'0'}>2.4 * 2.4 cm</Option>
                                    <Option value={'1'}>3.6 * 3.6 cm</Option>
                                    <Option value={'2'}>4.8 * 4.8 cm</Option>
                                </Select>))}
                        </Form>
                    </div>),
                    handleOK: () => { this.api_edit_page(); },
                    cancelText: this.getText(11303),
                    okText: this.getText(11306)
                }, {
                    title: this.getText(11299),
                    icon: 'copy',
                    onClick: this.onClickCopyPage,
                    disabled: (this.state.mPOSID) ? false : true,

                    popConfirm: {
                        title: this.getText(19592) + " " + mCopyPage_pageNumber + " "
                            + this.getText(19593) + " " + mCopyPage_pos + " "
                            + this.getText(19594) + " " + mCopyPage_posListTo + " "
                            + this.getText(19595),
                        okText: this.getText(19596),
                        cancelText: this.getText(19597),
                    },

                    modal: TableIDs.modalCopyPage,
                    modalContent: (() => {
                        return (<div>
                            <Form id="mCopyPage" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                                style={{ columnWidth: 'auto', columnCount: '1' }}
                                onSubmit={this.handleSaveChanges}>
                                {this.getSelector(11307, true, 11308, false, TableIDs.genericSelectorPos, mCopyPage_pos, this.onChangePosCopyPage)}
                                {this.getSelector(15487, true, 15488, true, TableIDs.genericSelectorPos,
                                    mCopyPage_posListTo ? mCopyPage_posListTo.split(',') : [],
                                    (e) => { this.setState({ pageController: { ...this.state.pageController, ['mCopyPage_posListTo']: e.join(',') } }) },
                                    this.props.data[TableIDs.genericSelectorPos].dataset.filter(f => f.code !== mCopyPage_pos).map(x => x.code)
                                )}
                                {this.getSelector(11309, true, 11310, true, TableIDs.genericSelectorButtonPage + '2',
                                    mCopyPage_pageNumber ? mCopyPage_pageNumber.split(',') : [],
                                    (e) => { this.setState({ pageController: { ...this.state.pageController, ['mCopyPage_pageNumber']: e.join(',') } }) })}
                            </Form>
                        </div>)
                    })(),
                    handleOK: () => { this.api_copy_pages() },
                    cancelText: this.getText(11303),
                    okText: this.getText(11311)
                }, {
                    title: this.getText(11300),
                    icon: 'delete',
                    onClick: this.onClickDeletePage,
                    disabled: (this.state.mPOSID) ? false : true,

                    popConfirm: {
                        title: this.getText(19588) + " " + mDeletePage_pageNumber + " " + this.getText(19589),
                        okText: this.getText(19590),
                        cancelText: this.getText(19591),
                    },

                    modal: TableIDs.modalDeletePage,
                    modalContent: (() => {
                        let listPages = Jax.get(this.props.data, TableIDs.genericSelectorButtonPage + ".dataset", []).map((obj, index) => {
                            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' - ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
                        });

                        return (<div>
                            <Form id="mDeletePage" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                                style={{ columnWidth: 'auto', columnCount: '1' }}
                                onSubmit={this.handleSaveChanges}>

                                < FormItem
                                    key={'mDeletePageList'} label={this.getText(11309)}
                                    required={true}
                                    style={{ marginBottom: 12 }
                                    } help={''} labelCol={{ span: 8 }
                                    } wrapperCol={{ span: 16 }}
                                >
                                    {this.createCheckBox('mDeletePage_pageNumber',
                                        Jax.get(this.props.data, TableIDs.genericSelectorButtonPage + ".dataset",
                                            []).map((obj, index) => obj['code']).join(','))}

                                    <Select key={"mDeletePageNumber"} style={{ width: '90%' }}
                                        placeholder={this.getText(11310)}
                                        mode="multiple"
                                        showsearch
                                        filterOption={true}
                                        optionFilterProp="valuefilter"
                                        //defaultValue={0}
                                        onChange={(e) => {
                                            this.setState({ pageController: { ...this.state.pageController, ['mDeletePage_pageNumber']: e.join(',') } });
                                        }}
                                        value={(this.state.pageController.mDeletePage_pageNumber) ?
                                            this.state.pageController.mDeletePage_pageNumber.split(',') : []}
                                    //notFoundContent={"לא נמצאו עובדים"}       
                                    >
                                        {listPages}
                                    </Select>
                                </ FormItem>
                            </Form>
                        </div>)
                    })(),
                    handleOK: () => {
                        if (this.state.pageController.mDeletePage_pageNumber) this.api_delete_page();
                        else message.error(this.getText(17911))
                    },
                    cancelText: this.getText(11303),
                    okText: this.getText(11312)
                }]

            return config.map((i, index) => {
                return (<span>
                    <Tooltip title={i.title} trigger={'hover'}>
                        <Icon style={{
                            fontSize: 24, pointerEvents: (i.disabled) ? 'none' : 'auto',
                            display: (i.disabled) ? 'none' : 'inline'
                        }} className={"clickable margin-4"}
                            type={i.icon} onClick={i.onClick} />
                    </Tooltip>
                    <Modal
                        title={i.title}
                        destroyOnClose={true}
                        width={'40%'}
                        visible={false || this.props.ui.modalStack.includes(i.modal)}
                        onCancel={() => { this.props.uiActions.hideModal(i.modal) }}
                        // onOk={() => { console.log("inOK"); i.handleOK(); }}
                        // okText={i.okText}
                        // cancelText={i.cancelText}
                        footer={[
                            <Button onClick={() => { this.props.uiActions.hideModal(i.modal) }}>{i.cancelText}</Button>,
                            i.popConfirm ?
                                <Popconfirm
                                    title={i.popConfirm.title}
                                    okText={i.popConfirm.okText}
                                    cancelText={i.popConfirm.cancelText}
                                    onConfirm={() => { console.log("inOK"); i.handleOK(); }}>
                                    <Button type="primary">{i.okText}</Button>,
                                </Popconfirm>
                                : <Button type="primary" onClick={() => { console.log("inOK"); i.handleOK(); }}>{i.okText}</Button>,
                        ]}
                    >
                        {i.modalContent}
                    </Modal>
                </span>
                )
            }
            )
        })();

        const colorPickerContent = (
            <TwitterPicker
                colors={colorPallete}
                width={'100%'}
                triangle='hide' /*| {'top-left'} /*| 'top-right'*/
                //onSwatchHover?(color: Color, event: MouseEvent): void;
                onChange={(color) => {
                    //message.success(colourHexToName(color.hex));
                    this.props.dataActions.genericDataSetEdit(this.id_buttons,
                        { ['mColor']: /*colourHexToNameCS(color.hex)*/ colourHexToName(color.hex) })
                    //{ ['mColor']: colourHexToNameCS(color.hex) /*colourHexToName(color.hex)*/ })
                }}
            />);

        // console.log('numCols', this.state.pageController.mEditPage_colsPage)
        const numRows = this.state.pageController.mEditPage_extendPage == 1 ? 8 : 3;
        const numCols = this.state.pageController.mEditPage_colsPage ? parseInt(this.state.pageController.mEditPage_colsPage) : 6;
        const boxSize = this.state.pageController.mEditPage_style == '1' ? 135 : this.state.pageController.mEditPage_style == '2' ? 180 : 90

        let createTile = (index) => {
            // index 0 -> end
            let button = this.dataSourceButtons.find(i => i.mNumber == index);

            // in case page hasnt been selected. show empty grid
            if (!this.state.mPageNumber) button = undefined;

            const styleBasicTile = {
                backgroundColor: (button) ? button.mColor : 'white',
                height: boxSize + 'px', //(100 / numRows) + '%',
                width: boxSize + 'px', //(100 / numCols) + '%',
                margin: 4,
                padding: 0
            };

            const stylePickedTile = { borderStyle: 'inset', borderColor: 'Yellow' }

            const styleTile = (index == this.state.mPick)
                ? { ...styleBasicTile, ...stylePickedTile }
                : { ...styleBasicTile }

            let imgURI = (button) ? button.mImage : null;
            // TODO:X REPLACE //
            // THIS IN DEVELOPE //
            //if (imgURI) imgURI = imgURI.replace("http://cashontab.com", "http://147.235.163.248");
            // THIS IN PRODUCTION //
            if (imgURI) {
                //imgURI = imgURI.replace("imoogi.net", "com");
                let origin = window.location.origin;
                const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
                let baseUrl = isDev ? "http://147.235.163.248" : origin;
                imgURI = baseUrl + "/uploads/" + this.props.user.companyCode + "/" + imgURI;
            }
            ////////////////////////////////////

            return (
                <div style={{ height: '100%', width: '100%' }}>
                    <Button value={index} style={styleTile} onClick={this.onTileClick} disabled={!this.state.mPageNumber}>
                        <div style={{ pointerEvents: 'none' }}>
                            {(imgURI && imgURI !== '') ? <Avatar shape="square" size={60} src={imgURI} /> : <div />}
                        </div>
                        <div style={{ pointerEvents: 'none' }}>{(button) ? button.mValue1 : null}</div>
                    </Button>
                </div >
            )
        };

        const handleDrag = (to, from) => {
            this.props.dataActions.genericDataSaveEditWithParams(
                this.id_buttons, this.props.user.companyCode, this.props.user.token,
                this.api.swapButtons, this.api.getButtons,
                {
                    ['mPOSID']: this.state.mPOSID,
                    ['mPageNumber']: this.state.mPageNumber,
                    ['mNumber']: from,
                    ['mNumberDest']: to,
                });
        }


        const board = ((_rows, _cols, _size, _tile) => {
            const styleBoardTile = {
                backgroundColor: 'LightSlateGrey',
                display: 'inline-block',
                verticalAlign: 'text-top',

                /*height: '50', width: '50'*/
            };
            const rows = Array.from(Array(_rows).keys());
            const cols = Array.from(Array(_cols).keys());

            return rows.map(i => {
                const colTags = cols.map(j => {
                    const location = i * _cols + j + 1;
                    return (
                        // <Col key={location} span={4/*24 / _cols*/} 
                        <div
                            style={styleBoardTile} ondrop={(e) => {
                                console.log(e); console.log(e.target);
                                // TODO: call api
                            }}>
                            <Tile
                                user={this.props.user}
                                pick={this.state.mPick}
                                index={location} onClick={this.onTileClick}
                                dataSourceButtons={this.dataSourceButtons}
                                pageNumber={this.state.mPageNumber}
                                boxSize={_size}
                                handleDrag={handleDrag}></Tile>
                            {/*_tile(location)*/}
                            {/* </Col> */}
                        </div>);
                })
                // return (<Row key={i + 1}>{colTags}</Row>);
                return (<div>{colTags}</div>);
            });
        })(numRows, numCols, boxSize, createTile);

        const createFormItems = (actionID) => {
            // mActionId , mFieldName , mNumber , mValidationMethodId , mIsRequired
            return this.dataSourceButtonParams.filter(i => i.mActionId == actionID).map(i => {
                const valueField = 'mValue' + i.mNumber;
                const isRequired = (i.mIsRequired == 1) ? true : false;
                let key = 'default';
                let content = '';
                let onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.id_buttons, { [valueField]: e }) }
                let value = this.props.data[this.id_buttons].editedRecord[valueField];
                let style = { width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }

                switch (i.mValidationMethodId) {
                    case '1': key = 'mText';
                        content = (<Input {...{ style, value }} onChange={(e) => { onChange(e.target.value) }} />);
                        break;
                    case '2':
                        key = 'mIntNumber';
                        content = (<InputNumber {...{ style, onChange, value }} precision={1} step={1} />);
                        break;
                    case '3':
                        key = 'mNumber';
                        content = (<InputNumber {...{ style, onChange, value }} precision={3} step={0.1} />);
                        break;
                    case '4':
                        key = 'mIntPercentage';
                        content = (<InputNumber {...{ style, onChange, value }} precision={1} step={1} max={100} />);
                        break;
                    case '5':
                        key = 'mPercentage';
                        content = (<InputNumber {...{ style, onChange, value }} precision={3} step={0.1} max={100} />);
                        break;
                    case '9':
                        key = 'mPositiveNumber';
                        content = (<InputNumber {...{ style, onChange, value }} precision={3} step={0.1} min={0} />);
                        break;
                    case '6':
                        return <div key='6' />
                    case '7':
                        key = 'mGoto';
                        content = (<div>
                            <Select showSearch filterOption optionFilterProp="valuefilter" defaultValue="" style={{ width: '90%', paddingLeft: '4px' }} {...{ onChange, value }}>
                                {listPages}
                            </Select>
                            {value ? <Icon type="close-circle" onClick={() => { onChange('') }} /> : ""}
                        </div>);
                        break;
                    case '8':
                        key = 'mItemPicker';
                        content = this.createItemPicker(this.id_buttons, valueField, "valueItemName");
                        break;
                    case '10':
                        return <div key='10' />
                    case '11':
                        key = 'mCheckBox';
                        content = (<Checkbox {...{ onChange, value }} />);
                        break;
                    case '12':
                        key = 'mItemTags';
                        content = (<GenericSelectorItemTags  {...this.props} {...{ onChange, value }}
                            onChangeGetDetailed={e => {
                                if (!this.props.data[this.id_buttons].editedRecord['mValue1']) {
                                    this.props.dataActions.genericDataSetEdit(this.id_buttons, { ['mValue1']: e.name })
                                }
                            }}
                        />);
                        break;
                    default:
                        return <div key={'default' + i.mNumber}></div>
                }

                return (<FormItem key={key + i.mNumber} label={i.mFieldName} required={isRequired} validateStatus={isRequired && !value ? "error" : "success"}
                    style={{ marginBottom: 12 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                >{content}</FormItem >)
            })
        };

        const { mActionID, mValue1 } = this.props.data[this.id_buttons].editedRecord;
        const disabledButton = (this.state.mPick && this.props.data[this.id_buttons].editedRecord.mNumber) ? false : true;
        const vaildateRequired = this.dataSourceButtonParams.filter(f => {
            return f.mIsRequired == 1 && !this.props.data[this.id_buttons].editedRecord['mValue' + f.mNumber] && this.props.data[this.id_buttons].editedRecord.mActionID == f.mActionId
        }).length
        const disabledSaveButton = disabledButton || !mActionID || !mValue1 || vaildateRequired;


        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(11313)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: '100%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Row id={'main'} gutter={16} >
                        <Col id={'buttonEditor'} span={mobile ? 24 : 16} /*pull={18}*/ >
                            <div id={'toolbarEditor'} style={{ padding: 4 }}>
                                <Select key={"PosList"} style={{ width: 250 }}
                                    placeholder={this.getText(11308)}
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="valuefilter"
                                    //defaultValue={0}
                                    onChange={
                                        this.onChangePos
                                        //     (e) => {
                                        //     this.setState({ ['mPOSID']: e, ['mPageNumber']: null, ['mPick']: null }),
                                        //         ((e) => {
                                        //             this.handleclearForm();
                                        //             this.props.ActionQueue.addToQueue({
                                        //                 action: this.props.dataActions.genericSelectorRefreshDataset,
                                        //                 args: [TableIDs.genericSelectorButtonPage, this.props.user.companyCode, this.props.user.token, this.api.selectorPage, { mPOSID: e }]
                                        //             });
                                        //         })(e)
                                        // }
                                    }
                                    value={this.state.mPOSID}
                                    notFoundContent={this.getText(11314)}
                                >
                                    {listPos}
                                </Select>
                                <Select key={"mPageNumber"} style={{ width: 250 }}
                                    disabled={(this.state.mPOSID) ? false : true}
                                    placeholder={this.getText(11315)}
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="valuefilter"
                                    //defaultValue={0}
                                    onChange={this.onChangePageNumber}
                                    value={this.state.mPageNumber}
                                    notFoundContent={this.getText(11316)}
                                >
                                    {listPages}
                                </Select>
                                <span>
                                    {toolBar}
                                    {(this.state.mPageNumber) ?
                                        (<Tooltip
                                            title={this.getText(13987)}
                                            trigger={'hover'}>
                                            <Popconfirm
                                                title={this.getText(13988)}
                                                onConfirm={this.orderButtonsOrderAbc}
                                                okText={this.getText(13989)} cancelText={this.getText(13990)}>
                                                <Icon
                                                    style={{ fontSize: 24 }}
                                                    className={"clickable margin-4"}
                                                    type={'ordered-list'} />
                                            </Popconfirm>
                                        </Tooltip>)
                                        : ''}

                                </span>

                            </div>
                            <div><div style={{ maxWidth: numCols * (boxSize + 10), padding: 4, backgroundColor: 'LightSlateGrey' }}>{board}</div></div>
                        </Col>

                        <Col id={'sideEditorMenu'} span={mobile ? 24 : 8} /*push={6}*/>
                            <div id={'sideEditorFrame'} style={{ padding: 4 }}>
                                <PageToolbar title={this.getText(11317)} showsearch={false} actionButtons={[]} />
                                <Form id="sideEditorForm" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                                    style={{ columnWidth: 'auto', columnCount: '1' }}
                                    onSubmit={this.handleSaveChanges}>

                                    <FormItem
                                        key={'mActionID'} label={this.getText(11318)}
                                        //validateStatus={this.isWorkerValid()}
                                        /////// hasFeedback={false} //help={"בלה בלה"}
                                        //extra={<label style={(this.isWorkerValid() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>שדה חובה</label>}
                                        required={true}
                                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={true}
                                            optionFilterProp="valuefilter"
                                            //disabled={!(this.props.data[this.id].editing === record.key)}
                                            //value={(this.props.data[this.id].editing === record.key) ?
                                            //    parseInt(this.props.data[this.id].editedRecord['xxxxx'], 10) : parseInt(text, 10)}
                                            //defaultValue={'1'}
                                            style={{ width: '100%', paddingLeft: '4px' }}

                                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id_buttons, { ['mActionID']: e }) }}
                                            value={this.props.data[this.id_buttons].editedRecord['mActionID']}
                                        >
                                            {/*<Option key={''} value={''} valuefilter={''}>- בחירת פעולה לכפתור -</Option>*/}
                                            {listButtonActions}
                                        </Select>
                                    </FormItem>
                                    <FormItem
                                        key={'mLabel'} label={this.getText(11319)}
                                        //validateStatus={this.isWorkerValid()}
                                        /////// hasFeedback={false} //help={"בלה בלה"}
                                        //extra={<label style={(this.isWorkerValid() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>שדה חובה</label>}
                                        required={true}
                                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                                    >
                                        <Input
                                            maxlength="20"
                                            style={{ width: '98%', textAlign: 'center' }}
                                            //    defaultValue={text}
                                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id_buttons, { ['mValue1']: e.target.value }) }}
                                            value={this.props.data[this.id_buttons].editedRecord['mValue1']}
                                        />
                                    </FormItem>

                                    <FormItem
                                        key={'mImage'} label={this.getText(11320)}
                                        //validateStatus={this.isWorkerValid()}
                                        ///// hasFeedback={false} //help={"בלה בלה"}
                                        //extra={<label style={(this.isWorkerValid() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>שדה חובה</label>}
                                        //required={true}
                                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                                    >
                                        <PictureView
                                            user={this.props.user} data={this.props.data} dataActions={this.props.dataActions} ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                                            url={this.props.data[this.id_buttons].editedRecord['mImage']}
                                            onChange={(url) => this.props.dataActions.genericDataSetEdit(this.id_buttons, { ['mImage']: url })}
                                            onRemove={() => this.props.dataActions.genericDataSetEdit(this.id_buttons, { ['mImage']: '' })}
                                            onUploadedSuccess={(response, url) => { console.log(response, url) }}
                                            onUploadedFailed={(errorMessage, errorCode) => { }}
                                            imageType={'B'}
                                            aspect={1} ///sliceImage

                                        />
                                    </FormItem>

                                    <FormItem
                                        key={'mColor'} label={this.getText(11321)}
                                        //validateStatus={this.isWorkerValid()}
                                        /////// hasFeedback={false} //help={"בלה בלה"}
                                        //extra={<label style={(this.isWorkerValid() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>שדה חובה</label>}
                                        //required={true}
                                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                                    >

                                        <Tooltip title={this.getText(11322)}>
                                            <Popover
                                                placement="top"
                                                content={colorPickerContent}
                                                title={
                                                    //<div style={{ textAlign: 'center' }}>בחירת צבע לכפתור</div>
                                                    <PageToolbar
                                                        title={this.getText(11323)}
                                                        actionButtons={[]}
                                                        isModal={true}
                                                        ui={this.props.ui} uiActions={this.props.uiActions}
                                                        data={this.props.data} dataActions={this.props.dataActions}
                                                    />
                                                }
                                                trigger="click">
                                                <Button className={"btn"} style={{
                                                    marginTop: "5px", marginLeft: "5px",
                                                    width: "100px", backgroundColor: this.props.data[this.id_buttons].editedRecord['mColor']
                                                }}>
                                                    <Icon /*className={"clickable margin-4"}*/ style={{ fontSize: 16 }} type="edit" theme="outlined" />
                                                </Button>
                                            </Popover>
                                        </Tooltip>
                                    </FormItem>

                                    <FormItem
                                        key={'mPermision'} label={this.getText(16955)}
                                        style={{ marginBottom: 12 }} help={''} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                                    >
                                        <GenericSelector
                                            {...this.props}
                                            id={"POS_PERMISSIONS_LIST"}
                                            api={"get_pos_permissions_list"}
                                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id_buttons, { ['mValue5']: e }) }}
                                            value={this.props.data[this.id_buttons].editedRecord['mValue5']}
                                            addFirstRow={{ code: '0', name: this.getText(16956) }}
                                        />
                                    </FormItem>

                                    {createFormItems(this.props.data[this.id_buttons].editedRecord['mActionID'])}

                                    <Button key="save" onClick={this.handleSave} disabled={disabledSaveButton}>{this.getText(11324)}</Button>
                                    <Button key="clear" /*loading={loading}*/ onClick={this.handleclearForm} disabled={disabledButton}>{this.getText(11325)}</Button>
                                    <Button key="delete" /*loading={loading}*/ onClick={this.handleDelete} disabled={disabledButton}>{this.getText(11326)}</Button>

                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }

    // submit side form functions
    handleclearForm = () => {
        this.props.dataActions.genericDataSetEdit(this.id_buttons,
            {
                //key']: null,
                mActionID: null, mColor: null, mImage: null, //mNumber: null,
                mValue1: '', mValue2: '', mValue3: '', mValue4: '', mValue5: '',
            });
    }

    handleDelete = () => {
        this.props.dataActions.genericDataSaveEditWithParams(
            this.id_buttons, this.props.user.companyCode, this.props.user.token,
            this.api.deleteButton, this.api.getButtons,
            { ['mPOSID']: this.state.mPOSID, ['mPageNumber']: this.state.mPageNumber });
    }

    handleSave = () => {
        const buttonText = this.props.data[this.id_buttons].editedRecord['mValue1'];
        if (buttonText !== '' || !buttonText) {
            this.props.dataActions.genericDataSaveEditWithParams(
                this.id_buttons, this.props.user.companyCode, this.props.user.token,
                this.api.editCreateButton, this.api.getButtons,
                { ['mPOSID']: this.state.mPOSID, ['mPageNumber']: this.state.mPageNumber });
            this.setState({ mPick: null });
        }
        else {
            message.warning(this.getText(12766));
        }
    }

    onTileClick = (e) => {
        //message.warning("tile " + e.target.value);
        const ind = e.target.value
        this.setState({ mPick: ind }, () => { this.populateForm(ind) })
    }

    populateForm = (ind) => {
        const indKey = ind;
        const button = this.dataSourceButtons.find(i => i.mNumber == indKey);

        if (button) {
            this.props.dataActions.genericDataStartEditing(this.id_buttons, button.key, button.key);
        } else {
            this.props.dataActions.genericDataSetEdit(this.id_buttons,
                {
                    key: indKey,
                    mActionID: '1', mColor: null, mImage: null,
                    mNumber: indKey,
                    mValue1: '', mValue2: '', mValue3: '', mValue4: '', mValue5: '',
                });
        }
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setState({ pageController: { ...this.state.pageController, [field]: data } });
                    else
                        this.setState({ pageController: { ...this.state.pageController, [field]: '' } });
                }} />);
    }

    // open modal
    onClickAddPage = () => {
        this.setState({
            pageController: {
                ...this.state.pageController,
                mCreatePage_suppId: undefined,
                mCreatePage_tagId: undefined,
                mCreatePage_pageName: undefined,
                mCreatePage_backToPage: undefined,
                mCreatePage_extendPage: undefined,
                mCreatePage_colsPage: undefined,
                mCreatePage_style: undefined,
            }
        }, () => {
            this.props.uiActions.showModal(TableIDs.modalAddPage);
        })


    }
    onClickEditPage = () => {
        this.sendAPI(this.api.getPage, 'mPOSID\fmPageNumber\r' + this.state.mPOSID + '\f' + this.state.mPageNumber, (ob) => {
            let data = ob.data ? ob.data.split('\f') : []
            this.setState({
                pageController: {
                    ...this.state.pageController,
                    mEditPage_pageNumber: this.state.mPageNumber,
                    mEditPage_pageName: data[0],
                    mEditPage_extendPage: data[1],
                    mEditPage_colsPage: data[2],
                    mEditPage_style: data[3],
                    // Jax.get(this.props.data, TableIDs.genericSelectorButtonPage + ".dataset", []).
                    // filter(i => i['code'] == this.state.mPageNumber)[0].name
                }
            }, () => {
                this.props.uiActions.showModal(TableIDs.modalEditPage)
            })
        })
    }

    onChangePosCopyPage = (e) => {
        this.setState({ pageController: { ...this.state.pageController, mCopyPage_pos: e, mCopyPage_pageNumber: '', mCopyPage_posListTo: '' } });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorButtonPage + '2', this.props.user.companyCode, this.props.user.token, this.api.selectorPage, { ['mPOSID']: e }]
        });
    }

    onClickCopyPage = () => {
        this.onChangePosCopyPage(this.state.mPOSID);
        this.props.uiActions.showModal(TableIDs.modalCopyPage);
    }

    onClickDeletePage = () => { this.props.uiActions.showModal(TableIDs.modalDeletePage); }

    // accept modal form - api calls
    api_copy_pages = () => {
        let dataSend = "mToPOSList\fmFromPOSID\fmPageNumber\r" +
            this.state.pageController.mCopyPage_posListTo
            + '\f' + this.state.pageController.mCopyPage_pos
            + '\f' + this.state.pageController.mCopyPage_pageNumber;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.copyPages, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.copy_page_successCallback, this.copy_page_errorCallback);
    }

    copy_page_successCallback = (ob: Object) => {
        console.log("success:", ob.data);
        if (ob.data == 0 || ob.data == '') {
            message.success(this.getText(11327) +
                this.state.pageController.mCopyPage_pos + ' ' + this.getText(11328) + ' ' + this.state.pageController.mCopyPage_posListTo);
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericSelectorRefreshDataset,
                args: [TableIDs.genericSelectorButtonPage, this.props.user.companyCode, this.props.user.token, this.api.selectorPage, { mPOSID: this.state.mPOSID }]
            });


            //this.setState({ mPageNumber: (this.state.pageController.mEditPage_pageNumber).toString(10) })
            this.setState({
                pageController:
                    { ...this.state.pageController, mCopyPage_pos: undefined, mCopyPage_pageNumber: undefined }
            })
            this.props.uiActions.hideModal(TableIDs.modalCopyPage);
        } else {
            //message.error('העמוד המבוקש לא נמצא לעריכה');
            message.error(this.getText(11329));
        }
    }
    copy_page_errorCallback = (error: any) => { message.error(this.getText(11295)); }


    //this.props.uiActions.hideModal(TableIDs.modalEditPage)
    api_edit_page = () => {
        let dataSend = "mPOSID\fmOldPageNumber\fmColsNumber\fmPageName\fmExtendPage\fmStyle\r" +
            this.state.mPOSID
            + '\f' + this.state.mPageNumber
            + '\f' + this.state.pageController.mEditPage_colsPage
            + '\f' + this.state.pageController.mEditPage_pageName
            + '\f' + this.state.pageController.mEditPage_extendPage
            + '\f' + this.state.pageController.mEditPage_style;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.editPage, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.edit_page_successCallback, this.edit_page_errorCallback);
    };

    edit_page_successCallback = (ob: Object) => {
        console.log("success:", ob.data);
        if (ob.data = -1) {
            message.success(this.getText(11330));
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericSelectorRefreshDataset,
                args: [TableIDs.genericSelectorButtonPage, this.props.user.companyCode, this.props.user.token, this.api.selectorPage, { mPOSID: this.state.mPOSID }]
            });
            this.setState({ mPageNumber: (this.state.pageController.mEditPage_pageNumber).toString(10) })
            //this.setState({ mPageNumber: this.state.pageController.mEditPage_pageNumber })
        } else {
            message.error(this.getText(11331));
        }

        this.props.uiActions.hideModal(TableIDs.modalEditPage);
    }
    edit_page_errorCallback = (error: any) => { message.error(this.getText(11295)); }


    //() => { this.props.uiActions.hideModal(TableIDs.modalDeletePage) },
    api_delete_page = () => {
        let dataSend = "mPOSID\fmPageNumber\r" +
            this.state.mPOSID + '\f' + this.state.pageController.mDeletePage_pageNumber;
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.deletePage, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.delete_page_successCallback, this.delete_page_errorCallback);
    };

    delete_page_successCallback = (ob: Object) => {
        console.log("success:", ob.data);
        message.success(this.getText(11332));
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorButtonPage, this.props.user.companyCode, this.props.user.token, this.api.selectorPage, { mPOSID: this.state.mPOSID }]
        });

        const isFound = this.state.pageController.mDeletePage_pageNumber
            .split(',').find(i => i === this.state.mPageNumber);

        if (isFound) { this.setState({ ...this.state, mPageNumber: null }) }
        this.setState({ pageController: { ...this.state.pageController, mDeletePage_pageNumber: null } });
        this.props.uiActions.hideModal(TableIDs.modalDeletePage);
    }
    delete_page_errorCallback = (error: any) => { message.error(this.getText(11295)); }

    api_create_page = () => {
        this.setState({ createLoading: true }, () => {
            const { mCreatePage_tagId, mCreatePage_pageName, mCreatePage_suppId, mCreatePage_backToPage, mCreatePage_extendPage, mCreatePage_colsPage, mCreatePage_style } = this.state.pageController;

            let dataSend = "mPOSID\fmTagId\fmPageName\fmSuppId\fmBackToPage\fmExtendPage\fmColsPage\fmStyle\r" +
                this.state.mPOSID + '\f' +
                (mCreatePage_tagId ? mCreatePage_tagId : 0) + '\f' +
                (mCreatePage_pageName ? mCreatePage_pageName : '') + '\f' +
                (mCreatePage_suppId ? mCreatePage_suppId : 0) + '\f' +
                (mCreatePage_backToPage ? mCreatePage_backToPage : 0) + '\f' +
                (mCreatePage_extendPage ? mCreatePage_extendPage : '') + '\f' +
                (mCreatePage_colsPage ? mCreatePage_colsPage : '') + '\f' +
                (mCreatePage_style ? mCreatePage_style : '');

            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, this.api.createPage, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab", this.create_page_successCallback, this.create_page_errorCallback);
        })
    };

    create_page_successCallback = (ob: Object) => {
        console.log("success:", ob.data);

        if (ob.data) {
            message.success(this.getText(11333) + ' ' + ob.data.split('\r').join(',') + ' ' + this.getText(11334));

            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericSelectorRefreshDataset,
                args: [TableIDs.genericSelectorButtonPage, this.props.user.companyCode, this.props.user.token, this.api.selectorPage, { mPOSID: this.state.mPOSID }]
            });

            this.props.uiActions.hideModal(TableIDs.modalAddPage);
        }
        else {
            message.error(this.getText(11333) + '  '
                + this.state.pageController.mCreatePage_pageNumber
                + ' ' + this.getText(11335) + ' ');
        }
        this.setState({ createLoading: false })

    }
    create_page_errorCallback = (error: any) => {
        message.error(this.getText(11295));
        this.setState({ createLoading: false })
    }

    api_meta_page = (_pos, _pageNum) => {
        let dataSend = "mPOSID\fmPageNumber\r" + _pos /*this.state.mPOSID*/ + '\f' + _pageNum/*this.state.pageController.mEditPage_pageNumber*/ + '\f';
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, this.api.getPageMeta, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", this.meta_page_successCallback, this.meta_page_errorCallback);
    }

    meta_page_successCallback = (ob: Object) => {
        let meta = ob.data.split('\f');
        this.setState({
            pageController: { ...this.state.pageController, mEditPage_extendPage: parseInt(meta[2], 10), mEditPage_colsPage: meta[3], mEditPage_style: meta[4] }
        });
    }
    meta_page_errorCallback = (error: any) => { message.error(this.getText(11295)); }

}
export default ButtonsEditor;


class Tile extends Component<Props, State> {
    // props: index, dataSourceButtons, pageNumber
    constructor(props: Props) {
        super(props)
        this.state = {}
    }

    onDragStart = (e) => {
        console.log("ON DRAG START", e.target.id);
        e.dataTransfer.setData("fromID", e.target.id);
    }

    onDragOver = (e) => {
        e.preventDefault();
        //console.log("ON DRAG OVER", e.target);
    }

    onDrop = (e) => {
        const fromID = e.dataTransfer.getData("fromID");
        const toID = e.target.value;
        console.log("ON DROP - TO: ", toID, " FROM: ", fromID);

        this.props.handleDrag(toID, fromID);
    }

    render() {
        let createTile = (index) => {
            // index 0 -> end
            let button = this.props.dataSourceButtons.find(i => i.mNumber == index);

            // in case page hasnt been selected. show empty grid
            //if (!this.state.mPageNumber) button = undefined;
            if (!this.props.pageNumber) button = undefined;

            const styleBasicTile = {
                backgroundColor: (button) ? button.mColor : 'white',
                height: this.props.boxSize + 'px', //(100 / numRows) + '%',
                width: this.props.boxSize + 'px', //(100 / numCols) + '%',
                margin: 4,
                padding: 0
            };

            const stylePickedTile = {
                borderStyle: 'inset', borderColor: 'red', borderWidth: 3,
            }

            const styleTile = (index == this.props.pick)
                ? { ...styleBasicTile, ...stylePickedTile }
                : { ...styleBasicTile }

            let imgURI = (button) ? button.mImage : null;
            // TODO:X REPLACE //
            // THIS IN DEVELOPE //
            //if (imgURI) imgURI = imgURI.replace("http://cashontab.com", "http://147.235.163.248");
            // THIS IN PRODUCTION //
            if (imgURI) {
                // imgURI = imgURI.replace("imoogi.net", "com");
                let origin = window.location.origin;
                const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
                let baseUrl = isDev ? "http://147.235.163.248" : origin;
                imgURI = baseUrl + "/uploads/" + this.props.user.companyCode + "/" + imgURI;
            }
            ////////////////////////////////////


            return (
                <div id={index} style={{ height: '100%', width: '100%' }}
                    draggable={true}
                    onDragStart={(e) => this.onDragStart(e)}
                    onDragOver={(e) => this.onDragOver(e)}
                    onDrop={(e) => this.onDrop(e)}
                >
                    <Button value={index} style={styleTile} onClick={this.props.onClick} disabled={!this.props.pageNumber}>
                        <div style={{ pointerEvents: 'none' }}>
                            {(imgURI && imgURI !== '') ? <Avatar shape="square" size={60} src={imgURI} /> : <div />}
                        </div>
                        <div style={{ pointerEvents: 'none', whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                            {(button) ? button.mValue1 : null}
                        </div>
                    </Button>
                </div >
            )
        };

        return <div>{createTile(this.props.index)}</div>;
    }
}
