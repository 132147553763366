/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import TableIDs from '../data/TableIDs';
import TaxesMenu from "../menus/TaxesMenu";
import OpenFormat from "./Taxes/OpenFormat";
import MoveinFormat from "./Taxes/MoveinFormat";
import MoveinSettings from "./Taxes/MoveinSettings";
import HilanReport from "./Taxes/HilanReport";
import HeshinFile from "./Taxes/HeshinFile";
import DebtEshcol from "./Taxes/DebtEshcol";
import Paradigma from "./Taxes/Paradigma";
import ExportSales from "./Taxes/ExportSales";
import DepositDocs from "./Taxes/DepositDocs";
import LoadfncFormat from "./Taxes/LoadfncFormat";
import AgingCreditSupp from "./Taxes/AgingCreditSupp";
import AdvancesSetting from "./Taxes/AdvancesSetting";
import TaxAdvancesReport from "./Taxes/TaxAdvancesReport";
import Imovein from "./Taxes/Imovein";


type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};

type State = {};

class Taxes extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key: any) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }
 
        const routes = [
            (<Route key={122} path={this.props.baseUrl + "/taxes/open-format"} exact render={() => <OpenFormat {...routeProps}  />} />),
            (<Route key={151} path={this.props.baseUrl + "/taxes/movein-format"} exact render={() => <MoveinFormat {...routeProps}  />} />),
            (<Route key={159} path={this.props.baseUrl + "/taxes/loadfnc-format"} exact render={() => <LoadfncFormat {...routeProps} />} />),
            (<Route key={152} path={this.props.baseUrl + "/taxes/movein-settings"} exact render={() => <MoveinSettings {...routeProps}  />} />),
            (<Route key={153} path={this.props.baseUrl + "/taxes/hilan-report"} exact render={() => <HilanReport {...routeProps}  />} />),
            (<Route key={154} path={this.props.baseUrl + "/taxes/heshin-file"} exact render={() => <HeshinFile {...routeProps}  />} />),
            (<Route key={155} path={this.props.baseUrl + "/taxes/debt-eshcol"} exact render={() => <DebtEshcol {...routeProps}  />} />),
            (<Route key={156} path={this.props.baseUrl + "/taxes/paradigma"} exact render={() => <Paradigma {...routeProps}  />} />),
            (<Route key={157} path={this.props.baseUrl + "/taxes/export-sales"} exact render={() => <ExportSales {...routeProps} />} />),
            (<Route key={158} path={this.props.baseUrl + "/taxes/deposit-docs"} exact render={() => <DepositDocs {...routeProps} />} />),
            (<Route key={160} path={this.props.baseUrl + "/taxes/aging-credit-supp"} exact render={() => <AgingCreditSupp {...routeProps} />} />),
            (<Route key={261} path={this.props.baseUrl + "/taxes/advances-setting"} exact render={() => <AdvancesSetting {...routeProps} />} />),
            (<Route key={262} path={this.props.baseUrl + "/taxes/tax-advances-report"} exact render={() => <TaxAdvancesReport {...routeProps} />} />),
            (<Route key={263} path={this.props.baseUrl + "/taxes/imovein"} exact render={() => <Imovein {...routeProps} />} />),

        ].filter((route) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/taxes"} exact render={routes[0].props.render} />)
            : <div />;

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <TaxesMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default Taxes;
