/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import { ModalFilterForm } from "../../components/ModalFilterForm";
import { BargainEditor } from "./BargainEditor";
import /*type*/ { Field } from "../../components/ModalForm";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableIDs from "../../data/TableIDs";
import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import moment from "moment";
import "../../App.css";
import { Form, Icon, Select, Radio, message, Tooltip, Button, Checkbox } from "antd";
import Jax from "../../Jax/jax.es6.module";
import LangContext from "../../contextProvider/LangContext";
import CustomerPicker from "../../components/CustomerPicker";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import { escapeRegExp } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";


const { Option, OptGroup } = Select;
const RadioGroup = Radio.Group;

const topHeight = 340; // px
const rowHeight = 30;  // px

class Bargains extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalPosAttribute: false,
            modalPosCopy: false,
            posAttr: null,
            posCopy1: null,
            posCopy2: null,
            filterPosList: []
        };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.id = "bargains";
        this.id_item = "bargain";
        this.posAttr_id = "MIVZA_POS_ATTRIBUTE";

        this.selectors = [
            { id: TableIDs.genericSelectorBargainTypes, api: "get_bargain_types_list" },
            { id: TableIDs.genericSelectorPos, api: "list_pos" },
        ]

        this.api = {
            get: "get_bargains_Table",
            get_item: "data_get",
            set_item: "data_set",

            get_pos_attr: "get_mivza_pos_attribute",
            save_pos_attr: "save_mivza_pos_attribute",
            save_pos_copy: "save_mivza_pos_copy",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.id_item] === undefined) props.data[this.id_item] = { ...props.data.genericData };
        if (props.data[this.posAttr_id] === undefined) props.data[this.posAttr_id] = { ...props.data.genericData };

        this.selectors.map((x) => { if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } } })


        this.toolbarActions = [
            {
                buttonType: "filter",
                buttonFunction: this.handleShowFilters.bind(this)
            }, {
                buttonType: "add",
                buttonFunction: this.handleCreateNew.bind(this)
            },
            {
                buttonType: "refresh",
                buttonFunction: this.refreshData
            }
        ];

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        this.selectors.map((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.api);
        });
        this.getDataModal();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(10174);
        let posList = this.props.data[TableIDs.genericSelectorPos].dataset;
        if (prev.data[TableIDs.genericSelectorPos].dataset !== posList) {
            this.filterFullPos();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    setFilter = (id, params, get) => {
        this.props.dataActions.genericDataSetFilter(id, this.props.user.companyCode, this.props.user.token, params, get)
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    filterFullPos = () => {
        let posList = this.props.data[TableIDs.genericSelectorPos].dataset;
        this.setFilter(this.id, { posList: posList.map(x => x.code).join(',') }, this.api.get)
    }

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let posList = this.props.data[TableIDs.genericSelectorPos].dataset;

        let request = {
            // ui
            page: 1,
            recordsPerPage: /*5,*/numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: /*5,*/numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            //filters
            dateFilter1: 0,
            dateFilter2: 1,
            dateFilter3: 1,
            isActive: '1',
            onSun: -1,
            onMon: -1,
            onTue: -1,
            onWed: -1,
            onThu: -1,
            onFri: -1,
            onSat: -1,
            bargainType: -1,
            allowMulti: -1,
            itemCode: null,
            itemTag: null,
            custId: null,
            custTag: null,
            posList: posList.map(x => x.code).join(','),
        };

        this.setFilter(this.id, request, this.api.get)
    }

    getDataModal = () => {
        this.props.ActionQueue.addToQueue({ action: this.props.dataActions.bargainTypesGetRequested, args: [this.props.user.companyCode, this.props.user.token] });
        this.props.ActionQueue.addToQueue({ action: this.props.dataActions.getCustomerTags, args: [this.props.user.companyCode, this.props.user.token] });
        this.props.ActionQueue.addToQueue({ action: this.props.dataActions.getItemTags, args: [this.props.user.companyCode, this.props.user.token] });
        this.props.ActionQueue.addToQueue({ action: this.props.dataActions.getItemCodes, args: [this.props.user.companyCode, this.props.user.token] });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataGetWithParams, args: ["posList", this.props.user.companyCode, this.props.user.token, {
                dataObject: "posList"
            }, "data_get"]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataGetWithParams, args: ["mutList", this.props.user.companyCode, this.props.user.token, {
                dataObject: "mutList"
            }, "data_get"]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericDataGetWithParams, args: ["customerList", this.props.user.companyCode, this.props.user.token, {
                dataObject: "customerList"
            }, "data_get"]
        });
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 5;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "bargainId": col--;
            case "bargainDescription": col--;
            case "bargainTypeId": col--;
            case "bargainDateFrom": col--;
            case "bargainDateTo": break; // 5
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.setFilter(this.id, request, this.api.get)
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }


    handleShowFilters() {
        this.props.dataActions.genericDataShowFilters(this.id);
    }

    handleHideFilters() {
        this.props.dataActions.genericDataHideFilters(this.id);
    }

    handleModalSaveEdit(data: Object, companyCode: string, token: string) {
        this.props.dataActions.genericDataSaveEdit(this.id_item, companyCode, token, this.api.set_item, this.api.get_item);
    }

    handleCreateFormCancel() {
        this.props.dataActions.genericDataCancelNew("bargain");
    }

    handleCreateNew() {
        let curdate = moment(Date.now()).format("YYYY-MM-DD");
        // let curyear = curdate.slice(0,4);
        // let nextYear = (parseInt(curyear) + 1) + curdate.slice(4)

        this.props.dataActions.genericDataStartNew("bargain", {
            bargainDateFrom: curdate,
            bargainDateTo: null,
            bargainTimeFrom: "00:00:00",
            bargainTimeTo: "23:59:59",
            bargainIsActive: 1,
            isMultiAllowed: 0,
            isActiveSun: 1,
            isActiveMon: 1,
            isActiveTue: 1,
            isActiveWed: 1,
            isActiveThu: 1,
            isActiveFri: 1,
            isActiveSat: 1,
            bargainCalcOrder: 0,
            isActivateByQuestion: 0,
            items: {
                tagId: null,
                itemCode: null,
                itemName: null,
                allItems: 0
            },
            customers: {
                tagId: null,
                customerId: null,
                customerName: null,
                allCustomers: 1
            },
            conditions: {
                conditionType: 1,
                amountFrom: null,
                amountTo: null,
                multiplier: null
            },
            pos: [],
            zhut: {
                data: [],
                headers: [],
                editing: -1,
                pagination: {
                    current: 1,
                    defaultCurrent: 1,
                    defaultPageSize: 10, //TODO:
                    total: 0,
                    hideOnSinglePage: false,
                    showQuickJumper: false,
                    showTotal: (total: number, range: Array<number>) => `  ${this.getText(10455)} ${range[0]} - ${range[1]} ${this.getText(10456)} ${total}`
                }
            }
        });
    }

    checkIfItemExist = (s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
            this.sendAPI("check_all_item_code", dataSend, (ob) => {
                let itemsRows = (ob.data).split("\r");
                let _ItemCode = itemsRows[0].split('\f')[1];
                if (!_ItemCode || itemsRows.length > 1) {
                    this.props.uiActions.showModal(TableIDs.SelectorItemsModal/*, modalParamObj*/);
                } else {

                    this.onChangeItemCode(_ItemCode)
                }
            }, () => {
                message.error(this.getText(12034));
            });
        }
    }

    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }

    keyboardListener(e) {
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.onChangeItemCode('');
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                if (this.barcodeReaderBuffer || this.isBufferFull === true) this.checkIfItemExist(this.barcodeReaderBuffer)
                break;

            default: break;
        }
    }


    onChangeItemCode = (code) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.setFilter(this.id, { itemCode: code }, this.api.get)
        }
    }

    bargainDateFrom = (text, record) => {
        const date = text ? text.split("-") : [];
        if (date.length === 3) {
            return date[2] + "/" + date[1] + "/" + date[0];
        } else {
            return text ? text : "";
        }
    }

    bargainDateTo = (text, record) => {
        const date = text ? text.split("-") : [];
        if (date.length === 3) {
            return date[2] + "/" + date[1] + "/" + date[0];
        } else {
            return text ? text : "";
        }
    }

    editActions = (text, record) => {
        let onClick = () => {
            this.props.dataActions.genericDataStartEditingPath("bargain", "bargains", record.key, record.index)
        }
        return (<span>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={onClick} />
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"copy"} onClick={() => {
                let copyName = record.bargainDescription + " - " + this.getText(13025)
                onClick();
                setTimeout(() => {
                    this.props.dataActions.setJson("bargain.editedRecord", {
                        bargainId: null,
                        bargainDescription: copyName
                    })
                }, 1000);
            }} />
        </span>
        )
    }

    showSearchTable = (val) => {
        this.setFilter(this.id, {
            ...this.props.data[this.id].filters,
            _words: escapeRegExp(val),
            // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
            page: 1,
            _page_num: 1,
        }, this.api.get)
    }

    genericActionButtons = () => {
        let buttons = [
            { tooltip: 15317, type: 'tag', state: 'modalPosAttribute' },
            { tooltip: 15318, type: 'copy', state: 'modalPosCopy' },
        ];

        return buttons.map((obj) => {
            return (<Tooltip title={this.getText(obj.tooltip)}>
                <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => { this.setState({ [obj.state]: true }) }}>
                    <Icon type={obj.type} style={{ fontSize: "16px", }} />
                </Button>
            </Tooltip>)
        })
    }

    createCheckboxAttr = (text, record) => {
        let allChecked = true;
        let allLines = record === undefined;
        if (allLines) {
            this.props.data[this.posAttr_id].data.forEach((x) => {
                let y = x.split('\f');
                if (y[2] != '1') allChecked = false;
            });
        }
        let checked = allLines ? allChecked : text == '1';
        let onChange = (e) => {
            let newValue = e.target.checked ? '1' : '0';
            let newData = this.props.data[this.posAttr_id].data.map((x) => {
                let y = x.split('\f');
                if (allLines || record.key === y[0]) {
                    return y[0] + '\f' + y[1] + '\f' + newValue
                } else {
                    return x;
                }
            });
            this.props.dataActions.setJson(this.posAttr_id, { data: newData });
        }
        return (<Checkbox checked={checked} onChange={onChange} />)
    }

    clearState = () => {
        this.setState({
            modalPosAttribute: false,
            modalPosCopy: false,
            posAttr: null,
            posCopy1: null,
            posCopy2: null,
        })
    }

    createModal = (title, state, handleOk, disabled) => {
        let gt = this.getText;

        let columns = [
            { title: gt(15321), key: 'mID', dataIndex: 'mID', width: '20%', render: (t) => t },
            { title: gt(15322), key: 'mName', dataIndex: 'mName', width: '60%', render: (t) => t },
            { title: this.createCheckboxAttr(), key: 'mChecked', dataIndex: 'mChecked', width: '20%', render: this.createCheckboxAttr },
        ];

        let getPosSelect = (label, value, onChange) => {
            return (<Form.Item label={gt(label)}><GenericSelector value={value} onChange={onChange}  {...this.selectors[1]} {...this.props} /></Form.Item>)
        }

        return (<GenericModal
            title={gt(title)}
            width={500}
            visible={this.state[state]}
            onCancel={this.clearState}
            footer={[
                <Button key="back" onClick={this.clearState} style={{ marginLeft: 5 }}>{gt(15319)}</Button>,
                <Button key="submit" type="primary" onClick={handleOk} disabled={disabled}>{gt(15320)}</Button>
            ]}>
            {state == 'modalPosAttribute' ?
                (<span>
                    {getPosSelect(15323, this.state.posAttr, (e) => { this.setState({ posAttr: e }, this.apiPosAttribute) })}
                    {this.state.posAttr ? (<ResponsiveTable tableOnly idTable={this.props.data[this.posAttr_id]} columns={columns} scroll={{ y: 280 }} pagination={false} />) : ''}
                </span>)
                : (<span>
                    {getPosSelect(15324, this.state.posCopy1, (e) => { this.setPosCopy(e, this.state.posCopy2) })}
                    {getPosSelect(15325, this.state.posCopy2, (e) => { this.setPosCopy(this.state.posCopy1, e) })}
                </span>)}
        </GenericModal>)
    }

    setPosCopy = (posCopy1, posCopy2) => {
        if (posCopy1 === posCopy2) {
            message.error(this.getText(15326));
        } else {
            this.setState({ posCopy1, posCopy2 });
        }
    }

    successModalPos = () => {
        this.clearState();
        message.success(this.getText(15327));
    }

    savePosAttribute = () => {
        let mivzaList = this.props.data[this.posAttr_id].data.map(x => x.split('\f')).filter(f => f[2] == '1').map(y => y[0]).join(',');
        let dataSend = 'posID\fmivzaList\r' + this.state.posAttr + '\f' + mivzaList;
        this.sendAPI(this.api.save_pos_attr, dataSend, this.successModalPos, (e) => { console.error(e) })
    }

    savePosCopy = () => {
        let dataSend = 'pos1\fpos2\r' + this.state.posCopy1 + '\f' + this.state.posCopy2;
        this.sendAPI(this.api.save_pos_copy, dataSend, this.successModalPos, (e) => { console.error(e) })
    }

    apiPosAttribute = () => {
        this.setFilter(this.posAttr_id, { posId: this.state.posAttr }, this.api.get_pos_attr);
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let gt = this.getText;
        let rt = (text) => text;

        let columns = [
            { title: gt(10430), dataIndex: "bargainId", key: "bargainId", sorter: true, width: 100, render: rt },
            { title: gt(10431), dataIndex: "bargainDescription", key: "bargainDescription", sorter: true, width: 200, render: rt, },
            { title: gt(10432), dataIndex: "bargainTypeId", key: "bargainTypeId", sorter: true, width: 125, render: (text, record) => text + " - " + record.bargainTypeDescription },
            { title: gt(10433), dataIndex: "bargainDateFrom", key: "bargainDateFrom", sorter: true, width: 125, render: this.bargainDateFrom },
            { title: gt(10434), dataIndex: "bargainDateTo", key: "bargainDateTo", sorter: true, width: 125, render: this.bargainDateTo },
            { title: "", dataIndex: "", key: "actions", sorter: false, width: 100, render: this.editActions },
        ];

        let types = this.props.data[this.selectors[0].id].dataset;
        const bargainTypes = types[0] ? types.map((x) => (<Option key={x.code}>{x.name}</Option>)) : []

        // const posListOptions = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj, index) => {
        //     return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        // });

        let YesNoNull = [
            <Option key={"-1"} > - </Option>,
            <Option key={"1"}>{gt(10435)}</Option>,
            <Option key={"0"}>{gt(10436)}</Option>
        ];

        let onChangeFilters = (field, value) => {
            this.setFilter(this.id, { [field]: value }, this.api.get)
        }

        let valueFields = (field) => {
            let v = this.props.data[this.id].filters[field];
            return v > -1 ? v : ""
        }

        let selectField = (f) => (<Select style={{ width: '90%' }} value={valueFields(f)} onChange={(v) => { onChangeFilters(f, v) }} size={"default"}>{YesNoNull}</Select>)

        let multiSelectorFields = (field, idSelector) => {
            let value = valueFields(field);
            let onChange = v => { onChangeFilters(field, v) }
            switch (idSelector) {
                case 'itemTags': return (<GenericSelectorItemTags {...this.props} notReport {...{ value, onChange }} />)
                case 'customerTags': return (<GenericSelectorCustTags {...this.props} {...{ value, onChange }} />)
                default:
                    return (<Select key={field} style={{ width: '90%' }} showSearch filterOption={true} optionFilterProp="valuefilter" {...{ value, onChange }}>
                        {this.props.data[idSelector].map((node) => {
                            return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                                {node.tags.map((item) => (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>))}
                            </OptGroup>)
                        })}
                    </Select>)
            }
        }

        const filterFormFields = [
            {
                key: "dateFilter",
                label: gt(10437),
                help: "",
                // node: (<RadioGroup
                //     onChange={(e) => { onChangeFilters("dateFilter", e.target.value) }}
                //     value={valueFields("dateFilter")}>
                //     <Radio value={""}>{gt(10438)}</Radio>
                //     <Radio value={1}>{gt(10439)}</Radio>
                //     <Radio value={2}>{gt(10440)}</Radio>
                //     <Radio value={3}>{gt(10441)}</Radio>
                // </RadioGroup>
                // )
                node: (<div>
                    <Checkbox checked={valueFields("dateFilter1") == 1} onChange={e => { onChangeFilters("dateFilter1", e.target.checked ? 1 : 0) }}>{gt(10439)}</Checkbox>
                    <Checkbox checked={valueFields("dateFilter2") == 1} onChange={e => { onChangeFilters("dateFilter2", e.target.checked ? 1 : 0) }}>{gt(10440)}</Checkbox>
                    <Checkbox checked={valueFields("dateFilter3") == 1} onChange={e => { onChangeFilters("dateFilter3", e.target.checked ? 1 : 0) }}>{gt(10441)}</Checkbox>
                </div>)
            }, {
                key: "bargainType",
                label: gt(10451),
                help: "",
                node: (<Select
                    style={{ width: '90%' }}
                    size={"large"} notFoundContent={gt(10452)}
                    value={valueFields("bargainType")}
                    showsearch={true} allowClear={true}
                    onChange={(value) => { onChangeFilters("bargainType", value.length > 0 ? value : -1) }}
                    placeholder={gt(10453)}>{bargainTypes}</Select>)
            }, {
                key: "itemCode",
                label: gt(13364),
                help: "",
                node: (<SelectorItems
                    doFocus={true}
                    user={this.props.user}
                    uiActions={this.props.uiActions}
                    ui={this.props.ui}
                    dataActions={this.props.dataActions}
                    data={this.props.data}
                    ActionQueue={this.props.ActionQueue}
                    ctrlBKeyFlag={null}
                    isExpandMatrixFatherItem={false}
                    itemType={1}
                    value={valueFields('itemCode')}
                    onChangeSearch={(s) => { this.onChangeItemCode(s.mCode) }}
                    onChange={(s) => { this.onChangeItemCode(s.target.value) }}
                    onBlur={(s) => { this.checkIfItemExist(s.target.value) }}
                    onKeyDown={this.keyboardListener.bind(this)}
                />)
            }, {
                key: "itemTag",
                label: gt(13365),
                help: "",
                node: multiSelectorFields("itemTag", "itemTags")
            }, {
                key: "custId",
                label: gt(13366),
                help: "",
                node: (<CustomerPicker
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit("bargain.editedRecord.customers", value);
                        onChangeFilters('custId', value.customerId)
                    }}
                    label={((this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["customers"] && this.props.data["bargain"].editedRecord["customers"].customerId) || "") + " - " + ((this.props.data["bargain"].editedRecord && this.props.data["bargain"].editedRecord["customers"] && this.props.data["bargain"].editedRecord["customers"].customerName) || "")}
                    data={this.props.data.customerList ? this.props.data.customerList.data : []}
                    showDialog={this.props.uiActions.uiShowCustomerPickerDialog}
                    hideDialog={this.props.uiActions.uiHideCustomerPickerDialog}
                />
                )
            }, {
                key: "custTag",
                label: gt(13367),
                help: "",
                node: multiSelectorFields("custTag", "customerTags")
            }, {
                key: "posList",
                label: gt(14018),
                help: "",
                node: (<GenericSelectorPosStorages
                    {...this.props}
                    // id={TableIDs.genericSelectorPos}
                    multi
                    maxTagCount={10}
                    value={this.state.filterPosList}
                    onChange={(value) => {
                        if (value.length > 0)
                            this.setState({ filterPosList: value }, () => { onChangeFilters("posList", value.join(',')) })
                        else
                            this.setState({ filterPosList: [] }, this.filterFullPos)
                    }}
                />)
                // (<Select
                //     style={{ width: '90%' }}
                //     value={this.props.data[this.id].filters.posList ? this.props.data[this.id].filters.posList.split(',') : []}
                //     showsearch={true} allowClear={true} mode="multiple"
                //     onChange={(value) => {
                //         if (value.length > 0) onChangeFilters("posList", value.join(','));
                //         else this.filterFullPos();
                //     }} >{posListOptions}</Select>)
            }, {
                key: "isActive",
                label: gt(10442),
                help: "",
                node: selectField("isActive")
            }, {
                key: "allowMulti",
                label: gt(10443),
                help: "",
                node: selectField("allowMulti")
            }, {
                key: "onSun",
                label: gt(10444),
                help: "",
                node: selectField("onSun")
            }, {
                key: "onMon",
                label: gt(10445),
                help: "",
                node: selectField("onMon")
            }, {
                key: "onTue",
                label: gt(10446),
                help: "",
                node: selectField("onTue")
            }, {
                key: "onWed",
                label: gt(10447),
                help: "",
                node: selectField("onWed")
            }, {
                key: "onThu",
                label: gt(10448),
                help: "",
                node: selectField("onThu")
            }, {
                key: "onFri",
                label: gt(10449),
                help: "",
                node: selectField("onFri")
            }, {
                key: "onSat",
                label: gt(10450),
                help: "",
                node: selectField("onSat")
            },
        ];


        return (
            <div style={divStyle}>

                <ResponsiveTable
                    title={gt(10427)}
                    actionButtons={this.toolbarActions}
                    genericActionButtons={this.genericActionButtons()}
                    idTable={this.props.data[this.id]}
                    rowClassName={(record) => record.status == 4 ? "row_lightblue" : record.status == 2 ? "" : "row_pink"}
                    showsearch={this.showSearchTable}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    percentageToMobile={[false, 70, 30]}
                    columns={columns}
                    onChange={this.handleTableChange}
                />


                <BargainEditor zIndex={50} visible={this.props.ui.modalStack.indexOf("bargainNew") > -1 || this.props.ui.modalStack.indexOf("bargainEditor") > -1}
                    workInProgress={this.props.ui.workInProgress}
                    token={this.props.user.token} companyCode={this.props.user.companyCode}
                    cancelAction={this.handleCreateFormCancel.bind(this)}
                    recordData={this.props.data[this.id_item] ? this.props.data[this.id_item].editedRecord : {}}
                    saveAction={this.handleModalSaveEdit.bind(this)} data={this.props.data} dataActions={this.props.dataActions}
                    uiActions={this.props.uiActions}
                    ui={this.props.ui}
                    user={this.props.user}
                    bargainId={Jax.get(this.props.data, "bargain.editing", -1)}
                    ActionQueue={this.props.ActionQueue}
                />

                <ModalFilterForm
                    widthPercent={window.innerWidth > 600 ? 60 : false}
                    zIndex={50}
                    visible={this.props.ui.modalStack.indexOf(this.id + "Filters") > -1}
                    workInProgress={this.props.ui.workInProgress}
                    title={gt(10454)}
                    token={this.props.user.token}
                    companyCode={this.props.user.companyCode}
                    cancelAction={() => {
                        this.props.uiActions.hideModal(this.id + "Filters");
                        this.initFilters();
                    }}
                    recordData={this.props.data[this.id].filters}
                    fields={filterFormFields} saveAction={this.handleHideFilters.bind(this)}
                />
                {this.createModal(15317, 'modalPosAttribute', this.savePosAttribute, !this.state.posAttr)}
                {this.createModal(15318, 'modalPosCopy', this.savePosCopy, !this.state.posCopy1 || !this.state.posCopy2)}
            </div>);
    }
}

export default Bargains;