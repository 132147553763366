/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../App.css";
import { Button, Icon, Tooltip, Popover } from "antd";
import LangContext from "../contextProvider/LangContext";
import { colorPallete, colourHexToName, } from "../utils/utils";
import { TwitterPicker, } from 'react-color';
import PageToolbar from "./PageToolbar";

class ColorPicker extends Component {
    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    render() {
        const colorPickerContent = (<TwitterPicker
            colors={colorPallete}
            width={'100%'}
            triangle='hide'
            onChange={(color) => {
                this.props.onChange(colourHexToName(color.hex))
            }} />);

        const colorPickerTitle = (<PageToolbar
            title={this.getText(11507)}
            actionButtons={[]}
            isModal={true} />);

        const colorPickerStyle = {
            marginTop: "5px",
            marginLeft: "5px",
            width: "100px",
            backgroundColor: this.props.value,
        }

        return (
            <Tooltip title={this.getText(11506)}>
                <Popover
                    placement="top"
                    content={colorPickerContent}
                    title={colorPickerTitle}
                    trigger="click">
                    <Button className={"btn"} style={colorPickerStyle}>
                        <Icon style={{ fontSize: 16 }} type="edit" theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip>
        )
    }

}

export default ColorPicker;