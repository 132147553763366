/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import FoodOrdersIndex from "../../pages/VirtualShop/FoodOrdersIndex";

class FoodOrders extends Component<Props, State> {
   
    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCall, eCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        if (data && data[0] && data[0].data)
            data.map((x) => { job.setSection(x.section, x.data) })
        else
            job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, eCall);
    }

    render() {
      return(
      <FoodOrdersIndex
      data={this.props.data}
      dataActions={this.props.dataActions}
      getText={this.getText}
      sendAPI={this.sendAPI}
      setJson={this.props.dataActions.setJson}
      companyName={this.props.user.companyName}
      companyCode={this.props.user.companyCode}
      companyLogo={this.props.user.profilePicture}
      token={this.props.user.token}
      lang={this.getText(100)}
      isBackOffice={true}
      />)
    }
}

export default FoodOrders;