/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import { Checkbox, Form, Input,message } from "antd";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import TableLineIcons from "../../components/TableLineIcons";



class FixRemarks extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "FIX_REMARKS_TABLE";

        this.api = {
            get: "get_fix_remarks",
            create_edit: "create_edit_fix_remarks"
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.genericSelectorDocumentTypes] === undefined) props.data[TableIDs.genericSelectorDocumentTypes] = { ...props.data.genericSelector };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(19228);
    }

    nameField = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]

        if (record.key == editing) {
            return (<Form.Item required validateStatus={editedRecord.mName ? "success" : "error" } >
            <Input
                value={editedRecord.mName}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mName: e.target.value }) }}
            />
            </Form.Item>)
        } else {
            return text;
        }
    }

    textField = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]

        if (record.key == editing) {
            return (<Form.Item required validateStatus={editedRecord.mText ? "success" : "error" } >
            <Input.TextArea
                value={editedRecord.mText}
                onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mText: e.target.value }) }}
            />
             </Form.Item>)
        } else {
            return text;
        }
    }

    docTypesField = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        const value = record.key == editing ? editedRecord.mDocumentTypes : text
        return (<Form.Item required validateStatus={value ? "success" : "error" } >
            <GenericSelector
            {...this.props}
            disabled={record.key != editing}
            multi
            id={TableIDs.genericSelectorDocumentTypes}
            api={"get_document_types_list"}
            filterList={"1,2,3,4,5,8,9,11,15,16,17,18,22,23,30,31,50,51,53".split(",")}
            value={value ? value.split(",") : []}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mDocumentTypes: e ? e.join(",") : "" }) }}
        /> 
        </Form.Item>)
    }

    isActiveField = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        const value = record.key == editing ? editedRecord.mIsActive : text

        return (<Checkbox
            disabled={record.key != editing}
            checked={value == 1}
            onChange={e => { this.props.dataActions.genericDataSetEdit(this.id, { mIsActive: e.target.checked ? '1' : '0' }) }}
        />)
    }
    editField = (text, record) => {
        const { editing, editedRecord } = this.props.data[this.id]
        if (record.key == editing) {
            return (<TableLineIcons
                onCheck={() => {
                    if (!editedRecord.mName || !editedRecord.mText || !editedRecord.mDocumentTypes ){
                        message.error(this.getText(19236));

                    } else if (editedRecord.mName.length>50) {

                            message.error(this.getText(19237))

                    } else if (editedRecord.mName.length>500) {

                        message.error(this.getText(19238))

                } else {  
                    this.props.dataActions.genericDataSaveEdit(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get);
                    }
                
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
                    else this.props.dataActions.genericDataCancelEditing(this.id);
                }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => {
                    this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) 
                }}
                
            />)

        }

    }


    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(19229)}
                addButton={() => { this.props.dataActions.genericDataStartInlineCreate(this.id) }}
                columns={[
                    { title: gt(19230), key: 'mName', dataIndex: 'mName', width: '20%', sorter: true, render: this.nameField },
                    { title: gt(19231), key: 'mText', dataIndex: 'mText', width: '40%', sorter: true, render: this.textField },
                    { title: gt(19232), key: 'mDocumentTypes', dataIndex: 'mDocumentTypes', width: '20%', sorter: true, render: this.docTypesField },
                    { title: gt(19234), key: 'mIsActive', dataIndex: 'mIsActive', width: '10%', sorter: true, render: this.isActiveField },
                    { title: gt(19235), width: '10%', render: this.editField },

                ]}
                searchBox
            />


        </div>)
    }

}

export default FixRemarks;