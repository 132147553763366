/* @flow */
/*jshint esversion: 6 */
import React from "react";
import "../App.css";
import { Icon, Tooltip, Popconfirm } from "antd";
import TableIDs from "../data/TableIDs";

type Props = {
    data: Object,
    onEdit: Function,
    onDelete: Function,
    onCheck: Function,
    onClose: Function,
    deleteQuetion: { title: String, okText: String, cancelText: String },
    genericIcons: [
        {
            type: String,
            onClick: Function,
            onCancel: Function,
            tooltip: String,
            popconfirm: { title: String, okText: String, cancelText: String },
            disabled: Boolean,
            limitUiComponent: String,
        }
    ],
}

class TableLineIcons extends React.Component {

    getIcon = (type, onClick, disabled, color) => {
        return (<Icon style={{ fontSize: 16, color: disabled ? "#aaaaaa" : color ?? "#000000" }} className={"clickable margin-4"} type={type} onClick={disabled ? () => { } : onClick} />)
    }

    getPopconfirm = (type, quetion, onConfirm, onCancel) => {
        return (<Popconfirm title={quetion.title} okText={quetion.okText} cancelText={quetion.cancelText} onConfirm={onConfirm} onCancel={onCancel}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={type} />
        </Popconfirm>)
    }

    getEditIcon = () => {
        return this.props.onEdit ? this.getIcon('edit', this.props.onEdit) : "";
    }

    getDeleteIcon = () => {
        return this.props.onDelete ?
            this.props.deleteQuetion ?
                this.getPopconfirm('delete', this.props.deleteQuetion, this.props.onDelete)
                : this.getIcon('delete', this.props.onDelete)
            : '';
    }

    getCheckIcon = () => {
        return this.props.onCheck ? this.getIcon('check', this.props.onCheck) : "";
    }

    getCloseIcon = () => {
        return this.props.onClose ? this.getIcon('close', this.props.onClose) : "";
    }

    getGenericIcons = () => {
        if (this.props.genericIcons) {
            return this.props.genericIcons.map((x) => {
                
                if (!x.type) return x;

                if (x.limitUiComponent && !this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == x.limitUiComponent)) return ""

                let res = x.popconfirm && !x.disabled ? this.getPopconfirm(x.type, x.popconfirm, x.onClick, x.onCancel) : this.getIcon(x.type, x.onClick, x.disabled, x.color);
                return x.tooltip ? (<Tooltip title={x.tooltip}>{res}</Tooltip>) : res
            })
        } else {
            return ''
        }
    }

    render() {
        return (<span>
            {this.getEditIcon()}
            {this.getDeleteIcon()}
            {this.getCheckIcon()}
            {this.getCloseIcon()}
            {this.getGenericIcons()}
        </span>)
    }
}
export default TableLineIcons;