/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";

class VirtualShopHeader extends Component {
    render() {
        return (<div className="cotvs_header">
            cotvs_header
        </div>)
    }
}
export default VirtualShopHeader;