/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import { Card, InputNumber, Input, Checkbox, Form, DatePicker, Select, Button, message, Spin, Row, Col } from "antd";
import "../../App.css";
import moment from "moment";
import TableIDs from '../../data/TableIDs.js';
import ResponsiveTable from "../../components/ResponsiveTable";
import FormLinkStyle from "../../forms/FormLinkStyle";
import LangContext from "../../contextProvider/LangContext";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

class PersonalInformationCustomer extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            pCust: '',
            pCustOk: false,
            pCustError: false,
            resetStep: null,
            pCustNew1: '',

            searchInput: '',
            endLoadLang: false,
        }

        this.comp_id = "COMPANY_DETAILS_DATA";
        this.doc_id = "DOCUMENT_COPY_FROM_CUST";
        this.fixLabDoc_id = "FIX_LAB_DOCUMENT_FROM_CUST";
        this.declar_id = "CUSTOMER_PORTAL_DECLARATIONS";

        this.api = {
            get_details: "get_company_details_to_form",
            get_declar: "get_customer_portal_declarations",
            get_doc: "get_doc_list_to_form",
            get_fixLabDoc: "get_fix_lab_doc_list_to_form",
            check_password: "check_password_to_forms",
            regi_code: "send_registration_code_to_site",
            check_regi_code: "check_registration_code_to_site",
            reset_password: "reset_password_from_site",
        }

        if (props.forms[this.comp_id] === undefined) props.forms[this.comp_id] = { data: [] }
        if (props.forms[this.doc_id] === undefined) props.forms[this.doc_id] = { data: [], headers: [] }
        if (props.forms[this.fixLabDoc_id] === undefined) props.forms[this.fixLabDoc_id] = { data: [], headers: [] }
        if (props.forms[this.declar_id] === undefined) props.forms[this.declar_id] = {}

        this.params = this.props.forms.formsFunctions.getParams();
        this.formsFunctions = this.props.forms.formsFunctions;

        this.testLoop = null;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        let dbParams = this.getDBParams()
        let custID = dbParams.custID ? dbParams.custID : ""
        if (this.props.custID) custID = this.props.custID;


        this.getFormData(this.api.get_details, {}, this.getCompanyDetails);
        this.getFormData(this.api.get_doc, { custID }, this.getDocList);
        this.getFormData(this.api.get_fixLabDoc, { custID }, this.getFixLabDocList);
        this.getFormData(this.api.get_declar, { _id: 6 }, this.getDeclarList);

        this.testLangLoading()

    }

    componentDidUpdate(prev) {
        let compData = this.props.forms[this.comp_id];
        let companyName = compData && compData.name ? compData.name : "";
        document.title = companyName + " - " + this.getText(15983);
    }

    testLangLoading = () => {
        let isLoading = this.getText(13050)[0] === '[' ? true : false;
        if (isLoading) {
            clearTimeout(this.testLoop)
            this.testLoop = setTimeout(this.testLangLoading, 1000);
        } else {
            this.setState({ endLoadLang: true })
        }
    }


    getCompanyDetails = (data) => {
        let d = data[0].split("\f");
        this.props.formsActions.setJson(this.comp_id, {
            name: d[0],
            desc: d[1],
            address1: d[2],
            address2: d[3],
            address3: d[4],
            telephone: d[5],
            term: d[6],
            logo: d[7],
            about: d[8],
        });
    }

    getDocList = (dataGet) => {
        let headers = dataGet[0].split('\f');
        let data = dataGet.slice(1);
        this.props.formsActions.setJson(this.doc_id, { data, headers });
    }

    getFixLabDocList = (dataGet) => {
        let headers = dataGet[0].split('\f');
        let data = dataGet.slice(1);
        this.props.formsActions.setJson(this.fixLabDoc_id, { data, headers });
    }

    getDeclarList = (dataGet) => {
        if (dataGet[0]) {
            let y = dataGet[0].split('\f');

            this.props.formsActions.setJson(this.declar_id, {
                report1: y[0],
                report2: y[1],
                report3: y[2],
                report4: y[3],
                documentCopy: y[4],
                topBanner: y[5],
                bottomBanner: y[6],
                isPassword: y[7],
                color: y[8],
                fixLabDocuments: y[9],
            });

            if (y[7] == '1') {
                const { custID, custMobile } = this.getDBParams();

                this.getFormData(this.api.check_password, { custID, custPassword: '' }, (data) => {
                    if (data[0] == '1') this.resetPassword(custMobile);
                })
            }
        }
    }

    getFormData = (script, params, next) => {
        let sCallback = (ob) => {
            let fullData = ob.data.split("\r");
            next(fullData);
        }
        let data = this.parseParamsToApi(params);
        this.formsFunctions.sendAPI(script, data, sCallback, this.formsFunctions.errorMessage)
    }

    parseParamsToApi = (params) => {
        let keys = Object.keys(params)
        let data1 = "";
        let data2 = "";
        keys.map((x, i) => {
            if (i > 0) {
                data1 += "\f";
                data2 += "\f";
            }
            data1 += x;
            data2 += params[x];
        })
        return data1 + "\r" + data2;
    }

    generateReport = (report) => {
        let dataSend = report.params + '\freportName\fREPORT_LOCALE\r' + report.paramValues + '\freports/' + report.name + '.pdf\f';
        switch (this.params.lang) {
            case '1': dataSend += 'en'; break;
            case '2': dataSend += 'iw_IL'; break;
            case '3': dataSend += 'ar'; break;
        }

        this.formsFunctions.sendAPI('generate_report', dataSend, (ob) => {
            const { blob } = ob;
            const a = document.createElement("a");
            let fileName = report.name + '.pdf';
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            message.success(this.getText(15984))
        }, this.formsFunctions.errorMessage)
    }

    getParamsToDocReport = (docNum, docType, docPos) => {
        return {
            name: 'DocumentCopy',
            params: 'DocumentNumber\fDocumentTypeID\fDocumentPOSID\fCompanyCode\freportName\fuserID\fuserName\fisOriginal',
            paramValues: docNum + '\f' + docType + '\f' + docPos + '\f' + this.params.code + '\f' + 'reports/DocumentCopy.pdf\f0\f\ffalse',
        }
    }


    docReportButton = (text, record) => {
        let report = this.getParamsToDocReport(record.docNum, record.docType, record.docPos)
        // {
        //     name: 'DocumentCopy',
        //     params: 'DocumentNumber\fDocumentTypeID\fDocumentPOSID\fCompanyCode\freportName\fuserID\fuserName\fisOriginal',
        //     paramValues: record.docNum + '\f' + record.docType + '\f' + record.docPos + '\f' + this.params.code + '\f' + 'reports/DocumentCopy.pdf\f0\f\ffalse',
        // }
        return (<Button type="danger" size="small" onClick={() => { this.generateReport(report) }}>{this.getText(15985)}</Button>)
    }

    fixLabDocReportButton = (text, record) => {

        let report = {
            name: 'DocumentFixLab',
            params: 'DocumentNumber\fDocumentTypeID\fDocumentPOSID\fCompanyCode\freportName',
            paramValues: record.fldNumber + '\f12\f' + record.fldPosId + '\f' + this.params.code + '\f' + 'reports/DocumentFixLab.pdf',
        }
        return (<Button type="danger" size="small" onClick={() => { this.generateReport(report) }}>{this.getText(15985)}</Button>)
    }

    getDBParams = () => {
        let dbParams = {}
        if (this.props.forms.formData && this.props.forms.formData.params) {
            this.props.forms.formData.params.split('&').forEach(x => {
                let y = x.split('=');
                dbParams = { ...dbParams, [y[0]]: y[1] }
            })
        }
        return dbParams;
    }

    sendPassword = () => {
        let dbParams = this.getDBParams();
        let custID = dbParams.custID ? dbParams.custID : ""
        let custPassword = this.state.pCust;
        // let dataSend = 'custID\fcustPassword\r' + custID + '\f' + custPassword;
        this.getFormData(this.api.check_password, { custID, custPassword }, (data) => {
            let pCustOk = data[0] && data[0] == '1' ? true : false;
            let pCustError = !pCustOk ? this.getText(15986) : false;
            this.setState({ pCustOk, pCustError, pCust: '' })
        })
    }

    resetPassword = (_mobile) => {
        this.getFormData(this.api.regi_code, { _mobile, _is_reset: 2 }, () => {
            this.setState({ resetStep: 1, pCust: '', pCustError: false })
        })
    }

    checkRegiCode = (_mobile) => {
        this.getFormData(this.api.check_regi_code, { _mobile, _code: this.state.pCust }, (data) => {
            if (data[0] == '1') this.setState({ resetStep: 2, pCust: '', pCustError: false })
            else this.setState({ pCust: '', pCustError: this.getText(15986) })
        })
    }

    saveNew1 = (_mobile) => {
        let pCust = this.state.pCust;
        if (pCust)
            this.setState({ resetStep: 3, pCust: '', pCustNew1: pCust, pCustError: false });
    }

    saveNew2 = (_mobile) => {
        let _password = this.state.pCust;
        if (_password === this.state.pCustNew1) {
            this.getFormData(this.api.reset_password, { _mobile, _password }, () => {
                this.setState({ pCust: '', pCustOk: true, pCustError: false, resetStep: null, pCustNew1: '' })
            })
        } else {
            this.setState({ pCust: '', pCustNew1: '', resetStep: 2, pCustError: this.getText(15987) })
        }
    }

    imgFormat = (url) => {
        return url ? window.location.origin + '/uploads/' + this.params.code + '/' + url : null
    }

    spinView = (content) => {
        return (<div style={{ paddingTop: window.innerHeight / 3, textAlign: 'center' }}>{content}<br /><br /><Spin /></div>)
    }

    render() {
        // let formFields = this.props.forms[this.view_id];
        // let resForm = formFields && formFields.data && formFields.data[0] ? formFields.data.map((x, i) => x ? this.getContainerV2(x, i + 1) : null).filter(f => f) : [];
        let cData = this.props.forms[this.comp_id];
        let pData = this.props.forms[this.declar_id];

        let dbParams = this.getDBParams()
        let companyName = cData ? cData.name : ""
        let companyLogo = cData ? cData.logo : ""
        let checkPassword = !this.state.pCustOk && pData.isPassword == '1';
        let custID = dbParams.custID ? dbParams.custID : ""
        let custName = dbParams.custName ? dbParams.custName : ""
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        if (isDev) origin += '/cash-on-tab/build';
        let linkToDoc = dbParams.linkToCust ? origin + '?type=2&token=' + dbParams.linkToCust + '&code=' + this.params.code + '&lang=' + this.params.lang : ""
        // let companyTerm = this.props.forms[this.comp_id] ? this.props.forms[this.comp_id].term : ""

        // if (companyTerm) companyTerm = companyTerm.split("\n").join("<br/>");

        let mobile = window.innerWidth <= 600 ? true : false;

        let reports = [
            {
                title: this.getText(15988),
                name: 'CustomerDebtDetailed',
                isActive: pData.report1 == '1',
                params: 'mID\fmLowerDate\fmUpperDate\fisWithPurchase\fisWithStandardInvoice\fisWithMatchDocs\fisWithOpenBalance',
                paramValues: custID + '\f\f\f1\f1\f1\f1',
            }, {
                title: this.getText(15989),
                name: 'CustomerPointsDetails',
                isActive: pData.report2 == '1',
                params: '_id\f_words\f_page_num\f_rows_num\f_isAsc',
                paramValues: custID + '\f\f1\f999999\f1',
            }, {
                title: this.getText(15990),
                name: 'SubscriptionsBalance',
                isActive: pData.report3 == '1',
                params: 'custID',
                paramValues: custID,
            }, {
                title: this.getText(15991),
                name: 'SubscriptionsHistory',
                isActive: pData.report4 == '1',
                params: 'custID',
                paramValues: custID,
            },
        ].filter(f => f.isActive);


        let resetStep = 0;
        let passwordTitle = this.getText(15997);
        let onPasswordSend = this.sendPassword;


        if (this.state.resetStep == 1) {
            resetStep = 1;
            passwordTitle = this.getText(15998);
            onPasswordSend = () => { this.checkRegiCode(dbParams.custMobile) }
        }
        if (this.state.resetStep == 2) {
            resetStep = 2;
            passwordTitle = this.getText(15999);
            onPasswordSend = () => { this.saveNew1(dbParams.custMobile) }
        }
        if (this.state.resetStep == 3) {
            resetStep = 3;
            passwordTitle = this.getText(16000);
            onPasswordSend = () => { this.saveNew2(dbParams.custMobile) }
        }

        let sections = [];

        if (reports.length) {
            sections.push({
                title: this.getText(16001),
                key: sections.length + 1,
                content: reports.map(x => {
                    return (<Button
                        style={{ width: mobile ? '95%' : 300, margin: 5 }}
                        type="danger" size="large"
                        onClick={() => { this.generateReport(x) }}>
                        {x.title}
                    </Button>)
                })
            })
        }

        if (pData.documentCopy == '1') {
            let idTable = { ...this.props.forms[this.doc_id] };
            if (this.state.searchInput) {
                idTable = { ...idTable, data: idTable.data.filter(f => f.indexOf(this.state.searchInput) > -1) }
            }
            sections.push({
                title: this.getText(16002),
                key: sections.length + 1,
                content: (<div className={'cart_title'}>
                    <Input.Search onChange={(e) => { this.setState({ searchInput: e.target.value }) }} />
                    <ResponsiveTable
                        idTable={idTable}
                        columns={[
                            { title: this.getText(15992), key: 'docStorage', dataIndex: 'docStorage', width: '20%', render: t => t },
                            { title: this.getText(15993), key: 'docTypeName', dataIndex: 'docTypeName', width: '20%', render: t => t },
                            { title: this.getText(15994), key: 'docNum', dataIndex: 'docNum', width: '15%', render: (t, r) => r.docIsClosed == '1' ? "ס " + t : t },
                            { title: this.getText(15995), key: 'docSum', dataIndex: 'docSum', width: '15%', render: t => t ? parseFloat(t).toFixed(2) : '0.00' },
                            { title: this.getText(15996), key: 'docDatetime', dataIndex: 'docDatetime', width: '20%', render: t => t },
                            { width: '10%', render: this.docReportButton },
                        ]}
                        scroll={{ y: window.innerHeight - 300 }}
                        tableOnly
                        getText={this.getText}
                        percentageToMobile={[30, 0, 0, 30, 40]}
                    />
                </div>)
            })
        }

        if (pData.fixLabDocuments == '1') {
            let idTable = { ...this.props.forms[this.fixLabDoc_id] };
            if (this.state.searchInput) {
                idTable = { ...idTable, data: idTable.data.filter(f => f.indexOf(this.state.searchInput) > -1) }
            }

            const renderFLDStatus = (t, r) => (<div style={{ backgroundColor: r.fldStatusColor }}>{t}</div>)

            sections.push({
                title: this.getText(17930),
                key: sections.length + 1,
                content: (<div className={'cart_title'}>
                    <Input.Search onChange={(e) => { this.setState({ searchInput: e.target.value }) }} />
                    <ResponsiveTable
                        idTable={idTable}
                        columns={[
                            { title: this.getText(17931), key: 'fldNumber', dataIndex: 'fldNumber', width: '20%', render: t => t },
                            { title: this.getText(17932), key: 'fldReceivingTime', dataIndex: 'fldReceivingTime', width: '20%', render: t => t },
                            { title: this.getText(17933), key: 'fldEstimatedDeliveryTime', dataIndex: 'fldEstimatedDeliveryTime', width: '20%', render: t => t },
                            { title: this.getText(17934), key: 'fldStatusDescription', dataIndex: 'fldStatusDescription', width: '20%', render: renderFLDStatus },
                            { width: '20%', render: this.fixLabDocReportButton },
                        ]}
                        scroll={{ y: window.innerHeight - 300 }}
                        tableOnly
                        getText={this.getText}
                        percentageToMobile={[0, 30, 30, 40]}
                    />
                </div>)
            })
        }

        let isLoading = this.getText(13050)[0] === '[' ? true : false;
        
        let hn = isDev ? "/cash-on-tab/build/" : "/";
        let cotLogo = (<img src={hn + "img/logo.png"} style={{ display: isLoading ? "inline-block" : "none" }} />);

        return this.props.isStyle3 ?
            (<Row
                style={{
                    fontFamily: "FbTipograf",
                    fontWeight: "bold",
                    lineHeight: 0.5,
                    fontSize: 18,
                }}>
                {this.props.forms[this.doc_id].data.map(x => {
                    let y = x.split("\f");
                    let dateTime = y[4] ? y[4].split(" ") : []

                    return (<Col span={12} style={{ padding: 10 }}>
                        <div style={{ padding: 10, border: "1px solid #888888", background: "white" }}>
                            <p>{y[2]}</p>
                            <p>{this.getText(18523)} {y[0]}</p>
                            <p dir="ltr">{dateTime[0] ? dateTime[0].split("-").reverse().join("/") : ""} {dateTime[1] ? dateTime[1].split(":").slice(0, 2).join(":") : ""}</p>
                            <p>₪{y[6]}</p>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}
                                    style={{ color: "black", cursor: "pointer", textAlign: "right" }}
                                    onClick={() => { this.generateReport(this.getParamsToDocReport(y[0], y[1], y[3])) }}>
                                    {this.getText(18521)}
                                </Col>
                                <Col span={12}
                                    style={{ color: "gold", cursor: "pointer", textAlign: "left" }}
                                    onClick={() => { this.generateReport(this.getParamsToDocReport(y[0], y[1], y[3])) }}>
                                    {this.getText(18522)}
                                </Col>

                            </Row>
                        </div>
                    </Col>)
                })}
            </Row>)
            : isLoading ? this.spinView(cotLogo)
                : (<FormLinkStyle
                    topBanner={this.imgFormat(pData.topBanner)}
                    bootomBanner={this.imgFormat(pData.bottomBanner)}
                    colorForm={pData.color}
                    defaultSection={1}
                    compData={{ name: companyName, logo: companyLogo }}
                    formTitle={this.getText(15983) + ' - ' + custName}
                    sections={checkPassword ? [{
                        title: passwordTitle,
                        key: 1,
                        content: (<span>
                            <Input.Password
                                autoFocus={true}
                                value={this.state.pCust}
                                onChange={(e) => { this.setState({ pCust: e.target.value, pCustError: false }) }}
                                onPressEnter={onPasswordSend}
                            />
                            {this.state.pCustError ? (<div style={{ color: 'red' }}>{this.state.pCustError}</div>) : ""}
                            <Button
                                type="danger"
                                disabled={!this.state.pCust}
                                style={{ margin: 5 }}
                                onClick={onPasswordSend}
                            >{this.getText(16004)}</Button>
                            {resetStep <= 1 ? (
                                <Button style={{ margin: 5 }} onClick={() => { this.resetPassword(dbParams.custMobile) }}>
                                    {resetStep ? this.getText(16006) : this.getText(16005)}
                                </Button>
                            ) : ""}
                        </span>)
                    }] : sections}
                    linkToDoc={linkToDoc && !checkPassword ? { title: this.getText(16003), key: 3, link: linkToDoc } : null}
                />)

    }
}
export default PersonalInformationCustomer;
