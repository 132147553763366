/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import {
    Icon, Row, Col, Button, Modal, Input, Form, message, Card, Select, Checkbox, Sea,
    InputNumber, Tooltip, Spin, Carousel, Table
} from "antd";
import SocialIconLinks from './SocialIconLinks';
import moment from "moment";
import FoodsFormatMain from "./FoodsFormatMain";
import CustomerRegistration from "./CustomerRegistration";
import PrevOrders from "./PrevOrders";
import FoodOrderPayments from "./FoodOrderPayments";
import TableIDs from '../../data/TableIDs.js';
import RegiCode from "./RegiCode";
import DeliveryTypeFields from "./DeliveryTypeFields";
import FoodFormatMessage from "./FoodFormatMessage";
import FoodOrderBranches from "./FoodOrderBranches";
import ReCAPTCHA from "react-google-recaptcha";
import AddToHomeScreen from "../../forms/addToHomeScreen";
import LangContext from "../../contextProvider/LangContext";
import FoodOrdersHeader from "./FoodOrdersHeader";
import FoodOrdersFooter from "./FoodOrdersFooter";
import GenericModal from "../../components/GenericModal"
// import VirtualShopHeader from "../VirtualShop-V2/VirtualShopHeader";
// import VirtualShopHeaderNavbar from "./VirtualShopHeaderNavbar";
import star_icon from "../../skins/index/star.png"
import PersonalInformationCustomer from "../Customers/PersonalInformationCustomer";
import ResponsiveTable from "../../components/ResponsiveTable.js";


const { Option } = Select;
// const recaptchaRef = React.createRef();

class FoodOrdersIndex extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            orderFieldsModal: '',
            nextRegi: 1,
            registration: false,
            rgiForm: false,
            menuId: 1,
            mobileMenu: false,
            loginName: '',
            loginImage: '',
            loginPhone: '',
            modalNullCart: false,
            modalNotOpen: false,
            modalPrevOrders: false,
            modalUpdatePriceList: false,
            modalBlockBranchMethod: false,
            successSale: false,
            cardcomUrl: false,
            lowprofilecode: false,
            DealResponse: false,
            InternalDealNumber: false,
            paymentTypeCheck: false,
            paymentType: null,
            paymentSum: null,
            ownerSave: false,
            paymentBalance: null,
            paymentTotal: null,
            mobileCartView: false,

            modalRegiCode: false,
            mobileToRegiCode: false,
            modalRegiCodeToPos: false,

            modalNotItemsInPos: null,
            modalCouponCustList: false,

            contactAs: {
                name: '',
                phone: '',
                city: '',
                email: '',
                message: ''
            },
            modalMessageSent: false,
            recaptshaHash: null,
            minimizeCart: false,
            cartFullScreen: false,
            bannerUrl: null,

            messagesView: [],
            messagesView1: [],
            messagesView2: [],
            messagesView3: [],

            loadingCodeSend: false,

            pizzaViewTop: null,
            changeBranch: null,

            webViewDiscountInlinePermission: {},

            custMenuType: 1,
            modalCustData: false,

            notValidAddress: false,
        }

        this.profileCodeUpdated = null;
        this.priceListBranch = null;
        this.mobile = window.innerWidth <= 700 ? true : false;

        this.isFromPos = window.location.search && window.location.search.indexOf('type=8') > -1;
        this.isFromPosUpdated = false;

        this.modalNotOpenIsUsed = false;

        this.id = "FOOD_FORMAT_ORDER_FIELDS";
        this.cart_id = "FOOD_FORMAT_CART";
        this.prev_id = "PREV_ORDERS_LIST";
        this.setting_id = "FORMAT_SITE_SETTINGS";
        this.branches_id = "BRANCHES_LIST";
        this.comp_id = "COMPANY_DETAILS_DATA";
        this.mivzaim_id = "FORMAT_SITE_MIVZAIM";
        this.mivzaim_cart_id = "FORMAT_MIVZAIM_CART";
        this.texts_id = "FORMAT_SITE_TEXTS";
        this.points_id = "FORMAT_SITE_POINTS";
        this.benefits_id = "FORMAT_SITE_BENEFITS";
        this.coupons_id = "FORMAT_COUPONS_CART";
        this.coupon_cust_id = "FORMAT_COUPON_CUST_LIST";
        this.discount_id = "FORMAT_DISCOUNT_ITEMS";
        this.itemProps_id = "VIRTUAL_SHOP_ITEM_PROPERTIES";

        if (props.data[this.id] === undefined) props.data[this.id] = { orderType: '0', orderFields: {} };
        if (props.data[this.cart_id] === undefined) props.data[this.cart_id] = { data: [] };
        if (props.data[this.prev_id] === undefined) props.data[this.prev_id] = { data: [] };
        if (props.data[this.benefits_id] === undefined) props.data[this.benefits_id] = { data: [], distributorList: [] };
        if (props.data[this.setting_id] === undefined) props.data[this.setting_id] = {};
        if (props.data[this.points_id] === undefined) props.data[this.points_id] = {};
        if (props.data[this.branches_id] === undefined) props.data[this.branches_id] = { dataset: [] };
        if (props.data[this.comp_id] === undefined) {
            props.data[this.comp_id] = {
                name: '',
                desc: '',
                address1: '',
                address2: '',
                address3: '',
                telephone: '',
                term: '',
                logo: '',
                about: ''
            };
        }
        if (props.data[this.mivzaim_id] === undefined) props.data[this.mivzaim_id] = { data: [] };
        if (props.data[this.mivzaim_cart_id] === undefined) props.data[this.mivzaim_cart_id] = { data: [] };
        if (props.data[this.texts_id] === undefined) props.data[this.texts_id] = { data: [] };
        if (props.data[TableIDs.genericSelectorDeliveryMethods] === undefined) props.data[TableIDs.genericSelectorDeliveryMethods] = { dataset: [] };
        if (props.data[TableIDs.genericSelectorLanguages] === undefined) props.data[TableIDs.genericSelectorLanguages] = { dataset: [] };
        if (props.data[this.coupons_id] === undefined) props.data[this.coupons_id] = { dataset: [] };
        if (props.data[this.coupon_cust_id] === undefined) props.data[this.coupon_cust_id] = { dataset: [] };
        if (props.data[this.discount_id] === undefined) props.data[this.discount_id] = { dataset: [] };
        if (props.data[this.itemProps_id] === undefined) props.data[this.itemProps_id] = { dataset: [] };

        this.api = {
            get_prev: "get_prev_orders_from_user",
            get_settings: "get_orders_site_settings",
            check_items: "check_prices_items_to_site",
            check_options: "check_prices_options_to_site",
            save_order: "save_order_from_site",
            save_order_lines: "save_order_lines_from_site",
            save_payment: "save_order_payment_from_site",
            langs: "get_languages_list",

            branches: "get_delivery_radius",
            get_details: "get_company_details_to_form",

            delivery_methods: "delivery_methods_list",
            delivery_qtn: "delivery_qtn_table",

            regi_code: "send_registration_code_to_site",
            update_regi: "update_registration_customer",
            guest_cust: "save_guest_customer",
            send_contact_as: "send_contact_as_message",

            get_mivzaim: "get_mivzaim_to_site",
            get_texts: "get_texts_for_virtual_shop",
            get_points: "get_customer_points",

            get_msg_view: "get_virtual_shop_messages_list",

            login_cust_pos: "login_vs_cust_from_pos",

            check_method: "check_method_branch_delivery",

            get_benefits: "get_benefits_to_site",
            get_discount: "get_discount_items",

            get_item_props: "get_virtual_shop_item_properties",
        }

        this.sendAPI = this.props.sendAPI;

        this.numberIconStyle = {
            backgroundColor: 'red',
            color: 'white',
            borderRadius: '50%',
            float: 'left',
            width: 25,
            height: 25,
            fontSize: 18,
            marginTop: -6,
            marginRight: 'auto',
            marginLeft: -6,
        }

        this.tokenRefresh = false;
        setTimeout(() => {
            this.tokenRefresh = true;
        }, 3600000);

        this.isToUpdateRegiCustomer = false;

        this.mobileMenuIsOpen = false;

        this.firstChangeBranch = false;

    }

    static contextType = LangContext;

    getText = (id) => {
        if (this.props.data[this.texts_id]['t' + id]) {
            let res = this.props.data[this.texts_id]['t' + id];
            if (res.indexOf('\n')) {
                res = res.split('\n');
                let resParseRow = [];
                res.forEach((x, i) => {
                    resParseRow.push(x);
                    if (res[i + 1]) resParseRow.push(<br />);
                })
                return resParseRow;
            } else {
                return res;
            }
        } else {
            return this.context.get(id) || '[' + id + ']'
        }
    }

    getFontStyle2 = (className) => {
        let typeStyle = this.props.data[this.setting_id].pDesktopCategoriesView;
        if (typeStyle == '2') {
            className += ' font_style_2';
        }
        return className;
    }

    getClassNameStyle = (className, style, content, onClick) => {
        let sSettings = this.props.data[this.setting_id];
        let isStyle4 = sSettings.pDesktopCategoriesView == '4';
        let isStyle5 = sSettings.pDesktopCategoriesView == '5';
        let isStyle6 = sSettings.pDesktopCategoriesView == '6';

        let background = "auto";
        let color = "auto";
        switch (className) {
            case "sale_title":
            case "title_block":
                background = isStyle4 ? "#BE1E2D" : isStyle5 ? "orange" : isStyle6 ? "#ddc268" : sSettings["rSubTitles"];
                color = isStyle4 || isStyle5 ? "white" : isStyle6 ? "black" : sSettings["rFonts"]
                break;
            case "extra_checkbox_checked":
                content = (<span>&#10004;</span>)
            case "menu_button_active":
            case "cart_icon_fixed_bottom":
                background = sSettings["rPrimaryButtons"];
                color = sSettings["rFonts"]
                break;
            case "logo_text":
                color = sSettings["rFonts"]
                break;
        }

        return (<div
            className={className === 'logo_text' ? className : this.getFontStyle2(className)}
            style={{ background: background, color: color, ...style }}
            onClick={onClick}>
            {content}
        </div>)
    }

    webViewRequest = () => {
        window.webViewReceiveRequest = (script, hash, params) => {
            switch (script) {
                case 'web_view_change_cust':
                    this.sendAPI(script, params, (ob) => {
                        this.props.setJson('formData', { params: ob.data })
                        this.updateCustFromPos(ob.data);
                        this.webViewCallback(0, 'success', hash, ob.data)
                    }, (error) => {
                        this.webViewCallback(1, 'error', hash, error);
                        this.errorCallback(error)
                    })
                    break;
                case 'web_view_permission_ok':
                    this.setState({ webViewDiscountInlinePermission: { hash, data: params } }, () => {
                        this.webViewCallback(0, 'success', hash, '');
                    });
                    break;

            }

        }
    }

    webViewCallback = (code, desc, hash, data) => {
        if (window.Android && window.Android.webViewGenericCallback)
            window.Android.webViewGenericCallback(code, desc, hash, data)
    }


    webViewChangeMethod = () => {
        // let orderType = '2' // static temp

        setTimeout(() => {
            let orderType = this.props.data[this.setting_id].pPOSDefaultDeliveryMethod;
            let dBranch = this.props.data[this.id].orderFields.dBranch;
            let list = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset.filter(f => f.branchList.indexOf(dBranch) > -1 && f.branchListBlockWeek.indexOf(dBranch) == -1);
            if (!list.find(f => f.code == orderType)) orderType = '';
            this.props.setJson(this.id, { orderType });
            this.props.setJson(TableIDs.genericSelectorDeliveryMethods, { dataset: list });
        }, 1000);
    }

    componentDidMount() {
        window.onbeforeunload = () => true;
        this.webViewRequest();

        this.apiDeliveryMethods();
        this.apiLangs();
        this.apiDetails();
        this.apiBranches();
        this.apiSettings();
        this.apiTexts();
        this.apiMivzaim(null, null);
        this.apiMsgView();
        this.apiBenefits();
        this.apiItemProps();

        setTimeout(() => {
            let pPOSDefaultDeliveryMethod = this.props.data[this.setting_id].pPOSDefaultDeliveryMethod;
            if (!this.isFromPos && pPOSDefaultDeliveryMethod) {
                let dataset = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset.filter(f => f.code !== pPOSDefaultDeliveryMethod);
                this.props.setJson(TableIDs.genericSelectorDeliveryMethods, { dataset })
            }
        }, 3000);

        window.addEventListener("click", (e) => {
            if (this.mobile && this.mobileMenuIsOpen) {
                this.setState({ mobileMenu: false })
                this.mobileMenuIsOpen = false;
            }
        })
    }

    componentDidUpdate(p) {
        let title = this.props.title ? decodeURI(this.props.title) : "Cash On Tab - " + this.getText(12934);

        document.title = title;

        let orderFields = this.props.data[this.id].orderFields;
        let pl = orderFields ? orderFields.dPriceList : null;

        let fd = this.props.data.formData;

        if (this.isFromPos) {
            if (!this.isFromPosUpdated) {
                this.updateCustFromPos(fd.params);
                this.isFromPosUpdated = true;
            }
        } else {
            if (pl !== this.priceListBranch) {
                if (pl && this.priceListBranch) {
                    this.setState({ modalUpdatePriceList: true }, () => {
                        this.apiMivzaim(orderFields.cID, orderFields.dPosNumber);
                        this.apiCartPrices(orderFields.dPriceList);
                    });
                }

                this.priceListBranch = pl;
            }

            if (fd && fd.custLocation && fd.custLocation.latitude && fd.custLocation.longitude &&
                (!p.data.formData.custLocation || !p.data.formData.custLocation.longitude || !p.data.formData.custLocation.longitude)
            ) { this.apiBranches() }
        }

        let page_scroll_style_6 = document.getElementById("page_scroll_style_6")
        if (page_scroll_style_6) {
            page_scroll_style_6.style.overflowY = (page_scroll_style_6.offsetHeight >= window.innerHeight - 230) ? "scroll" : "hidden";
        }

        if (!p.data[this.id].orderFields.dBranch && orderFields.dBranch) this.firstChangeBranch = true;

        if (p.data[this.id].orderFields.dBranch !== orderFields.dBranch && this.state.loginId) {
            this.apiCoupnCustList(this.state.loginId, orderFields.dPosNumber)
        }
    }

    updateCustFromPos = (urlParams) => {
        let params = {}

        if (urlParams) {
            urlParams.split('&').forEach((x) => {
                let y = x.split('=');
                params = { ...params, [y[0]]: y[1] }
            })
        }

        if (params.cust && params.pos) {
            this.sendAPI(this.api.login_cust_pos, '_pos\f_cust\r' + params.pos + '\f' + params.cust, (ob) => {
                let res = ob.data ? ob.data.split('\f') : [];
                let firstPage = res[6] === 'OrderType' ? 0 : 1;
                let custName = params.custName ? params.custName : res[1];
                this.login(custName, res[2], res[0], params.cust, firstPage);
                this.props.setJson(this.id + '.orderFields', {
                    dName: custName,
                    dPhone: res[0],
                    dFullAddress: res[3],
                    cID: params.cust,
                    dBranch: res[4],
                    dPosNumber: params.pos,
                    dPriceList: res[5],
                    dUserCheck: true,
                });

                this.apiMivzaim(params.cust, params.pos);
            }, this.errorCallback)
        }

        this.webViewChangeMethod();
        this.props.setJson(this.id, { futureOrder: false })
        this.props.setJson(this.id + '.orderFields', { futureTime: '', futureDate: '' })
    }

    apiDeliveryMethods = () => {
        this.sendAPI(this.api.delivery_methods, "", (ob) => {
            if (ob.data) {
                let dataset = ob.data.split("\r").map((x) => {
                    let y = x.split("\f");
                    return {
                        code: y[0],
                        name: y[1],
                        needAddress: y[2],
                        type: y[3],
                        branchList: y[4] ? y[4].split(',') : [],
                        image: y[5],
                        branchListBlockWeek: y[6] ? y[6].split(',') : [],
                    }
                })
                this.props.setJson(TableIDs.genericSelectorDeliveryMethods, { dataset });
            }
        }, this.errorCallback);
    }

    apiLangs = () => {
        this.sendAPI(this.api.langs, "", (ob) => {
            if (ob.data) {
                let dataset2 = ob.data.split("\r").map((x) => {
                    let y = x.split("\f");
                    return { code: y[0], name: y[1] }
                })
                this.props.setJson(TableIDs.genericSelectorLanguages, { dataset: dataset2 })
            }
        }, this.errorCallback);
    }

    apiDetails = () => {
        this.sendAPI(this.api.get_details, '', (ob) => {
            let d = ob.data.split("\f");
            this.props.setJson(this.comp_id, {
                name: d[0],
                desc: d[1],
                address1: d[2],
                address2: d[3],
                address3: d[4],
                telephone: d[5],
                term: d[6],
                logo: d[7],
                about: d[8],
            });
        }, this.errorCallback);
    }

    apiMsgView = () => {
        this.sendAPI(this.api.get_msg_view, "isFromPos\r" + (this.isFromPos ? '1' : '0'), (ob) => {
            if (ob && ob.data) {
                let data = ob.data.split('\r');
                data = data.map((x) => {
                    let y = x.split('\f');
                    return { id: y[0], desc: y[1], type: y[2], content: y[3], }
                });
                let messagesView = data.filter(f => f.type == '0');
                let messagesView1 = data.filter(f => f.type == '1');
                let messagesView2 = data.filter(f => f.type == '2');
                let messagesView3 = data.filter(f => f.type == '3');

                this.setState({ messagesView, messagesView1, messagesView2, messagesView3 })
            }
        }, this.errorCallback)
    }

    apiBenefits = () => {
        this.sendAPI(this.api.get_benefits, '', (ob) => {
            let data = ob.data ? ob.data.split("\r") : [];
            let distributorList = [];
            data = data.map(x => {
                let y = x.split('\f');
                if (distributorList.indexOf(y[0]) === -1) distributorList.push(y[0]);
                return {
                    distributorId: y[0],
                    companyId: y[1],
                    minusItemCode: y[2],
                    minusItemName: y[3],
                }
            })
            this.props.setJson(this.benefits_id, { data, distributorList });
        }, this.errorCallback);
    }

    apiItemProps = () => {
        this.sendAPI(this.api.get_item_props, '', (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { key: y[0], desc: y[1], image: y[2], itemCode: y[3] }
            }) : [];
            this.props.setJson(this.itemProps_id, { dataset });
        }, this.errorCallback);
    }

    apiCartPrices = (priceList) => {
        let cData = this.props.data[this.cart_id].data;
        let newPricesInCart = [];
        let orderFields = this.props.data[this.id].orderFields;
        let branch = this.isFromPos && orderFields.dBranch ? orderFields.dBranch : "0";

        let itemsData = "iCode\fiPrice\fiIndex\fiPriceList";
        let optionsData = "";

        cData.forEach((x, i) => {
            itemsData += "\r" + x.item.iCode + "\f" + x.item.iPrice + "\f" + i + "\f" + priceList;
            if (x.levels) {
                x.levels.forEach((y) => {
                    y.ownerData.forEach((z) => {
                        let oPrice = parseFloat(z.oPrice ? z.oPrice : 0);
                        let oExtrasList = "";
                        let oFreeExtrasList = "";
                        let oExtrasList05 = "";
                        let oFreeExtrasList05 = "";
                        let oExtrasList025 = "";
                        let oFreeExtrasList025 = "";
                        let oExtrasList075 = "";
                        let oFreeExtrasList075 = "";
                        if (z.oExtraItems) {
                            z.oExtraItems.forEach((e) => { oPrice = parseFloat(e.ePrice) + parseFloat(oPrice) });
                            oExtrasList = z.oExtraItems.filter(f => f.eType === "2" && (!f.eLocals || f.eLocals.length == z.oPizzaPiecesNum)).map((e) => e.eID).join(",");
                            oFreeExtrasList = z.oExtraItems.filter(f => f.eType === "3" && (!f.eLocals || f.eLocals.length == z.oPizzaPiecesNum)).map((e) => e.eID).join(",");
                            oExtrasList05 = z.oExtraItems.filter(f => f.eType === "2" && f.eLocals && f.eLocals.length * 2 == z.oPizzaPiecesNum).map((e) => e.eID).join(",");
                            oFreeExtrasList05 = z.oExtraItems.filter(f => f.eType === "3" && f.eLocals && f.eLocals.length * 2 == z.oPizzaPiecesNum).map((e) => e.eID).join(",");
                            oExtrasList025 = z.oExtraItems.filter(f => f.eType === "2" && f.eLocals && f.eLocals.length == 1 && z.oPizzaPiecesNum == '4').map((e) => e.eID).join(",");
                            oFreeExtrasList025 = z.oExtraItems.filter(f => f.eType === "3" && f.eLocals && f.eLocals.length == 1 && z.oPizzaPiecesNum == '4').map((e) => e.eID).join(",");
                            oExtrasList075 = z.oExtraItems.filter(f => f.eType === "2" && f.eLocals && f.eLocals.length == 3 && z.oPizzaPiecesNum == '4').map((e) => e.eID).join(",");
                            oFreeExtrasList075 = z.oExtraItems.filter(f => f.eType === "3" && f.eLocals && f.eLocals.length == 3 && z.oPizzaPiecesNum == '4').map((e) => e.eID).join(",");
                        }
                        optionsData += "\r" + z.oID + "\f" +
                            oExtrasList + "\f" +
                            oFreeExtrasList + "\f" +
                            z.oFreeExtras + "\f" +
                            oPrice + "\f" +
                            i + "\f" +
                            priceList + "\f" +
                            branch + "\f" +
                            oExtrasList05 + "\f" +
                            oFreeExtrasList05 + "\f" +
                            oExtrasList025 + "\f" +
                            oFreeExtrasList025 + "\f" +
                            oExtrasList075 + "\f" +
                            oFreeExtrasList075;
                    })
                })
            }
        });

        optionsData = optionsData ?
            "oID\foExtrasList\foFreeExtrasList\foFreeExtras\foPrice\fiIndex\f_priceList\f_branch" +
            "\foExtrasList05\foFreeExtrasList05\foExtrasList025\foFreeExtrasList025\foExtrasList075\foFreeExtrasList075" +
            optionsData
            : null;

        let isFoodDish = (index) => cData[parseInt(index)].item.iFoodDishId;

        let checkItemsAndOptions = (iData, oData) => {
            newPricesInCart = iData.filter(f => !isFoodDish(f.iIndex));

            iData.filter(f => isFoodDish(f.iIndex)).forEach((x) => {
                let sumFD = parseFloat(x.iPrice);
                oData.filter(f => f.iIndex === x.iIndex).forEach((y) => {
                    sumFD = parseFloat(sumFD) + parseFloat(y.oPrice);
                });
                let sumItemPrice = parseFloat(sumFD).toFixed(2);
                if (sumItemPrice != cData[x.iIndex].item.iPrice) {
                    newPricesInCart = [...newPricesInCart, { ...x, iPrice: sumItemPrice }];
                }
            });

            if (newPricesInCart[0]) {
                let ccData = cData.map((x, i) => {
                    let iChange = newPricesInCart.find(f => f.iIndex == i);
                    return iChange ? { ...x, item: { ...x.item, iPrice: iChange.iPrice } } : x;
                });
                this.props.setJson(this.cart_id, { data: ccData });
                setTimeout(() => {
                    this.mivzaimLogic(this.cart_id);
                }, 1000);

            }
        }

        this.props.sendAPI(this.api.check_items, itemsData, (itemOb) => {
            let iData = itemOb.data ? itemOb.data.split("\r").map((xx) => {
                let x = xx.split('\f');
                return { iCode: x[1], iPrice: x[2], iIndex: x[0] }
            }) : [];

            if (optionsData) {
                this.props.sendAPI(this.api.check_options, optionsData, (optionsOb) => {
                    let oData = optionsOb.data ? optionsOb.data.split("\r").map((xx) => {
                        let x = xx.split('\f');
                        return { oID: x[1], oPrice: x[2], iIndex: x[0] }
                    }) : [];
                    checkItemsAndOptions(iData, oData);
                }, (e) => {
                    this.errorCallback(e)
                    message.error(this.getText(13114))
                })
            } else {
                checkItemsAndOptions(iData, []);
            }
        }, this.errorCallback)

    }


    mivzaimGroup = (skip, zhut, item) => {

        let resetGroup = () => {
            this.mivzaValue += this.mivzaGroupValue;
            this.mivzaGroupValue = 0;
            this.mivzaGroupSkipSum = 0;
            this.mivzaGroupZhutSum = 0;
        }

        if (this.mivzaGroupSkipSum < skip) {
            if (this.mivzaGroupSkipSum + item.qty <= skip) {
                this.mivzaGroupSkipSum += item.qty;
            } else {
                let balance = skip - this.mivzaGroupSkipSum;
                let itemB = { ...item, qty: item.qty - balance }
                this.mivzaGroupSkipSum += balance;
                this.mivzaimGroup(skip, zhut, itemB);
            }
        } else if (this.mivzaGroupZhutSum < zhut) {
            if (this.mivzaGroupZhutSum + item.qty <= zhut) {
                this.mivzaGroupZhutSum += item.qty;
                this.mivzaGroupValue += item.value * item.qty;
                if (this.mivzaGroupZhutSum == zhut) {
                    resetGroup();
                }
            } else {
                let balance = zhut - this.mivzaGroupZhutSum;
                let itemB = { ...item, qty: item.qty - balance }
                this.mivzaGroupZhutSum += balance;
                this.mivzaGroupValue += item.value * balance;
                resetGroup();
                this.mivzaimGroup(skip, zhut, itemB);
            }
        }
    }

    mivzaimLogic = (idStore, finishOrder) => {
        let mData = this.props.data[this.mivzaim_id].data.filter(f => f.mToCoupon != '1');
        let cData = this.props.data[idStore].data.filter(f => !f.item.inlineDiscount);
        let mmt = (time) => moment(time, "HH:mm:ss")._d;
        let dn = Date.now();

        let mTable = mData.filter(f => mmt(f.mTimeFrom) < dn && mmt(f.mTimeTo) > dn).map((x, i) => {
            return { data: x, qty: 0, sum: 0, value: 0, mIndex: i }
        });

        let miTable = [];

        /// items value
        cData.forEach((x) => {
            let indexItem = miTable.findIndex(f => f.code === x.item.iCode);
            let iQty = parseFloat(x.item.iQty);
            let iPrice = parseFloat(x.item.iPrice);
            let stepQty = parseFloat(x.item.stepQty);

            if (indexItem === -1) {
                miTable.push({
                    code: x.item.iCode,
                    qty: iQty,
                    price: iPrice,
                    stepQty: stepQty,
                    mivzaim: x.item.mivzaList ?
                        mTable.filter(f => x.item.mivzaList.split(",").indexOf(f.data.mId) > -1).map((y) => {
                            mTable[y.mIndex] = {
                                ...mTable[y.mIndex],
                                qty: mTable[y.mIndex].qty + iQty,
                                sum: mTable[y.mIndex].sum + (iPrice * iQty),
                            }
                            let mzPrice = parseFloat(y.data.mzPrice);
                            let mzDscnt = parseFloat(y.data.mzDscnt);
                            let mValue = mzPrice ? iPrice - mzPrice : mzDscnt ? iPrice * mzDscnt * 0.01 : 0;
                            return { id: y.data.mId, value: mValue, qty: 0 }
                        }) : []
                });
            } else {
                let newQty = miTable[indexItem].qty + iQty;
                miTable[indexItem] = {
                    ...miTable[indexItem],
                    qty: newQty,
                    mivzaim: miTable[indexItem].mivzaim.map((z) => {
                        let y = mTable.find(f => f.data.mId === z.id);
                        mTable[y.mIndex] = {
                            ...mTable[y.mIndex],
                            qty: mTable[y.mIndex].qty + iQty,
                            sum: mTable[y.mIndex].sum + (iPrice * iQty),
                        }
                        return z
                    })
                }
            }
        });

        miTable = miTable.sort((a, b) => b.price - a.price);

        /// mivzaim value

        mTable = mTable.map((x) => this.mivzaGetValue(x, miTable))
            .sort((a, b) => b.value - a.value)
            .sort((a, b) => parseInt(a.data._hierarchy) - parseInt(b.data._hierarchy));

        /// mivzaim use

        this.miTableFilter = miTable;

        mTable = mTable.map((x) => {

            let miTableFilter = this.miTableFilter.filter(f => f.qty > 0)
            let mNewQty = 0;
            let mNewSum = 0;

            miTableFilter.forEach((y) => {
                if (y.mivzaim.find(f => f.id == x.data.mId)) {
                    mNewQty += y.qty;
                    mNewSum += y.qty * y.price;
                }
            });

            let mNewData = { ...x, qty: mNewQty, sum: mNewSum, value: 0 }

            let res = this.mivzaGetValue(mNewData, miTableFilter);
            let itemsUse = [];


            let qtyToUse = x.qty - (x.qty % parseFloat(x.data.mtnKofel));
            let miTableSlice = miTable.filter(f => f.mivzaim.find(fm => fm.id == x.data.mId));
            miTableSlice.forEach((y) => {
                let iIndex = this.miTableFilter.findIndex(f => f.code === y.code);
                if (iIndex > -1 && itemsUse.indexOf(y.code) === -1) itemsUse.push(y.code);

                if (x.data.mIsMultiAllowed != '1') {

                    if (qtyToUse > 0 && y.qty > 0) {
                        let mivzaimToUpdate = this.miTableFilter[iIndex].mivzaim;
                        if (y.qty <= qtyToUse) {
                            this.miTableFilter[iIndex] = {
                                ...this.miTableFilter[iIndex],
                                qty: 0,
                                mivzaim: mivzaimToUpdate.map((ma) => ma.id === x.data.mId ? { ...ma, qty: y.qty } : ma)
                            }
                            qtyToUse -= y.qty;
                        } else {
                            this.miTableFilter[iIndex] = {
                                ...this.miTableFilter[iIndex],
                                qty: y.qty - qtyToUse,
                                mivzaim: mivzaimToUpdate.map((ma) => ma.id === x.data.mId ? { ...ma, qty: qtyToUse } : ma)
                            }
                            qtyToUse = 0;
                        }
                    }
                }
            });

            return { ...res, itemsUse: itemsUse };
        });

        // local mivza in cart

        mTable = mTable.filter(f => f.value).map((x) => {
            let lastItem = -1;
            x.itemsUse.forEach((y) => {
                let lastItemUpdate = cData.map(f => f.item.iCode).lastIndexOf(y);
                if (lastItem < lastItemUpdate) lastItem = lastItemUpdate;
            })

            return { ...x, lastItem }
        })

        // insert new price after discount

        let dCeil = (n) => Math.ceil(n * 100) / 100;

        this.miTableFilter = this.miTableFilter.map((mi) => {
            let discountValue = 0;
            let discountQty = 0;

            mi.mivzaim.forEach((mim) => {
                let mimMivzaData = mTable.find(f => f.data.mId === mim.id);
                let multiplier = 0;
                if (mimMivzaData) {
                    if (mimMivzaData.itemsUse.indexOf(mi.code) > -1) {
                        multiplier = mimMivzaData.data.mtId == '3' ?
                            parseFloat(mimMivzaData.data.mzBonusQuantity) / parseFloat(mimMivzaData.data.mtnKofel)
                            : 1
                    }
                }
                discountValue += mim.qty * mim.value * multiplier
                discountQty += mim.qty
            });
            let discountAvg = discountValue && discountQty ? (discountValue / discountQty) : 0
            return { ...mi, afterDiscount: dCeil(mi.price - discountAvg) }
        })

        this.props.setJson(this.mivzaim_cart_id, { data: mTable, itemsTable: this.miTableFilter })
        this.pointsLogic(cData, finishOrder);
        let contentCart = document.getElementById('contentCart');
        if (contentCart && !this.mobile) {
            contentCart.scrollTop = contentCart.scrollHeight;
        }
    }

    mivzaGetValue = (x, miTable) => {
        let dCeil = (n) => Math.ceil(n * 100) / 100;

        switch (x.data.mtId) {
            case '2':
                let mivzaSum = 0;
                let mivzaValue = 0;
                let mtnAmountFrom = parseFloat(x.data.mtnAmountFrom);
                let mtnAmountTo = parseFloat(x.data.mtnAmountTo);
                let mtnType = x.data.mtnType;
                let mSumField = mtnType == '1' ? x.qty : x.sum;

                if (mSumField >= mtnAmountFrom && mSumField <= mtnAmountTo) {
                    let mtnKofel = parseFloat(x.data.mtnKofel);
                    mSumField = mSumField - (mSumField % mtnKofel);

                    miTable.forEach((y, i) => {
                        let mInItem = y.mivzaim.find((z) => z.id === x.data.mId);
                        if (mInItem && dCeil(mSumField) > dCeil(mivzaSum)) {
                            let balance = (mSumField - mivzaSum);
                            let iSumField = (mtnType == '1' ? y.qty : y.qty * y.price);

                            if (balance >= iSumField) {
                                mivzaSum += iSumField;
                                mivzaValue += mInItem.value * y.qty;
                            } else {
                                let pQtySum = mtnType == '1' ? 1 : y.price;
                                let pQtybalance = y.stepQty == 1 ? Math.ceil(balance / pQtySum) : balance / pQtySum;
                                mivzaSum += iSumField;
                                mivzaValue += mInItem.value * pQtybalance;
                            }

                        }
                    });
                    return { ...x, value: /* dCeil(*/mivzaValue/*) */ }
                } else {
                    return x;
                }

            case '3':
                let mtnKofel = parseFloat(x.data.mtnKofel);
                let mzBonusQuantity = parseFloat(x.data.mzBonusQuantity);
                let mGroupSkip = mtnKofel - mzBonusQuantity;

                this.mivzaValue = 0;
                this.mivzaGroupValue = 0;
                this.mivzaGroupSkipSum = 0;
                this.mivzaGroupZhutSum = 0;

                miTable.forEach((y, i) => {
                    let mInItem = y.mivzaim.find((z) => z.id === x.data.mId);
                    if (mInItem) {
                        let iToGroup = { qty: y.qty, value: mInItem.value };
                        this.mivzaimGroup(mGroupSkip, mzBonusQuantity, iToGroup);
                    }
                });

                return { ...x, value: dCeil(this.mivzaValue) }
        }
    }

    pointsLogic = (cartData, finishOrder) => {
        // let cartData = this.props.data[this.cart_id].data;
        let mivzaimCartItems = this.props.data[this.mivzaim_cart_id].itemsTable;

        const {
            TotalPointsForUse,
            MinForUse,
            PointUsePercent,
            PointValue,
            TagsForNotUsingPoints,
            UseDecimalPoints,
            UseOnDiscount
        } = this.props.data[this.points_id];

        let sumToPoints = 0;
        cartData.forEach((x) => {
            let priceToSum = parseFloat(x.item.iPrice) * parseFloat(x.item.iQty ? x.item.iQty : 1)
            let pItem = mivzaimCartItems ? mivzaimCartItems.find(f => f.code === x.item.iCode) : null;
            let discountPrecent = pItem ? pItem.afterDiscount / pItem.price : 1;
            let priceToSumPoints = (priceToSum * discountPrecent) / parseFloat(PointValue);
            let includeToPoints = true;
            let pUse = UseOnDiscount ? parseInt(UseOnDiscount) / 100 : 0;
            if (pUse < (1 - discountPrecent)) {
                includeToPoints = false;
            }
            if (TagsForNotUsingPoints) {
                TagsForNotUsingPoints.split(',').forEach((y) => {
                    if (x.item.tagList && x.item.tagList.split(',').indexOf(y) > -1) {
                        includeToPoints = false;
                    }
                })
            }
            if (pUse && pItem && pItem.price - (pItem.price * pUse) > pItem.afterDiscount) {
                includeToPoints = false;
            }

            if (includeToPoints) sumToPoints += priceToSumPoints;
        })

        let pointsForThisSale = 0;
        sumToPoints = parseFloat(sumToPoints) * (parseFloat(PointUsePercent) / 100);
        if (sumToPoints >= parseFloat(MinForUse)) {
            let stp = sumToPoints < parseFloat(TotalPointsForUse) ? sumToPoints : parseFloat(TotalPointsForUse)
            pointsForThisSale = UseDecimalPoints == '1' ? parseFloat(stp) : parseInt(stp)
        }

        this.props.setJson(this.points_id, { pointsForThisSale: pointsForThisSale });

        if (finishOrder) {
            this.changeScreen(2)
        }
    }



    regiCodeOk = (_phone) => {
        let _name = this.props.data[this.id].orderFields.dName;
        let ec = (e) => {
            message.error(e);
            this.errorCallback(e)
        }

        this.props.sendAPI(this.api.guest_cust, "_name\f_phone\r" + _name + "\f" + _phone, (ob) => {
            let updateStore = () => {
                this.props.setJson(this.id + '.orderFields', { custId: ob.data.split('\r')[1] });
                this.setState({ menuId: 2 });
            }
            if (this.isToUpdateRegiCustomer) {
                this.props.sendAPI(this.api.update_regi, ob.data, updateStore, ec);
                this.isToUpdateRegiCustomer = false;
            } else {
                updateStore()
            }
        }, ec);
    }

    regiCodeToCheck = () => {
        if (this.isFromPos) {
            let pSumTwoFactorInPos = this.props.data[this.setting_id].pSumTwoFactorInPos;
            let notRegiCodeFromPos = parseFloat(pSumTwoFactorInPos) && (this.getSumPrices() - parseFloat(pSumTwoFactorInPos)) < 0 ? true : false;

            if (notRegiCodeFromPos) {
                this.regiCodeOk(this.props.data[this.id].orderFields.dPhone)
            } else {
                this.setState({ modalRegiCodeToPos: true })
            }
        } else {
            this.regiCodeToCheck2();
        }
    }

    regiCodeToCheck2 = () => {
        let _mobile = this.props.data[this.id].orderFields.dPhone;
        /////////////////
        // if (window.location.origin === "http://localhost:3000") {
        //     this.regiCodeOk(_mobile); return;
        // }
        ///////////////////////////////
        this.setState({ loadingCodeSend: true }, () => {

            this.props.sendAPI(this.api.regi_code, "_mobile\f_is_reset\r" + _mobile + "\f0", (ob) => {
                this.isToUpdateRegiCustomer = true;
                console.log('ob.data ', ob.data)
                switch (ob.data) {
                    case '1': this.setState({ modalRegiCode: true, mobileToRegiCode: _mobile }); break;
                    case '2': this.regiCodeOk(_mobile); break;
                    default: console.error('No Data...', this.api.regi_code, "_mobile\f_is_reset\r" + _mobile + "\f0")
                }
                this.setState({ loadingCodeSend: false })
            }, (e) => {
                this.errorCallback(e)
                this.setState({ loadingCodeSend: false })
            })
        })
    }

    getSumPrices = () => {
        let cartData = this.props.data[this.cart_id].data;
        let sumPrices = 0;
        let addToPrices = (sp) => { sumPrices = parseFloat(parseFloat(sumPrices) + sp).toFixed(2) }
        cartData.forEach((x) => { addToPrices(parseFloat(x.item.iPrice) * parseFloat(x.item.iQty ? x.item.iQty : 1)) })
        return sumPrices;
    }

    changeScreen = (num) => {
        this.setState({
            mobileMenu: false,
            successSale: false,
            cardcomUrl: false,
            lowprofilecode: false,
            DealResponse: false,
            InternalDealNumber: false,
            paymentTypeCheck: false,
            paymentType: null,
            paymentSum: null,
            paymentBalance: null,
            paymentTotal: null,
        }, () => {
            if (this.state.menuId === 3) {
                this.apiOrders(this.state.loginPhone, 1)
            }

            let pMinimum = this.props.data[this.setting_id].pMinimum;
            let nullCart = !this.props.data[this.cart_id].data[0] ? true : false;
            let sumDiscount = 0;
            if (this.isFromPos) {
                this.props.data[this.cart_id].data.filter(f => f.item.isDiscountItem).forEach(x => {
                    sumDiscount += x.item.iPrice;
                })
            }
            console.log('sumDiscount', this.getSumPrices(), sumDiscount, parseFloat(pMinimum))
            // let lessMinimum = parseFloat(pMinimum) && (parseFloat(this.getSumPrices()) + sumDiscount - parseFloat(pMinimum)) < 0.005 ? true : false;
            let lessMinimum = parseFloat(pMinimum) && (parseFloat(this.getSumPrices()) - parseFloat(pMinimum)) < 0.005 ? true : false;
            let isGuest = !this.state.loginPhone;
            let guestTag = this.props.data[this.setting_id].pGuestTag;
            let ofData = this.props.data[this.id];
            let f = ofData.orderFields;

            if (num === 2 || num === 3) {

                if (nullCart || lessMinimum) {
                    this.setState({ modalNullCart: true })
                } else if (!parseInt(ofData.orderType)) {
                    let dataset = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset
                    let orderFieldsModal = (dataset.length === 1) ? dataset[0].code : "0"
                    this.setState({ orderFieldsModal, nextRegi: num, cartFullScreen: false })
                } else if (!f.dName || !f.dPhone || (ofData.futureOrder && (!f.futureTime || !f.futureDate))) {
                    this.setState({ orderFieldsModal: ofData.orderType, nextRegi: num, cartFullScreen: false })
                } else if (this.props.data[this.setting_id].pDeliveryCalcType == 1 && (parseFloat(this.getSumPrices()) - parseFloat(f.dAreaMinimum)) < -0.005) {
                    message.error(this.getText(19760) + " " + f.dAreaMinimum + "₪");
                    setTimeout(() => {
                        this.setState({ orderFieldsModal: ofData.orderType, cartFullScreen: false })
                    }, 1000);
                } else if (num === 2) {
                    if (!guestTag && isGuest) {
                        this.regiModal(false);
                    } else {
                        let fDate = f.futureDate ? f.futureDate : "";
                        let fTime = f.futureTime ? f.futureTime : "";
                        let dataSend = '_branch\f_method\f_date\f_time\r' + f.dBranch + "\f" + ofData.orderType + "\f" + fDate + "\f" + fTime;

                        this.sendAPI(this.api.check_method, dataSend, (ob) => {
                            if (ob.data == '1') this.regiCodeToCheck();
                            else this.setState({ modalBlockBranchMethod: true })
                        }, this.errorCallback)
                    }
                } else {
                    if (isGuest) {
                        this.regiModal(false);
                    } else {
                        this.setState({ menuId: 3, nextRegi: 1 });
                    }
                }
            } else {
                this.setState({ menuId: num })
            }
        })
    }

    getStandartPage = (title, content) => {
        let cData = this.props.data[this.comp_id];
        let siteSettings = this.props.data[this.setting_id];
        let styleType = siteSettings.pDesktopCategoriesView;


        return (<div style={{ minHeight: styleType == '6' ? window.innerHeight - 210 : "auto" }}>
            {styleType == '6' ? ""
                // this.getClassNameStyle("sale_title", { background: null, color: "#ddc268", fontWeight: "bold", fontSize: 40 }, this.getText(title))
                : this.getClassNameStyle("sale_title", { marginTop: 0 }, this.getText(title))}
            <div
                className={this.mobile && (styleType == '4' || styleType == '5' || styleType == '6') ? "" : "standart_page"}
                style={{
                    background: styleType == '6' ? null : '#404041',
                    paddingBottom: styleType == '6' ? 20 : "auto",
                    paddingTop: styleType == '6' ? 0 : "auto",
                }}>
                <div
                    id={styleType == '6' ? "page_scroll_style_6" : ""}
                    className={this.getFontStyle2(this.mobile && (styleType == '4' || styleType == '5' || styleType == '6') ? "" : "dinamic_box")}
                    style={styleType == '6' ? { maxHeight: window.innerHeight - 230, overflowY: 'scroll' } : {}}>
                    {styleType == '6' ? ""
                        : styleType == '2' || styleType == '4' || styleType == '5' ?
                            (<div id="slider_pic">
                                <img alt="slider_pic" src={this.imgUrl(siteSettings.pImageTop)} width="100%" />
                            </div>)
                            : (<h1 style={{ textAlign: 'center' }}>
                                <img src={cData.logo} alt={'Logo'} title={cData.name} />
                                <br />
                                {cData.name + (cData.desc ? " - " + cData.desc : "")}
                            </h1>)}
                    {content}
                </div>
            </div>
        </div>);
    }

    getBranchesPage = (title) => {
        return;
    }

    getSplitHtml = (text) => {
        return text ? (<div dangerouslySetInnerHTML={{ __html: text }} />) : '';
    }

    getBoldText = (field) => {
        let text = this.props.data[this.comp_id][field];
        return text ? (<h2 style={{ textAlign: 'center' }}><b>{text}</b></h2>) : '';
    }

    // apiCaptshaCheck = (onSuccess) => {
    //     let value = this.state.recaptshaHash;
    //     console.log('value: ', value);
    //     this.sendAPI(this.api.recaptsha_verify, value, (ob) => {
    //         if (ob.data == '1') {
    //             onSuccess();
    //         }
    //     });

    //     if (value) {
    //         let xmlHttp = new XMLHttpRequest();

    //         xmlHttp.onload = () => {
    //             if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
    //                 console.log('yuda xmlHttp', xmlHttp.getAllResponseHeaders(), '  --end')
    //                 // onSuccess() 
    //             }
    //         }

    //         xmlHttp.open("POST",
    //             "https://www.google.com/recaptcha/api/siteverify?secret=6Ldp6qUZAAAAAGyG0FNcG8r3NQw2qYsjn642hspu&response=" + value,
    //             true);
    //         xmlHttp.setRequestHeader('sec-fetch-site', 'same-origin');
    //         // xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    //         // xmlHttp.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost:3000');
    //         xmlHttp.send();
    //     }
    // }

    genericProps = () => {
        return {
            ...this.props,
            getText: this.getText,
            isFromPos: this.isFromPos,
            getClassNameStyle: this.getClassNameStyle,
            getFontStyle2: this.getFontStyle2,
            errorCallback: this.errorCallback,
            changeScreen: this.changeScreen,
            toggleMobileCartView: this.toggleMobileCartView,
            regiModal: this.regiModal,
            mivzaimLogic: this.mivzaimLogic,
            getPiecesText: this.getPiecesText,
            getSumExtraPrices: this.getSumExtraPrices,
            apiPoints: this.apiPoints,
            webViewChangeMethod: this.webViewChangeMethod,
            changeModal: this.changeModalType,
            mobileValidate: this.mobileValidate,
            webViewDiscountInlinePermission: this.state.webViewDiscountInlinePermission,
            mobileCartView: this.state.mobileCartView,
            minimizeCart: this.state.minimizeCart,
            cartFullScreen: this.state.cartFullScreen,
            isLogin: this.state.loginName ? true : false,
            ownerSave: this.state.ownerSave,
            ownerUpdate: (x) => { this.setState({ ownerSave: x }) },
            minimizeCartOn: () => { this.setState({ minimizeCart: true }) },
            cartFullScreenChange: () => { this.setState({ cartFullScreen: !this.state.cartFullScreen }) },
        }
    }

    getScreen = () => {

        let oData = this.props.data[this.id];
        let cData = this.props.data[this.comp_id];
        let saveScreen = false;
        let siteSettings = this.props.data[this.setting_id];
        let styleType = siteSettings.pDesktopCategoriesView

        let buttonStyle = {
            background: (styleType == '4') ? "#BE1E2D" : styleType == '5' ? "orange" : styleType == '6' ? "#ddc268" : siteSettings.rPrimaryButtons,
            color: (styleType == '4' || styleType == '5') ? "white" : styleType == '6' ? "black" : siteSettings.rFonts
        }

        let genericProps = { ...this.genericProps(), buttonStyle }

        switch (this.state.menuId) {
            case 0:
                let delTypes = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset;
                let delIcon = (type) => {
                    switch (type) {
                        case 'delivery': return (<div style={{ paddingTop: 24 }}><div id="d_method_icon_delivery" /></div>);
                        case 'takeaway': return (<div style={{ paddingTop: 16 }}><div id="d_method_icon_takeaway" /></div>);
                        case 'sitting': return (<div style={{ paddingTop: 13 }}><div id="d_method_icon_sitting" /></div>);
                        default: return '';
                    }
                }



                return (<div>
                    {styleType == '2' && !this.isFromPos ?
                        (<div id="slider_pic">
                            <img alt="slider_pic" src={this.imgUrl(siteSettings.pImageTop)} width="100%" />
                        </div>)
                        : ''}
                    {(styleType == '4' || styleType == '5' || styleType == '6') ? ""
                        // : styleType == '6' ? this.getClassNameStyle("sale_title", { background: null, color: "#ddc268", fontWeight: "bold", fontSize: 40 }, this.getText(13040))
                        : this.getClassNameStyle("sale_title", { marginTop: 0 }, this.getText(13040))}
                    <div
                        style={{
                            padding: this.mobile ? '5%' : 31,
                            paddingBottom: 70,
                            textAlign: 'center',
                            background: styleType == '6' ? 'auto'
                                : styleType == '3' ? "#e6e7e8"
                                    : (styleType == '4' || styleType == '5') ? "url(" + this.imgUrl(siteSettings.pImageTop) + ")"
                                        : '#404041',
                            backgroundSize: (styleType == '4' || styleType == '5' || styleType == '6') ? 'cover' : "auto",
                            minHeight: (styleType == '4' || styleType == '5') ? window.innerHeight - 220
                                : styleType == '6' ? window.innerHeight - 210
                                    : "auto"
                        }}>
                        {delTypes.map((x, i) => {
                            if (styleType == '4' || styleType == '5' || styleType == '6') {
                                return (<div
                                    onClick={() => { this.changeModalType(x.code) }}
                                    style={{
                                        background: styleType == '6' ? "#ddc268" : "#BE1E2D",
                                        color: styleType == '6' ? "black" : "white",
                                        cursor: "pointer",
                                        display: "inline-block",
                                        margin: 20,
                                        marginTop: i ? 20 : 200,
                                        width: 300,
                                        fontSize: 28,
                                        padding: 8,
                                        borderRadius: 20,
                                    }} >{x.name}</div>)
                            }
                            return (<div className={this.getFontStyle2("delivery_method_box")}>
                                {x.image ?
                                    (<img
                                        src={this.imgUrl(x.image)}
                                        style={{ width: this.mobile ? '60%' : '80%', cursor: 'pointer' }}
                                        onClick={() => { this.changeModalType(x.code) }}
                                    />) :
                                    (<div
                                        className={this.getFontStyle2("delivery_method_card")}
                                        onClick={() => { this.changeModalType(x.code) }}>
                                        <div className={"delivery_method_circle"}>{delIcon(x.type)}</div>
                                        <div style={{ backgroundColor: x.code === oData.orderType ? '#f2b819' : '#ffffff' }}>{x.name}</div>
                                    </div>)}
                            </div>)
                        })}
                    </div>
                </div>);

            case 1: case 8: case 9:
                let isModalOpen = false;
                if (this.state.orderFieldsModal) isModalOpen = true;
                if (this.state.registration) isModalOpen = true;
                if (this.state.modalNullCart) isModalOpen = true;
                if (this.state.modalNotOpen) isModalOpen = true;
                if (this.state.modalPrevOrders) isModalOpen = true;
                if (this.state.modalUpdatePriceList) isModalOpen = true;
                if (this.state.modalBlockBranchMethod) isModalOpen = true;
                if (this.state.modalRegiCode) isModalOpen = true;
                if (this.state.modalRegiCodeToPos) isModalOpen = true;
                if (this.state.modalMessageSent) isModalOpen = true;
                if (this.state.modalNotItemsInPos) isModalOpen = true;
                if (this.state.modalCouponCustList) isModalOpen = true;

                const openModalCouponCustList = styleType == '6' && this.props.data[this.coupon_cust_id].dataset?.filter(f => f.errorCode == 0).length ? () => { this.setState({ modalCouponCustList: true }) } : null

                return (<FoodsFormatMain
                    {...genericProps}
                    messagesView1={this.state.messagesView1}
                    messagesView2={this.state.messagesView2}
                    messagesView3={this.state.messagesView3}
                    isModalOpen={isModalOpen}
                    isMivzaimPage={this.state.menuId == 9}
                    isStyle3={styleType == '3'}
                    isStyle6={styleType == '6'}
                    changeToShopScreen={() => { this.changeScreen(1) }}
                    openModalCouponCustList={openModalCouponCustList}
                />);
            case 3:
                saveScreen = this.state.nextRegi;
            case 2:

                return (
                    <FoodOrderPayments {...genericProps}
                        saveScreen={saveScreen}
                        isView={this.state.menuId === 2 ? true : false}
                        nextRegi={(n) => { this.setState({ nextRegi: n }) }}
                        notValidAddress={this.state.notValidAddress}
                        setStateRoot={(data, next) => { this.setState(data, next) }}
                        couponCustList={styleType == '6' ? this.props.data[this.coupon_cust_id].dataset?.filter(f => f.errorCode == 0) : null}
                    />)
            case 4:
                return (<FoodOrderBranches {...genericProps} />)
            case 5:
                return this.getStandartPage(13890, this.getSplitHtml(cData.term));
            case 6:
                return this.getStandartPage(13891, this.getSplitHtml(cData.about));
            case 11:
                return this.getStandartPage(18734, this.getSplitHtml(siteSettings.pPrivacyStatementHtml));
            case 7: case 10:
                let inputContactAs = (text, key) => {
                    let mobile = window.innerWidth <= 700 ? true : false;
                    let style = { width: '95%', height: 50, marginRight: 20, textAlign: 'left' }
                    let value = this.state.contactAs[key]
                    let onChange = (v) => { this.setState({ contactAs: { ...this.state.contactAs, [key]: v.target.value } }) }
                    let onFocus = (e) => { e.target.select() }

                    if (isStyle6) {
                        let props = {
                            className: this.getFontStyle2("text_item"),
                            id: key,
                            style: {
                                textAlign: "right",
                                width: key !== 'message' ? "48%" : "97%",
                                height: key !== 'message' ? 50 : 150,
                                marginTop: 5,
                                marginRight: "1%",
                                borderRadius: 30,
                            },
                            value, onChange, onFocus,
                            placeholder: this.getText(text)
                        }
                        return key !== 'message' ? (<Input {...props} />) : (<Input.TextArea {...props} />)

                    } else {

                        return (<Row style={{ textAlign: 'right', marginBottom: 30 }}>
                            <Col span={mobile ? 24 : 4} className={this.getFontStyle2("text_item")}>
                                <span style={{ color: 'red' }}> * </span>
                                {this.getText(text)}
                            </Col>
                            <Col span={mobile ? 24 : 20}>
                                {key !== 'message' ?
                                    (<Input
                                        className={this.getFontStyle2("text_item")} id={key} style={style}
                                        value={value} onChange={onChange} onFocus={onFocus} />)
                                    : (<Input.TextArea
                                        className={this.getFontStyle2("text_item")} id={key} style={{ ...style, height: 200 }}
                                        value={value} onChange={onChange} onFocus={onFocus} />)}
                            </Col>
                        </Row>)
                    }
                }

                let titlePage = this.state.menuId == 7 ? 13892 : 18644;
                let startMessage = this.state.menuId == 7 ? 'גולש מהאתר יצר קשר' : 'גולש מהאתר מתעניין בזכיינות';
                let isStyle4 = styleType == '4';
                let isStyle5 = styleType == '5';
                let isStyle6 = styleType == '6';
                let topTexts = isStyle4 || isStyle5 || isStyle6 ? [this.getSplitHtml(siteSettings[this.state.menuId == 7 ? 'pContactAsHtml' : 'pContactAsFranchiseHtml'])]
                    : [
                        this.getBoldText('address1'),
                        this.getBoldText('address2'),
                        this.getBoldText('address3'),
                        this.getBoldText('telephone'),
                    ]

                return this.getStandartPage(titlePage, [
                    ...topTexts,
                    <div style={{ background: "#f1f1f1", padding: 20 }}>
                        {inputContactAs(13893, 'name')}
                        {inputContactAs(13894, 'phone')}
                        {inputContactAs(19550, 'city')}
                        {inputContactAs(13895, 'email')}
                        {inputContactAs(13896, 'message')}
                        <ReCAPTCHA
                            sitekey="6Ldp6qUZAAAAAK4u5ONAqeuaWrIRAj0rmQ6rsT6x"
                            onChange={(value) => {
                                console.log("Captcha value:", value);
                                this.setState({ recaptshaHash: value });
                            }}
                        />

                        <Button
                            disabled={!this.state.recaptshaHash}
                            style={{
                                margin: 10,
                                width: (isStyle4 || isStyle5) ? "100%" : isStyle6 ? "97%" : 140,
                                height: 50,
                                borderRadius: isStyle6 ? 30 : 0,
                                ...buttonStyle
                            }}
                            onClick={() => {
                                let s = this.state.contactAs;

                                if (!s.name) {
                                    message.error(this.getText(13898));
                                } else if (!this.mobileValidate(s.phone)) {
                                    message.error(this.getText(13899));
                                } else if (!this.mobileValidate(s.city)) {
                                    message.error(this.getText(19551));
                                } else if (!this.emailValidate(s.email)) {
                                    message.error(this.getText(13900));
                                } else if (!s.message) {
                                    message.error(this.getText(13901));
                                } else if (!this.state.recaptshaHash) {
                                    message.error(this.getText(13901));
                                } else {
                                    // this.apiCaptshaCheck(() => {
                                    let fullMessage = startMessage +
                                        '\n\nשם:\n' + s.name +
                                        '\n\nטלפון:\n' + s.phone +
                                        '\n\nעיר:\n' + s.city +
                                        '\n\nדוא"ל:\n' + s.email +
                                        '\n\nהודעה:\n' + s.message;

                                    let recaptshaHash = this.state.recaptshaHash ? this.state.recaptshaHash : '';

                                    let dataSend = '_msg\f_recaptsha\r' + fullMessage + '\f' + recaptshaHash;

                                    this.sendAPI(this.api.send_contact_as, dataSend, (ob) => {

                                        console.log('contactAs: ', this.state.contactAs, fullMessage);
                                        if (ob.data) this.setState({ modalMessageSent: true });
                                    }, this.errorCallback)
                                    // });
                                }
                            }}>
                            <span className={this.getFontStyle2("text_item")} style={buttonStyle}>{this.getText(13897)}</span>
                        </Button>
                    </div>
                ]);

        }
    }

    toggleMobileCartView = () => {
        this.setState({ mobileCartView: this.state.mobileCartView ? false : true })
    }

    menuScreen = () => {

        let dMethods = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset;
        let orderType = this.props.data[this.id].orderType;
        let dMethodChange = dMethods.find(f => f.code === orderType);

        let dMethodName = dMethodChange ? dMethodChange.name : '';

        let prevOldNumber = this.props.data[this.prev_id].data.filter(f => f.split('\f')[5] === '0').length;

        let menuContent = [];

        const { pDesktopCategoriesView, pLogoHeader } = this.props.data[this.setting_id];
        const { loginPhone, loginName, loginImage, menuId, mobileMenu } = this.state;

        let isStyle2 = pDesktopCategoriesView == '2';
        let isStyle3 = pDesktopCategoriesView == '3';
        let isStyle4 = pDesktopCategoriesView == '4';
        let isStyle5 = pDesktopCategoriesView == '5';
        let isStyle6 = pDesktopCategoriesView == '6';

        let rNavbar1 = this.props.data[this.setting_id].rNavbar1;
        let background = rNavbar1 ? rNavbar1 : isStyle2 ? 'black' : 'auto';
        let color = (isStyle2 && !rNavbar1) || isStyle3 ? 'white' : 'auto';

        let btnStyle = {
            background: isStyle4 || isStyle5 ? undefined : isStyle6 ? "white" : background,
            color: isStyle4 || isStyle5 || isStyle6 ? this.mobile || isStyle6 ? "black" : "white" : color,
            fontSize: isStyle2 ? 20 : isStyle3 || isStyle4 || isStyle5 || isStyle6 ? 18 : 23,
            fontFamily: isStyle3 || isStyle4 || isStyle5 || isStyle6 ? "FbTipograf" : "auto",
            borderRight: isStyle3 ? "1px solid white" : "auto",
            borderLeft: isStyle3 ? "1px solid white" : "auto",
            width: (isStyle4 || isStyle5 || isStyle6) && !this.mobile ? 110 : "auto",
            height: isStyle4 || isStyle5 || isStyle6 ? 30 : "auto",
            padding: isStyle4 || isStyle5 || isStyle6 ? 2 : "auto",
            marginTop: (isStyle4 || isStyle5 || isStyle6) && !this.mobile ? 5 : "auto",
            marginRight: isStyle6 && !this.mobile ? 5 : "auto",
            borderRadius: (isStyle4 || isStyle5 || isStyle6) && !this.mobile ? 10 : "auto",
            display: (isStyle4 || isStyle5 || isStyle6) ? "inline-block" : "auto",
            verticalAlign: (isStyle4 || isStyle5 || isStyle6) ? "text-top" : "auto",
            cursor: (isStyle4 || isStyle5 || isStyle6) ? "pointer" : "auto",
            textAlign: (isStyle4 || isStyle5 || isStyle6) && !this.mobile ? "center" : "auto",

        }

        let btnStyleActive = {
            fontSize: isStyle2 || isStyle4 || isStyle5 || isStyle6 ? 20 : 23,
            color: isStyle3 ? "white" : isStyle4 || isStyle6 ? "black" : isStyle5 ? "#443325" : "auto",
            background: isStyle4 ? "#ddc268" : isStyle6 ? "#ddc268" : "orange",
            fontFamily: isStyle3 || isStyle4 || isStyle5 || isStyle6 ? "FbTipograf" : "auto",
            fontWeight: isStyle3 ? "bold" : "auto",
            borderRadius: (isStyle4 || isStyle5) && !this.mobile ? 10 : "auto",
            width: (isStyle4 || isStyle5 || isStyle6) && !this.mobile ? 110 : "auto",
            height: (isStyle4 || isStyle5) ? 40 : isStyle6 ? 30 : "auto",
            padding: (isStyle4 || isStyle5 || isStyle6) ? 1 : "auto",
            textAlign: (isStyle4 || isStyle5 || isStyle6) && this.mobile ? "right" : "auto",
            marginTop: (isStyle4 || isStyle5 || isStyle6) && !this.mobile ? 5 : "auto",
            marginRight: (isStyle5 || isStyle6) && !this.mobile ? 5 : "auto",
            display: (isStyle4 || isStyle5 || isStyle6) ? "inline-block" : "auto",
            verticalAlign: (isStyle4 || isStyle5 || isStyle6) ? "text-top" : "auto",
        }

        let dataMenu = [
            { text: 13040, screen: 0 },
            { text: 18482, screen: 9 },
            { text: 13041, screen: 1 },
            { text: 13042, screen: 2 },
            { text: 13416, screen: 3 },
            { text: 15582, screen: 4 },
            { text: 13890, screen: 5 },
            { text: 13891, screen: 6 },
            { text: 18644, screen: 10 },
            { text: 13892, screen: 7 },
        ];

        if (this.isFromPos) {
            dataMenu = dataMenu.filter(f => f.screen <= 3)
        } else if (isStyle3) {
            dataMenu = dataMenu.filter(f => [0, 9, 1, 2, 3, 7].indexOf(f.screen) > -1)
        } else if (isStyle4 || isStyle6) {
            dataMenu = dataMenu.filter(f => [0, 1, 9, 4, 6, 10, 7].indexOf(f.screen) > -1)
        } else if (isStyle5) {
            dataMenu = dataMenu.filter(f => [0, 1, 9, 4, 6, 7].indexOf(f.screen) > -1)
        } else {
            dataMenu = dataMenu.filter(f => f.screen <= 7)
        }


        dataMenu.forEach(x => {
            let isActive = x.screen === menuId;
            let onClick = () => { this.changeScreen(x.screen) }
            let content = (<div style={{ width: '100%' }}>
                {this.getText(x.text)}
                {x.screen === 0 ? (<div style={{ fontSize: 12, marginTop: -10 }}>{dMethodName}</div>) : ''}
            </div>)

            menuContent.push(isActive || isStyle5 ?
                this.getClassNameStyle("menu_button_active", btnStyleActive, content, onClick)
                : (< div style={btnStyle} className={isStyle4 || isStyle6 ? "" : this.getFontStyle2("menu_button")} onClick={onClick}>{content}</div >))
        });

        let prevOrdersVisible = false;
        if (this.isFromPos) {
            let cID = this.props.data[this.id].orderFields.cID;
            let pPOSDefaultCustomerId = this.props.data[this.setting_id].pPOSDefaultCustomerId;
            if (cID != pPOSDefaultCustomerId && cID > 0) prevOrdersVisible = true;
        } else {
            if (loginPhone) prevOrdersVisible = true;
        }

        if (prevOrdersVisible && this.props.data[this.prev_id].count && !isStyle3) {
            menuContent.push(
                <div
                    style={isStyle4 || isStyle5 || isStyle6 ? { ...btnStyle, position: "fixed", top: 0, left: 3, color: isStyle6 ? "#ddc268" : "#BE1E2D" } : btnStyle}
                    className={this.getFontStyle2("menu_button")}
                    onClick={() => { this.setState({ modalPrevOrders: true }) }}>
                    {this.getText(13402)}
                    {prevOldNumber ? (<div style={this.numberIconStyle}>
                        {prevOldNumber < 100 ? prevOldNumber : '99+'}
                    </div>) : ""}
                </div>)
        }

        if (isStyle4 || isStyle5 || isStyle6) {
            let className = isStyle4 ? "header_style_4" : isStyle5 ? "header_style_5" : ""

            const { telephone } = this.props.data[this.comp_id];

            let logoStyle = (<img
                style={{ width: isStyle6 ? 200 : 100, height: 100, margin: 20, marginBottom: 0 }}
                src={this.imgUrl(pLogoHeader)} />)

            let userIconStyle4 = (<div
                style={{
                    display: "inline-block",
                    verticalAlign: "text-top",
                    width: 40,
                    margin: 10,
                    marginLeft: this.mobile ? 10 : 30,
                    marginRight: this.mobile ? 10 : 30,
                    cursor: "pointer",
                }}>
                <span
                    onClick={() => {
                        this.regiModal(loginPhone ? true : false)
                    }}>
                    {loginImage ?
                        (<img alt="userImg" src={this.imgUrl(loginImage)} style={{ borderRadius: '50%' }} />)
                        : (<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.22 29.22"><g id="icon_connect"><g style={{ fill: isStyle4 || isStyle6 ? '#e6e6e6' : '#443325' }}><path class="cls-1" d="m14.61,0C6.55,0,0,6.55,0,14.61s6.55,14.61,14.61,14.61,14.61-6.55,14.61-14.61S22.66,0,14.61,0Zm0,1.22c7.38,0,13.39,6.01,13.39,13.39,0,2.19-.54,4.24-1.47,6.07-7.15-4.19-13.44-3.97-17.47-3.03-3.22.75-5.51,2.03-6.54,2.69-.83-1.74-1.3-3.68-1.3-5.73C1.22,7.23,7.23,1.22,14.61,1.22Zm0,26.78c-4.9,0-9.18-2.65-11.51-6.58.91-.59,3.15-1.87,6.3-2.6,5.53-1.27,11.09-.28,16.53,2.91-2.37,3.76-6.55,6.27-11.32,6.27Z" /><path class="cls-1" d="m14.4,15.43c3.38,0,6.12-2.75,6.12-6.12s-2.75-6.12-6.12-6.12-6.12,2.75-6.12,6.12,2.75,6.12,6.12,6.12Zm0-11.03c2.71,0,4.91,2.2,4.91,4.91s-2.2,4.91-4.91,4.91-4.91-2.2-4.91-4.91,2.2-4.91,4.91-4.91Z" /></g></g></svg>)}
                    <div style={{ color: isStyle4 || isStyle6 ? "white" : "#443325", fontSize: 12, textAlign: "center", lineHeight: 0.5 }}>{loginName ? loginName : this.getText(13050)}</div>
                </span>
                {loginPhone ?
                    <div style={{
                        ...btnStyle,
                        background: isStyle4 ? "#BE1E2D" : isStyle6 ? "#ddc268" : "orange",
                        color: isStyle6 ? "black" : "white",
                        fontSize: 15,
                        width: 60,
                        padding: 5,
                        marginRight: -10
                    }} onClick={this.logout}>{this.getText(13049)}</div>
                    : ""}
            </div>)

            return (<div>
                <div className={"desctop_block " + className} style={{ backgroundPositionY: -35 }}>
                    <div>
                        <div style={{ cursor: "pointer", display: "inline-block", width: isStyle6 ? 230 : 130 }} onClick={this.onClickLogo}>{logoStyle}</div>
                        <div style={{ display: "inline-block", width: window.innerWidth - (isStyle6 ? 600 : 500) }} >{menuContent}</div>
                        <div style={{ float: "left", paddingTop: 20 }}>
                            <a href={'tel:' + telephone}>
                                <div id={"telephone"} style={{ color: "#ddc268", padding: 10, fontSize: 40 }}>{telephone}</div>
                            </a>
                            <span style={isStyle6 ? { display: "inline-block", marginLeft: 10 } : {}}>
                                {userIconStyle4}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"mobile_block " + className} style={{ backgroundPositionY: -12, height: 130 }}>

                    <Row>
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <div style={{ width: 40, margin: 20 }} onClick={() => {
                                this.setState({ mobileMenu: true }, () => {
                                    setTimeout(() => {
                                        this.mobileMenuIsOpen = true;
                                    }, 1000);
                                })
                            }} >
                                <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20"><g id="nav_icon" ><g style={{ fill: isStyle4 || isStyle6 ? '#e6e6e6' : '#443325' }}><rect class="cls-1" width="30" height="3.78" /><rect class="cls-1" y="8.11" width="30" height="3.78" /><rect class="cls-1" y="16.22" width="30" height="3.78" /></g></g></svg>
                            </div>
                        </Col>
                        <Col span={8} style={{ textAlign: 'center' }}>
                            {logoStyle}
                        </Col>
                        <Col span={8} style={{ textAlign: 'left' }}>
                            {userIconStyle4}
                        </Col>
                    </Row>
                    {mobileMenu ?
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                height: 330,
                                width: 200,
                                background: "white",
                                zIndex: 5000,
                                borderBottomLeftRadius: 30,
                                padding: 10,
                                border: "1px solid #eeeeee"
                            }}>
                            <div style={{ textAlign: 'left' }}>
                                <a href={'tel:' + telephone}>
                                    <div id={"telephone"} style={{ color: "red", fontSize: 22, fontWeight: "bold" }}>{telephone}</div>
                                </a>
                            </div>
                            <hr style={{ margin: 0 }} />
                            {menuContent.map(x => [x, <hr style={{ margin: 0 }} />])}
                        </div>
                        : ""}
                </div>
            </div >)

        } else {

            return (<div>
                <div className={"desctop_block"} style={{ background, color }}>
                    {menuContent}
                </div>
                <div className={"mobile_block"}>
                    <div className={"mobile_menu_button"} style={{ textAlign: 'center' }}>
                        <Icon type={'menu'} onClick={() => { this.setState({ mobileMenu: mobileMenu ? false : true }) }} />
                    </div>
                    <div className={"mobile_menu_div"}>
                        {mobileMenu ? menuContent : menuContent[menuId]}
                    </div>
                </div>
            </div >)
        }
    }

    regiModal = (rgiForm) => {
        const { orderFields } = this.props.data[this.id];
        let isStyle3 = this.props.data[this.setting_id].pDesktopCategoriesView == '3';
        let isCustEdit = orderFields && orderFields.cID ? true : false;
        this.setState({
            registration: !(isCustEdit && isStyle3),
            modalCustData: (isCustEdit && isStyle3),
            rgiForm
        })
    }

    login = (name, img, cMobile, cID, page) => {
        let nextRegi = page || page === 0 ? page : this.state.nextRegi ? this.state.nextRegi : this.state.menuId
        this.setState({
            loginId: cID,
            loginName: name,
            loginImage: img,
            loginPhone: cMobile,
            registration: false,
            menuId: nextRegi,
        }, () => {

            let orderFields = this.props.data[this.id].orderFields;

            this.apiOrders(cMobile, 1)
            this.apiCoupnCustList(cID, orderFields.dPosNumber)
            this.apiPoints(cID);
            if (this.props.webPusherSetAttributes) this.props.webPusherSetAttributes([{ key: '_customerId', value: cID }]);
        });
    }

    logout = () => {
        this.setState({
            menuId: 1,
            loginId: '',
            loginPhone: '',
            loginName: '',
            loginImage: '',
            // registration: true, // דניאל ביקש להוריד 23.07.2023
            registration: this.props.data[this.setting_id].pStartPage == "LoginRequired", // דניאל ביקש לשנות 30.08.2023
        }, () => {
            this.props.setJson(this.id, {
                orderFields: {},
                deliveryCost: null,
                orderType: null
            })
            this.apiCoupnCustList()
        })
    }

    errorCallback = (error) => {
        console.error(error);
        if (error === 'Unrecognized Caller or User' && window.Android && window.Android.tokenExpired) {
            window.Android.tokenExpired()
        }
    }

    apiCoupnCustList = (cust, pos) => {
        console.log("login run ", { cust, pos })
        if (!cust) {
            this.props.setJson(this.coupon_cust_id, { dataset: [] })
        } else {

            this.props.sendAPI("get_new_coupons_per_cust_to_site", "_pos\f_cust\r" + (pos ?? "") + "\f" + cust, ob => {
                if (ob.data) {
                    let headers = []
                    const dataset = ob.data.split("\r").map((x, index) => {
                        let y = x.split("\f")
                        if (index == 0) {
                            headers = y
                        } else {
                            let obj = { key: y[0], index }
                            headers.forEach((h, i) => { obj[h] = y[i] })
                            return obj
                        }
                    }).filter(f => f)
                    this.props.setJson(this.coupon_cust_id, { dataset })
                }
            }, this.errorCallback)
        }
    }

    apiOrders = (cMobile, page, all) => {
        let rows = all ? '9999' : '10';
        this.props.sendAPI(this.api.get_prev, "cMobile\f_page_num\f_rows_num\fpage\frecordsPerPage\r" + cMobile + '\f' + page + '\f' + rows + '\f' + page + '\f' + rows, (ob) => {
            let res = ob.data.split("\r");
            let headers = res[0].split("\f");
            let data = res.slice(1, -1);
            let count = parseInt(res.slice(-1)[0]);
            let pagination = {
                current: page,
                defaultCurrent: 1,
                defaultPageSize: 10,
                pageSize: 10,
                total: count
            }
            this.props.setJson(this.prev_id, { data, headers, count, pagination });
        }, this.errorCallback)
    }

    apiBranches = () => {

        let dataSend = "_lat\f_long\f_method\r";
        let fd = this.props.data.formData;
        let siteSettings = this.props.data[this.setting_id];

        if (fd && fd.custLocation && fd.custLocation.latitude && fd.custLocation.longitude && !this.isFromPos) {
            dataSend += fd.custLocation.latitude + '\f' + fd.custLocation.longitude + '\f'
        } else {
            dataSend += '\f\f';
        }

        let _method = this.state.orderFieldsModal;
        if (_method) dataSend += _method;

        this.sendAPI(this.api.branches, dataSend, (ob) => {
            if (ob.data) {
                let bOpen = false;
                let of = this.props.data[this.id].orderFields;
                let dBranch = "";
                let dPosNumber = "";
                let dPriceList = "";
                let bAcceptNow = null;
                let bAcceptHour = null;
                if (of.dUserCheck) {
                    dBranch = of.dBranch;
                    dPosNumber = of.dPosNumber;
                    dPriceList = of.dPriceList;
                    bAcceptNow = of.bAcceptNow;
                    bAcceptHour = of.bAcceptHour;
                }

                let dataset = ob.data.split("\r").slice(1, -1).map((x) => {
                    let y = x.split("\f");
                    if (y[5] === '1' || this.isFromPos) bOpen = true;
                    if ((y[5] === '1' || this.isFromPos) && this.firstChangeBranch) {
                        if (!dBranch) {
                            dBranch = y[0];
                            dPosNumber = y[13];
                            dPriceList = y[14];
                            bAcceptNow = y[18];
                            bAcceptHour = y[19];
                        }
                    }
                    return {
                        code: y[0],
                        name: y[1],
                        address: y[2],
                        radius: y[3],
                        ps: y[4],
                        isOpen: this.isFromPos ? '1' : y[5],
                        prepareTime: y[6],
                        posNumber: y[13],
                        priceList: y[14],
                        closeHour: y[15],
                        bAcceptNow: y[18],
                        bAcceptHour: y[19],
                    }
                });

                if (this.isFromPos && siteSettings.pBranchSelectInPos != '1') dataset = [dataset.find(f => f.code == dBranch)]

                if (!bOpen) {
                    if (!this.modalNotOpenIsUsed) {
                        this.modalNotOpenIsUsed = true;
                        this.setState({ modalNotOpen: true });
                    }
                } else {
                    this.setState({ changeBranch: dBranch }, () => {
                        this.firstChangeBranch = true;
                        this.props.setJson(this.id + ".orderFields", { ...this.props.data[this.id].orderFields, dBranch, dPosNumber, dPriceList, bAcceptNow, bAcceptHour });
                    })
                }

                if (dBranch && !dataset.find(f => f.code === dBranch)) {
                    this.props.setJson(this.id + ".orderFields", { dBranch: '', dPosNumber: '', dPriceList: '' });

                }
                this.props.setJson(this.branches_id, { dataset })
                if (this.isFromPos) setTimeout(this.apiSettings, 500);
            }
        }, this.errorCallback);
    }

    apiSettings = () => {
        let _branch = this.isFromPos && this.props.data[this.id].orderFields.dBranch ? this.props.data[this.id].orderFields.dBranch : 0;

        this.sendAPI(this.api.get_settings, "_branch\r" + _branch, (ob) => {
            if (ob.data) {
                let parseData = ob.data.split("\r");
                let keys = parseData[0] ? parseData[0].split("\f") : [];
                let values = parseData[1] ? parseData[1].split("\f") : [];
                let sObj = {};
                keys.forEach((x, i) => { sObj = { ...sObj, [x]: values[i] } })
                this.props.setJson(this.setting_id, sObj);
                this.replaceBanners([
                    sObj.pBanner1,
                    sObj.pBanner2,
                    sObj.pBanner3,
                    sObj.pBanner4,
                    sObj.pBanner5,
                    sObj.pBanner6,
                    sObj.pBanner7
                ].filter(f => f));

                if (this.isFromPos) {
                    if (sObj.pDiscountItem || sObj.pDiscountItemTag) {
                        this.sendAPI(this.api.get_discount, "", (ob) => {
                            let dataset = []
                            if (ob.data) {
                                dataset = ob.data.split('\r').map(x => {
                                    let y = x.split('\f')
                                    return { code: y[0], name: y[1] }
                                })
                            }
                            this.props.setJson(this.discount_id, { dataset })
                        });
                    }
                } else {
                    switch (sObj.pStartPage) {
                        case 'OrderType': this.changeScreen(0); break;
                        case 'Login': case 'LoginRequired': setTimeout(() => { this.regiModal(false) }, 2000); break;
                    }
                }

                if (sObj.pDeliveryCalcType == '1') {
                    this.setState({ notValidAddress: true })
                }
            }
        }, this.errorCallback);
    }

    onClickLogo = () => {
        const { pStartPage } = this.props.data[this.setting_id];
        if (pStartPage == 'OrderType') {
            this.changeScreen(0);
        } else {
            this.changeScreen(1);
            if (pStartPage == 'OrderType') {
                setTimeout(() => { this.regiModal(false) }, 2000);
            }
        }
    }

    apiMivzaim = (cID, posNumber) => {
        if (!cID) cID = "";
        if (!posNumber) posNumber = "";

        this.props.sendAPI(this.api.get_mivzaim, "cID\fposNumber\r" + cID + "\f" + posNumber, (ob) => {
            if (ob.data) {
                let x = ob.data.split('\r');
                let headers = x ? x[0].split('\f') : [];
                let mivzaimData = x.slice(1).map((m) => {
                    let obj = {}
                    let y = m.split('\f');
                    headers.forEach((k, i) => {
                        obj = { ...obj, [k]: y[i] }
                    });
                    return obj;
                });
                this.props.setJson(this.mivzaim_id, { data: mivzaimData });
                console.log('apimivzaim', new Date())
            }
        }, this.errorCallback)
    }

    apiTexts = () => {
        this.sendAPI(this.api.get_texts, "lang\r" + this.props.lang, (ob) => {
            if (ob.data) {
                let parseData = ob.data.split("\r").slice(1, -1);
                let obj = {}
                parseData.forEach((x) => {
                    let y = x.split('\f');
                    obj = { ...obj, ['t' + y[0]]: y[2] ? y[2] : y[1] }
                });

                this.props.setJson(this.texts_id, obj);
            }
        }, this.errorCallback);
    }

    apiPoints = (cID) => {
        if (this.props.data[this.setting_id].pIsCustPoints === '1') {
            this.sendAPI(this.api.get_points, cID, (ob) => {
                if (ob.data) {
                    let x = ob.data.split("\r");
                    let kyes = x[0].split('\f');
                    let values = x[1].split('\f');
                    let obj = {}
                    kyes.forEach((k, i) => { obj = { ...obj, [k]: values[i] } });
                    this.props.setJson(this.points_id, obj);
                    this.pointsLogic(this.props.data[this.cart_id].data);
                }
            }, this.errorCallback);
        }
    }

    smallModal = (state, content, onClick, buttonText) => {
        let siteSettings = this.props.data[this.setting_id];
        let isStyle4 = siteSettings.pDesktopCategoriesView == 4
        let isStyle5 = siteSettings.pDesktopCategoriesView == 5
        let isStyle6 = siteSettings.pDesktopCategoriesView == 6

        let buttonsStyle = {
            margin: 10,
            width: 140,
            height: 50,
            background: isStyle4 ? "#BE1E2D" : isStyle6 ? "#ddc268" : isStyle5 ? "orange" : siteSettings.rPrimaryButtons,
        }

        return (<Modal
            className={isStyle4 || isStyle5 || isStyle6 ? "padding_0" : ""}
            zIndex={10000}
            title={this.getClassNameStyle("title_block", { background: isStyle4 ? "#BE1E2D" : isStyle6 ? "#ddc268" : "auto" }, "")}
            visible={this.state[state]} onCancel={() => this.setState({ [state]: false })} destroyOnClose={true} width={300}
            footer={[
                <Button style={buttonsStyle} onClick={onClick}>
                    <span className={this.getFontStyle2(this.getFontStyle2("text_item"))} style={{ color: isStyle4 || isStyle5 || isStyle6 ? "white" : siteSettings.rFonts }}>{this.getText(buttonText ? buttonText : 13006)}</span>
                </Button>
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >{content}</Modal>)
    }

    modalNullCart = () => {
        let pMinimum = this.props.data[this.setting_id].pMinimum;
        let nullCart = !this.props.data[this.cart_id].data[0] ? true : false;
        let textView = nullCart ? this.getText(13111) : this.getText(13530) + ': ' + pMinimum + ' ₪';
        return this.smallModal(
            'modalNullCart',
            (<div className={this.getFontStyle2("text_item")}>
                <h2 style={{ textAlign: 'center' }}>{textView}</h2>
            </div>),
            () => { this.setState({ menuId: 1, modalNullCart: false, mobileCartView: false }) });
    }


    modalNotItemsInPos = () => {
        let siteSettings = this.props.data[this.setting_id];
        let isStyle4 = siteSettings.pDesktopCategoriesView == 4
        let isStyle5 = siteSettings.pDesktopCategoriesView == 5
        let isStyle6 = siteSettings.pDesktopCategoriesView == 6

        let buttonsStyle = {
            margin: 10,
            width: 140,
            height: 50,
            background: isStyle4 ? "#BE1E2D" : isStyle6 ? "#ddc268" : isStyle5 ? "orange" : siteSettings.rPrimaryButtons,
        }


        const onClick = (remove) => {
            const cData = this.props.data[this.cart_id].data;
            const ccData = cData.map(x => { return { ...x, notInPos: this.state.modalNotItemsInPos?.find(f => f.code == x.item.iCode) ? true : false } })
            this.setState({ menuId: 1, modalNotItemsInPos: null, mobileCartView: false }, () => { this.props.setJson(this.cart_id, { data: remove ? ccData.filter(f => !f.notInPos) : ccData }) })
        }

        return (<Modal
            className={isStyle4 || isStyle5 || isStyle6 ? "padding_0" : ""}
            zIndex={10000}
            title={this.getClassNameStyle("title_block", { background: isStyle4 ? "#BE1E2D" : isStyle6 ? "#ddc268" : "auto" }, "")}
            visible={this.state.modalNotItemsInPos} onCancel={() => { onClick(false) }} destroyOnClose={true} width={600}
            footer={[
                <Button style={buttonsStyle} onClick={() => { onClick(true) }}>
                    <span className={this.getFontStyle2(this.getFontStyle2("text_item"))} style={{ color: isStyle4 || isStyle5 || isStyle6 ? "white" : siteSettings.rFonts }}>
                        {this.getText(19872)}
                    </span>
                </Button>,
                <Button style={buttonsStyle} onClick={() => { onClick(false) }}>
                    <span className={this.getFontStyle2(this.getFontStyle2("text_item"))} style={{ color: isStyle4 || isStyle5 || isStyle6 ? "white" : siteSettings.rFonts }}>
                        {this.getText(19873)}
                    </span>
                </Button>
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <div className={this.getFontStyle2("text_item")}>
                <h2>{this.getText(19874)}</h2>
                {this.state.modalNotItemsInPos ? this.state.modalNotItemsInPos.map(x => <h3>{x.name}</h3>) : ""}
            </div>
        </Modal>)
    }

    modalCouponCustList = () => {

        let columns = [
            { title: this.getText(19894), key: "nCode", dataIndex: "nCode", width: "25%" },
            { title: this.getText(19895), key: "nDescription", dataIndex: "nDescription", width: "50%", render: (t, r) => [r.nName, <br />, t] },
            { title: this.getText(19896), key: "nValidDateTimeEnd", dataIndex: "nValidDateTimeEnd", width: "25%", render: t => <div dir="ltr">{moment(t).format("DD/MM/YYYY")}</div> },
        ]

        return (<Modal
            className={"padding_0"}
            zIndex={10000}
            title={this.getClassNameStyle("title_block", { background: "#ddc268" }, this.getText(19893))}
            visible={this.state.modalCouponCustList} onCancel={() => { this.setState({ modalCouponCustList: false }) }} destroyOnClose={true} width={800}
            footer={false}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <ResponsiveTable
                tableOnly
                dataSource={this.props.data[this.coupon_cust_id].dataset.filter(f => f.errorCode == 0)}
                columns={columns}
            />

        </Modal>)
    }

    modalNotOpen = () => {
        return this.smallModal(
            'modalNotOpen',
            (<div className={this.getFontStyle2("text_item")}>
                <h2 style={{ textAlign: 'center' }}>{this.getText(13563)}</h2>
                <h2 style={{ textAlign: 'center' }}>{this.getText(13564)}</h2>
            </div>),
            () => { this.setState({ modalNotOpen: false }) });
    }

    modalUpdatePriceList = () => {
        return this.isFromPos ? "" : this.smallModal(
            'modalUpdatePriceList',
            (<div className={this.getFontStyle2(this.getFontStyle2("text_item"))}>
                <h2 style={{ textAlign: 'center' }}>{this.getText(13563)}</h2>
                <h2 style={{ textAlign: 'center' }}>{this.getText(14316)}</h2>
            </div>),
            () => { this.setState({ modalUpdatePriceList: false }) },
            14317);
    }

    modalMessageSent = () => {
        return this.smallModal(
            'modalMessageSent',
            (<div className={this.getFontStyle2("text_item")}>
                <h2 style={{ textAlign: 'center' }}>{this.getText(13902)}</h2>
                <h2 style={{ textAlign: 'center' }}>{this.getText(13903)}</h2>
            </div>),
            () => { this.setState({ modalMessageSent: false }, () => { this.changeScreen(1) }) });
    }

    modalBlockBranchMethod = () => {
        let bCode = this.props.data[this.id].orderFields.dBranch;
        let bData = this.props.data[this.branches_id].dataset.find(f => f && f.code === bCode);
        let dBranch = bData ? bData.name : "";
        let mCode = this.props.data[this.id].orderType;
        let mData = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset.find(f => f.code === mCode);
        let dMethod = mData ? mData.name : "";

        return this.smallModal(
            'modalBlockBranchMethod',
            (<div className={this.getFontStyle2("text_item")}>
                <h2 style={{ textAlign: 'center' }}>{this.getText(15588)} {dBranch} {this.getText(15589)}{dMethod}</h2>
            </div>),
            () => { this.setState({ modalBlockBranchMethod: false }, () => { this.changeScreen(4) }) },
            15590);
    }

    modalMessageView = () => {
        let siteSettings = this.props.data[this.setting_id];

        let buttonsStyle = {
            margin: 10,
            width: 140,
            height: 50,
            background: siteSettings.rPrimaryButtons,
        }

        let visible = this.state.messagesView[0] ? this.state.messagesView[0].id : null;
        let content = this.state.messagesView[0] ? this.state.messagesView[0].content : null;
        let title = this.state.messagesView[0] ? this.state.messagesView[0].desc : '';

        let onCancel = () => { this.setState({ messagesView: this.state.messagesView.slice(1) }) }

        return content ? (<FoodFormatMessage
            visible={visible} content={content} onCancel={onCancel}
            title={this.getClassNameStyle("title_block", {}, title)}
            footer={[<Button style={buttonsStyle} onClick={onCancel}>
                <span className={this.getFontStyle2("text_item")} style={{ color: siteSettings.rFonts }}>
                    {this.getText(13419)}
                </span>
            </Button>]}
            sendAPI={this.sendAPI}
            errorCallback={this.errorCallback}
        />) : ''
    }

    modalRegiCodeToPos = () => {
        let siteSettings = this.props.data[this.setting_id];

        let buttonsStyle = {
            margin: 10,
            width: 140,
            height: 50,
            background: siteSettings.rPrimaryButtons,
        }

        let bm = (t, oc) => {
            return (<Button style={buttonsStyle} onClick={() => { this.setState({ modalRegiCodeToPos: false }, oc) }}>
                <span className={this.getFontStyle2("text_item")} style={{ color: siteSettings.rFonts }}>{this.getText(t)}</span>
            </Button>)
        }

        return (<Modal
            zIndex={10000}
            title={this.getClassNameStyle("title_block", {}, "")}
            visible={this.state.modalRegiCodeToPos}
            onCancel={() => { this.setState({ modalRegiCodeToPos: false }) }}
            width={500}
            footer={[
                bm(15392, this.regiCodeToCheck2),
                bm(15393, () => {
                    console.log('test webview lazi load 2', moment())

                    this.regiCodeOk(this.props.data[this.id].orderFields.dPhone)
                })
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >
            <div className={this.getFontStyle2("text_item")}><h2 style={{ textAlign: 'center' }}>{this.getText(15391)}</h2> </div>
        </Modal>)
    }

    imgUrl = (x) => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        const baseUrl = isDev ? "http://147.235.163.248" : origin

        return baseUrl + "/uploads/" + this.props.companyCode + "/" + x;
    }
    mobileValidate = (mobile) => {
        var patt = new RegExp(/^05\d([-]{0,1})\d{7}$/);
        return patt.test(mobile);
    }

    emailValidate = (email) => {
        var patt = new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
        return patt.test(email);
    }

    changeModalType = (type, branch) => {
        this.setState({ orderFieldsModal: type, cartFullScreen: false }, () => {
            if (!this.isFromPos) {
                let m = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset.find(f => f.code === type);

                if (m && m.needAddress == 1) {
                    this.props.setJson(this.branches_id, { dataset: [] });
                    // this.props.setJson(this.id + ".orderFields", { dBranch: '' });

                } else {
                    this.apiBranches();
                }

                if (branch) {
                    this.setState({ changeBranch: branch })

                }
            }
        })
    }

    replaceBanners = (bannersList) => {
        let l = bannersList.length;
        let nextIndex = 0;
        if (l > 0) {
            if (this.state.bannerUrl) {
                let curIndex = bannersList.findIndex(f => f === this.state.bannerUrl);
                if (curIndex > -1 && (l - curIndex) > 1) nextIndex = curIndex + 1;
            }
            this.setState({ bannerUrl: bannersList[nextIndex] }, () => {
                // if (this.props.data[this.setting_id].pDesktopCategoriesView == 4) {
                //     let rootDiv = document.getElementById("main_root_div");
                //     if (rootDiv) {
                //         rootDiv.style.backgroundImage = "url(" + this.imgUrl(bannersList[nextIndex]) + ")";
                //         rootDiv.style.backgroundSize = "cover";
                //     }
                // }
                setTimeout(() => { this.replaceBanners(bannersList) }, 10000);
            })
        }
    }

    spinView = (content) => {
        return (<div style={{ paddingTop: window.innerHeight / 3, textAlign: 'center' }}>{content}<br /><br /><Spin /></div>)
    }

    onScrollUpdatePizzaView = () => {
        let pizzaView = document.getElementById('pizza-view');
        if (pizzaView) {
            let positions = pizzaView.getBoundingClientRect();
            let top = positions.top;
            if (top !== this.state.pizzaViewTop) this.setState({ pizzaViewTop: top })
        }
        //    
    }

    piecesText = (r1, r2, r3, r4) => {
        if (r1 && r2 && r3 && r4) {
            return ''
        } else if (!r1 && !r2 && !r3 && !r4) {
            return ''
        } else if (r1 && r2 && !r3 && !r4) {
            return this.getText(15560)
        } else if (!r1 && !r2 && r3 && r4) {
            return this.getText(15561)
        } else {
            return [r1, r2, r3, r4].map((x, i) => {
                switch (i) {
                    case 0: return x ? this.getText(15562) : '';
                    case 1: return x ? this.getText(15563) : '';
                    case 2: return x ? this.getText(15564) : '';
                    case 3: return x ? this.getText(15565) : '';
                }
            }).filter(f => f).join(', ');
        }
    }

    getPiecesText = (localList, areasQty) => {
        let res = { r1: false, r2: false, r3: false, r4: false }

        if (localList) {
            switch (areasQty) {
                case '1':
                    if (localList.indexOf(1) > -1) res = { r1: true, r2: true, r3: true, r4: true }
                    break;

                case '2':
                    if (localList.indexOf(1) > -1) res = { ...res, r1: true, r2: true }
                    if (localList.indexOf(2) > -1) res = { ...res, r3: true, r4: true }
                    break;

                case '4':
                    if (localList.indexOf(1) > -1) res = { ...res, r1: true }
                    if (localList.indexOf(2) > -1) res = { ...res, r2: true }
                    if (localList.indexOf(3) > -1) res = { ...res, r3: true }
                    if (localList.indexOf(4) > -1) res = { ...res, r4: true }
                    break;
            }
        }

        return this.piecesText(res.r1, res.r2, res.r3, res.r4);
    }

    getSumExtraPrices = (list, pizzaPieces) => {
        console.log('pPizzaLogicDetailed start')
        let sumPrices = 0;
        let filterList = list.map(x => { return { ...x, ePrice: parseFloat(x.ePrice) } }).filter(f => f);

        if (pizzaPieces) {
            let pPizzaLogic = this.props.data[this.setting_id].pPizzaLogic;
            switch (pPizzaLogic) {

                case '2':
                    let pricesList = [];
                    filterList.forEach((e) => {
                        let qty = e.eLocals && e.eLocals.length ? e.eLocals.length / pizzaPieces : 1;
                        if (qty == 0.5) pricesList.push(e.ePrice);
                        else sumPrices += e.ePrice * qty;
                    });
                    pricesList.sort((a, b) => b - a).forEach((e, i) => { if (i % 2 == 0) sumPrices += e })
                    break;


                case '3':
                    let sumQty = 0;
                    let sumPriceUpdated = false;
                    let pPizzaLogicDetailed = this.props.data[this.setting_id].pPizzaLogicDetailed;

                    filterList.forEach((e) => {
                        let qty = e.eLocals && e.eLocals.length ? e.eLocals.length / pizzaPieces : 1;
                        sumQty += qty;
                    });

                    let pricesData = pPizzaLogicDetailed ?
                        pPizzaLogicDetailed.split('&').map(x => x.split('=').map(y => parseFloat(y))).sort((a, b) => a[0] - b[0])
                        : [];

                    if (sumQty > 0) {
                        pricesData.forEach(x => {
                            if (!sumPriceUpdated && x[0] >= sumQty) {
                                sumPriceUpdated = true;
                                sumPrices += x[1];
                            }
                        });
                        if (!sumPriceUpdated) {
                            sumPriceUpdated = true;
                            sumPrices += pricesData.slice(-1)[0][1];
                        }
                    }
                    break;

                default:
                    filterList.forEach((e) => {
                        let qty = e.eLocals && e.eLocals.length ? e.eLocals.length / pizzaPieces : 1;
                        sumPrices += e.ePrice * qty;
                    });
            }

        } else {
            filterList.forEach((e) => { sumPrices += e.ePrice });
        }

        return sumPrices;
    }

    checkToken = () => {
        if (this.tokenRefresh) {
            window.onbeforeunload = null;
            window.location.reload();
        }
    }

    getDataSections = () => {

        let siteSettings = this.props.data[this.setting_id];

        return [
            { group: 1, name: "compLogo", value: siteSettings.pLogoHeader, style: { width: "10%", paddingTop: 20 }, mobileStyle: { width: "20%", paddingTop: 20, height: 30 }, order: 1, mobileOrder: 1, active: true, mobileActive: true },
            { group: 1, name: "compTelIcon", value: "tel_icon_2", style: { width: "5%", paddingTop: 40 }, mobileStyle: {}, order: 2, mobileOrder: 0, active: true, mobileActive: false },
            { group: 1, name: "compTel", value: "03-934-5813", style: { width: "60%", paddingTop: 50 }, mobileStyle: {}, order: 3, mobileOrder: 0, active: true, mobileActive: false },
            { group: 1, name: "logoutButton", value: "התנתק", style: { width: "10%", paddingTop: 50 }, mobileStyle: { width: "50%", paddingTop: 30 }, order: 4, mobileOrder: 4, active: true, mobileActive: true },
            { group: 1, name: "guestText", value: "התחבר / הרשם", style: { width: "10%", paddingTop: 45 }, mobileStyle: { width: "30%", paddingTop: 30 }, order: 5, mobileOrder: 5, active: true, mobileActive: true },
            { group: 1, name: "userProfile", value: "knisa_icon_2", style: { width: "5%", paddingTop: 45 }, mobileStyle: { width: "20%", paddingTop: 30 }, order: 6, mobileOrder: 6, active: true, mobileActive: true },

            { group: 2, name: "menu_methodType", value: this.getText(13040), style: {}, mobileStyle: {}, order: 1, mobileOrder: 1, active: true, mobileActive: true },
            { group: 2, name: "menu_shop", value: this.getText(13041), style: {}, mobileStyle: {}, order: 2, mobileOrder: 2, active: true, mobileActive: true },
            { group: 2, name: "menu_endOrder", value: this.getText(13042), style: {}, mobileStyle: {}, order: 3, mobileOrder: 3, active: true, mobileActive: true },
            { group: 2, name: "menu_saveOrder", value: this.getText(13416), style: {}, mobileStyle: {}, order: 4, mobileOrder: 4, active: true, mobileActive: true },
            { group: 2, name: "menu_contactAs", value: this.getText(13892), style: {}, mobileStyle: {}, order: 8, mobileOrder: 8, active: true, mobileActive: true },
            { group: 2, name: "menu_prevOrders", value: this.getText(13402), style: {}, mobileStyle: {}, order: 9, mobileOrder: 9, active: true, mobileActive: true },
        ]
    }

    custDataModal = () => {
        let siteSettings = this.props.data[this.setting_id];
        let buttonStyle = { background: siteSettings.rPrimaryButtons, color: siteSettings.rFonts }

        let custMenu = [
            { id: 1, text: 18492, style: { background: "silver", borderTopLeftRadius: 30 } },
            { id: 2, text: 18493, style: { background: "gainsboro" } },
            { id: 3, text: 18494, style: { background: "silver", borderBottomLeftRadius: 30 } },
        ]

        let custMenuStyle = {
            height: 46,
            fontSize: 22,
            fontFamily: "FbTipograf",
            textAlign: "center",
            cursor: "pointer",
            paddingTop: 5,
        }

        return (<Modal
            visible={this.state.modalCustData}
            onCancel={() => { this.setState({ modalCustData: false }) }}
            width={1000}
            className={"padding_0"}
            title={this.getClassNameStyle("title_block", {}, this.getText(18491))}
            footer={false}>

            <Row style={{ background: "#E6E7E8" }}>
                <Col span={5}>
                    <div style={{ width: "90%", marginTop: 10 }}>
                        {custMenu.map(x => {
                            return (<Row
                                style={{ ...custMenuStyle, ...x.style }}
                                onClick={() => {
                                    this.setState({ custMenuType: x.id })
                                }}>
                                <Col span={4}>{x.id === this.state.custMenuType ? (<img src={star_icon} />) : ""}</Col>
                                <Col span={20}>{this.getText(x.text)}</Col>
                            </Row>)
                        })}
                    </div>
                </Col>
                <Col span={19}>
                    {this.state.custMenuType == 1 ?
                        (<CustomerRegistration
                            {...this.genericProps()}
                            buttonStyle={buttonStyle}
                            login={this.login}
                            visible={this.state.modalCustData}
                            onCancel={() => { }}
                            apiMivzaim={this.apiMivzaim}
                            rgiForm={this.state.rgiForm}
                            getLocationAgainText={this.getText(15528)}
                            isCustEdit
                            notValidAddress={this.state.notValidAddress}
                            onChangeValidAddress={notValidAddress => { this.setState({ notValidAddress }) }}
                        />)
                        : this.state.custMenuType == 2 ?
                            (<PrevOrders
                                {...this.genericProps()}
                                visible={this.state.modalCustData}
                                close={() => { }}
                                refrashData={(page) => { this.apiOrders(this.state.loginPhone, page) }}
                                buttonStyle={buttonStyle}
                                isStyle3
                            />)
                            : this.state.custMenuType == 3 ?
                                <PersonalInformationCustomer
                                    {...this.genericProps()}
                                    forms={this.props.data}
                                    formsActions={this.props.dataActions}
                                    isStyle3
                                    custID={this.props.data[this.id].orderFields.cID}
                                />
                                : ""}
                </Col>
            </Row>
        </Modal >)
    }

    headerStyleType4 = () => {
        return (<div>
            aaa
        </div>)
    }



    render() {
        let siteSettings = this.props.data[this.setting_id];
        let styleType = siteSettings.pDesktopCategoriesView
        let buttonStyle = { background: siteSettings.rPrimaryButtons, color: siteSettings.rFonts }
        if (styleType == '4') buttonStyle = { background: "#BE1E2D", color: "white" }
        if (styleType == '5') buttonStyle = { background: "orange", color: "#443325" }
        if (styleType == '6') buttonStyle = { background: "#ddc268", color: "black" }
        let telephone = this.props.data[this.comp_id].telephone;

        let verticalTopStyle = {}
        if (styleType == '2' && !this.mobile && this.state.menuId == 1 && !this.props.isBackOffice) {
            verticalTopStyle = { position: 'fixed', top: 0, width: '100%', zIndex: 1000 }
        }



        let headerStyle = (<FoodOrdersHeader
            {...this.props}
            isFromPos={this.isFromPos}
            logout={this.logout}
            loginPhone={this.state.loginPhone}
            loginName={this.state.loginName}
            loginImage={this.state.loginImage}
            menuId={this.state.menuId}
            telephone={telephone}
            siteSettings={siteSettings}
            orderData={this.props.data[this.id]}
            regiModal={this.regiModal}
            getClassNameStyle={this.getClassNameStyle}
            getText={this.getText}
            onClickLogo={this.onClickLogo}
        />)


        let footerStyle = this.isFromPos ? "" :
            (<FoodOrdersFooter
                {...this.props}
                menuId={this.state.menuId}
                telephone={telephone}
                siteSettings={siteSettings}
                getClassNameStyle={this.getClassNameStyle}
                getText={this.getText}
                companyData={this.props.data[this.comp_id]}
                changeScreen={this.changeScreen}
                footerNavbar={styleType == '3' ? [
                    { text: 18482, screen: 9 },
                    { text: 13041, screen: 1 },
                    { text: 13892, screen: 7 },
                ] : (styleType == '4' || styleType == '5') ? [
                    { text: 13890, screen: 5 },
                    { text: 18734, screen: 11 },
                    { text: 13892, screen: 7 },
                ] : (styleType == '6') ? [
                    { text: 13890, screen: 5 },
                    { text: 18734, screen: 11 },
                ] : []}
            />)

        let bunnersStyle = this.isFromPos || styleType == '3' ? "" :
            (<div style={{ background: siteSettings.rBanners }}>
                <div style={{
                    width: this.mobile || this.state.menuId != 1 ? "100%" : "70%",
                    padding: "1%",
                    paddingRight: styleType == '2' ? 365 : "1%"
                }}>
                    {this.state.bannerUrl ? (<img alt="banner_pic" src={this.imgUrl(this.state.bannerUrl)} style={{ width: '100%' }} />) : ""}
                </div>
            </div>)

        let isLoading = this.getText(13050)[0] === '[' ? true : false;

        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        if (isDev) origin += '/cash-on-tab/build';

        let cotLogo = (<img src={origin + "/img/logo.png"} style={{ display: isLoading ? "inline-block" : "none" }} />);
        let cartNumber = this.props.data[this.cart_id].data.length;




        return isLoading ? this.spinView(cotLogo) :
            (<div
                id="scrollVirtualShop"
                style={{ height: '100%', overflowY: 'scroll', direction: 'ltr' }}
                onScroll={this.onScrollUpdatePizzaView}
                onMouseDown={this.checkToken}
                onKeyDown={this.checkToken}
            >

                <AddToHomeScreen getText={this.getText} />
                {cotLogo}
                <div className={styleType == '6' ? "background_style_6" + (this.state.menuId === 2 ? "_pay" : "") : ""} style={{ backgroundColor: styleType == '6' ? "#ffffff" : (styleType == '4' || styleType == '5') ? "white" : '#e6e7e8', direction: 'rtl' }}>
                    {(styleType == '4' || styleType == '5' || styleType == '6') ?
                        this.menuScreen()
                        : [
                            headerStyle,
                            this.isFromPos ? '' : <SocialIconLinks {...this.props} />,
                            <div id="main_menu" style={this.props.isBackOffice ? verticalTopStyle : { ...verticalTopStyle, top: this.isFromPos ? 75 : 145, width: '100%', backgroundColor: '#e6e7e8', }}>
                                {this.menuScreen()}
                            </div>
                        ]
                    }

                    <div style={{ minHeight: styleType == '3' || styleType == '6' ? window.innerHeight - 270 : "auto" }}>
                        {this.state.loadingCodeSend ? this.spinView('') : (<div id="main_root_div">{this.getScreen()}</div>)}
                    </div>
                    {(styleType == '4' || styleType == '5' || styleType == '6') ? "" : bunnersStyle}
                    {footerStyle}
                    {(this.mobile || this.state.minimizeCart) && (this.state.menuId == 1 || this.state.menuId == 9) ?
                        this.getClassNameStyle("cart_icon_fixed_bottom", {}, [
                            <Icon type={!this.state.mobileCartView ? "shopping-cart" : "shop"} />,
                            cartNumber ?
                                (<div style={{ ...this.numberIconStyle, margin: 0, paddingRight: 5 }}>
                                    {cartNumber < 100 ? cartNumber : '99+'}
                                </div>) : "",
                            <div style={{ fontSize: 15, marginTop: -20 }}>{this.getText(!this.state.mobileCartView ? 15492 : 15493)}</div>,
                        ], () => {
                            if (this.mobile) {
                                if (!this.state.mobileCartView && !this.props.data[this.cart_id].data.length) {
                                    this.setState({ modalNullCart: true })
                                } else {
                                    this.setState({ mobileCartView: !this.state.mobileCartView }, () => {
                                        let svs = document.getElementById('scrollVirtualShop');
                                        svs.scrollTop = 0;
                                    })
                                }
                            } else {
                                this.setState({ minimizeCart: false })
                            }
                        })

                        : ''}
                    <DeliveryTypeFields
                        {...this.genericProps()}
                        modalData={this.state.orderFieldsModal}
                        changeBranch={this.state.changeBranch}
                        buttonStyle={buttonStyle}
                        apiBranches={this.apiBranches}
                        nextRegi={this.state.nextRegi}
                        regiCodeToCheck={this.regiCodeToCheck}
                        isGuest={!this.state.loginPhone}
                        getLocationAgainText={this.getText(15528)}
                        isLogin={this.state.loginPhone}
                        regiModal={this.regiModal}
                        notValidAddress={this.state.notValidAddress}
                        onChangeValidAddress={notValidAddress => { this.setState({ notValidAddress }) }}

                    />
                    <CustomerRegistration
                        {...this.genericProps()}
                        visible={this.state.registration}
                        onCancel={() => { this.setState({ registration: false }) }}
                        buttonStyle={buttonStyle}
                        login={this.login}
                        apiMivzaim={this.apiMivzaim}
                        rgiForm={this.state.rgiForm}
                        getLocationAgainText={this.getText(15528)}
                        isLoginRequired={siteSettings.pStartPage == 'LoginRequired'}
                        notValidAddress={this.state.notValidAddress}
                        onChangeValidAddress={notValidAddress => { this.setState({ notValidAddress }) }}

                    />
                    <PrevOrders
                        {...this.genericProps()}
                        visible={this.state.modalPrevOrders}
                        close={() => { this.setState({ modalPrevOrders: false }) }}
                        refrashData={(page) => { this.apiOrders(this.state.loginPhone, page) }}
                        buttonStyle={buttonStyle}
                    />
                    {this.custDataModal()}
                    <RegiCode
                        {...this.genericProps()}
                        visible={this.state.modalRegiCode}
                        onCancel={() => {
                            this.setState({
                                modalRegiCode: false,
                                mobileToRegiCode: "",
                                modalRegiCodeToPos: false,
                                regiCodeValue: "",
                                regiCodeError: false,
                                timeoutForNewCode: -1,
                                termToggle: false,
                                isTermOk: false,
                            })
                        }}
                        buttonStyle={buttonStyle}
                        mobileInput={this.state.mobileToRegiCode}
                        mobileOnChange={(e) => { this.setState({ mobileToRegiCode: e.target.value }) }}
                        regiCodeOk={this.regiCodeOk}
                    />
                    {this.modalNullCart()}
                    {this.modalNotOpen()}
                    {this.modalUpdatePriceList()}
                    {this.modalMessageSent()}
                    {this.modalMessageView()}
                    {this.modalRegiCodeToPos()}
                    {this.modalBlockBranchMethod()}
                    {this.modalNotItemsInPos()}
                    {this.modalCouponCustList()}
                </div>

            </div>)
    }
}

export default FoodOrdersIndex;