/* @flow */
/*jshint esversion: 6 */
import React from "react";
import { DatePicker, Card, Form, Select, Button, message, Radio, Checkbox, Row, Col, Input } from 'antd';
import PageToolbar from "../../components/PageToolbar";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import ReportFavoriteSave from "../../components/ReportFavoriteSave";
import moment from "moment";
import { disabledDatePicker } from "../../utils/utils";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import GenericModal from "../../components/GenericModal";
import ResponsiveTable from "../../components/ResponsiveTable";

const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const Option = Select.Option;

class PaymentsZReport extends React.Component/*<Props, State>*/ {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            radioValue: 0,
            baseReportName: "reports/DocumentPayments_",
            reportType: "pdf",

            modalZList: false,
            modalZListData: [],
            modalZListSearch: "",

            zRequest: {
                PosList: '',
                BranchesList: '',
                StartDate: moment().add(-30, "days").format("YYYY-MM-DD"),
                EndDate: moment().format("YYYY-MM-DD"),
                WithDetails: 0,
                zNumber: null,
                includePayItems: 0,
                reportName: "reports/DocumentPayments_pw.pdf"
            }
        }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorFilterBranchesPos] === undefined) {
            props.data[TableIDs.genericSelectorFilterBranchesPos] = { ...props.data.genericSelector };
        }

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.apiSelector(0, (list) => { this.props.dataActions.setJson(TableIDs.genericSelectorPos, { dataset: list }) });

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }
    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10196);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            let reportType = this.props.ui.favoriteParams['reportName'].split('.')[1];
            this.setState({ reportType }, () => {
                this.setReportParameters("zRequest", this.props.ui.favoriteParams);
            })

        }

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            params = this.getReportParams(params);
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, params]
            });
        }
    }

    getReportParams = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        if (params.PosList === '') params = { ...params, PosList: fullPosList }
        params = { ...params, REPORT_LOCALE: this.getText(101) }

        return params;
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    apiSelector = (num, next) => {
        let script = ["list_pos", "branches_filter_list"];
        this.sendAPI(script[num], "", (ob) => {
            let d = ob.data ? ob.data.split("\r") : [];
            let list = d.map((x) => {
                let y = x.split("\f");
                return num === 1 ? { code: y[0], name: y[1], filterList: y[2] } : { code: y[0], name: y[1] }
            })
            next(list);
        })
        // let user = new User(this.props.user.token, this.props.user.companyCode);
        // let job = new Job(user, script[num], OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        // job.send("/cgi-bin/CashOnTab", (ob) => {
        //     let d = ob.data ? ob.data.split("\r") : [];
        //     let list = d.map((x) => {
        //         let y = x.split("\f");
        //         return num === 1 ? { code: y[0], name: y[1], filterList: y[2] } : { code: y[0], name: y[1] }
        //     })
        //     next(list);
        // }, (e) => { message.error(e) });
    }

    apiZList = (dateStart, dateEnd, posList, number, next) => {
        this.sendAPI("get_z_list", "dateStart\fdateEnd\fposList\fnumber\r"
            + (dateStart ?? "") + "\f"
            + (dateEnd ?? "") + "\f"
            + (posList ?? "") + "\f"
            + (number ?? ""),
            ob => {
                next(ob.data ? ob.data.split("\r").map(x => {
                    let y = x.split("\f");
                    return { num: y[0], pos: y[1], posName: y[2], count: y[3], start: y[4], end: y[5] }
                }) : [])
            })
    }

    onBlurZInput = () => {
        const { zNumber } = this.state.zRequest;
        if (zNumber) {
            this.apiZList("", "", "", zNumber, list => {
                if (!list[0]) {
                    this.setReportParameters("zRequest", { zNumber: "" });
                    message.error(this.getText(19492))
                } else {
                    const { num, pos, start, end } = list[0];
                    this.setReportParameters("zRequest", { zNumber: num, PosList: pos, StartDate: start?.split(" ")[0], EndDate: end?.split(" ")[0] });
                }
            })
        }
    }



    filterBranchesSelector = (value) => {
        let dataset = this.props.data[TableIDs.genericSelectorFilterBranchesPos].dataset;
        let nList = new Set();
        dataset.map((x) => {
            if (value && value.indexOf(x.code) > -1) {
                x.filterList.split(",").map((y) => { nList.add(y) });
            }
        });
        this.setReportParameters("zRequest", {
            BranchesList: value.join(','),
            PosList: value[0] ? [...nList].join(",") : this.state.zRequest.PosList,
        });
        this.apiSelector(0, (l) => {
            let nPosDataset = value[0] ? l.filter((y) => nList.has(y.code)) : l;
            this.props.dataActions.setJson(TableIDs.genericSelectorPos, { dataset: nPosDataset })
        });
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        const headStyle = {
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 4,
            height: 56
        }

        const respoVidth = {
            width: ((window.innerWidth > 600) ? '60' : '100') + '%'
        }

        const respoCol = {
            columnWidth: 'auto',
            columnCount: '1',
            paddingTop: (window.innerWidth > 600) ? 0 : 50
        }

        let dStyle = (window.innerWidth > 600) ? {
            display: 'inline-block',
            width: '46%',
            padding: '2%',
            verticalAlign: 'text-top'
        } : {}

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={this.getText(12252)}
                    actionButtons={[]}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let FI = (l, c) => (<FormItem label={l}>{c}</FormItem>)

        let datePicker = (f, l, p) => {
            let value = this.state.zRequest[f]
            let minDate = f == 'EndDate' ? this.state.zRequest.StartDate : this.state.zRequest.EndDate ? moment(this.state.zRequest.EndDate, "YYYY-MM-DD").add(-1, "month").format("YYYY-MM-DD") : null;
            let maxDate = f == 'StartDate' ? this.state.zRequest.EndDate : this.state.zRequest.StartDate ? moment(this.state.zRequest.StartDate, "YYYY-MM-DD").add(1, "month").format("YYYY-MM-DD") : null;
            return FI(l, (<DatePicker
                format="DD/MM/YYYY"
                placeholder={p}
                disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                value={value ? moment(value, "YYYY-MM-DD") : null}
                onChange={(e) => { this.setReportParameters("zRequest", { [f]: e ? e.format("YYYY-MM-DD") : null }) }}
            />))
        }

        let selectType = (l) => {
            let repType = (t) => this.state.baseReportName + ((t === 'xls') ? "Excel.xls" : "pw." + t);
            let updateIn = (e) => {
                this.setReportParameters("zRequest", { reportName: repType(e) });
                this.setState({ reportType: e });
            }
            let optionTypes = [
                <Option key={"xls"}>{this.getText(12258)}</Option>,
                <Option key={"pdf"}>{this.getText(12257)}</Option>,
                <Option key={"docx"}>{this.getText(12259)}</Option>
            ];
            if (this.state.radioValue === 1) optionTypes = optionTypes[0];
            return FI(l, (<Select style={{ width: 250 }} value={this.state.reportType} onChange={updateIn} >
                {optionTypes}
            </Select>
            ))
        }

        let radioSelect = (titles) => {
            let updateIn = (e) => {
                let s = e.target.value;
                let rt = this.state.reportType;
                // let bn = s < 2 ? "reports/DocumentPayments_" : "reports/ZReportByPos";
                let bn = [
                    "reports/DocumentPayments_",
                    "reports/DynamicPaymentsReport",
                    "reports/ZReportByPos",
                ];

                this.setState({
                    radioValue: s,
                    baseReportName: bn[s],
                    reportType: s == 1 ? "xls" : rt
                }, () => {
                    console.table({ yuda: bn[s] + (s == 1 ? ".xls" : rt === 'xls' ? "Excel.xls" : "pw." + rt) })
                    this.setReportParameters("zRequest", {
                        reportName: bn[s] + (s == 1 ? ".xls" : rt === 'xls' ? "Excel.xls" : "pw." + rt),
                        // WithDetails: s === 1 ? 1 : 0,
                    });


                    if (s == 1) {
                        setTimeout(() => {
                            this.apiSelector(1, (list) => { this.props.dataActions.setJson(TableIDs.genericSelectorFilterBranchesPos, { dataset: list }) });
                            let bList = this.state.zRequest.BranchesList.split(",");
                            this.filterBranchesSelector(bList);
                        }, 500);
                    } else {
                        this.apiSelector(0, (list) => { this.props.dataActions.setJson(TableIDs.genericSelectorPos, { dataset: list }) });
                    }
                });
            }

            return (<span>
                <RadioGroup onChange={updateIn} value={this.state.radioValue}>
                    {titles.map((x, i) => (<div>
                        <Radio value={i}>{this.getText(x)}</Radio>
                        {this.state.radioValue < 2 && i === this.state.radioValue ?
                            (<div>
                                <Checkbox
                                    checked={this.state.zRequest.WithDetails == 1 ? true : false}
                                    onChange={(e) => { this.setReportParameters("zRequest", { WithDetails: e.target.checked ? 1 : 0 }) }}
                                />
                                {" " + this.getText(12992)}
                            </div>
                            ) : ""}
                        {this.state.radioValue == 0 && i == 0 ?
                            (<div>
                                <Checkbox
                                    checked={this.state.zRequest.includePayItems == 1 ? true : false}
                                    onChange={(e) => { this.setReportParameters("zRequest", { includePayItems: e.target.checked ? 1 : 0 }) }}
                                />
                                {" " + this.getText(19871)}
                            </div>
                            ) : ""}
                    </div>))}
                </RadioGroup>
            </span>);
        }

        let posSelector = () => {
            // let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            //     return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
            // });

            // return (<FormItem label={this.getText(12255)}>
            //     <Select key={"PosList"} style={{ width: '90%' }}
            //         showSearch filterOption={true} optionFilterProp="valuefilter" mode="multiple" maxTagCount={4}
            //         onChange={(value) => this.setReportParameters("zRequest", { PosList: value.join(',') })}
            //         value={(this.state.zRequest.PosList === '') ? [] : this.state.zRequest.PosList.split(',')}
            //     >
            //         {listPos}
            //     </Select>
            // </FormItem>
            // )

            return (<FormItem label={this.getText(12255)}>
                <GenericSelectorPosStorages {...this.props} multi
                    onChange={(value) => this.setReportParameters("zRequest", { PosList: value.join(',') })}
                    value={(this.state.zRequest.PosList === '') ? [] : this.state.zRequest.PosList.split(',')}
                />
            </FormItem>
            )
        }

        let branchesSelector = () => {
            let dataset = this.props.data[TableIDs.genericSelectorFilterBranchesPos].dataset;

            let list = dataset.map((obj, index) => {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
            });

            return (<FormItem label={this.getText(12991)}>
                <Select key={"BranchesList"} style={{ width: 250 }}
                    showSearch filterOption={true} optionFilterProp="valuefilter" mode="multiple" maxTagCount={4}
                    onChange={this.filterBranchesSelector}
                    value={(this.state.zRequest.BranchesList === '') ? [] : this.state.zRequest.BranchesList.split(',')}
                >
                    {list}
                </Select>
            </FormItem>
            )
        }

        let mobile = window.innerWidth > 600 ? false : true;

        return (<div style={divStyle}>
            <Card title={titleBar} bordered={true} style={respoVidth} headStyle={headStyle}>
                <Form className="data-entry-form" layout='horizontal' style={respoCol} >
                    <div style={dStyle}>
                        {FI('', radioSelect([12785, 12982, 12786]))}
                        {FI(this.getText(14020), [
                            <Button type="primary" icon="unordered-list" onClick={() => {
                                const { zNumber, StartDate, EndDate, PosList } = this.state.zRequest;
                                this.apiZList(StartDate, EndDate, PosList, "", list => {
                                    this.setState({ modalZList: true, modalZListData: list, modalZListSearch: zNumber })
                                })
                            }} />,
                            <Input
                                style={{ width: 300 }}
                                value={this.state.zRequest.zNumber}
                                onChange={(e) => {
                                    if (e.target.value === "") this.setReportParameters("zRequest", { zNumber: "" })
                                    if (parseInt(e.target.value)) this.setReportParameters("zRequest", { zNumber: parseInt(e.target.value) })
                                }}
                                onBlur={this.onBlurZInput}
                            />])}
                        <Row>
                            <Col span={mobile ? 24 : 12}>
                                {datePicker('StartDate', this.getText(12253), this.getText(12261))}
                            </Col>
                            <Col span={mobile ? 24 : 12}>
                                {datePicker('EndDate', this.getText(12254), this.getText(12262))}
                            </Col>
                        </Row>
                    </div>
                    <div style={dStyle}>
                        {posSelector()}
                        {this.state.radioValue == 1 ? branchesSelector() : ""}
                        <ReportTypeButton
                            {...this.props}
                            params={this.state.zRequest}
                            xlsReportName={this.state.radioValue == 1 ? null : this.state.baseReportName + "Excel.xls"}
                            xlsOnly={this.state.radioValue == 1}
                            // branchesField={this.state.radioValue == 1 ? "BranchesList" : null}
                            posField={"PosList"}
                            datesToFavorites={[
                                { field: 'StartDate', label: this.getText(12253) },
                                { field: 'EndDate', label: this.getText(12254) },
                            ]}
                            onChange={(e) => {
                                this.setReportParameters("zRequest", { reportName: this.state.baseReportName + ((e === 'xls') ? "Excel.xls" : "pw." + e) });
                                this.setState({ reportType: e });
                            }}
                            favoriteSave
                            validSubmit={() => {
                                let st = this.state.zRequest.StartDate;
                                let en = this.state.zRequest.EndDate;
                                if ((st && en && new Date(st) <= new Date(en)) || this.state.zRequest.zNumber) return true
                                else message.error(this.getText(12264));
                            }}
                        />
                        {/* {selectType(this.getText(12256))}
                        <Button type={"primary"} style={{ marginTop: 40 }} onClick={() => {
                            let st = this.state.zRequest.StartDate;
                            let en = this.state.zRequest.EndDate;
                            if ((st && en && new Date(st) <= new Date(en)) || this.state.zRequest.zNumber) this.generateReport.apply(this, ["zRequest"]);
                            else message.error(this.getText(12264));
                        }}>{this.getText(12260)}</Button>
                        <ReportFavoriteSave
                            {...this.props}
                            params={this.getReportParams(this.state.zRequest)}
                            datesToFavorites={[
                                { field: 'StartDate', label: this.getText(12253) },
                                { field: 'EndDate', label: this.getText(12254) },
                            ]}
                        /> */}
                    </div>

                </Form>
            </Card>
            <GenericModal
                visible={this.state.modalZList}
                onCancel={() => { this.setState({ modalZList: false }, this.onBlurZInput) }}
                title={this.getText(19493)}
                width={1000}
                showsearch={() => { }}
                valueSearch={this.state.modalZListSearch}
                onChangeSearch={e => { this.setState({ modalZListSearch: e.target.value }) }}
            >
                <ResponsiveTable
                    tableOnly
                    dataSource={this.state.modalZListData.filter(f => !this.state.modalZListSearch || f.num?.indexOf(this.state.modalZListSearch) > -1)}
                    columns={[
                        { title: this.getText(19494), key: "num", dataIndex: "num" },
                        { title: this.getText(19495), key: "posName", dataIndex: "posName" },
                        { title: this.getText(19496), key: "count", dataIndex: "count" },
                        { title: this.getText(19497), key: "start", dataIndex: "start" },
                        { title: this.getText(19498), key: "end", dataIndex: "end" },
                        {
                            render: (t, r) => (<Button type="primary" onClick={() => {
                                this.setReportParameters("zRequest", { zNumber: r.num, PosList: r.pos, StartDate: r.start?.split(" ")[0], EndDate: r.end?.split(" ")[0] });
                                this.setState({ modalZList: false })
                            }}>{this.getText(19499)}</Button>)
                        },
                    ]}
                    pagination={{ pageSize: 10 }}
                />
            </GenericModal>
        </div>)

    }

}

export default PaymentsZReport;
