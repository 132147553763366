/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Modal, Button, Input, Switch } from "antd";
import "../App.css";
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";

const Search = Input.Search;

type DataMultiSelectModalProps = {
    data: Array<string>,
    visible: boolean,
    zIndex: number,
    keyColumnLabel: string,
    nameColumnLabel: string,
    title: string,
    searchPlaceholder: string,
    tableNotFound: string,
    selected: Array<number>,
    addToSelection: Function,
    removeFromSelection: Function,
    clearSelection: Function,
    closeDialog: Function
};

type State = { pagination: Object, query: string };

class DataMultiSelectModal extends Component<DataMultiSelectModalProps, State> {
    constructor(props: DataMultiSelectModalProps) {
        super(props);
        this.state = {
            query: "", pagination: {
                size: "small",
                hideOnSinglePage: true,
                defaultCurrent: 1,
            }
        };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prev: DataMultiSelectModalProps) {

    }

    handleSearch(query: string, force: boolean) {
        if (query.length > 2 || force) {
            this.setState({ ...this.state, query: query, pagination: Object.assign({}, this.state.pagination, { current: 1 }) })
        } else if (query.length === 0) {
            this.clearSearch();
        }

    }

    clearSearch() {
        this.setState({ ...this.state, query: "", pagination: Object.assign({}, this.state.pagination, { current: 1 }) })
    }

    render() {
        const data = Jax.get(this.props, "data", []).filter((value) => this.state.query.length === 0 || value.indexOf(this.state.query) > -1).map((node: string) => {
            let data = node.split("\f");
            let res = { key: data[0], name: data[1] };
            if (this.props.moreColumns) {
                this.props.moreColumns.forEach(x => {
                    res = { ...res, [x.key]: data[x.index] }
                })
            }
            return res
        });

        const keyColumnLabel = this.props.keyColumnLabel;
        const nameColumnLabel = this.props.nameColumnLabel;

        let columns = [{
            title: keyColumnLabel,
            dataIndex: "key",
            key: "key",
        },
        {
            title: nameColumnLabel,
            dataIndex: "name",
            key: "name",
        }]

        if (this.props.moreColumns) {
            columns = [
                ...columns,
                ...this.props.moreColumns.map(x => {
                    return { ...x, dataIndex: x.key }
                })
            ]
        }


        columns.push({
            title: "",
            key: "switch",
            render: (text, record) => {
                if (this.props.selected.indexOf(parseInt(record.key, 10)) > -1) {
                    return <Switch defaultChecked onChange={(checked) => {
                        if (checked) {
                            this.props.addToSelection(record.key);
                        } else {
                            this.props.removeFromSelection(record.key);
                        }
                    }} />
                } else {
                    return <Switch key={"switch" + record.key} onChange={(checked) => {
                        if (checked) {
                            this.props.addToSelection(record.key);
                        } else {
                            this.props.removeFromSelection(record.key);
                        }
                    }} />
                }
            }
        });


        return (
            <Modal zIndex={this.props.zIndex} title={this.props.title} visible={this.props.visible} closable={false} destroyOnClose={true} footer={[
                <Button key={"back"} onClick={() => { this.props.closeDialog(); }}>{(this.props.closeButton) ? this.props.closeButton : this.getText(12338)}</Button>,
                <Button type="primary" key={"clear"} onClick={() => { this.props.clearSelection(); }}>{this.getText(12339)}</Button>
            ]} afterClose={this.clearSearch.bind(this)}>
                <div>
                    <Search defaultValue={this.state.query} placeholder={this.props.searchPlaceholder} onChange={(value) => { this.handleSearch.apply(this, [value.target.value, false]); }} onSearch={(value) => { this.handleSearch.apply(this, [value, true]); }} size={"large"} enterButton />
                    <ResponsiveTable ui={this.props.ui} tableOnly={true} columns={columns} dataSource={data} pagination={this.state.pagination} onChange={(pagination) => { this.setState(Object.assign({}, this.state, { pagination })) }} locale={{ emptyText: this.props.tableNotFound }} />
                </div>
            </Modal>
        );
    }

}

export default DataMultiSelectModal;