/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import "../../App.css";
import { Card, Col, Row, Button, Form, Select, Checkbox, message, DatePicker } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import GenericSelector from '../../components/GenericSelector';
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { MonthPicker } = DatePicker;

class CreditCardCharge extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            balanceDate: null,
            custTags: "",
            chargeDate: null,
            branch: '0', // param is cancle use
        };

        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        }

        // if (props.data[TableIDs.genericSelectorBranches] === undefined) {
        //     props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };
        // }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // componentDidMount() {
    //     this.props.dataActions.genericMultiSelectorRefreshDataset(
    //         TableIDs.genericMultiSelectorCustomerTags,
    //         this.props.user.companyCode,
    //         this.props.user.token,
    //         "get_customer_tags_list");
    // }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(14034);
    }

    formItemStyle = (label, input, req) => {
        return (<Row style={{ marginTop: 10 }}>
            <Col span={8}>{req ? (<span>
                <span style={{ color: 'red' }}>* </span>
                {this.getText(label)}
            </span>)
                : this.getText(label)}</Col>
            <Col span={16}>{input}</Col>
        </Row>)
    }

    selectorCustTags = () => {
        // let dataset = this.props.data[TableIDs.genericMultiSelectorCustomerTags].dataset;
        // return (<span>
        //     <Checkbox onChange={(e) => {
        //         let custTags = e.target.checked ?
        //             dataset.map((node) => node.tags.map((item) => item.id).join(',')).join(',')
        //             : ""
        //         this.setState({ custTags: custTags })
        //     }} />
        //     <Select
        //         showsearch filterOption={true} optionFilterProp="valuefilter" mode="multiple" style={{ width: '90%' }}
        //         onChange={(e) => { this.setState({ custTags: e.join(',') }) }}
        //         value={this.state.custTags ? this.state.custTags.split(',') : []}
        //     >{dataset.map((node) => {
        //         return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
        //             {node.tags.map((item) => {
        //                 return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
        //             })}
        //         </OptGroup>)
        //     })}</Select>
        // </span>);
        return (<GenericSelectorCustTags
            {...this.props} multi
            onChange={(e) => { this.setState({ custTags: e.join(',') }) }}
            value={this.state.custTags ? this.state.custTags.split(',') : []}
        />)
    }

    // selectorBranch = () => {
    //     return (<GenericSelector
    //         id={TableIDs.genericSelectorBranches}
    //         api={"get_branch_list"}
    //         value={this.state.branch}
    //         onChange={(data) => { this.setState({ branch: data }) }}
    //         user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
    //     />)
    // }

    datePicker = (field) => {
        let m = field === "chargeDate" ? true : false;
        let format = m ? "MM/YYYY" : "DD/MM/YYYY";
        let apiFormat = m ? "MMYY" : "YYYY-MM-DD";
        let value = this.state[field] ? moment(this.state[field], apiFormat) : null
        let onChange = (date) => { this.setState({ [field]: date ? date.format(apiFormat) : "" }) }

        return m ?
            (<MonthPicker value={value} format={format} onChange={onChange} />) :
            (<DatePicker value={value} format={format} onChange={onChange} />)
    }

    sendAPI = (script, data, sCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, (e) => { console.error(e) });
    };

    render() {
        const { custTags, balanceDate, chargeDate, branch } = this.state;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        return (<div style={divStyle}>
            <Card title={(<PageToolbar title={this.getText(14035)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: (window.innerWidth > 600) ? '60%' : '96%' }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}
                actions={[<Button type="primary" onClick={() => {
                    if (!balanceDate || !chargeDate) {
                        message.error(this.getText(14040))
                        // } else if (!branch) {
                        //     message.error(this.getText(14102))
                    } else {
                        let dataSend = "_dateEnd\f_tagList\f_period\f_branch\r" +
                            balanceDate + "\f" + custTags + "\f" + chargeDate + "\f" + branch;
                        this.sendAPI("create_standing_orders_cust_debt", dataSend, (ob) => {
                            if (ob.data) {
                                message.success(ob.data + " " + this.getText(14041));
                            }
                        })
                    }
                }}>{this.getText(14039)}</Button>]}>
                {this.formItemStyle(14036, this.datePicker("balanceDate"), 1)}
                {this.formItemStyle(14037, this.selectorCustTags())}
                {this.formItemStyle(14038, this.datePicker("chargeDate"), 1)}
                {/* {this.formItemStyle(14101, this.selectorBranch(), 1)} */}
            </Card>
        </div>)
    }

}
export default CreditCardCharge;