/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import ReportTypeButton from "../../components/ReportTypeButton";
import { Form, Card, Row, Col, Checkbox, InputNumber, Select, Radio, } from "antd";


class AgingCreditSupp extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            _branchList: '',
            _isActive: "1",
            _isWithPurchase: "0",
            _fromBalance: 1,
            _toBalance: 9999999,
            _orderType: "0",
            _showPartialSum: "0",
            _sortPerAgeMonth: "0",
            _groupByBranch: '0',
            reportName: "reports/AgingCreditSupp.pdf"
        };

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" },
        ];

        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(18883);
        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setState(this.props.ui.favoriteParams)
        }
    }



    render() {
        const gt = this.getText;
        const mobile = window.innerWidth <= 600;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        return (<div style={divStyle}>
            <Card title={(<PageToolbar title={gt(18884)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: (window.innerWidth > 600) ? 1000 : '96%' }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 12} style={{ padding: 10 }}>
                        <Form.Item label={gt(18885)}>
                            <GenericSelector
                                {...this.props} multi {...this.selectors[0]}
                                value={this.state._branchList ? this.state._branchList.split(",") : []}
                                onChange={e => { this.setState({ _branchList: e ? e.join(",") : "" }) }}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Checkbox
                                checked={this.state._groupByBranch == '1'}
                                onChange={e => { this.setState({ _groupByBranch: e.target.checked ? "1" : "0" }) }}
                            >{gt(19275)}</Checkbox>
                            <br />
                            <Checkbox
                                checked={this.state._isActive == '1'}
                                onChange={e => { this.setState({ _isActive: e.target.checked ? "1" : "0" }) }}
                            >{gt(18886)}</Checkbox>
                            <br />
                            <Checkbox
                                checked={this.state._isWithPurchase == '1'}
                                onChange={e => { this.setState({ _isWithPurchase: e.target.checked ? "1" : "0" }) }}
                            >{gt(18887)}</Checkbox>
                            <br />
                            <Checkbox
                                checked={this.state._showPartialSum == '1'}
                                onChange={e => { this.setState({ _showPartialSum: e.target.checked ? "1" : "0" }) }}
                            >{gt(18888)}</Checkbox>
                        </Form.Item>

                        <Form.Item label={gt(18889)}>
                            <Row>
                                <Col span={2}>{gt(18890)}</Col>
                                <Col span={10}><InputNumber value={this.state._fromBalance} onChange={e => { this.setState({ _fromBalance: e }) }} /></Col>
                                <Col span={2}>{gt(18891)}</Col>
                                <Col span={10}><InputNumber value={this.state._toBalance} onChange={e => { this.setState({ _toBalance: e }) }} /></Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={mobile ? 24 : 12} style={{ padding: 10 }}>
                        <Form.Item label={gt(18892)}>
                            <Select style={{ width: 250 }} value={this.state._orderType} onChange={e => { this.setState({ _orderType: e }) }}>
                                <Select.Option value={"0"}>{gt(18893)}</Select.Option>
                                <Select.Option value={"1"}>{gt(18894)}</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Radio.Group
                                value={this.state._sortPerAgeMonth}
                                onChange={e => { this.setState({ _sortPerAgeMonth: e.target.value }) }}>
                                <Radio value={"0"}>{gt(18895)}</Radio><br />
                                <Radio value={"1"}>{gt(18896)}</Radio><br />
                                <Radio value={"2"}>{gt(18897)}</Radio><br />
                                <Radio value={"3"}>{gt(18898)}</Radio><br />
                                <Radio value={"4"}>{gt(18899)}</Radio><br />
                            </Radio.Group>
                        </Form.Item>
                        <ReportTypeButton
                            {...this.props}
                            params={this.state}
                            branchesField={'_branchList'}
                            favoriteSave
                        />
                    </Col>
                </Row>

            </Card>
        </div>)
    }

}

export default AgingCreditSupp;