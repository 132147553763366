/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Button, Card, Tooltip, Icon, Modal } from "antd";
import KdsBonItem from "./KdsBonItem";

type Props = {
    kdsData: Object,
    noDataText: String,
    sendAPI: Function,
    setJson: Function,
    data: Object,
    height: Number,
}

class KdsCheckerView extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {};

        this.loop1 = null;
        this.counterRefresh = 0;

        this.id = "KDS_CHECKER_VIEW";
        this.bon_id = "KDS_BON_OWNER_VIEW";

        this.api = {
            get: "get_kds_checker_view",
            get_bon: "get_kds_checker_bon_view",
            update_status: "update_status_external_orders_force",
        }

        this.selectors = [
            { id: 'BONIM_TAGS', api: 'get_tags_from_type_2' },
        ];

        if (props.data[this.id] === undefined) props.data[this.id] = { data: [] };
        if (props.data[this.bon_id] === undefined) props.data[this.bon_id] = { owner: {}, bon: {}, bonData: [] };

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { dataset: [] };
        });

    }

    componentDidMount() {
        this.getDataApi();
        this.loopRefresh();

        this.props.sendAPI(this.selectors[0].api, '', (obj) => {
            let dataset = obj.data ? obj.data.split('\r').map(x => {
                let y = x.split('\f');
                return { code: y[0], name: y[1] }
            }) : []

            this.props.setJson(this.selectors[0].id, { dataset });
        });
    }

    componentWillUnmount() {
        clearTimeout(this.loop1);
    }

    loopRefresh = () => {
        let refreshTime = parseInt(this.props.kdsData.sRefreshTime);

        this.counterRefresh++;

        if (refreshTime <= this.counterRefresh) {
            this.getDataApi();
            this.counterRefresh = 0;
        }

        this.loop1 = setTimeout(this.loopRefresh, 1000);
    }

    getDataApi = () => {
        this.props.sendAPI(this.api.get, '_id\r' + this.props.kdsData.sID, (obj) => {
            let data = []
            if (obj.data) {
                data = obj.data.split('\r').map(x => {
                    let y = x.split('\f');
                    return {
                        oID: y[0],
                        oNumber: y[1],
                        oTimeStart: y[2],
                        oTimeEnd: y[3],
                        oTimeOver: y[4],
                        oMethod: y[5],
                        oMethodsDesc: y[6],
                        oSupplierId: y[7],
                        oSupplierName: y[8],
                        _finishBons: y[9] ? y[9].split(',') : [],
                        _notFinishBons: y[10] ? y[10].split(',') : [],
                    }
                })
            }

            this.props.setJson(this.id, { data });


            if (this.props.data[this.bon_id].owner.oNumber) {
                let owner = data.find(f => f.oNumber === this.props.data[this.bon_id].owner.oNumber);
                let list = this.getBonimList(owner, (e) => {

                    console.log('bonimList', e)
                });

                let bonColor = list.find(f => f.id === this.props.data[this.bon_id].bon.code)
                this.getBonData(owner, { ...this.props.data[this.bon_id].bon, ...bonColor });
            }
        });

    }

    getBonimList = (owner, next) => {
        if (owner) {

            let res = owner._finishBons.map(x => { return { id: x, _finish: true } });

            owner._notFinishBons.forEach(x => {
                let findItem = res.findIndex(f => f.id === x);
                if (findItem > -1) {
                    res[findItem] = { ...res[findItem], _notFinish: true }
                } else {
                    res.push({ id: x, _notFinish: true })
                }
            });

            if (next) next({ res, owner })

            return res.map(x => {
                if (x._finish && x._notFinish) return { id: x.id, color: 'yellow' }
                else if (x._finish && !x._notFinish) return { id: x.id, color: 'lawngreen' }
                else if (!x._finish && x._notFinish) return { id: x.id, color: 'pink' }
            })
        } else {
            return []
        }


        // let greenBons = owner._finishBons.filter(f => !owner._notFinishBons.find(ff => ff === f));
        // let redBons = owner._notFinishBons.filter(f => !owner._finishBons.find(ff => ff === f));
        // let greenLightBons = owner._notFinishBons.filter(f => owner._finishBons.find(ff => ff === f));

        // return [
        //     ...redBons.map(x => { return { id: x, color: 'pink' } }),
        //     ...greenLightBons.map(x => { return { id: x, color: 'yellow' } }),
        //     ...greenBons.map(x => { return { id: x, color: 'lawngreen' } }),
        // ];

    }

    bonimTags = (owner) => {
        // let greenBons = owner._finishBons.filter(f => !owner._notFinishBons.find(ff => ff === f));
        // let redBons = owner._notFinishBons.filter(f => !owner._finishBons.find(ff => ff === f));
        // let greenLightBons = owner._notFinishBons.filter(f => owner._finishBons.find(ff => ff === f));

        // let list = [
        //     ...redBons.map(x => { return { id: x, color: 'pink' } }),
        //     ...greenLightBons.map(x => { return { id: x, color: 'yellow' } }),
        //     ...greenBons.map(x => { return { id: x, color: 'lawngreen' } }),
        // ];
        if (owner) {

            let list = this.getBonimList(owner);
            return list.map(x => {
                let tagData = this.props.data[this.selectors[0].id].dataset.find(f => f.code === x.id);
                if (!tagData) tagData = { code: '0', name: this.props.getText(15895) }
                tagData = { ...tagData, color: x.color }
                return (<Button
                    size={'large'}
                    style={{ background: x.color, margin: 2, fontSize: 20 }}
                    onClick={() => { this.getBonData(owner, tagData) }}>
                    {tagData.name}
                </Button>)
            });
        } else {
            return []
        }
    }

    timeFormatHHmm = (time) => {
        return time ? time.split(':').slice(0, -1).join(':') : ''
    }

    getBonData = (owner, bon) => {
        if ( owner && parseInt(this.props.kdsData.sZoomEnable)) {
            this.props.sendAPI(this.api.get_bon, 'oID\fbID\r' + owner.oID + '\f' + bon.code, (obj) => {
                if (obj.data) {
                    let data = obj.data.split('\r').map(x => {
                        let y = x.split('\f');
                        return {
                            id: y[0],
                            itemCode: y[1],
                            itemName: y[2],
                            qty: y[3],
                            readyStatus: y[4],
                            title: y[5],
                            group: y[6],
                            groupTitle: y[7],
                        }
                    });
                    this.props.setJson(this.bon_id, { owner, bon, bonData: data })
                }
            })
        }
    }

    render() {
        let gt = this.props.getText;

        let rowHeight = this.props.height / 2;

        const divStyle = {
            height: this.props.height,
            overflowY: 'auto',
        }

        let cardStyle = {
            borderRadius: 15,
            padding: rowHeight * 0.05,
            margin: rowHeight * 0.05,
            fontSize: rowHeight * 0.06,
            textAlign: 'center',
            background: '#FAEBD7',
            display: 'inline-block',
            verticalAlign: 'text-top',
            // height: rowHeight * 0.9,
            width: rowHeight * 1.5,
        }

        let titleStyle = {
            fontSize: rowHeight * 0.1,
            borderRadius: 5,
            padding: 2,
        }

        console.log('bon props', this.props.data)

        return (<div style={divStyle}>
            {this.props.data[this.id].data.length ?
                this.props.data[this.id].data.map(x => {
                    let color = !x._notFinishBons.length ? 'lawngreen' : 'pink';

                    return (<Card
                        style={{
                            ...cardStyle,
                            border: '2px solid ' + color,
                            // cursor: parseInt(this.props.kdsData.sZoomEnable) ? 'pointer' : 'auto',
                        }}
                    // onClick={() => {
                    //     if (parseInt(this.props.kdsData.sZoomEnable)) this.setState({ modalOwner: x.oID })
                    // }}
                    >
                        <h1 style={{ ...titleStyle, background: color }}>{gt(15889)}: {x.oNumber}</h1>
                        <p>{gt(15890)}: {this.timeFormatHHmm(x.oTimeStart)} &nbsp;&nbsp;&nbsp; {gt(15891)}: {this.timeFormatHHmm(x.oTimeEnd)}</p>
                        <p>{gt(15893)}: {x.oSupplierName} &nbsp;&nbsp;&nbsp; {gt(15892)}: {x.oMethodsDesc}</p>
                        <p><b>{gt(15894)}: {this.timeFormatHHmm(x.oTimeOver)}</b></p>
                        {this.bonimTags(x)}
                        {this.props.kdsData.sStatusListLeft ?
                            (<div style={{ float: 'right' }}>
                                <Tooltip title={gt(15896)}>
                                    <Button className={"btn"} size={'large'} style={{ margin: 1 }} onClick={(e) => {
                                        // e.stopPropagation();
                                        let dataSend = 'eStatus\feOwnerId\r' + this.props.kdsData.sStatusListLeft + '\f' + x.oID;
                                        this.props.sendAPI(this.api.update_status, dataSend, this.getDataApi)
                                    }}>
                                        <Icon type='carry-out' style={{ fontSize: 16 }} />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={gt(15897)}>
                                    <Button className={"btn"} size={'large'} style={{ margin: 2 }} onClick={this.getDataApi}>
                                        <Icon type='reload' style={{ fontSize: 16 }} />
                                    </Button>
                                </Tooltip>
                            </div>)
                            : ""}
                    </Card>)
                }) :
                (<div style={{ height: rowHeight }}>
                    {this.props.getText(15878)}
                </div>)}
            {this.props.data[this.bon_id].bon.code ? (
                <KdsBonItem
                    {...this.props}
                    {...this.props.data[this.bon_id]}
                    isModalZoom
                    isFromChecker
                    cardWidth={window.innerWidth / 2.5}
                    closeModal={() => { this.props.setJson(this.bon_id, { owner: {}, bon: {}, bonData: [] }) }}
                    handleChange={() => {
                        this.getBonData(this.props.data[this.bon_id].owner, this.props.data[this.bon_id].bon);
                        this.getDataApi();
                    }}
                    getDataApi={this.getDataApi}
                />
            ) : ''}


        </div>)
    }
}

export default KdsCheckerView;