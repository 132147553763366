/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Table, Icon, Popconfirm } from "antd";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import ModalCreateEditBranch from "../../components/ModalCreateEditBranch";
import { fixCurrentPage } from "../../utils/utils.js"

//const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class PrinterLabels extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "PRINTER_LABELS";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        //if (props.data[TableIDs.genericSelectorPrices] === undefined) {
        //    props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        //}

        this.api = {
            get: "get_printer_labels_table", 
            //create: "create_price",
            //edit: "edit_price",
            delete: "delete_printer_label",
            //selector: "get_prices",
            //modalPriceListGet: "Prices_List_v2",
        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreateEditBranch) }
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];

        this.columns = [
            {
                title: "מזהה",
                dataIndex: "mID",
                key: "mID",
                sorter: true,
                width: '22%',
                render: (text, record) => text,
            }, {
                title: 'שם סניף',
                dataIndex: "mDesc",
                key: "mDesc",
                width: '35%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: 'כתובת',
                dataIndex: "mHeight",
                key: "mHeight",
                width: '35%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: "עריכה",
                width: '8%',
                render: (text, record) => {
                    //if (this.props.data[this.id].editing !== record.key) {
                    return (
                        <span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                this.props.uiActions.showModal(TableIDs.modalCreateEditBranch, record['mID']);
                            }} />
                            <Popconfirm title={"האם למחוק?"}
                                onConfirm={() => {
                                    console.log("record", record);
                                    fixCurrentPage.bind(this)();
                                    this.props.dataActions.genericDataDeleteRow(
                                        this.id,
                                        record['mID'],
                                        this.props.user.companyCode,
                                        this.props.user.token,
                                        this.api.delete,
                                        this.api.get);
                                }
                                }
                                okText={"כן"} cancelText={"לא"}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                        </span >);
                }
            }
        ];

    }

    componentDidMount() {
        document.title = "Cash On Tab - סניפים";
        //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        //console.log("kkkk initfilter - num rows: ", numOfRowsByHeight, " h: ", this.state.height)

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        /*
        console.log("branch - handle table change: ",
            JSON.stringify(pagination), "\n",
            JSON.stringify(filters), "\n",
            JSON.stringify(sorter));
        */
        let col = 8;
        // fall through
        switch (sorter.field) {
            case "key": col--; // fallsthrough  // 0
            case "mID": col--; // fallsthrough 
            case "mName": col--; // fallsthrough 
            case "mAddress": col--; // fallsthrough 
            case "mPhones": col--; // fallsthrough 
            case "mActivityTime": col--; // fallsthrough 
            case "mAltitude": col--; // fallsthrough 
            case "mLongitude": col--; // fallsthrough 
            case "mPOSNumber": break; // 8
            default:
                col = 0;
        }
        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //col
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mID: y[0],
                mName: y[1],
                mAddress: y[2],
                mPhones: y[3],
                mActivityTime: y[4],
                mAltitude: y[5],
                mLongitude: y[6],
                mPOSNumber: y[7],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        //console.log("kkkk render: ", this.state.height, " pg: ", this.props.data[this.id].pagination)
        return (
            <div style={divStyle}>
                <PageToolbar
                    title={'מדבקות למדפסות'}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            { ...request, 
                                _words: val
                                .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                             }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <Table
                    dataSource={dataSource}
                    columns={this.columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />

                <ModalCreateEditBranch
                    id={TableIDs.modalCreateEditBranch}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditBranch)}
                    title={"יצירת/עריכת סניף"}
                />
            </div>
        );
    }
}

export default PrinterLabels;
