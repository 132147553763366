/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
// import { Button, message } from 'antd';
// import { Job, User, OutputType, ProcessType } from '../JmReact';
//import { isNoop } from '@babel/types';

class ClockOn extends Component<Props, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            curTime: this.clock()
        }
    }

    clock = () => {
        let today = new Date();
        let tH = today.getHours();
        let tM = today.getMinutes();
        let tS = today.getSeconds();
        let zeros = (n) => n < 10 ? "0" + n : n;
        return zeros(tH) + ":" + zeros(tM) + ":" + zeros(tS);
    }

    render() {
        setTimeout(() => { this.setState({ curTime: this.clock() }) }, 1000);
        let cStyle = {
           // float:'right',
            display:'inline-block',
            padding: 5,
            paddingRight: 10,
            paddingLeft: 10,
            margin:5,
            color: '#40a9ff',
            backgroundColor:'#ffffff',
            borderRadius: 10,
            fontSize: 35,
        }
        return (<h3 style={cStyle}>{this.state.curTime}</h3>);
    }
}

export default ClockOn;
