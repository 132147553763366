/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../../App.css";
import { Icon, Popconfirm, Button, Form, Radio, Tooltip } from "antd";
import ResponsiveTable from "../../components/ResponsiveTable";

import TableIDs from "../../data/TableIDs";
import PageToolbar from "../../components/PageToolbar";
import ModalCreateEditBranch from "../../components/ModalCreateEditBranch";
import { fixCurrentPage } from "../../utils/utils.js"
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";

// const { Option, OptGroup } = Select;

const topHeight = 340; // px
const rowHeight = 30;  // px

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    dataActions: any,
    data: Object
};
type State = {
    defaultItemCode: string,
    itemCodeSelectionVisible: boolean
};

class BranchesTable extends Component<Props, State> {
    id: any;
    api: any;
    actionButtons: any;

    constructor(props: Props) {
        super(props);
        this.id = "BRANCHES_MAIN";
        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            height: window.innerHeight,
            modalFilter: false,
            modalFilterIsActive: "1",
            modalFilterFranchiseList: "",
        };

        // calc page height
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        //if (props.data[TableIDs.genericSelectorPrices] === undefined) {
        //    props.data[TableIDs.genericSelectorPrices] = { ...props.data.genericSelector };
        //}

        this.api = {
            get: "get_branches_list_table",
            //create: "create_price",
            //edit: "edit_price",
            delete: "delete_branch",
            copyAT: "copy_branch_activity_time",
            //selector: "get_prices",
            //modalPriceListGet: "Prices_List_v2",
        }

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalCreateEditBranch) }
            }, {
                buttonType: 'filter',
                buttonFunction: () => { this.setState({ modalFilter: true }) }
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];

        this.franchiseSelector = { id: "FRANCHISEES_USER_LIST", api: "franchisees_user_list" };
        if (props.data.FRANCHISEES_USER_LIST === undefined) {
            props.data.FRANCHISEES_USER_LIST = { ...props.data.genericSelector };
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - סניפים";
        //this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
        this.initFilters();
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate(prev) {
        document.title = "Cash On Tab - " + this.getText(10219);
        if (prev.data.FRANCHISEES_USER_LIST.dataset !== this.props.data.FRANCHISEES_USER_LIST.dataset) {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, {
                _franchiseList: this.props.data.FRANCHISEES_USER_LIST.dataset.map(x => x.code).join(","),
            }, this.api.get)
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
        //console.log("re render", window.innerHeight, " state: ", this.state.height)
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        console.log("kkkk initfilter - num rows: ", numOfRowsByHeight, " h: ", this.state.height)

        let request = {
            _id: 0, // for testing. TODO: "read" from selector
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            _isActive: "1",
            _franchiseList: "",
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPrices, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        // console.log("branch - handle table change: ",
        //     JSON.stringify(pagination), "\n",
        //     JSON.stringify(filters), "\n",
        //     JSON.stringify(sorter));

        let col = 4;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "mID": col--;
            case "mName": col--;
            case "mAddress": col--;
            case "mFranchise": break; // 4
            default:
                col = 0;
        }
        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //col
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            //_words: '',
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    handleFilter = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, {
            _isActive: this.state.modalFilterIsActive,
            _franchiseList: this.state.modalFilterFranchiseList ? this.state.modalFilterFranchiseList : this.props.data.FRANCHISEES_USER_LIST.dataset.map(x => x.code).join(","),
        }, this.api.get)
        this.setState({ modalFilter: false })
    }

    handleCreateNew = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id);
    }
    handleCreateFormCancel = () => {
        this.props.dataActions.genericDataCancelNew(this.id);
    }
    handleShowFilters = () => {
        this.props.dataActions.genericDataShowFilters(this.id);
    }
    handleHideFilters = () => {
        this.props.dataActions.genericDataHideFilters(this.id);
    }
    handleClearFilters = () => {
        this.props.dataActions.genericDataClearFilters(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data.genericData.pagination.defaultPageSize);
    }

    handleChangeCheckBox = (e: any, val: any, dataIndex: string, record: any) => {
        console.log("checkbox change: ", e.target.checked, { [dataIndex]: (e.target.checked) ? 1 : 0 });
        this.props.dataActions.genericDataSetEdit(
            this.id, { [dataIndex]: (e.target.checked) ? 1 : 0 }
        )
    }

    checkIfChecked = (col: any, text: any, record: any) => {
        let checkedVal = (this.props.data[this.id].editing === record.key)
            ? this.props.data[this.id].editedRecord[col] : text;
        return (checkedVal == 1 || checkedVal === '1') ? true : false;
    }

    render() {
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        const dataSource = this.props.data[this.id].data.map((x, index) => {
            let y = x.split("\f");
            return ({
                key: y[0],
                mID: y[0],
                mName: y[1],
                mAddress: y[2],
                mPhones: y[3],
                mActivityTime: y[4],
                mAltitude: y[5],
                mLongitude: y[6],
                mPOSNumber: y[7],
                mKosherId: y[8],
                mFranchise: y[9],
                index: index
            })
        });

        let request = {
            // ui-pagination 
            page: 1,
            sortType: 1,
            // api
            _page_num: 1,
            _isAsc: 1,
            _sort_by: 'col_1',
        };

        let columns = [
            {
                title: this.getText(11467),
                dataIndex: "mID",
                key: "mID",
                sorter: true,
                width: '15%',
                render: (text, record) => text,
            }, {
                title: this.getText(11468),
                dataIndex: "mName",
                key: "mName",
                width: '27%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11469),
                dataIndex: "mAddress",
                key: "mAddress",
                width: '30%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(15945),
                dataIndex: "mFranchise",
                key: "mFranchise",
                width: '20%',
                sorter: true,
                render: (text, record) => text,
            }, {
                title: this.getText(11470),
                width: '8%',
                render: (text, record) => {
                    //if (this.props.data[this.id].editing !== record.key) {
                    return (
                        <span>
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                                //this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                                this.props.uiActions.showModal(TableIDs.modalCreateEditBranch, record['mID']);
                            }} />
                            <Popconfirm title={this.getText(11471)}
                                onConfirm={() => {
                                    console.log("record", record);
                                    fixCurrentPage.bind(this)();
                                    this.props.dataActions.genericDataDeleteRow(
                                        this.id,
                                        record['mID'],
                                        this.props.user.companyCode,
                                        this.props.user.token,
                                        this.api.delete,
                                        this.api.get);
                                }
                                }
                                okText={this.getText(11472)} cancelText={this.getText(11473)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                            </Popconfirm>
                            <Tooltip title={this.getText(18854)}>
                                <Popconfirm title={this.getText(18853)}
                                    onConfirm={() => {
                                        console.log("record", record);
                                        fixCurrentPage.bind(this)();
                                        this.props.dataActions.genericDataDeleteRow(
                                            this.id,
                                            record['mID'],
                                            this.props.user.companyCode,
                                            this.props.user.token,
                                            this.api.copyAT,
                                            this.api.get);
                                    }
                                    }
                                    okText={this.getText(11472)} cancelText={this.getText(11473)}>
                                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"clock-circle"} />
                                </Popconfirm>
                            </Tooltip>
                        </span >);
                }
            }
        ];

        //console.log("kkkk render: ", this.state.height, " pg: ", this.props.data[this.id].pagination)
        return (
            <div style={divStyle}>
                <PageToolbar
                    title={this.getText(11466)}
                    actionButtons={this.actionButtons}
                    showsearch={(val) => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token,
                            {
                                ...request, _words: val
                                    .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                                page: 1,
                                _page_num: 1,
                            }, this.api.get)
                    }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions} />

                <ResponsiveTable
                    tableOnly={true}
                    percentageToMobile={[20, 40, 40]}
                    ui={this.props.ui}
                    idTable={this.props.data[this.id]}
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.handleTableChange}
                />
                {/* <Table
                    dataSource={dataSource}
                    columns={this.columns}
                    loading={this.props.ui.workInProgress}
                    //scroll={{ x: 1650 }}
                    bordered
                    pagination={this.props.data[this.id].pagination}
                    onChange={this.handleTableChange}
                    locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
                />*/}

                <ModalCreateEditBranch
                    id={TableIDs.modalCreateEditBranch}
                    user={this.props.user}
                    uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                    dataActions={this.props.dataActions} data={this.props.data}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditBranch)}
                    title={this.getText(11474)}
                />

                <GenericModal
                    visible={this.state.modalFilter}
                    onCancel={() => { this.setState({ modalFilter: false }) }}
                    width={500}
                    title={this.getText(18817)}
                    footer={[
                        <Button onClick={() => { this.setState({ modalFilter: false }) }}>{this.getText(18818)}</Button>,
                        <Button onClick={() => { this.setState({ modalFilterIsActive: "1", modalFilterFranchiseList: "" }) }}>{this.getText(18819)}</Button>,
                        <Button type="primary" onClick={this.handleFilter}>{this.getText(18820)}</Button>,
                    ]}
                >
                    <Form.Item label={this.getText(18821)}>
                        <Radio.Group value={this.state.modalFilterIsActive} onChange={e => { this.setState({ modalFilterIsActive: e.target.value }) }}>
                            <Radio value={"1"}>{this.getText(18823)}</Radio>
                            <Radio value={"0"}>{this.getText(18824)}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label={this.getText(18822)}>
                        <GenericSelector
                            {...this.props}
                            {...this.franchiseSelector}
                            multi
                            value={this.state.modalFilterFranchiseList ? this.state.modalFilterFranchiseList.split(",") : []}
                            onChange={e => { this.setState({ modalFilterFranchiseList: e ? e.join(",") : "" }) }}
                        />
                    </Form.Item>
                </GenericModal>
            </div>
        );
    }
}

export default BranchesTable;
