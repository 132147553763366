
import React from "react";
import {
    Form, Button, DatePicker, Modal, Checkbox, Select, message, InputNumber, Row, Col
} from 'antd';
import moment from "moment";
import PageToolbar from "./PageToolbar";
import LangContext from "../contextProvider/LangContext";
import TableIDs from "../data/TableIDs";
import CustPicker from "./CustPicker";
import ReportFavoriteSave from "./ReportFavoriteSave";
import DateTimePicker from "./DateTimePicker";

const FormItem = Form.Item;
const { Option } = Select;

class ModalSearchCoupons extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.selectors = [
            { id: TableIDs.genericSelectorCustomers, script: "get_customers_list_v3" },
            { id: TableIDs.genericSelectorPos, script: "list_pos" },
            { id: 'COUPONS_SUUPLIERS_SELCTOR', script: "coupons_suupliers_list" },
            { id: 'COUPONS_ITEM_SELECTOR', script: "coupons_item_list" },
            { id: TableIDs.genericSelectorPriceList, script: "get_prices" },
            { id: TableIDs.genericSelectorBranches, script: "get_branch_list" },
        ]

        this.selectors.map((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            request: {
                _expiryDateStart: null,
                _expiryDateEnd: null,
                _activityDateStart: null,
                _activityDateEnd: null,
                _customer: null,
                _posList: '',
                _isUsed: 0,
                _supplierList: '',
                _itemList: '',
                _priceList: null,
                _branchList: null,
                _fromFranchiseCost: null,
                _toFranchiseCost: null,
            }
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prev) {
        if (prev.filterActive && !this.props.filterActive) this.resetFilter()
    }

    // send data to parent
    // componentWillReceiveProps(newProps) {
    //     let _state = this.state;
    //     _state.visible = newProps.toggle;
    //     this.setState(_state);
    //     console.log("yuda componentWillReceiveProps")
    // }

    refreshData = () => {
        this.selectors.map((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.script, { _words: null });
        })
    }

    createCheckBox = (field, data) => {
        return (<Checkbox onChange={(e) => { this.onChange(field, e.target.checked ? data : '') }} />);
    }

    // bind input field
    onChange = (field, value) => {
        let r = this.state.request;
        let nr = { ...r, [field]: value }
        if (field === '_posList') nr = { ...nr, _isUsed: 1 }
        this.setState({ request: nr });
    }

    createDatePicker = (text, field) => {
        return (<FormItem label={this.getText(text)} style={{ margin: '4px', breakInside: 'avoid-column' }}>
            {/* <DatePicker
                format="DD/MM/YYYY"//"YYYY-MM-DD"
                value={this.state.request[field] ? moment(this.state.request[field], "YYYY-MM-DD") : null}
                onChange={(v) => { this.onChange(field, v ? v.format("YYYY-MM-DD") : null) }}
            /> */}
            <DateTimePicker
                format="DD/MM/YYYY"//"YYYY-MM-DD"
                value={this.state.request[field]}
                onChange={(v) => { this.onChange(field, v) }}
                minDate={field == '_expiryDateEnd' ? this.state.request._expiryDateStart : field == '_activityDateEnd' ? this.state.request._activityDateStart : null}
                maxDate={field == '_expiryDateStart' ? this.state.request._expiryDateEnd : field == '_activityDateStart' ? this.state.request._activityDateEnd : null}
            />
        </FormItem>)
    }

    createSelector = (text, field, selector, multi, keyDown) => {
        let onInputKeyDown = keyDown ? (e) => {
            clearTimeout(this.timeOut);
            this.timeout = setTimeout((ev) => {
                this.props.ActionQueue.addToQueue({
                    action: this.props.dataActions.genericSelectorRefreshDataset,
                    args: [this.selectors[selector].id, this.props.user.companyCode, this.props.user.token,
                    this.selectors[selector].script, { _words: ev }]
                });
            }, 500, e.target.value)
        } : false;

        let data = this.state.request[field];
        let s = this.props.data[this.selectors[selector].id]
        let sData = s ? s.dataset : [];
        let list = sData.map((x) => x.code ? (<Option key={x['code']} value={x['code']} valuefilter={x['code'] + ' ' + x['name']}>{x['code'] + ' - ' + x['name']}</Option>) : '');
        let fullList = sData.map((x) => x.code).join(',');
        return (<FormItem label={this.getText(text)}>
            {multi ? this.createCheckBox(field, fullList) : ""}
            <Select key={field} showSearch filterOption={true} optionFilterProp="valuefilter"
                mode={multi ? "multiple" : null} maxTagCount={10}
                style={{ width: multi ? '90%' : '95%', paddingLeft: '4px', paddingRight: '4px' }}
                value={data && data.length > 0 ? multi ? data.split(',') : data : multi ? [] : ""}
                onChange={(e) => { this.onChange(field, multi ? e.join(',') : e) }}
                onInputKeyDown={onInputKeyDown}
            >{list}</Select>
        </FormItem>)
    }

    createYesNoSelector = (label, field, yes, no) => {
        return (<FormItem label={this.getText(label)}>
            <Select key={field} style={{ width: '95%', paddingLeft: '4px', paddingRight: '4px' }}
                value={this.state.request[field]} onChange={(value) => { this.onChange(field, value) }}
            >
                <Option key={1} value={0}> - </Option>
                <Option key={2} value={1}>{this.getText(yes)}</Option>
                <Option key={3} value={2}>{this.getText(no)}</Option>
            </Select>
        </FormItem>)
    }

    handleReset = () => {
        if (this.props.setFilterActive) this.props.setFilterActive(false)
        else this.resetFilter()
    }

    resetFilter = () => {
        let _request = {
            _expiryDateStart: null,
            _expiryDateEnd: null,
            _activityDateStart: null,
            _activityDateEnd: null,
            _customer: null,
            _posList: '',
            _isUsed: 0,
            _supplierList: '',
            _itemList: '',
            _priceList: null,
            _branchList: null,
            _fromFranchiseCost: null,
            _toFranchiseCost: null,
        }

        this.setState({ request: _request }, () => { this.props.dataSetFilter({ ..._request, _words: "" }) });
    }

    handleOk = () => {
        this.props.dataSetFilter(this.state.request);
        this.handleCancel();
        if (this.props.setFilterActive) this.props.setFilterActive(true)
    }

    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }

    render() {
        const { visible, loading } = this.state;

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };

        let colDivStyle = {
            width: window.innerWidth > 600 ? '33%' : '100%',
            padding: 10,
            verticalAlign: 'text-top',
            display: 'inline-block'
        }

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(12734)}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>);

        let params = this.props.getParamsToReport ? this.props.getParamsToReport() : {}
        params = { ...params, ...this.state.request }

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={'70%'}
                visible={this.props.toggle}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <ReportFavoriteSave
                        {...this.props}
                        btnText={this.getText(16682)}
                        notSaveUrl
                        params={params}
                        datesToFavorites={[
                            { label: this.getText(12738), field: '_expiryDateStart' },
                            { label: this.getText(12739), field: '_expiryDateEnd' },
                            { label: this.getText(12740), field: '_activityDateStart' },
                            { label: this.getText(12741), field: '_activityDateEnd' },
                        ]}
                    />,
                    <Button key="back" onClick={this.handleCancel}>{this.getText(12735)}</Button>,
                    <Button key="clear" onClick={this.handleReset}>{this.getText(12736)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(12737)}</Button>
                ]}
            >
                <Form layout="horizontal" className="ant-advanced-search-form">
                    <div style={colDivStyle}>
                        {this.createDatePicker(12738, '_expiryDateStart')}
                        {this.createDatePicker(12739, '_expiryDateEnd')}
                        {this.createDatePicker(12740, '_activityDateStart')}
                        {this.createDatePicker(12741, '_activityDateEnd')}
                    </div>
                    <div style={colDivStyle}>
                        {/* {this.createSelector(12742, '_customer', 0, false, true)} */}
                        <FormItem label={this.getText(12742)}>
                            <CustPicker
                                value={this.state.request ? this.state.request._customer : null}
                                onChange={(value) => { this.onChange('_customer', value) }}
                                defaultView={this.getText(12121)}
                                data={this.props.data}
                                dataActions={this.props.dataActions}
                                uiActions={this.props.uiActions}
                                user={this.props.user}
                            />
                        </FormItem>
                        {this.createSelector(12743, '_posList', 1, true, false)}
                        {this.createYesNoSelector(12744, '_isUsed', 12745, 12746)}
                    </div>
                    <div style={colDivStyle}>
                        {this.props.version2 ? this.createSelector(15655, '_branchList', 5, true, false) : ''}
                        {this.props.version2 ? '' : this.createSelector(14061, '_supplierList', 2, true, false)}
                        {this.props.version2 ? '' : this.createSelector(14060, '_itemList', 3, true, false)}
                        {this.createSelector(14062, '_priceList', 4, false, false)}
                        {this.props.version2 ?
                            <Form.Item label={this.getText(19464)}>
                                <Row>
                                    <Col span={4}>{this.getText(19465)}</Col>
                                    <Col span={8}><InputNumber value={this.state.request._fromFranchiseCost} onChange={(value) => { this.onChange('_fromFranchiseCost', value) }} /></Col>
                                    <Col span={4}>{this.getText(19466)}</Col>
                                    <Col span={8}><InputNumber value={this.state.request._toFranchiseCost} onChange={(value) => { this.onChange('_toFranchiseCost', value) }} /></Col>
                                </Row>
                            </Form.Item>
                            : ''}
                    </div>
                </Form >
            </Modal >
        )
    }


}

export default ModalSearchCoupons;
