/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import TableIDs from '../data/TableIDs';
import AdminMenu from "../menus/AdminMenu";
import Companies from "./Admin/Companies";
import PosVersions from "./Admin/PosVersions";
import PointsOfSale from "./Admin/PointsOfSale";
import UsersTable from "./Admin/UsersTable";
import UpdateParameters from "./Admin/UpdateParameters";
import ScheduledServices from "./Admin/ScheduledServices";
import Multilingual from "./Admin/Multilingual";
import ExternalOrderSettings from "./Settings/ExternalOrderSettings";
import Franchisees from "./Settings/Franchisees";
import ButtonActions from "./Admin/ButtonActions";
import KosherTypes from "./Admin/KosherTypes";
import HeshinSettings from "./Admin/HeshinSettings";
import PrintDocuments from "./Admin/PrintDocuments";
import HelpDocumentations from "./HelpDocumentations";
import HashavshevetJob from "./Admin/HashavshevetJob";
import StoreNext from "./Admin/StoreNext";
import BenefitsDistributors from "./Admin/BenefitsDistributors";
import Currencies from "./Admin/Currencies";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};

type State = {};

class Admin extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key: any) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={191} path={this.props.baseUrl + "/admin/companies"} exact render={() => <Companies {...routeProps} />} />),
            (<Route key={192} path={this.props.baseUrl + "/admin/pos-versions"} exact render={() => <PosVersions {...routeProps} />} />),
            (<Route key={97} path={this.props.baseUrl + "/admin/points-of-sale"} exact render={() => <PointsOfSale {...routeProps} />} />),
            (<Route key={99} path={this.props.baseUrl + "/admin/users"} exact render={() => <UsersTable {...routeProps} />} />),
            (<Route key={181} path={this.props.baseUrl + "/admin/update-parameters"} exact render={() => <UpdateParameters admin={true} {...routeProps} />} />),
            (<Route key={149} path={this.props.baseUrl + "/admin/scheduled-services"} exact render={() => <ScheduledServices {...routeProps} />} />),
            (<Route key={141} path={this.props.baseUrl + "/admin/multilingual"} exact render={() => <Multilingual {...routeProps} />} />),
            (<Route key={143} path={this.props.baseUrl + "/admin/external-order-settings"} exact render={() => <ExternalOrderSettings {...routeProps} />} />),
            (<Route key={146} path={this.props.baseUrl + "/admin/franchisees"} exact render={() => <Franchisees {...routeProps} />} />),
            (<Route key={193} path={this.props.baseUrl + "/admin/button-actions"} exact render={() => <ButtonActions {...routeProps} />} />),
            (<Route key={194} path={this.props.baseUrl + "/admin/kosher-types"} exact render={() => <KosherTypes {...routeProps} />} />),
            (<Route key={195} path={this.props.baseUrl + "/admin/heshin-settings"} exact render={() => <HeshinSettings {...routeProps} />} />),
            (<Route key={196} path={this.props.baseUrl + "/admin/print-documents"} exact render={() => <PrintDocuments {...routeProps} />} />),
            (<Route key={197} path={this.props.baseUrl + "/admin/help-documentations-edit"} exact render={() => <HelpDocumentations admin {...routeProps} />} />),
            (<Route key={198} path={this.props.baseUrl + "/admin/hashavshevet-job"} exact render={() => <HashavshevetJob {...routeProps} />} />),
            (<Route key={199} path={this.props.baseUrl + "/admin/storenext"} exact render={() => <StoreNext {...routeProps} />} />),
            (<Route key={211} path={this.props.baseUrl + "/admin/benefits-distributors"} exact render={() => <BenefitsDistributors {...routeProps} />} />),
            (<Route key={212} path={this.props.baseUrl + "/admin/currencies"} exact render={() => <Currencies {...routeProps} />} />),
        ].filter((route) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/admin"} exact render={routes[0].props.render} />)
            : <div />;

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <AdminMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}

export default Admin;
