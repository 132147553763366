/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Switch, Card, Col, Row, Button, Form, Select, Checkbox, InputNumber, Input, message, DatePicker, TimePicker, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
// import DateTimePicker from '../../components/DateTimePicker';
import PageToolbar from "../../components/PageToolbar";
import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import StockTransferStorages from "../../components/StockTransferStorages"
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import moment from "moment";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class StockItemBalance extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            mIsMatrix: '0',
            type: "pdf",
            isCentral: false,
            StockItemBalance: {
                //mRequestedReport: 0,
                mLowerSelectionDate: null,
                mLowerSelectionTime: '00:00:00',
                mUpperSelectionDate: null,
                mUpperSelectionTime: '23:59:59',
                mApplyRangeApplyItemCode: 0,
                mApplyRangeLowerItemCode: '',
                mApplyRangeUpperItemCode: '',
                mApplyRangeApplyItemName: 0,
                mApplyRangeLowerItemName: '',
                mApplyRangeUpperItemName: '',
                mApplyRangeApplySupplierCode: 0,
                mApplyRangeLowerSupplierCode: '',
                mApplyRangeUpperSupplierCode: '',
                mApplyRangeApplySupplierName: 0,
                mApplyRangeLowerSupplierName: '',
                mApplyRangeUpperSupplierName: '',
                mPosList: '',
                mEmployeeList: '',
                mStorageList: '', // compatibility
                mItemGroupList: '',
                mGroupList: '', // compatibility
                mSupplierList: '',
                mTagList: '',
                mIsActive: '-1',
                mSortItem: '',
                //mMinimalPercentage: 0,
                mShowZeroBalanceItems: '0',
                mTagRelation: '1', // bool
                mSortField: 'code',
                mCalculateBalanceSeparateType: '0',
                mCalculateBalanceWithPrices: '0',
                mCentralFatherItems: '0',

                mIncludeDeleteItems: '0',
                mIncludeNotInvItems: '0',
                mViewBarcode2: '0',

                reportName: "reports/stockItemBalance.pdf",

            },

        };
        // this.state.type = "pdf";

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;


        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
            props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    // generateReport(report: string) {
    //     let params = Jax.get(this.state || {}, report, null);
    //     if (params !== null && params.reportName) {
    //         window.console.log("yuda params ", params);
    //         params = this.getFullSelector(params, "mPosList", "mStorageList");
    //         let splitName = params.reportName.split('.');
    //         if (splitName[1] == 'xls') {
    //             params = { ...params, reportName: splitName[0] + 'Excel.xls' }
    //         }
    //         params = { ...params, REPORT_LOCALE: this.getText(101) }
    //         this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
    //     }
    // }

    getFullSelector = (params, posField, storagesField) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params[posField] === '') params = { ...params, [posField]: fullPosList }
        if (params[storagesField] === '') params = { ...params, [storagesField]: fullStoragesList }
        ////console.log('yuda report ', params)
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10184);
        if (this.props.data[TableIDs.genericSelectorPos].dataset[0] &&
            this.props.data[TableIDs.genericSelectorStorages].dataset[0] &&
            !this.state.loading) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            let isCentral = this.props.ui.favoriteParams.reportName.indexOf('Central') > -1;
            let mIsMatrix = this.props.ui.favoriteParams.reportName.indexOf('Matrix') > -1 ? '1' : '0';
            console.log('get_report_params222', this.props.ui.favoriteParams, isCentral, mIsMatrix)
            this.setState({ isCentral, mIsMatrix }, () => {
                this.setReportParameters("StockItemBalance", this.props.ui.favoriteParams)
            })
        }
    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.StockItemBalance.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.StockItemBalance.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("StockItemBalance", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        let x = value ? value.split(' ') : [null, null];
        let mLowerSelectionDate = x[0];
        let mLowerSelectionTime = x[1];
        this.setReportParameters("StockItemBalance", { mLowerSelectionDate, mLowerSelectionTime })
    }

    onEndChange = (value) => {
        let x = value ? value.split(' ') : [null, null];
        let mUpperSelectionDate = x[0];
        let mUpperSelectionTime = x[1];
        this.setReportParameters("StockItemBalance", { mUpperSelectionDate, mUpperSelectionTime })
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setReportParameters("StockItemBalance", { [field]: data });
                else
                    this.setReportParameters("StockItemBalance", { [field]: '' });
            }} />);
    }


    reportStringBuilder = (isMatrix, separateType, type) => {
        if (isMatrix) {
            // switch (separateType) {
            //     case '1': return 'reports/stockItemMatrixBalanceByStorage.' + type;
            //     default: return 'reports/stockItemMatrixBalanceByColor.' + type;
            // }
            return "reports/stockItemMatrixBalance_v2." + type;
        } else if (this.state.isCentral && separateType != '0') {
            return "reports/stockItemBalanceCentral." + type;
        } else {
            switch (separateType) {
                case '1': return 'reports/stockItemBalanceByStorage.' + type;
                case '2': return 'reports/StockItemBalanceByTag.' + type;
                case '3': return 'reports/StockItemBalanceBySupplier.' + type;
                default: return 'reports/stockItemBalance.' + type;
            }
        }
    }

    checkIfItemExist = (s, m) => {
        if (s) {
            let p = m ? "\f0\f2" : "\f1\f0";
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + p;
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let _ItemCode = (ob.data).split('\f')[1];
                    if (!_ItemCode) this.props.uiActions.showModal(TableIDs.SelectorItemsModal, {});
                    else this.onChangeItemCode(_ItemCode)
                },
                (error) => {
                    message.error(this.getText(11873));
                }
            );
        }
    }

    keyboardListener(e) {
        let m = this.state.mIsMatrix == 1 ? true : false;
        ////console.log('yuda test ', this.barcodeReaderBuffer, m);
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.setReportParameters("StockItemBalance", { mSortItem: '' })
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                if (this.barcodeReaderBuffer || this.isBufferFull === true) this.checkIfItemExist(this.barcodeReaderBuffer, m)
                break;

            default: break;
        }
    }

    onChangeItemCode = (code) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.setReportParameters("StockItemBalance", { mSortItem: code })
        }
    }

    getTextsForSortField = () => {
        if (this.state.isCentral) {
            switch (this.state.StockItemBalance.mCalculateBalanceSeparateType) {
                case '1': return [16576, 16577];
                case '2': return [16578, 16579];
                case '3': return [16580, 16581];
            }
        }
        return [11906, 11907];
    }

    createRadioButton = (label, field, list, visible) => {
        return visible ?
            (<FormItem label={this.getText(label)}>
                <Radio.Group
                    key={field}
                    value={this.state.StockItemBalance[field]}
                    onChange={(value) => {
                        this.setReportParameters("StockItemBalance", { [field]: value.target.value })
                    }}>
                    {list.map(x => (<Radio value={x.id}>{this.getText(x.text)}</Radio>))}
                </Radio.Group>
            </FormItem>)
            : ""
    }

    render() {
        const { StockItemBalance, endOpen } = this.state;
        console.log('StockTransferStorages 2', StockItemBalance.mStorageList)
        const { mLowerSelectionDate, mLowerSelectionTime,
            mUpperSelectionDate, mUpperSelectionTime } = StockItemBalance;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };


        let listItemTags = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map((node: TagObj, index) => {
            return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                {node.tags.map((item: string, index) => {
                    return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                })}
            </OptGroup>)
        })

        let rMobile = window.innerWidth <= 600 ? true : false;

        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(11874)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: rMobile ? '95%' : '80%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: '1' }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <Row>
                            <Col span={rMobile ? 24 : 8}>
                                <FormItem label={this.getText(11875)} style={{ marginTop: rMobile ? 50 : 0 }}/*style={{ display: 'none' }}*/>
                                    <Switch key={"mIsMatrix"} checkedChildren={this.getText(11876)} unCheckedChildren={this.getText(11877)}
                                        checked={this.state.mIsMatrix == 1}
                                        onChange={(value) => {
                                            this.setState({ mIsMatrix: (value === true) ? '1' : '0' }, () => {
                                                this.setReportParameters("StockItemBalance", {
                                                    reportName: this.reportStringBuilder(value, this.state.StockItemBalance.mCalculateBalanceSeparateType, this.state.type),
                                                    mSortItem: '',
                                                    mCalculateBalanceSeparateType: '0'
                                                })
                                            })
                                        }} />

                                </FormItem>
                                {/* <FormItem style={{ display: this.state.mIsMatrix == 1 ? "none" : "block" }}>
                                    <Checkbox key={"mCentralFatherItems"}
                                        checked={this.state.StockItemBalance.mCentralFatherItems == 1}
                                        onChange={(value) => {
                                            this.setReportParameters("StockItemBalance", {
                                                mCentralFatherItems: (value.target.checked) ? '1' : '0'
                                            })
                                        }}>{this.getText(16984)}</Checkbox>
                                </FormItem> */}

                                {this.createRadioButton(16984, "mCentralFatherItems", [{ id: '0', text: 11900 }, { id: '1', text: 11901 }], this.state.mIsMatrix != 1)}

                                <FormItem label={this.getText(this.state.mIsMatrix == 1 ? 11878 : 11879)}>
                                    <SelectorItems
                                        doFocus={true}
                                        user={this.props.user}
                                        uiActions={this.props.uiActions}
                                        ui={this.props.ui}
                                        dataActions={this.props.dataActions}
                                        data={this.props.data}
                                        ActionQueue={this.props.ActionQueue}
                                        ctrlBKeyFlag={null}
                                        isExpandMatrixFatherItem={false}
                                        itemType={this.state.mIsMatrix == '1' ? 2 : 1}
                                        value={this.state.StockItemBalance.mSortItem}
                                        onChangeSearch={(s) => { this.onChangeItemCode(s.mCode) }}
                                        onChange={(s) => { this.onChangeItemCode(s.target.value) }}
                                        onBlur={(s) => { this.checkIfItemExist(s.target.value, this.state.mIsMatrix == 1) }}
                                        onKeyDown={this.keyboardListener.bind(this)}
                                    />
                                </FormItem>
                                <FormItem label={this.getText(11882)} /*style={isEndDateShownStyle}*/>
                                    <DatePicker
                                        disabledDate={this.disabledEndDate}
                                        format="DD/MM/YYYY"//"YYYY-MM-DD"
                                        value={mUpperSelectionDate ? moment(mUpperSelectionDate, "YYYY-MM-DD") : null}
                                        placeholder={this.getText(11903)}
                                        onChange={(value) => {
                                            this.setReportParameters("StockItemBalance", {
                                                mUpperSelectionDate: value ? value.format("YYYY-MM-DD") : null,
                                            })
                                        }}
                                    />
                                    <TimePicker
                                        format="HH:mm:ss"
                                        value={mUpperSelectionTime ? moment(mUpperSelectionTime, "HH:mm:ss") : null}
                                        onChange={(value) => {
                                            this.setReportParameters("StockItemBalance", {
                                                mUpperSelectionTime: value ? value.format("HH:mm:ss") : null,
                                            })
                                        }}
                                    />
                                </FormItem>
                                <FormItem label={this.getText(16574)}>
                                    <Select key={"mCalculateBalanceSeparateType"} style={{ width: 250 }}
                                        value={this.state.StockItemBalance.mCalculateBalanceSeparateType}
                                        onChange={(value) => {
                                            this.setReportParameters("StockItemBalance", {
                                                mCalculateBalanceSeparateType: value,
                                                reportName: this.reportStringBuilder(this.state.mIsMatrix == 1, value, this.state.type)
                                            });
                                        }} >
                                        {(this.state.mIsMatrix == 1 ? [
                                            <Option key={'0'} value={'0'}>{this.getText(16582)}</Option>,
                                            <Option key={'1'} value={'1'}>{this.getText(16571)}</Option>,
                                        ] : [
                                            <Option key={'0'} value={'0'}> - </Option>,
                                            <Option key={'1'} value={'1'}>{this.getText(16571)}</Option>,
                                            <Option key={'2'} value={'2'}>{this.getText(16572)}</Option>,
                                            <Option key={'3'} value={'3'}>{this.getText(16573)}</Option>,
                                        ])}

                                    </Select>
                                </FormItem>

                                {this.state.mIsMatrix != 1 && this.state.StockItemBalance.mCalculateBalanceSeparateType != '0' ? (
                                    <FormItem>
                                        <Checkbox
                                            checked={this.state.isCentral}
                                            onChange={e => {
                                                this.setState({ isCentral: e.target.checked }, () => {
                                                    this.setReportParameters("StockItemBalance", {
                                                        reportName: this.reportStringBuilder(this.state.mIsMatrix == 1, this.state.StockItemBalance.mCalculateBalanceSeparateType, this.state.type)
                                                    });
                                                })
                                            }}
                                        >{this.getText(16575)}</Checkbox>
                                    </FormItem>
                                ) : ""}

                                <FormItem label={this.getText(11894)}>
                                    <Select key={"mSortField"} style={{ width: 220 }}
                                        value={this.state.StockItemBalance.mSortField}
                                        onChange={(value) => { this.setReportParameters("StockItemBalance", { mSortField: value }); }}
                                    >
                                        {this.getTextsForSortField().map((x, i) => (<Option key={i + 1} value={i ? 'name' : 'code'}>{this.getText(x)}</Option>))}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={rMobile ? 24 : 8}>
                                {this.createRadioButton(11892, "mIsActive", [{ id: '-1', text: 18417 }, { id: '1', text: 18418 }, { id: '0', text: 18419 }], true)}
                                {this.createRadioButton(19484, "mShowZeroBalanceItems", [{ id: '1', text: 19481 }, { id: '0', text: 19482 }, { id: '-1', text: 19483 }], true)}
                                {this.createRadioButton(18420, "mIncludeDeleteItems", [{ id: '0', text: 11900 }, { id: '1', text: 11901 }], true)}
                                {this.createRadioButton(18421, "mIncludeNotInvItems", [{ id: '0', text: 11900 }, { id: '1', text: 11901 }], true)}
                                {this.createRadioButton(18422, "mViewBarcode2", [{ id: '0', text: 18423 }, { id: '1', text: 18424 }],
                                    this.state.StockItemBalance.mCentralFatherItems != 1 && this.state.isCentral != 1)}
                            </Col>
                            <Col span={rMobile ? 24 : 8}>


                                <StockTransferStorages
                                    {...this.props}
                                    fieldName={'mStorageList'}
                                    value={this.state.StockItemBalance.mStorageList ? this.state.StockItemBalance.mStorageList.split(',') : []}
                                    onChange={(value) => this.setReportParameters("StockItemBalance", { mStorageList: value ? value.join(',') : '' })}
                                />
                                <FormItem label={this.getText(11888)}>
                                    {/* <GenericSelectorItemTags {...this.props} multi notApi
                                        onChange={(value) => this.setReportParameters("StockItemBalance", { mTagList: value.join(',') })}
                                        value={(this.state.StockItemBalance.mTagList === '') ? [] : this.state.StockItemBalance.mTagList.split(',')}
                                    />

                                    <TagRelationRadio showed={this.state.StockItemBalance.mTagList}
                                        checked={this.state.StockItemBalance.mTagRelation == 1}
                                        onChange={(value) => this.setReportParameters("StockItemBalance", { mTagRelation: (value === true) ? 1 : 0 })}
                                    //checked={}
                                    /> */}
                                    <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                        value={this.state.StockItemBalance.mTagList}
                                        onChange={(value) => { this.setReportParameters("StockItemBalance", { mTagList: value }) }}
                                        tagRelationValue={this.state.StockItemBalance.mTagRelation}
                                        tagRelationOnChange={(value) => {
                                            let mTagList = this.state.StockItemBalance.mTagList;
                                            let from = this.state.StockItemBalance.mTagRelation;
                                            let to = value.target.value;
                                            let oldJson = mTagList.indexOf("{") > -1 ? JSON.parse(mTagList) : {}
                                            if (from == "2") mTagList = oldJson.tagList1 ?? ""
                                            if (to == "2") mTagList = JSON.stringify({ tagList1: mTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                            this.setReportParameters("StockItemBalance", { mTagRelation: to, mTagList })
                                        }}
                                    />

                                </FormItem>
                                <FormItem label={this.getText(11890)} >
                                    <GenericSelectorSuppliers {...this.props} multi notApi
                                        onChange={(value) => this.setReportParameters("StockItemBalance", { mSupplierList: value.join(',') })}
                                        value={(this.state.StockItemBalance.mSupplierList === '') ? [] : this.state.StockItemBalance.mSupplierList.split(',')}
                                    />
                                </FormItem>

                                <ReportTypeButton
                                    {...this.props}
                                    // params={{ ...this.state.StockItemBalance, mSortItem: encodeURIComponent(this.state.StockItemBalance.mSortItem) }}
                                    params={this.state.StockItemBalance}
                                    storagesField={'mStorageList'}
                                    tagsField={'mTagList'}
                                    // xlsReportName={this.state.StockItemBalance.reportName.split('.')[0] + "Excel.xls"}
                                    xlsReportName={this.state.mIsMatrix == 1 ? "reports/stockItemMatrixBalanceExcel_v2.xls"
                                        : this.state.StockItemBalance.reportName.split('.')[0] + "Excel.xls"}
                                    datesToFavorites={[{ field: 'mUpperSelectionDate', label: this.getText(11882) }]}
                                    encodeURIFields={this.state.StockItemBalance.mTagRelation == '2' ? ['mTagList', 'mSortItem'] : ['mSortItem']}
                                    favoriteSave
                                />
                            </Col>

                        </Row>
                    </Form>
                    <div />
                </Card>
            </div>);
    }
}
export default StockItemBalance;

