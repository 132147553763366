/* @flow */
/*jshint esversion: 6 */
import React from "react";
import ReactDOM from "react-dom";
import { Menu, Icon, Badge } from "antd";
import { withRouter } from "react-router-dom";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext"

const SubMenu = Menu.SubMenu;

type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    history: History,
    baseUrl: string
};

type State = {
    menuTag: any,
    menuItemsTop: Array<Object>,
    menuItemsDrop: Array<Object>
};

class ItemMenu extends React.Component<Props, State> {
    myHistory: History;
    baseUrl: string;

    constructor(props: Props) {
        super(props);
        this.myHistory = this.props.history;
        this.baseUrl = this.props.baseUrl;

        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }

        this.state = {
            menuTag: <div></div>,
            menuItemsTop: [{KeyComp: "31", key: "itemList", text: "רשימת פריטים", keyLang: 10171, icon: "table" },
            //{ KeyComp: "36", key: "matrixItemList", text: "פריטי מטריצה", icon: "table" },
            { KeyComp: "34" ,key: "prices", text: "מחירים", keyLang: 10172, icon: "table" },
            { KeyComp: "32" ,key: "tags", text: "תגיות", keyLang: 10173, icon: "gift" },
            { KeyComp: "33" ,key: "bargains", text: "מבצעים", keyLang: 10174, icon: "gift" },
            { KeyComp: "35" ,key: "providers", text: "ספקים", keyLang: 10175, icon: "table" },
            { KeyComp: "37" ,key: "foods", text: "בניית מנות", keyLang: 12642, icon: "coffee" },
            // { KeyComp: "38" ,key: "pizza-items", text: "פריטי פיצה", keyLang: 15531, icon: "pie-chart" },
            { KeyComp: "112" ,key: "rulers", text: "סרגלי מידות", keyLang: 10181, icon: "table" },
            { KeyComp: "39" ,key: "multi-image-upload", text: "העלאת תמונות מרובה", keyLang: 16675, icon: "table" },
            { KeyComp: "0" ,key: "trash", text: "סל מחזור", icon: "delete" }]
                .filter((menuItem, index) => this.isActive(menuItem.KeyComp)),
            menuItemsDrop: []
        };
     }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    onMenuSelect(args) {
        this.myHistory.push(
            this.baseUrl + "/items/" + (args.key !== "home" ? args.key : ""),
            {}
        );
    }

    updatePriorityMenu = () => {
        let maxHeight = 50; //px
        let _height = -1;
        let firstIter = true;

        let listTop = this.state.menuItemsTop;
        let listDrop = this.state.menuItemsDrop;
        // re-populate menu
        for (let item of listDrop) {
            listTop.push(listDrop.pop());
        }

        //ReactDOM.findDOMNode(this).clientHeight;
        while (_height > maxHeight || _height < 0) {
            //this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            if (!firstIter) {
                this.state.menuItemsDrop.push(this.state.menuItemsTop.pop());
            }
            else {
                firstIter = false;
            }
            this.setState((prevState, props) => {
                return {
                    menuTag: <Menu
                        mode="horizontal"
                        defaultSelectedKeys={[
                            this.myHistory.location.pathname.split(this.baseUrl).join("").split("/")[2] || "home"
                        ]}
                        onSelect={this.onMenuSelect.bind(this)}
                    >
                        {this.renderMenuItemsTop()}
                        <SubMenu title={<Badge count={listDrop.length}>
                            <span><Icon type="ellipsis" /></span></Badge>}>
                            {this.renderMenuItemsDrop()}
                        </SubMenu>
                    </Menu>
                }
            });
            console.log("findDomNode items: ", (this) ? "true" : "false", this);
            let e: Element | null | Text = ReactDOM.findDOMNode(this);
            _height = (e instanceof HTMLElement) ? e.clientHeight : 0;

            console.log("h: ", _height);
            //alert(_height);
        }
    }

    renderMenuItemsTop() {
        let res = [];
        let list = this.state.menuItemsTop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
            return res;
        }
    }

    renderMenuItemsDrop() {
        let res = [];
        let list = this.state.menuItemsDrop;
        if (list.length > 0) {
            for (let item of list) {
                res.push(
                    <Menu.Item key={item.key}>
                        <Icon type={item.icon} className="menu-icon" />
                        <span>{item.keyLang ? this.getText(item.keyLang) : ""}</span>
                    </Menu.Item>);
            }
        }
        return res;
    }

    doUpdateOfPriorityMenu = () => {
        setTimeout(this.updatePriorityMenu);
    };

    // Add event listener
    componentDidMount() {
        setTimeout(this.updatePriorityMenu);
        window.addEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    // Remove event listener
    componentWillUnmount() {
        window.removeEventListener("resize", this.doUpdateOfPriorityMenu);
    }

    render() {
        return this.state.menuTag;
    }
}

export default withRouter(ItemMenu);
