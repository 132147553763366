/* @flow */
/*jshint esversion: 6 */
import React from 'react';
import "../App.css";
import SelfCreateCustomer from '../pages/Customers/SelfCreateCustomer';
import PersonalInformationCustomer from '../pages/Customers/PersonalInformationCustomer';
import FoodOrdersIndex from '../pages/VirtualShop/FoodOrdersIndex';
import VirtualShopIndex from '../pages/VirtualShop_v4/VirtualShopIndex';
import OrderStatusToCustomer from '../pages/foods/OrderStatusToCustomer';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { message, Button, Icon, Input, Modal } from 'antd';
import InitFirebase from '../push-notification';
// import AddToHomeScreen from "./addToHomeScreen";
import TextValuesData from "../components/TextValuesData";
import { LangProvider } from "../contextProvider/LangContext";
import FormKds from "./FormKds";
import FormUnsubscribe from "./FormUnsubscribe";
import AdminCompLink from "./AdminCompLink";
import QuestionnaireOnly from './QuestionnaireOnly';
import FormQrCodeCustLink from './FormQrCodeCustLink';
import FormReportDownload from './FormReportDownload';


let dataTextValues = new Map();

class FormRoute extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isMessage: false,
            // addToHomeScreen: false,
            // deferredPrompt: null,
            getLocationAgain: false,
            indexedDbModal: false,
            deviceToken: null,
        }

        // this.addToHomeScreen();

        this.params = this.getParams();

        this.comp_id = "COMPANY_DETAILS_DATA";

        if (props.forms[this.comp_id] === undefined) props.forms[this.comp_id] = { name: "", term: "", logo: "" };
        if (props.forms.formData === undefined) props.forms.formData = { token: "" };

    }

    componentDidMount() {
        if (this.params.token && this.params.type && this.params.code) {

            if (this.params.type == '9') {
                this.indexedDbCheck();
            } else {
                this.loginForm()
            }

            let langID = this.params.lang ? this.params.lang : 2;
            if (['4', '8', '9', '6'].indexOf(this.params.type) == -1) {
                this.sendAPINoToken("get_interface_language_values", "pLanguageId\fpDocId\r" + langID + "\f3", (ob) => {
                    let res = ob.data.split("\r");
                    let obj = {}
                    res.map((y) => {
                        let x = y.split("\f");
                        obj = { ...obj, ["t" + x[0]]: x[1] }
                    })
                    this.props.formsActions.setJson("langTexts", obj)
                });
            }

            let formsFunctions = {
                sendAPI: this.sendAPI,
                getParams: this.getParams,
                setEditProps: this.setEditProps,
                setMessageProps: this.setMessageProps,
                errorMessage: this.errorMessage
            }
            this.props.formsActions.setJson("formsFunctions", formsFunctions);

        }

    }

    loginForm = (deviceToken) => {
        let data = "mToken\fmType\fmDeviceToken\r" + this.params.token + "\f" + this.params.type + '\f';
        if (deviceToken) data += deviceToken;

        this.sendAPINoToken("get_form_token", data, (ob) => {
            if (ob.data !== "") {
                let d = ob.data.split("\f");
                let formData = { linkId: d[0], token: d[1], params: d[2] }

                let cust = formData.params ? formData.params.split('&').find(f => f && f.indexOf('cust=') > -1) : null;
                cust = cust ? cust.split('=')[1] : null;

                if (cust) this.webPusherSetAttributes([{ key: '_customerId', value: cust }]);

                this.props.formsActions.setJson("formData", formData);
                this.setState({ indexedDbModal: false })
                // if (this.params.type === '4') this.addToHomeScreen();
            }
            if (ob.errorMessage !== "") setTimeout(() => { this.errorMessage() }, 500);
        });
    }

    indexedDbConnect = (onsuccess) => {
        let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
        let open = indexedDB.open("kds_form_data_device", 1);
        open.onupgradeneeded = () => {
            let db = open.result;
            let store = db.createObjectStore("kds", { keyPath: "deviceKey" });
            store.createIndex("deviceKey", "deviceKey");
            store.createIndex("deviceToken", "deviceToken");
        };
        open.onsuccess = () => { onsuccess(open) }
    }

    indexedDbCheck = () => {
        this.indexedDbConnect((open) => {
            let db = open.result;
            let tx = db.transaction("kds", "readwrite");
            let store = tx.objectStore("kds");
            let index = store.index("deviceKey");
            let getJohn = store.get(this.params.token);
            getJohn.onsuccess = () => {
                if (!getJohn.result) {
                    this.setState({ indexedDbModal: true })
                } else {
                    console.log('open.result', getJohn.result);
                    this.loginForm(getJohn.result.deviceToken)
                }
            };
        })
    }

    indexedDbCreate = () => {
        const { deviceToken } = this.state;
        this.indexedDbConnect((open) => {
            let db = open.result;
            let tx = db.transaction("kds", "readwrite");
            let store = tx.objectStore("kds");
            let index = store.index("deviceKey");
            store.put({ deviceKey: this.params.token, deviceToken });
            this.loginForm(deviceToken);
            tx.oncomplete = () => { db.close() };
        })
    }

    getParams = () => {
        let fullParams = window.location.search.replace("?", "");
        let data = fullParams.split('&');
        let obj = {}
        data.map((x) => {
            let y = x.split("=");
            obj = { ...obj, [y[0]]: y[1] }
        });

        if (obj.r) obj = { ...obj, code: obj.r.slice(0, 4), token: obj.r.slice(4), type: '10' }

        return obj;
    }

    getLocationAgain = () => {
        this.setState({ getLocationAgain: true }, () => {
            setTimeout(() => { this.setState({ getLocationAgain: false }) }, 3000);
        })
    }

    webPusherSetAttributes = (list) => {
        if (window.webpushr) {
            let p = this.params;
            let obj = { updated: new Date().toLocaleString(), [p.code + "_company"]: p.code }
            list.forEach(x => { obj = { ...obj, [p.code + x.key]: x.value } })
            window.webpushr('attributes', obj);
            console.log('window.webpushr attributes', obj);

            window.webpushr('fetch_id', (sid) => {
                //save id to database
                console.log('webpushr subscriber id: ' + sid);
            })
        } else {
            console.error('window.webpushr not exists')
        }
    }

    getComponent = () => {
        // console.log("yuda getComponent", this.state)

        //let initFirebase = (<InitFirebase getToken={this.getFirebaseToken} getCustLocation={this.getCustLocation} getLocationAgain={this.state.getLocationAgain} />)
        let initFirebase = (<div />)

        let mText = this.props.forms.message;
        let f = this.props.forms.formData;
        if (f && f.token) {
            switch (this.params.type) {
                case '0':
                    return ""

                // customer forms
                case '1': case '2':
                    return (<span><SelfCreateCustomer forms={this.props.forms}
                        formsActions={this.props.formsActions} />{initFirebase}</span>)
                case '3':
                    return (<h1>FixLabDocumentToCustomer</h1>)
                case '5': return (<span>
                    <OrderStatusToCustomer
                        data={this.props.forms}
                        dataActions={this.props.formsActions}
                        getText={this.getText}
                        sendAPI={this.sendAPI}
                        errorMessage={this.errorMessage}
                        setJson={this.props.formsActions.setJson}
                        companyName={this.props.forms[this.comp_id].name}
                        companyCode={this.params.code}
                        companyLogo={this.props.forms[this.comp_id].logo}
                        token={f.token}
                        lang={this.params.lang}
                    />
                    {initFirebase}
                </span>);

                case '6':
                    return (<LangProvider value={dataTextValues}>
                        <PersonalInformationCustomer forms={this.props.forms} formsActions={this.props.formsActions} />
                        <TextValuesData
                            user={{ token: "", companyCode: this.params.code }}
                            dataTextValues={dataTextValues}
                            language={this.params.lang}
                            endpoint={"JMForms"}
                        />
                        {initFirebase}
                    </LangProvider>)

                case '7':
                    return (<span>
                        <QuestionnaireOnly
                            data={this.props.forms}
                            dataActions={this.props.formsActions}
                            getText={this.getText}
                            sendAPI={this.sendAPI}
                            setJson={this.props.formsActions.setJson}
                            overrideJson={this.props.formsActions.overrideJson}
                            companyName={this.props.forms[this.comp_id].name}
                            companyCode={this.params.code}
                            companyLogo={this.props.forms[this.comp_id].logo}
                            token={f.token}
                            lang={this.params.lang}
                        />{initFirebase}</span>)

                //site forms
                case '4':
                case '8':
                    let isFromPos = this.params.type == '8';

                    if (!isFromPos && this.params.isNewVersion) {
                        return (<LangProvider value={dataTextValues}>
                            <VirtualShopIndex
                                data={this.props.forms}
                                dataActions={this.props.formsActions}
                                sendAPI={this.sendAPI}
                                setJson={this.props.formsActions.setJson}
                                companyName={this.props.forms[this.comp_id].name}
                                companyCode={this.params.code}
                                companyLogo={this.props.forms[this.comp_id].logo}
                                token={f.token}
                                lang={this.params.lang}
                                title={this.params.title}
                                isBackOffice={false}
                                getLocationAgain={isFromPos ? null : this.getLocationAgain}
                                webPusherSetAttributes={isFromPos ? null : this.webPusherSetAttributes}
                            />
                            <TextValuesData
                                user={{ token: "", companyCode: this.params.code }}
                                dataTextValues={dataTextValues}
                                language={this.params.lang}
                                endpoint={"JMForms"}
                            />
                            {isFromPos ? '' : initFirebase}
                        </LangProvider>);
                    }

                    return (<LangProvider value={dataTextValues}>
                        <FoodOrdersIndex
                            data={this.props.forms}
                            dataActions={this.props.formsActions}
                            sendAPI={this.sendAPI}
                            setJson={this.props.formsActions.setJson}
                            companyName={this.props.forms[this.comp_id].name}
                            companyCode={this.params.code}
                            companyLogo={this.props.forms[this.comp_id].logo}
                            token={f.token}
                            lang={this.params.lang}
                            title={this.params.title}
                            isBackOffice={false}
                            getLocationAgain={isFromPos ? null : this.getLocationAgain}
                            webPusherSetAttributes={isFromPos ? null : this.webPusherSetAttributes}
                        />
                        <TextValuesData
                            user={{ token: "", companyCode: this.params.code }}
                            dataTextValues={dataTextValues}
                            language={this.params.lang}
                            endpoint={"JMForms"}
                        />
                        {isFromPos ? '' : initFirebase}
                    </LangProvider>);

                //KDS
                case '9':
                    return (<LangProvider value={dataTextValues}>
                        <FormKds
                            data={this.props.forms}
                            dataActions={this.props.formsActions}
                            sendAPI={this.sendAPI}
                            setJson={this.props.formsActions.setJson}
                            companyName={this.props.forms[this.comp_id].name}
                            companyCode={this.params.code}
                            logo={this.props.forms[this.comp_id].logo}
                            token={f.token}
                            lang={this.params.lang}
                        />
                        <TextValuesData
                            user={{ token: "", companyCode: this.params.code }}
                            dataTextValues={dataTextValues}
                            language={this.params.lang}
                            endpoint={"JMForms"}
                        />
                    </LangProvider>)
                case '10':
                    return (
                        <FormUnsubscribe
                            forms={this.props.forms}
                            formsActions={this.props.formsActions}
                        />)
                case '11':
                    return (<AdminCompLink
                        formData={this.props.forms.formData}
                        sendAPI={this.sendAPI}
                        compCode={this.params.code}
                        login={this.props.adminLogin}
                        baseUrl={this.props.baseUrl}
                    />)
                case '12':
                    return (<LangProvider value={dataTextValues}>
                        <FormQrCodeCustLink
                            compCode={this.params.code}
                            sendAPI={this.sendAPI}
                        />
                        <TextValuesData
                            user={{ token: "", companyCode: this.params.code }}
                            dataTextValues={dataTextValues}
                            language={this.params.lang}
                            endpoint={"JMForms"}
                        />
                    </LangProvider>
                    )
                case '13':
                case '14':
                    return (
                        <FormReportDownload
                            forms={this.props.forms}
                            formsActions={this.props.formsActions}
                            formType={this.params.type}
                        />)

                case '15': // used by invoice taxes IL 
                    return (<h1>forms-page</h1>)

                /////////////////

                default: 
                return (<h1>forms-page</h1>)
            }
        } else if (mText) {
            return (<div><h1>{mText.title}</h1><h4>{mText.desc}</h4></div>)
        } else {
            return ""
        }
    }

    getText = (id) => {
        let data = this.props.forms.langTexts;
        return data && data["t" + id] ? data["t" + id] : '[' + id + ']'
    }

    sendAPI = (script, data, sCallback, eCallback) => {
        let _outputType = script === "generate_report" ? "OUTPUT_TYPE_REPORT_A4" : "OUTPUT_TYPE_DATA";
        let user = new User(this.props.forms.formData.token, this.params.code);
        let job = new Job(user, script, OutputType[_outputType], ProcessType["PROCESS_TYPE_SYNC"], true);
        if (typeof data === "string") job.setInput(data);
        else data.map((x) => { job.setSection(x.section, x.data) })
        job.send("/cgi-bin/JMForms", sCallback, eCallback);
    }

    sendAPINoToken = (script, data, sCallback) => {
        let user = new User("", this.params.code);
        // console.log('new User', {...user, device: 999})
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"], true);
        job.setInput(data);
        job.send("/cgi-bin/JMForms", sCallback, this.errorMessage);
    }

    setEditProps = (id, key, value) => {
        this.props.formsActions.setJson(id, { [key]: value })
    }

    setMessageProps = (title, desc) => {
        this.props.formsActions.setJson("message", { title: title, desc: desc })
        this.setState({ isMessage: true })
    }

    errorMessage = (e) => {
        this.props.formsActions.setJson("message", { title: this.getText(90002), desc: this.getText(90003) })
        this.setState({ isMessage: true })
    }

    getFirebaseToken = (token) => {
        this.props.formsActions.setJson("formData", { firebaseToken: token });
    }

    getCustLocation = (latitude, longitude) => {
        this.props.formsActions.setJson("formData", { custLocation: { latitude: latitude, longitude: longitude } });
    }

    render() {
        return (<span style={{ direction: "rtl" }}>
            {this.getComponent()}
            <Modal
                visible={this.state.indexedDbModal} title={'נא הזן קוד מכשיר'} width={300}
                footer={[<Button type="primary" onClick={this.indexedDbCreate}>שמור</Button>]}>
                <Input value={this.state.deviceToken} onChange={(e) => { this.setState({ deviceToken: e.target.value }) }} />
            </Modal>
        </span>)
    }

}
export default FormRoute;
