/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Icon, Popconfirm, Input, Button, Form, Row, Col } from "antd";
import ResponsiveTable from "../../components/ResponsiveTable";
import PictureView from "../../components/PictureView";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
//import GenericSelector from "../../components/GenericSelector";

const topHeight = 340; // px
const rowHeight = 30;  // px

class Franchisees extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            modalEditWhatsapp: null,
            modalEditLogo: null,
            searchInput: '',
        };

        this.api = {
            get: "get_franchisees_table",
            create_edit: "create_edit_franchisees_table",
            delete: "delete_franchisees_table",
        }

        this.id = 'FRANCHISEES_TABLE';

        this.selectors = [
            { id: "EDI_SUPP_LIST", api: "get_edi_supp_list" }
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => {
                    this.props.dataActions.genericDataStartInlineCreate(this.id);
                }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ]

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(13994);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    editButtons = (t, r) => {
        return (<span>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"}
                onClick={() => {
                    let rIndex = this.props.data[this.id].data.findIndex(f => f.split('\f')[0] === r.key)
                    this.props.dataActions.genericDataStartEditing(this.id, r.key, rIndex)
                }} />
            <Popconfirm title={this.getText(11835)}
                onConfirm={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id,
                        r.key,
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.delete,
                        this.api.get);
                }}
                okText={this.getText(11836)} cancelText={this.getText(11837)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
            </Popconfirm>
        </span>)
    }

    inputEdit = (label, field) => {
        let d = this.props.data[this.id];
        let value = d.editedRecord[field];
        let onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }
        let input = '';

        if (field === 'fLogo' || field === 'fFooterLogo') {
            input = (<PictureView   {...this.props}
                url={value} onChange={onChange}
                onRemove={() => { onChange('') }}
                onUploadedSuccess={(response) => { }}
                onUploadedFailed={(errorMessage, errorCode) => { }}
                imageType={field === 'fLogo' ? 'L' : 'N'}
            />);
            // } else if (field === 'fEDI') {
            //     input = (<GenericSelector
            //         {...this.props}
            //         {...this.selectors[0]}
            //         {...{ value, onChange }}
            //     />)
        } else {
            input = (<Input
                value={value}
                onChange={(e) => { onChange(e.target.value) }} />)
        }

        return (<Form.Item label={this.getText(label)} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>{input}</Form.Item>)
    }

    cancelEdit = () => {
        const { editing } = this.props.data[this.id];
        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
        else this.props.dataActions.genericDataCancelEditing(this.id);
    }

    saveEdit = () => {
        this.props.dataActions.genericDataSaveEdit(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            this.api.create_edit,
            this.api.get);
    }

    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };

        const { editing } = this.props.data[this.id];
        const render = t => t;

        const modalSpan = window.innerWidth > 600 ? 12 : 24;
        const modalSpanStyle = window.innerWidth > 600 ? { paddingRight: 20, paddingLeft: 20 } : {};


        let numOfRowsByHeight = parseInt((window.innerHeight - topHeight > rowHeight)
            ? (window.innerHeight - topHeight) / rowHeight
            : 1);

        let columns = [
            { title: this.getText(13995), dataIndex: "fID", key: "fID", width: '5%', render },
            { title: this.getText(13996), dataIndex: "fName", key: "fName", width: '12%', render },
            { title: this.getText(13997), dataIndex: "fOsek", key: "fOsek", width: '12%', render },
            { title: this.getText(13998), dataIndex: "fNikuy", key: "fNikuy", width: '12%', render },
            { title: this.getText(13999), dataIndex: "fStreet", key: "fStreet", width: '12%', render },
            { title: this.getText(14000), dataIndex: "fHouse", key: "fHouse", width: '5%', render },
            { title: this.getText(14001), dataIndex: "fCity", key: "fCity", width: '10%', render },
            { title: this.getText(14002), dataIndex: "fZip", key: "fZip", width: '7%', render },
            { title: this.getText(15530), dataIndex: "fEmail", key: "fEmail", width: '15%', render },
            { title: this.getText(14003), width: '10%', render: this.editButtons },
        ];

        let idTable = { ...this.props.data[this.id] }
        if (this.state.searchInput) {
            idTable = { ...idTable, data: idTable.data.filter(f => f.indexOf(this.state.searchInput) > -1) }
        }
        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={idTable}
                    columns={columns}
                    percentageToMobile={[20, 40, 40]}
                    title={this.getText(14004)}
                    actionButtons={this.actionButtons}
                    showsearch={(e) => { this.setState({ searchInput: e }) }}
                    pagination={{ pageSize: numOfRowsByHeight }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <GenericModal
                    visible={editing > -1}
                    onCancel={this.cancelEdit}
                    title={this.getText(17252)}
                    footer={[
                        <Button onClick={this.cancelEdit}>{this.getText(17249)}</Button>,
                        <Button type="primary" onClick={this.saveEdit}>{this.getText(17250)}</Button>
                    ]}
                >
                    <Row>
                        <Col span={modalSpan} style={modalSpanStyle}>
                            {this.inputEdit(13996, 'fName')}
                            {this.inputEdit(13997, 'fOsek')}
                            {this.inputEdit(13998, 'fNikuy')}
                            {this.inputEdit(13999, 'fStreet')}
                            {this.inputEdit(14000, 'fHouse')}
                            {this.inputEdit(14001, 'fCity')}
                            {this.inputEdit(14002, 'fZip')}
                            {this.inputEdit(15530, 'fEmail')}
                            {this.inputEdit(19511, 'fAccountingNumber')}
                        </Col>
                        <Col span={modalSpan} style={modalSpanStyle}>
                            {this.inputEdit(14384, 'fManagersWhatsapp')}
                            {this.inputEdit(14385, 'fEmployeesWhatsapp')}
                            {this.inputEdit(16694, 'fLogo')}
                            {this.inputEdit(18839, 'fFooterLogo')}
                            {this.inputEdit(17251, 'fEDI')}
                            {this.inputEdit(17523, 'fManagersTelegram')}
                            {this.inputEdit(17524, 'fEmployeesTelegram')}
                        </Col>
                    </Row>
                </GenericModal>
            </div>
        )

    }
}
export default Franchisees;