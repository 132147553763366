/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import "../../virtualShop.css";
import { Icon, Row, Col, Button, Modal, Input, Form, message, Card, Select, Checkbox } from "antd";
import TableIDs from '../../data/TableIDs.js';
import GoogleAddress from "./GoogleAddress";
import modal_logo from "../../skins/index/modal_logo.png";
import moment from "moment";
import ResponsiveTable from "../../components/ResponsiveTable.js";

const { Option } = Select;

class DeliveryTypeFields extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            addressError: false,
            modalNotOpen: false,
            // addressValue: null,
        }

        this.mobile = window.innerWidth <= 700 ? true : false;

        this.id = "FOOD_FORMAT_ORDER_FIELDS";
        this.dQtn_id = "DELIVERY_QTN_TABLE";
        this.setting_id = "FORMAT_SITE_SETTINGS";
        this.branches_id = "BRANCHES_LIST";
        this.times_id = "BRANCHES_TIMES";
        this.areas_id = "BRANCH_SHIPPING_AREAS";

        if (props.data[this.id] === undefined) props.data[this.id] = { orderType: '0', orderFields: {} };
        if (props.data[this.dQtn_id] === undefined) props.data[this.dQtn_id] = { data: [] };
        if (props.data[this.setting_id] === undefined) props.data[this.setting_id] = {};
        if (props.data[this.branches_id] === undefined) props.data[this.branches_id] = { dataset: [] };
        if (props.data[this.times_id] === undefined) props.data[this.times_id] = { dataset: [], hours: [] };
        if (props.data[this.areas_id] === undefined) props.data[this.areas_id] = { dataset: [] };

        if (props.data[TableIDs.genericSelectorDeliveryMethods] === undefined) props.data[TableIDs.genericSelectorDeliveryMethods] = { dataset: [] };

        this.api = {
            get_settings: "get_orders_site_settings",
            delivery_qtn: "delivery_qtn_table",
            get_times: "get_future_order_times",
        }

        this.sendAPI = this.props.sendAPI;
        this.getText = this.props.getText;

        this.branchChecked = null;



    }

    componentDidMount() {
        this.props.apiBranches();
        let _branch = this.props.isFromPos && this.props.data[this.id].orderFields.dBranch ? this.props.data[this.id].orderFields.dBranch : 0;
        this.sendAPI(this.api.get_settings, "_branch\r" + _branch, (ob3) => {
            if (ob3.data) {
                let parseData = ob3.data.split("\r");
                let keys = parseData[0] ? parseData[0].split("\f") : [];
                let values = parseData[1] ? parseData[1].split("\f") : [];
                let sObj = {};
                keys.map((x, i) => { sObj = { ...sObj, [x]: values[i] } })
                this.props.setJson(this.setting_id, sObj)
            }
        }, this.props.errorCallback);
    }

    componentDidUpdate(p) {
        let ftData = this.props.modalData;
        let changeBranch = this.props.changeBranch;

        if (ftData && p.modalData !== ftData) {
            // this.props.apiBranches();

            this.sendAPI(this.api.delivery_qtn, ftData, (ob) => {
                let data = []
                if (ob.data) {
                    data = ob.data.split("\r").map((x) => {
                        let y = x.split("\f");
                        let isSelect = y[2] === 'Select';
                        let options = y[4] && isSelect ? y[4].split(',') : [];
                        return {
                            mID: y[0],
                            mDesc: y[1],
                            mType: y[2],
                            mIsMandatory: y[3],
                            mOptions: options,
                            mValue: y[5],
                        }
                    })
                }
                this.props.setJson(this.dQtn_id, { data })
            }, this.props.errorCallback)

            setTimeout(() => {
                let dMethods = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset;
                const { orderFields } = this.props.data[this.id]
                let dmData = dMethods.find(f => f.code === ftData);
                let nAddress = dmData && dmData.needAddress === '1' ? true : false;
                if (nAddress && !(orderFields.dLat || orderFields.dLng)) {
                    this.props.setJson(this.id + ".orderFields", { dBranch: null })
                }
            }, 1000);

        }

        if (changeBranch && p.changeBranch !== changeBranch) {
            setTimeout(() => {
                this.onChangeBranch(changeBranch)
            }, 500);
        }

        if (this.branchChecked !== this.props.data[this.id].orderFields?.dBranch && this.props.data[this.setting_id].pDeliveryCalcType == '1') {
            this.branchChecked = this.props.data[this.id].orderFields?.dBranch;
            this.props.sendAPI("get_branch_shipping_areas", '_branch\f_filterType\r' + this.props.data[this.id].orderFields?.dBranch + '\f1', (ob) => {
                let d = ob.data ? ob.data.split("\r") : [];
                let h = d[0] ? d[0].split("\f") : []
                let dataset = d.slice(1, -1).map(x => {
                    let y = x.split("\f");
                    let obj = {}
                    h.forEach((z, i) => { obj = { ...obj, [z]: y[i] } })
                    return obj
                })
                this.props.setJson(this.areas_id, { dataset })
                if (!dataset.find(f => f._ID == this.props.data[this.id].orderFields?.dAreaId))
                    this.props.setJson(this.id + ".orderFields", { dAreaId: "", dAreaName: "", dAreaPrice: "", dAreaMinimum: "", dAreaMinutes: "" })

            }, this.props.errorCallback)

        }


    }

    labelStyle = (text, required) => {
        let siteSettings = this.props.data[this.setting_id];
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';
        return (<div className={"text_item"}>
            {required ?
                isStyle3 ?
                    (<span><span style={{ color: 'red' }}> * </span>{text}</span>)
                    : (<span style={{ color: 'red' }}>{text} *</span>)
                : text}
        </div>)
    }

    fieldSelectStyle = (key, value, onChange, content, valid, showSearch) => {
        let isStyle4 = this.props.data[this.setting_id].pDesktopCategoriesView == '4';
        let isStyle5 = this.props.data[this.setting_id].pDesktopCategoriesView == '5';
        let isStyle6 = this.props.data[this.setting_id].pDesktopCategoriesView == '6';
        let arrowIcon = isStyle4 || isStyle5 || isStyle6 ?
            (<span><div style={{
                padding: 2,
                marginTop: -5,
                borderRadius: "50%",
                background: isStyle6 ? "#ddc268" : "#BE1E2D",
                color: isStyle6 ? "black" : "white",
                width: 24,
                height: 24,
                fontSize: 20,
                fontWeight: "bold"
            }} ><Icon type="down" /></div></span>)
            : (<span><div className={"collapse_icon_active"} style={{ marginTop: -5 }} /></span>);

        let classDiv = "choose_container";
        if (key === "dBranch" && !isStyle6) classDiv += "_b_selector"
        if (isStyle6) classDiv += "_type_6"

        let props = { key, value, onChange, showSearch, filterOption: showSearch, optionFilterProp: showSearch ? "valuefilter" : "" }
        if ((isStyle4 || isStyle5) && key === "dBranch") props = { ...props, style: { height: 100 } }
        // if (key === "dAreaId") props = { ...props, onFocus:  }

        return (<div className={classDiv}>
            <Form.Item validateStatus={!valid ? "error" : "Success"}>
                {key === "dAreaId" ?
                    (<Input {...props} className={"text_item"} style={{ height: 50, cursor: "pointer" }} readOnly
                        suffix={<span onClick={() => { this.setState({ modalAreas: true }) }}>{arrowIcon}</span>}
                        onClick={() => { this.setState({ modalAreas: true }) }} />)
                    : <Select {...props} suffixIcon={arrowIcon} >{content}</Select>}
            </Form.Item>
        </div>)
    }

    fieldStyle = (text, field, span) => {
        let mData = this.props.modalData;
        let oData = this.props.data[this.id];
        let dMethods = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset;
        let dmData = dMethods.find(f => f.code === mData);

        let RequiredFields = ['dName', 'dPhone', 'dBranch'];
        if (dmData && dmData.needAddress === '1') {
            RequiredFields = [...RequiredFields, 'dCity', 'dStreet', 'dBuilding'];
            if (this.props.data[this.setting_id].pDeliveryCalcType == '1') RequiredFields = [...RequiredFields, 'dAreaId'];
        }

        let isStyle4 = this.props.data[this.setting_id].pDesktopCategoriesView == '4'
        let isStyle5 = this.props.data[this.setting_id].pDesktopCategoriesView == '5'

        return (<Col style={{ paddingLeft: 10, paddingRight: 10 }} span={this.mobile || isStyle4 || isStyle5 ? 24 : span}>
            {this.labelStyle(this.getText(text), RequiredFields.indexOf(field) > -1 ? true : false)}
            {field === 'dAreaId' ?
                this.fieldSelectStyle(
                    'dAreaId',
                    oData.orderFields['dAreaName'],
                    () => { },
                    this.props.data[this.areas_id].dataset.map(x => <Option value={x._ID}>{x._Name}</Option>),
                    oData.orderFields['dAreaId'],
                    false)
                : field === 'dBranch' ?
                    this.fieldSelectStyle(
                        'dBranch',
                        oData.orderFields['dBranch'],
                        this.onChangeBranch,
                        this.props.data[this.branches_id].dataset[0] ?
                            this.props.data[this.branches_id].dataset.map((x) => {
                                console.log('branchesList', x.closeHour)
                                return (<Option value={x.code} valuefilter={x.code + " " + x.name + " " + x.address}>
                                    <span className={"text_item"} style={x.closeHour ? { color: '#aaaaaa' } : {}}>
                                        {x.name}<br />
                                        {x.address}
                                        {x.closeHour || x.isOpen !== '1' ? ' - ' : ''}
                                        {x.closeHour ? <u style={{ color: 'red' }}>{this.getText(14319) + " " + x.closeHour.slice(0, 5)}</u> : ''}
                                        {x.isOpen !== '1' ? <u style={{ color: 'red' }}>{this.getText(13562)}</u> : ''}
                                    </span>
                                </Option>)
                            }) : dmData && dmData.needAddress === '1' ?
                                (<Option value={'notAddress'}>
                                    <span className={"text_item"} style={{ color: 'red' }}> - {this.getText(13588)} - </span>
                                </Option>) : '',
                        oData.orderFields['dBranch'],
                        true
                    )
                    : (<Form.Item
                        required={RequiredFields.indexOf(field) > -1}
                        validateStatus={(RequiredFields.indexOf(field) > -1 && !oData.orderFields[field]) ? "error" : "success"}
                    >
                        <Input
                            className={"text_item"} style={{ height: 50 }}
                            type={field === 'dPhone' ? 'tel' : undefined}
                            value={oData.orderFields[field]}
                            readOnly={field === 'dPhone' && !this.props.isGuest && !(this.props.isFromPos && this.props.data[this.setting_id].pPOSDefaultCustomerId === oData.orderFields.cID)}
                            onChange={(v) => {
                                if (field == 'dName' && v.target.value && v.target.value.length > 45) message.error(this.getText(17646))
                                else this.props.setJson(this.id + ".orderFields", { [field]: v.target.value })
                            }} />
                    </Form.Item>)}
        </Col>)
    }

    addressStyle = () => {
        let changeAddress = (dCity, dStreet, dBuilding, dLat, dLng, dCountry) => {



            let valid = (this.props.notValidAddress && dLat && dLng) || (dCity && dStreet && dBuilding && dLat && dLng) ? true : false;
            let isFromPos = this.props.isFromPos;
            let isBlockBranchDelivery = this.props.data[this.setting_id].pBlockBranchWhenDelivery == '1';
            let dBranch = this.props.data[this.id].orderFields.dBranch;
            let dPosNumber = this.props.data[this.id].orderFields.dPosNumber;
            let dPriceList = this.props.data[this.id].orderFields.dPriceList;

            let aError = (e) => {
                this.setState({ addressError: e }, () => {
                    this.props.setJson(this.id + ".orderFields", {
                        dCity: '',
                        dStreet: '',
                        dBuilding: '',
                        dLat: '',
                        dLng: '',
                        dBranch,
                        dFullAddress: dStreet + " " + dBuilding + ", " + dCity
                    })

                })
            }

            if (valid) {
                this.props.sendAPI(
                    'check_branch_delivery_radius',
                    'dLat\fdLng\fdCountry\fdMethod\r' + dLat + '\f' + dLng + '\f' + dCountry + '\f' + this.props.modalData,
                    (ob) => {
                        let data = ob.data ? ob.data.split("\r") : [];
                        let dataset = [];

                        let bOpen = false;
                        dBranch = null;
                        // let of = this.props.data[this.id].orderFields;
                        // if (of.dUserCheck) {
                        //     dBranch = of.dBranch;
                        //     dPosNumber = of.dPosNumber;
                        //     dPriceList = of.dPriceList;
                        // }

                        data.forEach((x, i) => {
                            let y = x.split("\f");

                            if (y[5] === '1' || this.props.isFromPos) {
                                bOpen = true;
                                if (!dBranch) {
                                    dBranch = y[0];
                                    dPosNumber = y[13];
                                    dPriceList = y[14];
                                }
                            }

                            dataset.push({
                                code: y[0],
                                name: y[1],
                                address: y[2],
                                radius: y[3],
                                ps: y[4],
                                isOpen: this.props.isFromPos ? '1' : y[5],
                                prepareTime: y[6],
                                betwenRadius: y[7],
                                dItem: y[8],
                                dItemName: y[9],
                                dPricePerKm: y[10],
                                dMaxPrice: y[11],
                                dIsRound: y[12],
                                posNumber: y[13],
                                priceList: y[14],
                                dMinPrice: y[15],
                                dPriceFirstKm: y[16],
                                bAcceptNow: y[17],
                                bAcceptHour: y[18],
                            });
                        });

                        if (isFromPos || isBlockBranchDelivery) dataset = [dataset.find(f => f.code == dBranch)]


                        this.setState({ addressError: dataset[0] ? false : 13551 }, () => {
                            this.props.setJson(this.branches_id, { dataset: dataset });
                            this.props.setJson(this.id + ".orderFields", {
                                dCity: dCity,
                                dStreet: dStreet,
                                dBuilding: dBuilding,
                                dLat: dLat,
                                dLng: dLng,
                                dBranch, dPosNumber, dPriceList,
                                dFullAddress: dStreet + " " + dBuilding + ", " + dCity
                            });

                        })
                    }, this.props.errorCallback);
            } else {
                aError(13372);
            }
        }

        let orderFields = this.props.data[this.id].orderFields;
        let custLocation = this.props.data.formData ? this.props.data.formData.custLocation : null;
        let isStyle4 = this.props.data[this.setting_id].pDesktopCategoriesView == '4'
        let isStyle5 = this.props.data[this.setting_id].pDesktopCategoriesView == '5'


        return (<Col span={this.mobile || isStyle4 || isStyle5 ? 24 : 15}>
            {this.labelStyle(this.getText(13371), true)}
            <Form.Item
                required={true}
                onBlur={() => {
                    this.setState({
                        addressError: !this.props.notValidAddress && (!orderFields.dCity || !orderFields.dStreet || !orderFields.dBuilding) ? 13372 : false
                    });
                }} >
                <GoogleAddress
                    placeholder={''}
                    inputStyle={{ paddingTop: 0, paddingBottom: 0, height: 50, marginRight: 10, borderRadius: 5 }}
                    changeAddress={changeAddress}
                    value={orderFields.dFullAddress}
                    latLng={custLocation ? { lat: custLocation.latitude, lng: custLocation.longitude } : null}
                    getLocationAgain={this.props.getLocationAgain}
                    getLocationAgainText={this.props.getLocationAgainText}
                    getText={this.getText}
                />
                {this.state.addressError ? (<div style={{ color: 'red' }}>{this.getText(this.state.addressError)}</div>) : ''}
                <Checkbox
                    checked={this.props.notValidAddress}
                    onChange={e => { this.props.onChangeValidAddress(e.target.checked) }}
                    className={"text_item"}>
                    {this.getText(19754)}
                </Checkbox>
            </Form.Item>
        </Col>)
    }

    dQtnFields = () => {
        let dqData = this.props.data[this.dQtn_id].data;
        let siteSettings = this.props.data[this.setting_id];
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';

        let iStyle = { margin: 5, width: '50%', height: 50 }
        let inputStyle = (text, index, required) => {
            return (<div>
                {this.labelStyle(text, required)}
                <span>
                    <Form.Item
                        required={required}
                        validateStatus={(required && !dqData[index].mValue) ? "error" : "success"}
                    >
                        <Input
                            className={"text_item"}
                            style={iStyle}
                            value={dqData[index].mValue}
                            onChange={(e) => {
                                let newData = [...dqData];
                                newData[index] = { ...dqData[index], mValue: e.target.value };
                                this.props.setJson(this.dQtn_id, { data: newData })
                            }} />
                    </Form.Item>
                </span>
            </div>)
        }

        let checkBoxStyle = (text, index) => {
            return (<div>
                <Checkbox checked={dqData[index].mValue == '1'} onChange={(e) => {
                    let newData = [...dqData];
                    newData[index] = { ...dqData[index], mValue: e.target.checked ? '1' : '0' };
                    this.props.setJson(this.dQtn_id, { data: newData })
                }} />
                <span className={"text_item"} style={{ marginRight: 10 }}>{text}</span>
            </div>)
        }

        let selectStyle = (text, index, required) => {
            return (<div>
                {this.labelStyle(text, required)}
                {this.fieldSelectStyle(
                    "dQtn" + index,
                    dqData[index].mValue !== '' ? parseInt(dqData[index].mValue) : '',
                    (e) => {
                        let newData = [...dqData];
                        newData[index] = { ...dqData[index], mValue: e };
                        this.props.setJson(this.dQtn_id, { data: newData })
                    },
                    dqData[index].mOptions.map((x, i) => (<Option key={index} value={i}>{x}</Option>)),
                    !required || dqData[index].mValue !== '')}
            </div>)
        }

        return (<div style={isStyle3 ? { paddingRight: 50 } : {}}>
            {dqData.map((x, i) => {
                switch (x.mType) {
                    case 'Text': return inputStyle(x.mDesc, i, x.mIsMandatory == '1' ? true : false);
                    case 'Checkbox': return checkBoxStyle(x.mDesc, i);
                    case 'Select': return selectStyle(x.mDesc, i, x.mIsMandatory == '1' ? true : false);
                    default: return ''
                }
            })}
        </div>)
    }

    selectTypeStyle = (span) => {
        let delTypes = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset;
        let mData = this.props.modalData;
        let isStyle4 = this.props.data[this.setting_id].pDesktopCategoriesView == '4'
        let isStyle5 = this.props.data[this.setting_id].pDesktopCategoriesView == '5'

        return (<Col span={this.mobile || isStyle4 || isStyle5 ? 24 : span}>
            {this.labelStyle(this.getText(13113), true)}
            {this.fieldSelectStyle(
                'orderType',
                mData,
                b => { this.props.changeModal(b) },
                [
                    mData === '0' ? (<Option value={'0'}> - </Option>) : "",
                    delTypes.map((x) => (<Option value={x.code}>{x.name}</Option>))
                ],
                parseInt(mData)
            )}
        </Col>)
    }

    futureOrder = () => {
        let fo = this.props.data[this.id].futureOrder;
        let bSelect = this.props.data[this.branches_id].dataset.find(f => f && f.code == this.props.data[this.id].orderFields['dBranch']);
        let isOpen = bSelect ? bSelect.isOpen : null;
        let bAcceptNow = bSelect ? bSelect.bAcceptNow : '0';
        let bAcceptHour = bSelect ? bSelect.bAcceptHour : '0';
        let prepareTime = bSelect ? bSelect.prepareTime : null;
        if (this.props.data[this.setting_id].pDeliveryCalcType == 1) prepareTime = this.props.data[this.id].orderFields.dAreaMinutes
        let fTimes = this.props.data[this.times_id].dataset;
        let filterTime = this.props.data[this.times_id].hours;
        let mobile = window.innerWidth <= 700 ? true : false;

        let futureDate = this.props.data[this.id].orderFields['futureDate'];
        let futureTime = this.props.data[this.id].orderFields['futureTime'];

        let cbStyle = (type) => {
            let onClick = () => {
                if (type == 1) {
                    this.props.setJson(this.id, { futureOrder: false })
                    this.props.setJson(this.id + '.orderFields', { futureTime: '', futureDate: '' })
                } else {
                    this.props.setJson(this.id, { futureOrder: true })
                    this.setHoursSelect(fTimes[0]);
                }
            }
            return (type == 1 && !fo) || (type == 2 && fo) ?
                this.props.getClassNameStyle("extra_checkbox_checked", { margin: 10 }, '', onClick)
                // (<div className={"extra_checkbox_checked"} style={{ margin: 10 }} onClick={onClick}>&#10004;</div>)
                : (<div className={"extra_checkbox"} style={{ margin: 10 }} onClick={onClick} />)
        }

        let textStyle = (cb, content) => (<div className={"text_item"}>{cb}{content}</div>);

        let dateSelector = this.fieldSelectStyle(
            'dateSelector',
            futureDate,
            (e) => {
                let dateData = fTimes.find(f => f.date === e);
                this.setHoursSelect(dateData, bAcceptNow != '1' && bAcceptHour == '1');
            },
            fTimes.map((x) => (<Option value={x.date}>{moment(x.date).format("DD/MM/YYYY")}</Option>)),
            futureDate);

        let timeSelector = this.fieldSelectStyle(
            'timeSelector',
            futureTime,
            (e) => { this.props.setJson(this.id + '.orderFields', { futureTime: e }) },
            filterTime.map((x) => (<Option value={x}>{x}</Option>)),
            futureTime);

        let isStyle4 = this.props.data[this.setting_id].pDesktopCategoriesView == '4'
        let isStyle5 = this.props.data[this.setting_id].pDesktopCategoriesView == '5'


        return bSelect ? (<Col span={24}>
            <Row>
                <Col span={mobile || isStyle4 || isStyle5 ? 24 : 8}>
                    {isOpen == 1 ? bAcceptNow == 1 ? textStyle(cbStyle(1), this.getText(13583)) : "" : textStyle('', this.getText(13550))}
                    {fTimes.length > 0 ? textStyle(cbStyle(2), this.getText(13582)) : textStyle('', this.getText(13587))}
                </Col>
                <Col span={mobile || isStyle4 || isStyle5 ? 24 : 8}>
                    {fo ? dateSelector : prepareTime ? textStyle('', this.getText(13584) + ' ' + prepareTime + ' ' + this.getText(13585)) : ""}
                </Col>
                <Col span={mobile || isStyle4 || isStyle5 ? 24 : 8}>{fo && bAcceptHour == '1' ? timeSelector : ''}</Col>
            </Row>
        </Col>) : "";
    }

    onChangeBranch = (bID) => {

        if (bID !== "notAddress") {
            let branchData = this.props.data[this.branches_id].dataset.find(f => f.code === bID);
            if (branchData) {
                let isOpen = branchData.isOpen === '1';
                let posNumber = branchData.posNumber;
                let newPriceList = branchData.priceList;
                let bAcceptNow = branchData.bAcceptNow;
                let bAcceptHour = branchData.bAcceptHour;

                this.props.sendAPI(this.api.get_times, 'bID\fmethodId\r' + bID + '\f' + this.props.modalData, (ob) => {
                    let data = ob.data ? ob.data.split("\r") : [];
                    if (data.length > 0 || isOpen) {
                        this.props.setJson(this.id, { futureOrder: isOpen ? false : true })

                        data = data.map((x) => {
                            let y = x.split("\f");
                            return { date: y[0], from: y[1], to: y[2] }
                        });
                        this.props.setJson(this.times_id, { dataset: data });
                        let newData = {
                            dBranch: bID,
                            dPosNumber: posNumber,
                            dPriceList: newPriceList,
                            dUserCheck: true,
                            bAcceptNow,
                            bAcceptHour,
                        }

                        if (this.props.data[this.id].orderFields.dBranch != bID) {
                            newData = {
                                ...newData,
                                dAreaId: null,
                                dAreaName: null,
                                dAreaPrice: null,
                                dAreaMinimum: null,
                                dAreaMinutes: null,
                            }
                        }
                        this.props.setJson(this.id + ".orderFields", newData);


                        if (data[0] && !isOpen && bAcceptNow === '1') this.setHoursSelect(data[0]);


                    } else {
                        this.setState({ modalNotOpen: true });
                    }
                    if (bAcceptNow !== '1') {
                        this.props.setJson(this.id, { futureOrder: true })
                        this.props.setJson(this.id + '.orderFields', { futureTime: '', futureDate: '' })
                    }
                }, this.props.errorCallback)


            }
        }
        this.setState({ changeBranch: null })
    }

    setHoursSelect = (dateToFilter, notUpdateTime) => {
        let filterTime = [];

        let fTime = dateToFilter.from.split(':');
        let firstTime = { h: parseInt(fTime[0]), m: parseInt(fTime[1]), s: parseInt(fTime[2]) }
        let lTime = dateToFilter.to.split(':');
        let lastTime = { h: parseInt(lTime[0]), m: parseInt(lTime[1]), s: parseInt(lTime[2]) }

        let hFormat = (hour, half) => (hour < 10 ? '0' + hour : hour) + ':' + (half ? '30' : '00') + ':00';

        for (let h = firstTime.h; h <= lastTime.h; h++) {
            if (h === firstTime.h && (firstTime.m > 0 || firstTime.s > 0)) {
                if (firstTime.m < 30 || (firstTime.m === 30 && firstTime.s === 0)) {
                    filterTime.push(hFormat(h, true));
                }
            } else if (h === lastTime.h && (lastTime.m < 30 || (firstTime.m === 30 && firstTime.s === 0))) {
                filterTime.push(hFormat(h, false));
            } else {
                filterTime.push(hFormat(h, false));
                filterTime.push(hFormat(h, true));
            }
        }

        this.props.setJson(this.times_id, { hours: filterTime })
        this.props.setJson(this.id + '.orderFields', { futureTime: notUpdateTime ? "" : filterTime[0], futureDate: dateToFilter.date })
    }

    handelClose = () => {
        this.setState({
            addressError: false,
            modalNotOpen: false,
        }, () => {
            this.props.changeModal(false)
        });
    }

    handleOk = () => {
        let mData = this.props.modalData;
        let oData = this.props.data[this.id];
        let _phone = oData.orderFields.dPhone;
        let dqData = this.props.data[this.dQtn_id].data;
        let dMethods = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset;
        let dmData = dMethods.find(f => f.code === mData);
        let deliveryCost = null;

        let RequiredFields = ['dName', 'dPhone', 'dBranch'];
        if (dmData && dmData.needAddress === '1') {
            RequiredFields = [...RequiredFields, 'dLat', 'dLng'];
            if (this.props.data[this.setting_id].pDeliveryCalcType == 1) {
                RequiredFields = [...RequiredFields, 'dAreaId'];
            }
            if (!this.props.notValidAddress) {
                RequiredFields = [...RequiredFields, 'dCity', 'dStreet', 'dBuilding'];
            }
        }


        if (oData.orderFields.bAcceptNow == 0 || oData.orderFields.bAcceptNow == '') {
            RequiredFields = [...RequiredFields, 'futureDate']
            if (oData.orderFields.bAcceptHour == '1') RequiredFields = [...RequiredFields, 'futureTime']
        }

        let req = RequiredFields.find(f => !oData.orderFields[f]);
        let reqQtn = dqData.find(f => f.mIsMandatory == '1' && f.mValue === '');
        if (req || reqQtn || mData === '0' || this.state.addressError) {
            message.error(this.getText(13100));
            console.log("test bbb ", [oData.orderFields, req, reqQtn, mData === '0', this.state.addressError])
        } else if (!this.props.mobileValidate(_phone)) {
            message.error(this.getText(13528));
        } else {
            let nextRegi = this.props.nextRegi;
            let isGuest = this.props.isGuest;
            let guestTag = this.props.data[this.setting_id].pGuestTag;
            let needAddress = dmData ? dmData.needAddress : 0;
            let futureOrder = this.props.data[this.id].futureOrder;

            switch (nextRegi) {
                case 2:
                    if (!guestTag && isGuest) {
                        this.props.regiModal(false);
                    } else {
                        this.props.changeScreen(2)
                        //  this.props.regiCodeToCheck();
                    }
                    break;
                case 3:
                    this.props.changeScreen(nextRegi)
                    break;
                default: this.props.changeScreen(1)
            }

            if (needAddress === '1') {
                deliveryCost = this.calculationShippingFees(oData.orderFields['dBranch']);
            }

            this.props.setJson(this.id, { orderType: mData, needAddress, futureOrder, deliveryCost });
            this.handelClose();
        }
    }

    calculationShippingFees = (bID) => {
        let bData = this.props.data[this.branches_id].dataset.find(f => f.code === bID);

        if (this.props.data[this.setting_id].pDeliveryCalcType == '1') {
            return {
                dItem: bData.dItem,
                dItemName: bData.dItemName,
                dPrice: this.props.data[this.id].orderFields.dAreaPrice
            }
        } else {
            let betwenRadius = bData && bData.betwenRadius ? parseFloat(bData.betwenRadius) : 0;
            let dPricePerKm = bData && bData.dPricePerKm ? parseFloat(bData.dPricePerKm) : 0;
            let dPriceFirstKm = bData && bData.dPriceFirstKm ? parseFloat(bData.dPriceFirstKm) : dPricePerKm;
            let dMaxPrice = bData && bData.dMaxPrice ? parseFloat(bData.dMaxPrice) : 9999;
            let dMinPrice = bData && bData.dMinPrice ? parseFloat(bData.dMinPrice) : 0;

            let betwenRadiusFirst = betwenRadius > 1 ? 1 : betwenRadius;
            let betwenRadiusOther = betwenRadius > 1 ? betwenRadius - 1 : 0;

            let pSum = dPriceFirstKm * (bData.dIsRound == '1' ? Math.ceil(betwenRadiusFirst) : betwenRadiusFirst);
            if (betwenRadiusOther) {
                pSum += dPricePerKm * (bData.dIsRound == '1' ? Math.ceil(betwenRadiusOther) : betwenRadiusOther);
            }

            return {
                dItem: bData.dItem,
                dItemName: bData.dItemName,
                dPrice: (pSum < dMinPrice) ? dMinPrice : (pSum > dMaxPrice) ? dMaxPrice : pSum
            };
        }
    }

    onChangeArea = (record) => {
        this.setState({ modalAreas: false }, () => {

            this.props.setJson(this.id + ".orderFields", {
                dAreaId: record._ID,
                dAreaName: record._Name,
                dAreaPrice: record._Price,
                dAreaMinimum: record._Minimum,
                dAreaMinutes: record._Minutes,
            })
        })
    }

    render() {
        let mData = this.props.modalData;
        let dMethods = this.props.data[TableIDs.genericSelectorDeliveryMethods].dataset;
        let dmData = dMethods.find(f => f.code === mData);
        let siteSettings = this.props.data[this.setting_id];
        let isStyle3 = siteSettings.pDesktopCategoriesView == '3';
        let isStyle4 = siteSettings.pDesktopCategoriesView == '4';
        let isStyle5 = siteSettings.pDesktopCategoriesView == '5';
        let isStyle6 = siteSettings.pDesktopCategoriesView == '6';

        let buttonsStyle = {
            margin: 10,
            width: 140,
            height: 50,
            background: isStyle3 ? "#88c23f" : isStyle4 || isStyle5 ? "#BE1E2D" : isStyle6 ? "#ddc268" : siteSettings.rPrimaryButtons,
        }

        let hClose = () => { this.setState({ modalNotOpen: false }) }

        let nAddress = dmData && dmData.needAddress === '1' ? true : false;

        let className = "";
        if (isStyle3) className = "padding_0";
        if (isStyle4) className = "padding_0 modal_style_4";
        if (isStyle5) className = "padding_0 modal_style_5";

        return (<Modal
            className={className}
            visible={mData}
            width={this.mobile ? "95%" : isStyle4 || isStyle5 ? 500 : "60%"}
            title={isStyle4 || isStyle5 ? false : this.props.getClassNameStyle("title_block", {}, this.getText(13090))}
            onCancel={this.handelClose}
            footer={isStyle4 || isStyle5 ? false : [
                <Button style={{ ...buttonsStyle, background: isStyle3 ? "red" : siteSettings.rSecondaryButtons }} onClick={this.handelClose}>
                    <span className={"text_item"} style={{ color: isStyle3 ? "white" : siteSettings.rFonts }}>{this.getText(13101)}</span>
                </Button>,
                <Button style={buttonsStyle} onClick={this.handleOk}>
                    <span className={"text_item"} style={{ color: isStyle3 ? "white" : siteSettings.rFonts }}>{this.getText(13089)}</span>
                </Button>
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}>
            {isStyle4 ? <img src={modal_logo} style={{ width: "100%" }} /> : isStyle5 ? <div style={{ height: 50 }}></div> : ""}
            <div style={this.props.isFromPos ?
                { height: window.innerHeight - 250, overflow: 'scroll' }
                : isStyle4 || isStyle5 ? { padding: 20, marginTop: 50, background: "white" }
                    : {}}>

                <Row style={isStyle3 ? { padding: 20, paddingRight: 50 } : {}}>
                    {this.selectTypeStyle(24)}
                    {(isStyle4 || isStyle5) && !this.props.isLogin ?
                        <div
                            style={{
                                width: 40,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                this.props.regiModal(false)
                            }}>
                            <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.22 29.22"><g id="icon_connect"><g style={{ fill: '#000000' }}><path class="cls-1" d="m14.61,0C6.55,0,0,6.55,0,14.61s6.55,14.61,14.61,14.61,14.61-6.55,14.61-14.61S22.66,0,14.61,0Zm0,1.22c7.38,0,13.39,6.01,13.39,13.39,0,2.19-.54,4.24-1.47,6.07-7.15-4.19-13.44-3.97-17.47-3.03-3.22.75-5.51,2.03-6.54,2.69-.83-1.74-1.3-3.68-1.3-5.73C1.22,7.23,7.23,1.22,14.61,1.22Zm0,26.78c-4.9,0-9.18-2.65-11.51-6.58.91-.59,3.15-1.87,6.3-2.6,5.53-1.27,11.09-.28,16.53,2.91-2.37,3.76-6.55,6.27-11.32,6.27Z" /><path class="cls-1" d="m14.4,15.43c3.38,0,6.12-2.75,6.12-6.12s-2.75-6.12-6.12-6.12-6.12,2.75-6.12,6.12,2.75,6.12,6.12,6.12Zm0-11.03c2.71,0,4.91,2.2,4.91,4.91s-2.2,4.91-4.91,4.91-4.91-2.2-4.91-4.91,2.2-4.91,4.91-4.91Z" /></g></g></svg>
                            <div style={{ fontSize: 12, textAlign: "center" }}>{this.getText(13050)}</div>
                        </div>
                        : ""}
                    {isStyle3 || isStyle4 || isStyle5 ? this.fieldStyle(13091, 'dName', 12) : ""}
                    {isStyle3 || isStyle4 || isStyle5 ? this.fieldStyle(13092, 'dPhone', 12) : ""}
                    {nAddress ? this.addressStyle() : ''}
                    {nAddress ? this.fieldStyle(13096, 'dEntrance', 3) : ''}
                    {nAddress ? this.fieldStyle(13097, 'dFloor', 3) : ''}
                    {nAddress ? this.fieldStyle(13098, 'dApartement', 3) : ''}
                    {isStyle3 || isStyle4 || isStyle5 ? "" : this.fieldStyle(13091, 'dName', 12)}
                    {isStyle3 || isStyle4 || isStyle5 ? "" : this.fieldStyle(13092, 'dPhone', 12)}
                    {this.props.isFromPos && siteSettings.pBranchSelectInPos != '1' ? '' : [
                        this.fieldStyle(13549, 'dBranch', nAddress && this.props.data[this.setting_id].pDeliveryCalcType == '1' ? 12 : 24),
                        nAddress && this.props.data[this.setting_id].pDeliveryCalcType == '1' ? this.fieldStyle(19758, 'dAreaId', 12) : "",
                    ]}
                    {this.futureOrder()}
                    {this.fieldStyle(13099, 'dRemarks', 24)}
                </Row>
                {this.dQtnFields()}
                {isStyle4 || isStyle5 ?
                    <div //className={"text_item"}
                        style={{ background: isStyle6 ? "#ddc268" : "#BE1E2D", color: isStyle6 ? "black" : "white", cursor: "pointer", textAlign: "center", fontSize: 22, padding: 8 }}
                        onClick={this.handleOk}>
                        {this.getText(18680)}
                    </div>
                    : ""}
            </div>
            <Modal
                title={this.props.getClassNameStyle("title_block")}
                visible={this.state.modalNotOpen} onCancel={hClose} destroyOnClose={true} width={300}
                footer={[
                    <Button style={buttonsStyle} onClick={hClose}>
                        <span className={"text_item"} style={{ color: isStyle4 ? "white" : siteSettings.rFonts }}>{this.getText(13586)}</span>
                    </Button>
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>
                <div className={"text_item"}>
                    <h2 style={{ textAlign: 'center' }}>{this.getText(13550)}</h2>
                </div>
            </Modal>
            <Modal
                title={this.props.getClassNameStyle("title_block")}
                visible={this.state.modalAreas} onCancel={() => { this.setState({ modalAreas: false }) }} width={800}
                footer={false}>
                <ResponsiveTable
                    tableOnly
                    dataSource={this.props.data[this.areas_id].dataset}
                    columns={[
                        { title: this.getText(19738), dataIndex: "_Name", key: "_Name", width: '30%', render: t => t },
                        { title: this.getText(19739), dataIndex: "_Price", key: "_Price", width: '20%', render: t => t },
                        { title: this.getText(19740), dataIndex: "_Minimum", key: "_Minimum", width: '20%', render: t => t },
                        { title: this.getText(19741), dataIndex: "_Minutes", key: "_Minutes", width: '20%', render: t => t },
                        { title: "", width: '10%', render: (t, r) => (<Button type="primary" onClick={() => { this.onChangeArea(r) }}>{this.getText(19759)}</Button>) },
                    ]}
                    pagination={false}
                    scroll={{ y: window.innerHeight - 200 }}
                />
            </Modal>
        </Modal>)

    }
}

export default DeliveryTypeFields;