/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import KdsCustView from "./KdsCustView";
import KdsCheckerView from "./KdsCheckerView";
import KdsBonimView from "./KdsBonimView";
import KdsCustLink from "./KdsCustLink";
import KdsCarList from "./KdsCarList";
import ClockOn from "../../components/ClockOn";

class KdsView extends Component {

    height = this.props.height ? this.props.height : window.innerHeight;

    getViewData = () => {
        if (this.props.kdsData) {
            switch (this.props.kdsData.sDispType) {
                case '1': return (<KdsCustView {...this.props} />)
                case '2': return (<KdsCheckerView {...this.props} />)
                case '3': return (<KdsBonimView {...this.props} />)
                case '4': return (<KdsCustLink {...this.props} />)
                case '5': return (<KdsBonimView {...this.props} isShort />)
                case '6': return (<KdsCarList {...this.props} />)

            }
        }
    }

    render() {
        let background = 'auto';
        const sBackgroundImage = this.props.kdsData ? this.props.kdsData.sBackgroundImage : null;
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        let originUrl = isDev ?"http://147.235.163.248" : origin;

        if (sBackgroundImage) {
            background = 'url(' + originUrl + '/uploads/' + this.props.companyCode + '/' + sBackgroundImage + ')';
            console.log('formkds', background, sBackgroundImage)
        }

        console.log("formkds", this.props)

        return (<div style={this.props.isFromConfig ? { width: this.props.width, height: this.props.height } : {}}>
            <div style={{
                backgroundImage: background,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                opacity: 0.2,
                height: this.props.isFromConfig ? this.props.height : "100%",
                width: this.props.isFromConfig ? this.props.width : '100%',
                position: 'fixed',
            }} />
            <div style={{ height: this.height * 0.1 }}>
            {  this.props.logo? ( <img src={this.props.logo} alt={'logo'} height={this.height * 0.2} />):""}
                <div style={{
                    // borderRadius: this.height * 0.02,
                    // padding: this.height * 0.01,
                    fontSize: this.height * 0.035,
                    float: 'left',
                }}>
                    <ClockOn />
                </div>
            </div>

            {this.getViewData()}
        </div>)
    }
}

export default KdsView;