/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Row, Col, Card, Icon, Button, Spin, Input, message } from "antd";
//import clearIcon from "../../skins/index/clear.png"; /* removed by oz */
import clearIcon from "../../skins/index/clear_white.png"; /* add by oz */
import logo_autosoft from "../../skins/index/logo_white.png"; /* add by oz */

type Props = {
    kdsData: Object,
    noDataText: String,
    sendAPI: Function,
    setJson: Function,
    data: Object,
    height: Number,
}

class KdsCustLink extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            num0: null,
            num1: null,
            num2: null,
            num3: null,
            num4: null,
            num5: null,
            num6: null,
            num7: null,
            numActive: 0,
            renderStart: false,
            sendStatus: null,
            fullNumStyle1: null,
        };

        this.api = "create_random_connection_token";
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ renderStart: true })
        }, 3000);
    }

    sendNumber = () => {
        this.setState({ sendStatus: 'wait' }, () => {
            const { num0, num1, num2, num3, num4, num5, num6, num7 } = this.state;
            let value = '05' + num0 + num1 + num2 + num3 + num4 + num5 + num6 + num7;
            let params = "mobile=" + value + "&pos=999";
            let dataSend = "_type\f_params\f_mobile\f_lang\r1\f" + params + "\f" + value + "\f" + this.props.getText(100);
            this.props.sendAPI(this.api, dataSend, () => {
                this.setState({ sendStatus: 'success' }, this.refresh10s);
            }, (e) => {
                this.setState({ sendStatus: 'error' }, this.refresh10s);
            });
        })
    }

    refresh10s = () => {
        if (!this.props.isFromQRCode) {
            setTimeout(() => {
                this.setState({
                    num0: null,
                    num1: null,
                    num2: null,
                    num3: null,
                    num4: null,
                    num5: null,
                    num6: null,
                    num7: null,
                    numActive: 0,
                    renderStart: false,
                    sendStatus: null,
                })
            }, 10000);
        }
    }

    getKeyboardBtn = (num) => {
        const { numActive } = this.state;
        let fontSize = this.props.height / 20;
        let text = num;
        let disabled = numActive > 7;
        if (num === 'next') {
            text = this.props.getText(16342);
            disabled = numActive <= 7;
        }
        if (num === 'clear') {
            // text = (<Icon type="close-circle" />)
            disabled = numActive < 1;
            text = (<img src={clearIcon} style={{ width: "75%", opacity: disabled ? 0.2 : 1 }} />)
        }
        return (<Col span={8}>
            <Button
                style={{
                    color: 'white', /* added by oz */
                    background: '#e50018', // '#FAEBD7', /* changed by oz */
                    fontWeight: 'bold',
                    fontSize,
                    height: fontSize * 2,
                    width: fontSize * 3,
                    marginBottom: fontSize / 5
                }}
                disabled={disabled}
                onClick={() => {
                    if (num === 'next') {
                        this.sendNumber();
                    } else if (num === 'clear') {
                        this.setState({
                            ['num' + numActive]: null,
                            ['num' + (numActive - 1)]: null,
                            numActive: numActive - 1
                        })
                    } else {
                        this.setState({
                            ['num' + numActive]: num,
                            numActive: numActive + 1
                        })
                    }
                }}
            >{text}</Button>
        </Col>)
    }

    getScreen = () => {

        if (this.props.formStyle == '1') {
           
            return (<div style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: 'bold',
                position: "absolute",
                top: this.props.paddingTop,
                width: "100%",
            }}>
                טלפון נייד:
                <br />
                <Input
                    style={ {
                        borderRadius: 10,
                        backgroundColor: "#C4A484",
                        width: 250,
                        height: 40,
                        color: 'white',
                        fontSize: 25,
                        textAlign: "center",
                        border: "0px"
                    }}
                    value={this.state.fullNumStyle1}
                    onChange={e=>{this.setState({fullNumStyle1:e.target.value})}}
                    onPressEnter={e=>{
                        let numToSend = this.state.fullNumStyle1?this.state.fullNumStyle1.split("-").join(""):"";
                        let regexpTel = new RegExp(/^05\d\d{7}$/)
                        if(!regexpTel.test(numToSend)){
                            message.error("מספר נייד לא תקין")
                        }else{
                            let params = "mobile=" + numToSend + "&pos=999";
                            let dataSend = "_type\f_params\f_mobile\f_lang\r1\f" + params + "\f" + numToSend + "\f" + this.props.getText(100);
                            this.props.sendAPI(this.api, dataSend, () => {
                                message.success("הקישור נשלח בהצלחה")
                                this.setState({ fullNumStyle1: '' });
                            }, (e) => {
                                message.error("השליחה נכשלה")
                            });
                        }
                    }}
                    />
            </div>)
        }

        const isTextLoad = this.props.getText(16341).indexOf('[') === -1;
        if (!isTextLoad) {
            return <Spin />
        }
        let status = this.state.sendStatus;
        let fontSize = this.props.height / 20;
        const boxFactor = 1.5;  /* added by oz */

        let boxStyle = {
            color: 'black', /* added by oz */
            background: '#FFFFFF',
            fontSize: fontSize * (window.innerWidth > 600 ? 1.5 : boxFactor * 0.5),
            height: fontSize * (window.innerWidth > 600 ? 3 : boxFactor * 1),
            width: fontSize * (window.innerWidth > 600 ? 3 : boxFactor * 1),
            margin: fontSize / (window.innerWidth > 600 ? 8 : 24),
            marginTop: 0,
            marginBottom: 0,
            padding: fontSize / (window.innerWidth > 600 ? 8 : 24),
            display: 'inline-block',
            verticalAlign: 'text-top',
            fontWeight: 'bold',
            border: '2px solid pink',
        }

        let keyboardStyle = {
            background: '#FFFFFF',
            height: fontSize * 9.2,
            width: fontSize * 10,
            margin: 'auto',
            marginTop: fontSize,
            padding: fontSize / 4,
            border: '2px solid pink',
            borderRadius: fontSize / 4
        }

        const autosoftLinkStyle = { width: 340, marginTop: 60, float: "left", textAlign: "center", position: "absolute", bottom: 24 }
        const autosoftLink = (<div style={autosoftLinkStyle}>
            <a href="https://www.autosoft.co.il/" target="_blank">
                <img style={{ width: 80, backgroundColor: "auto" }} src={logo_autosoft} />
                <div style={{ color: 'black', fontSize: 8 }}>{this.props.getText(14141)}</div>
            </a>
        </div>)

        switch (status) {
            case 'wait':
                return (<div style={{ paddingTop: fontSize * 5 }}><Spin size={100} /></div>);
            case 'success':
                return (<div style={{ paddingTop: fontSize * 5 }}>
                    <h1>{this.props.getText(16345)}</h1>
                    <h2>{this.props.getText(16346)}</h2>
                </div>)
            case 'error':
                return (<div style={{ paddingTop: fontSize * 5 }}>
                    <h1>{this.props.getText(16347)}</h1>
                    <h2>{this.props.getText(16348)}</h2>
                </div>)
            default:
                return (<div>
                    <h1 style={{ color: 'black' }}/* added by oz */ >{this.props.getText(16341)}</h1>
                    <div style={{ direction: 'ltr' }}>
                        <div style={boxStyle}>0</div>
                        <div style={boxStyle}>5</div>
                        <div style={{ ...boxStyle, border: '2px solid ' + (this.state.numActive == 0 ? '#00FFFF' : 'pink') }}>
                            {this.state.num0}
                        </div>
                        <div style={{ ...boxStyle, /*background: '#FAEBD7', border: '0px' *//* removed by oz */ }}>-</div>
                        {/* {window.innerWidth < 600 ? <br /> : ""} */ /* removed by oz */}
                        {[1, 2, 3, 4, 5, 6, 7].map(x => {
                            let isActive = this.state.numActive == x;
                            return (<div style={{ ...boxStyle, border: '2px solid ' + (isActive ? '#00FFFF' : 'pink') }}>
                                {this.state['num' + x]}
                            </div>)
                        })}
                    </div>
                    <div style={keyboardStyle}>
                        <Row>{[3, 2, 1].map(x => this.getKeyboardBtn(x))}</Row>
                        <Row>{[6, 5, 4].map(x => this.getKeyboardBtn(x))}</Row>
                        <Row>{[9, 8, 7].map(x => this.getKeyboardBtn(x))}</Row>
                        <Row>{['next', 0, 'clear'].map(x => this.getKeyboardBtn(x))}</Row>
                    </div>
                    <div>{autosoftLink}</div>
                </div>)
        }
    }


    render() {
        let fontSize = this.props.formStyle == '1' ? 0 : this.props.height / (window.innerWidth > 600 ? 20 : 50);

        let contentStyle = {
            fontSize,
            borderRadius: fontSize,
            paddingRight: fontSize * (window.innerWidth > 600 ? 3 : 1),
            paddingLeft: fontSize * (window.innerWidth > 600 ? 3 : 1),
            paddingTop: fontSize,
            margin: fontSize,
            height: fontSize * (window.innerWidth > 600 ? 20 : 50),
            textAlign: 'center',
            //background: '#FAEBD7', /* removed by oz */
            //border: '2px solid pink' /* removed by oz */
        }

        return (<div style={contentStyle}>{this.getScreen()}</div>)
    }
}

export default KdsCustLink;